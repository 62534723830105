import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { API_URL } from "../../actions/settings";
import Ae2Header from "../../components/AE2/Ae2Header";
import "./REAPRSystemStatus.css";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import { isStaff } from "../../components/extras/Functions";
import { Redirect } from "react-router";

async function getREAPRStatus(token: any) {
  return fetch(`${API_URL}/reapr/reapr_status`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const handleGetData = async (token: any, setStatusList: any) => {
  const res = await getREAPRStatus(token);
  setStatusList(res.data);
};

const REAPRSystemStatus: React.FC = () => {
  const user = useAppSelector(selectUser);
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    const tkn: any = localStorage.getItem("token");
    const token = `Token ${tkn.slice(1, -1)}`;
    handleGetData(token, setStatusList);
  }, []);

  if (user.first_name !== "" && !isStaff(user)) {
    return <Redirect to="/403" />;
  }

  return (
    <IonPage className="ae2-home">
      <Ae2Header />
      <IonContent className="main-background">
        <IonRow className="ion-justify-content-center">
          <h1 className="main-heading reapr-system-title">
            REAPR System Status
          </h1>
        </IonRow>
        <IonRow className="reapr-table-row">
          {isMobile ? (
            <div className="reapr-table-mobile">
              <IonItem
                className="top-row"
                slot="header"
                fill="outline"
                color="medium"
              >
                <IonLabel>Customer - State</IonLabel>
              </IonItem>
              {statusList.map((item) => {
                return (
                  <IonAccordionGroup
                    key={item["cust_uuid"]}
                    className={
                      item["reapr_current_comm_state"] === "Offline"
                        ? "alert-red reapr-accordion"
                        : "reapr-accordion"
                    }
                  >
                    <IonAccordion value="first">
                      <IonItem slot="header" fill="outline">
                        <IonLabel>
                          <a href={`/?id=${item["cust_uuid"]}&sec=customers`}>
                            {item["cust_name"]}
                          </a>{" "}
                          - {item["reapr_current_comm_state"]}
                        </IonLabel>
                      </IonItem>
                      <div slot="content">
                        <ul>
                          <li>
                            Technical Solutions Area: {item["alpha_ts_area"]}
                          </li>
                          <li>
                            Current Comm State:{" "}
                            {item["reapr_current_comm_state"]}
                          </li>
                          <li>
                            Last Comm Timestamp:{" "}
                            {item["reapr_last_comm_timestamp"]}
                          </li>
                          <li>
                            Hours Since Last Comm:{" "}
                            {item["hours_since_last_comm"]}
                          </li>
                          <li>Offline Count: {item["offline_count"]}</li>
                          <li>
                            Last Offline Timestamp:{" "}
                            {item["last_offline_timestamp"]}
                          </li>
                        </ul>
                      </div>
                    </IonAccordion>
                  </IonAccordionGroup>
                );
              })}
            </div>
          ) : (
            <div
              style={{ width: "100%", overflowX: "auto" }}
              className="reapr-table"
            >
              <div
                className="reapr-table-container"
                style={{ minWidth: "875px" }}
              >
                <IonRow>
                  <IonCol className="heading-col">Customer</IonCol>
                  <IonCol className="heading-col">
                    Technical Solutions Area
                  </IonCol>
                  {/* <IonCol className="heading-col">Current REAPR Comm Status</IonCol> */}
                  <IonCol className="heading-col">Current Comm State</IonCol>
                  <IonCol className="heading-col">Last Comm Timestamp</IonCol>
                  <IonCol className="heading-col">Hours Since Last Comm</IonCol>
                  <IonCol className="heading-col">Offline Count</IonCol>
                  <IonCol className="heading-col">
                    Last Offline Timestamp
                  </IonCol>
                </IonRow>
                {statusList.map((item) => {
                  return (
                    <IonRow
                      className="reapr-status-row"
                      key={item["cust_uuid"]}
                    >
                      <IonCol
                        className={
                          item["reapr_current_comm_state"] === "Offline"
                            ? "alert-red customer-name"
                            : " customer-name"
                        }
                        style={{ textAlign: "center" }}
                      >
                        <a href={`/?id=${item["cust_uuid"]}&sec=customers`}>
                          {item["cust_name"]}
                        </a>
                      </IonCol>
                      <IonCol
                        className={
                          item["reapr_current_comm_state"] === "Offline"
                            ? "alert-red customer-name"
                            : " customer-name"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {item["alpha_ts_area"]}
                      </IonCol>
                      {/* <IonCol className={item['reapr_current_comm_state'] === "Offline" ? 'alert-red customer-name' : ' customer-name'}style={{textAlign:'center'}}>{item['reapr_current_comm_status']}</IonCol> */}
                      <IonCol
                        className={
                          item["reapr_current_comm_state"] === "Offline"
                            ? "alert-red customer-name"
                            : " customer-name"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {item["reapr_current_comm_state"]}
                      </IonCol>
                      <IonCol
                        className={
                          item["reapr_current_comm_state"] === "Offline"
                            ? "alert-red customer-name"
                            : " customer-name"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {item["reapr_last_comm_timestamp"]}
                      </IonCol>
                      <IonCol
                        className={
                          item["reapr_current_comm_state"] === "Offline"
                            ? "alert-red customer-name"
                            : " customer-name"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {item["hours_since_last_comm"]}
                      </IonCol>
                      <IonCol
                        className={
                          item["reapr_current_comm_state"] === "Offline"
                            ? "alert-red customer-name"
                            : " customer-name"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {item["offline_count"]}
                      </IonCol>
                      <IonCol
                        className={
                          item["reapr_current_comm_state"] === "Offline"
                            ? "alert-red customer-name"
                            : " customer-name"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {item["last_offline_timestamp"]}
                      </IonCol>
                    </IonRow>
                  );
                })}
              </div>
            </div>
          )}
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default REAPRSystemStatus;
