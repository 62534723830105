import { IonContent, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import { API_URL } from "../../../actions/settings";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import QuicksightEmbed from "../../extras/QuicksightEmbed";
import { isStaff } from "../../extras/Functions";
import { Redirect } from "react-router";

interface Props {
  id?:string |null,
  singleBuilding?:Boolean
}

async function getDashUrl(id: any, token: string) {
  return fetch(`${API_URL}/buildings/getDashUrl/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

async function getBuildingData(token:any,id:string) {
  return fetch(`${API_URL}/buildings/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
}

const CostAvoidanceDashboard:React.FC<Props> = ({id,singleBuilding=false}) => {
  const user = useAppSelector(selectUser);

  useEffect(() => {
    const handleBuilding = async () =>{
      const token = localStorage.getItem("token") || "";
      const res = await getBuildingData(`Token ${token.slice(1,-1)}`,id||'')
      return res.data
    }

    const handleDash = async (dashID: string,sheet:string,) => {
      const dashboard = new QuicksightEmbed();
      await dashboard.init();
      const token = localStorage.getItem("token") || "";
      const res = await getDashUrl(dashID, `Token ${token.slice(1, -1)}`);
      if(singleBuilding){
        dashboard.changeSettings({
          url: res[0],
          container: `#constAvoidanceDash`,
          exportDash: false,
          undoRedo: false,
          reset: false,
          sheetID:sheet
        });
        const building = await handleBuilding()
        dashboard.setParameters({
          PropertyId:building.baseline.property.id,
          CostAvoidanceDate:`${building.baseline.score.year}-${building.baseline.score.month}-1`,
          baselineDate:`${building.baseline.year}-${building.baseline.month}-1`,
          SavingsDate:building.co_start_date,
        })
      }
      else{
        dashboard.changeSettings({
          url: res[0],
          container: `#constAvoidanceDash`,
          exportDash: true,
          undoRedo: true,
          reset: true,
          sheetID:sheet
        });
      }
      await dashboard.embedDash();
    };


    if (isStaff(user) && !singleBuilding) {
      handleDash("955e18fc-9f61-4b8a-ab7f-d352f47c30f3","955e18fc-9f61-4b8a-ab7f-d352f47c30f3_f856de51-f167-45a3-b520-a12b75c8b62a");
    }
    if (singleBuilding){
      handleDash("955e18fc-9f61-4b8a-ab7f-d352f47c30f3","955e18fc-9f61-4b8a-ab7f-d352f47c30f3_cd6a72e7-8f29-4952-b75f-fcd0acffb0f3")
    }
  }, [id,user,singleBuilding]);

  if (user.first_name !== "" && !isStaff(user)) {
    return <Redirect to="/403" />;
  }

  return (
    <>
      <IonRow hidden={singleBuilding?true:false} className="ion-justify-content-center">
        <span className="main-heading">Cost Avoidance Dashboard</span>
      </IonRow>
      <IonContent id="constAvoidanceDash"></IonContent>
    </>
  );
};

export default CostAvoidanceDashboard;
