import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"


interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
licensedBedCapacity : "",
licensedBedCapacity_date : "",
numberOfResidentialLivingUnits : "",
numberOfResidentialLivingUnits_date : "",
averageNumberOfResidents : "",
averageNumberOfResidents_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
maximumResidentCapacity:"",
maximumResidentCapacity_date:"",
numberOfComputers:"",
numberOfComputers_date:"",
numberOfCommercialRefrigerationUnits:"",
numberOfCommercialRefrigerationUnits_date:"",
numberOfCommercialWashingMachines:"",
numberOfCommercialWashingMachines_date:"",
numberOfResidentialWashingMachines:"",
numberOfResidentialWashingMachines_date:"",
numberOfResidentialLiftSystems:"",
numberOfResidentialLiftSystems_date:"",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : ""
}


const startErrors = {
totalGrossFloorArea : "Field Required",
totalGrossFloorArea_units : "Select Units",
totalGrossFloorArea_date : "Date is required",
licensedBedCapacity : "",
licensedBedCapacity_date : "",
numberOfResidentialLivingUnits : "",
numberOfResidentialLivingUnits_date : "",
averageNumberOfResidents : "",
averageNumberOfResidents_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
maximumResidentCapacity:"",
maximumResidentCapacity_date:"",
numberOfComputers:"",
numberOfComputers_date:"",
numberOfCommercialRefrigerationUnits:"",
numberOfCommercialRefrigerationUnits_date:"",
numberOfCommercialWashingMachines:"",
numberOfCommercialWashingMachines_date:"",
numberOfResidentialWashingMachines:"",
numberOfResidentialWashingMachines_date:"",
numberOfResidentialLiftSystems:"",
numberOfResidentialLiftSystems_date:"",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : ""
}

const ResidentialCare: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])
    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('licensedBedCapacity'):
                if(value !==''){
                    form.licensedBedCapacity_date === '' ? handleError('licensedBedCapacity_date','Date cannot be empty') : handleError('licensedBedCapacity_date','')
                    value < 0 ? handleError('licensedBedCapacity','Must be a Positive number') : handleError('licensedBedCapacity','')
                }
                else{
                    handleError('licensedBedCapacity','')
                    handleError('licensedBedCapacity_date','')
                }
                break;
            case('licensedBedCapacity_date'):
                if(value !==''){
                    form.licensedBedCapacity === '' ? handleError('licensedBedCapacity','Field cannot be empty') : handleError('licensedBedCapacity','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('licensedBedCapacity_date','Invalid Date') : handleError('licensedBedCapacity_date','')
                }
                else{
                    handleError('licensedBedCapacity','')
                    handleError('licensedBedCapacity_date','')
                }
                break;
            case('numberOfResidentialLivingUnits'):
                if(value !==''){
                    form.numberOfResidentialLivingUnits_date === '' ? handleError('numberOfResidentialLivingUnits_date','Date cannot be empty') : handleError('numberOfResidentialLivingUnits_date','')
                    value < 0 ? handleError('numberOfResidentialLivingUnits','Must be a Positive number') : handleError('numberOfResidentialLivingUnits','')
                }
                else{
                    handleError('numberOfResidentialLivingUnits','')
                    handleError('numberOfResidentialLivingUnits_date','')
                }
                break;
            case('numberOfResidentialLivingUnits_date'):
                if(value !==''){
                    form.numberOfResidentialLivingUnits === '' ? handleError('numberOfResidentialLivingUnits','Field cannot be empty') : handleError('numberOfResidentialLivingUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfResidentialLivingUnits_date','Invalid Date') : handleError('numberOfResidentialLivingUnits_date','')
                }
                else{
                    handleError('numberOfResidentialLivingUnits','')
                    handleError('numberOfResidentialLivingUnits_date','')
                }
                break;
            case('averageNumberOfResidents'):
                if(value !==''){
                    form.averageNumberOfResidents_date === '' ? handleError('averageNumberOfResidents_date','Date cannot be empty') : handleError('averageNumberOfResidents_date','')
                    value < 0 ? handleError('averageNumberOfResidents','Must be a Positive number') : handleError('averageNumberOfResidents','')
                }
                else{
                    handleError('averageNumberOfResidents','')
                    handleError('averageNumberOfResidents_date','')
                }
                break;
            case('averageNumberOfResidents_date'):
                if(value !==''){
                    form.averageNumberOfResidents === '' ? handleError('averageNumberOfResidents','Field cannot be empty') : handleError('averageNumberOfResidents','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('averageNumberOfResidents_date','Invalid Date') : handleError('averageNumberOfResidents_date','')
                }
                else{
                    handleError('averageNumberOfResidents','')
                    handleError('averageNumberOfResidents_date','')
                }
                break;
            case('numberOfWorkers'):
                if(value !==''){
                    form.numberOfWorkers_date === '' ? handleError('numberOfWorkers_date','Date cannot be empty') : handleError('numberOfWorkers_date','')
                    value < 0 ? handleError('numberOfWorkers','Must be a Positive number') : handleError('numberOfWorkers','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('numberOfWorkers_date'):
                if(value !==''){
                    form.numberOfWorkers === '' ? handleError('numberOfWorkers','Field cannot be empty') : handleError('numberOfWorkers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWorkers_date','Invalid Date') : handleError('numberOfWorkers_date','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('maximumResidentCapacity'):
                if(value !==''){
                    form.maximumResidentCapacity_date === '' ? handleError('maximumResidentCapacity_date','Date cannot be empty') : handleError('maximumResidentCapacity_date','')
                    value < 0 ? handleError('maximumResidentCapacity','Must be a Positive number') : handleError('maximumResidentCapacity','')
                }
                else{
                    handleError('maximumResidentCapacity','')
                    handleError('maximumResidentCapacity_date','')
                }
                break;
            case('maximumResidentCapacity_date'):
                if(value !==''){
                    form.maximumResidentCapacity === '' ? handleError('maximumResidentCapacity','Field cannot be empty') : handleError('maximumResidentCapacity','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('maximumResidentCapacity_date','Invalid Date') : handleError('maximumResidentCapacity_date','')
                }
                else{
                    handleError('maximumResidentCapacity','')
                    handleError('maximumResidentCapacity_date','')
                }
                break;
            case('numberOfComputers'):
                if(value !==''){
                    form.numberOfComputers_date === '' ? handleError('numberOfComputers_date','Date cannot be empty') : handleError('numberOfComputers_date','')
                    value < 0 ? handleError('numberOfComputers','Must be a Positive number') : handleError('numberOfComputers','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case('numberOfComputers_date'):
                if(value !==''){
                    form.numberOfComputers === '' ? handleError('numberOfComputers','Field cannot be empty') : handleError('numberOfComputers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfComputers_date','Invalid Date') : handleError('numberOfComputers_date','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case('numberOfCommercialRefrigerationUnits'):
                if(value !==''){
                    form.numberOfCommercialRefrigerationUnits_date === '' ? handleError('numberOfCommercialRefrigerationUnits_date','Date cannot be empty') : handleError('numberOfCommercialRefrigerationUnits_date','')
                    value < 0 ? handleError('numberOfCommercialRefrigerationUnits','Must be a Positive number') : handleError('numberOfCommercialRefrigerationUnits','')
                }
                else{
                    handleError('numberOfCommercialRefrigerationUnits','')
                    handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                break;
            case('numberOfCommercialRefrigerationUnits_date'):
                if(value !==''){
                    form.numberOfCommercialRefrigerationUnits === '' ? handleError('numberOfCommercialRefrigerationUnits','Field cannot be empty') : handleError('numberOfCommercialRefrigerationUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfCommercialRefrigerationUnits_date','Invalid Date') : handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                else{
                    handleError('numberOfCommercialRefrigerationUnits','')
                    handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                break;
            case('numberOfCommercialWashingMachines'):
                if(value !==''){
                    form.numberOfCommercialWashingMachines_date === '' ? handleError('numberOfCommercialWashingMachines_date','Date cannot be empty') : handleError('numberOfCommercialWashingMachines_date','')
                    value < 0 ? handleError('numberOfCommercialWashingMachines','Must be a Positive number') : handleError('numberOfCommercialWashingMachines','')
                }
                else{
                    handleError('numberOfCommercialWashingMachines','')
                    handleError('numberOfCommercialWashingMachines_date','')
                }
                break;
            case('numberOfCommercialWashingMachines_date'):
                if(value !==''){
                    form.numberOfCommercialWashingMachines === '' ? handleError('numberOfCommercialWashingMachines','Field cannot be empty') : handleError('numberOfCommercialWashingMachines','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfCommercialWashingMachines_date','Invalid Date') : handleError('numberOfCommercialWashingMachines_date','')
                }
                else{
                    handleError('numberOfCommercialWashingMachines','')
                    handleError('numberOfCommercialWashingMachines_date','')
                }
                break;
            case('numberOfResidentialWashingMachines'):
                if(value !==''){
                    form.numberOfResidentialWashingMachines_date === '' ? handleError('numberOfResidentialWashingMachines_date','Date cannot be empty') : handleError('numberOfResidentialWashingMachines_date','')
                    value < 0 ? handleError('numberOfResidentialWashingMachines','Must be a Positive number') : handleError('numberOfResidentialWashingMachines','')
                }
                else{
                    handleError('numberOfResidentialWashingMachines','')
                    handleError('numberOfResidentialWashingMachines_date','')
                }
                break;
            case('numberOfResidentialWashingMachines_date'):
                if(value !==''){
                    form.numberOfResidentialWashingMachines === '' ? handleError('numberOfResidentialWashingMachines','Field cannot be empty') : handleError('numberOfResidentialWashingMachines','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfResidentialWashingMachines_date','Invalid Date') : handleError('numberOfResidentialWashingMachines_date','')
                }
                else{
                    handleError('numberOfResidentialWashingMachines','')
                    handleError('numberOfResidentialWashingMachines_date','')
                }
                break;
            case('numberOfResidentialLiftSystems'):
                if(value !==''){
                    form.numberOfResidentialLiftSystems_date === '' ? handleError('numberOfResidentialLiftSystems_date','Date cannot be empty') : handleError('numberOfResidentialLiftSystems_date','')
                    value < 0 ? handleError('numberOfResidentialLiftSystems','Must be a Positive number') : handleError('numberOfResidentialLiftSystems','')
                }
                else{
                    handleError('numberOfResidentialLiftSystems','')
                    handleError('numberOfResidentialLiftSystems_date','')
                }
                break;
            case('numberOfResidentialLiftSystems_date'):
                if(value !==''){
                    form.numberOfResidentialLiftSystems === '' ? handleError('numberOfResidentialLiftSystems','Field cannot be empty') : handleError('numberOfResidentialLiftSystems','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfResidentialLiftSystems_date','Invalid Date') : handleError('numberOfResidentialLiftSystems_date','')
                }
                else{
                    handleError('numberOfResidentialLiftSystems','')
                    handleError('numberOfResidentialLiftSystems_date','')
                }
                break;
            case('percentCooled'):
                if(value !==''){
                    form.percentCooled_date === '' ? handleError('percentCooled_date','Date cannot be empty') : handleError('percentCooled_date','')

                }
                else{
                    handleError('percentCooled','')
                    handleError('percentCooled_date','')
                }
                break;
            case('percentCooled_date'):
                if(value !==''){
                    form.percentCooled === '' ? handleError('percentCooled','Field cannot be empty') : handleError('percentCooled','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('percentCooled_date','Invalid Date') : handleError('percentCooled_date','')
                }
                else{
                    handleError('percentCooled','')
                    handleError('percentCooled_date','')
                }
                break;
            case('percentHeated'):
                if(value !==''){
                    form.percentHeated_date === '' ? handleError('percentHeated_date','Date cannot be empty') : handleError('percentHeated_date','')

                }
                else{
                    handleError('percentHeated','')
                    handleError('percentHeated_date','')
                }
                break;
            case('percentHeated_date'):
                if(value !==''){
                    form.percentHeated === '' ? handleError('percentHeated','Field cannot be empty') : handleError('percentHeated','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('percentHeated_date','Invalid Date') : handleError('percentHeated_date','')
                }
                else{
                    handleError('percentHeated','')
                    handleError('percentHeated_date','')
                }
                break;
            default:
                break;
        }
    }

    return(
        <div>
            <h1>Residential Care Facility</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Residential Living units</IonLabel>
                        <IonInput type="number" className={errors.numberOfResidentialLivingUnits === '' ? "valid":'invalid'}  value={form.numberOfResidentialLivingUnits} placeholder='# Residential Living Units' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnits'))}></IonInput>
                        <div className="error-detail">{errors.numberOfResidentialLivingUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfResidentialLivingUnits_date === '' ? "valid":'invalid'}  value={form.numberOfResidentialLivingUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfResidentialLivingUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Average Number of Residents</IonLabel>
                        <IonInput type="number" className={errors.averageNumberOfResidents === '' ? "valid":'invalid'}  value={form.averageNumberOfResidents} placeholder='Average Number of Residents' onIonChange={e => (handleFormChange(e.detail.value!,'averageNumberOfResidents'))}></IonInput>
                        <div className="error-detail">{errors.averageNumberOfResidents}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.averageNumberOfResidents_date === '' ? "valid":'invalid'}  value={form.averageNumberOfResidents_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'averageNumberOfResidents_date'))}></IonInput>
                        <div className="error-detail">{errors.averageNumberOfResidents_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Workers</IonLabel>
                        <IonInput type="number" className={errors.numberOfWorkers === '' ? "valid":'invalid'}  value={form.numberOfWorkers} placeholder='# Workers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWorkers_date === '' ? "valid":'invalid'}  value={form.numberOfWorkers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Maximum Resident Capacity</IonLabel>
                        <IonInput type="number" className={errors.maximumResidentCapacity === '' ? "valid":'invalid'}  value={form.maximumResidentCapacity} placeholder='Maximum Resident Capacity' onIonChange={e => (handleFormChange(e.detail.value!,'maximumResidentCapacity'))}></IonInput>
                        <div className="error-detail">{errors.maximumResidentCapacity}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.maximumResidentCapacity_date === '' ? "valid":'invalid'}  value={form.maximumResidentCapacity_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'maximumResidentCapacity_date'))}></IonInput>
                        <div className="error-detail">{errors.maximumResidentCapacity_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number of Computers</IonLabel>
                        <IonInput type="number" className={errors.numberOfComputers === '' ? "valid":'invalid'}  value={form.numberOfComputers} placeholder='# Computers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfComputers_date === '' ? "valid":'invalid'}  value={form.numberOfComputers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number Of Commercial Refrigeration/Freezer Units</IonLabel>
                        <IonInput type="number" className={errors.numberOfCommercialRefrigerationUnits === '' ? "valid":'invalid'}  value={form.numberOfCommercialRefrigerationUnits} placeholder='# Commercial Refrigeration Units' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfCommercialRefrigerationUnits'))}></IonInput>
                        <div className="error-detail">{errors.numberOfCommercialRefrigerationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfCommercialRefrigerationUnits_date === '' ? "valid":'invalid'}  value={form.numberOfCommercialRefrigerationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfCommercialRefrigerationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfCommercialRefrigerationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number Of Commercial-type Washing Machines</IonLabel>
                        <IonInput type="number" className={errors.numberOfCommercialWashingMachines === '' ? "valid":'invalid'}  value={form.numberOfCommercialWashingMachines} placeholder='# Commercial-type Washing Machines' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfCommercialWashingMachines'))}></IonInput>
                        <div className="error-detail">{errors.numberOfCommercialWashingMachines}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfCommercialWashingMachines_date === '' ? "valid":'invalid'}  value={form.numberOfCommercialWashingMachines_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfCommercialWashingMachines_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfCommercialWashingMachines_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number Of Residential Washing Machines</IonLabel>
                        <IonInput type="number" className={errors.numberOfResidentialWashingMachines === '' ? "valid":'invalid'}  value={form.numberOfResidentialWashingMachines} placeholder='# Residential Washing Machines' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfResidentialWashingMachines'))}></IonInput>
                        <div className="error-detail">{errors.numberOfResidentialWashingMachines}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfResidentialWashingMachines_date === '' ? "valid":'invalid'}  value={form.numberOfResidentialWashingMachines_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfResidentialWashingMachines_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfResidentialWashingMachines_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number Of Residential Electronic Lift Systems</IonLabel>
                        <IonInput type="number" className={errors.numberOfResidentialLiftSystems === '' ? "valid":'invalid'}  value={form.numberOfResidentialLiftSystems} placeholder='# Residential Electronic Lift System' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfResidentialLiftSystems'))}></IonInput>
                        <div className="error-detail">{errors.numberOfResidentialLiftSystems}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfResidentialLiftSystems_date === '' ? "valid":'invalid'}  value={form.numberOfResidentialLiftSystems_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfResidentialLiftSystems_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfResidentialLiftSystems_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Percent Cooled</IonLabel>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentCooled_date === '' ? "valid":'invalid'}  value={form.percentCooled_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></IonInput>
                        <div className="error-detail">{errors.percentCooled_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Percent Heated</IonLabel>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentHeated_date === '' ? "valid":'invalid'}  value={form.percentHeated_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></IonInput>
                        <div className="error-detail">{errors.percentHeated_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Licensed Bed Capacity</IonLabel>
                        <IonInput type="number" className={errors.licensedBedCapacity === '' ? "valid":'invalid'}  value={form.licensedBedCapacity} placeholder='Licensed Bed Capacity' onIonChange={e => (handleFormChange(e.detail.value!,'licensedBedCapacity'))}></IonInput>
                        <div className="error-detail">{errors.licensedBedCapacity}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.licensedBedCapacity_date === '' ? "valid":'invalid'}  value={form.licensedBedCapacity_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'licensedBedCapacity_date'))}></IonInput>
                        <div className="error-detail">{errors.licensedBedCapacity_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default ResidentialCare