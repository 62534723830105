import { IonLabel, IonItem, IonModal, IonTitle, IonInput, IonButton, IonHeader } from "@ionic/react"
import { useState } from "react"
import { API_URL } from "../../../../actions/settings"
import NotificationPopUp from "../../../extras/NotificationPopUp"

interface Props{
    opened:boolean,
    setShowUtilityServiceTypeModal:any,
    setUtilityServiceTypes:any
}

async function addUtilityServiceType(data:any,token:any) {

    return fetch(`${API_URL}/utility_service_type`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }


const startForm = {
    "name": "",
    "urjanet_name": ""
}
const errorsForm:any = {
    "name": "",
    "urjanet_name": ""
}

const ServiceTypeForm:React.FC<Props> = ({opened,setShowUtilityServiceTypeModal,setUtilityServiceTypes}) =>{
    const [form, setForm] = useState(startForm)
    const [errors, setErrors] = useState(errorsForm)

    const validate = (name:string, val:any) => {
        switch (name) {
            case 'name':
                if(val === null || val.length <=1){
                    return {name:'Service Type Name Must Be Longer Than 2 Characters'}
                }
                else{
                    return {name:''}
                }

            case 'urjanet_name':
                if(val === null || val.length <=1 ){
                    return {urjanet_name:'Service Type Urjanet Name Must Be Longer Than 2 Characters'}
                }
                else{
                    return {urjanet_name:''}
                }
            default:
                break;
        }
    }

    const validateAll = (form:any) => {
        let validation = errorsForm
        let valid = true
        for (let item in form){

            validation = {...validation, ...validate(item,form[item])}

        }
        setErrors({...errors,...validation})

        for(let val in validation){
            if (validation[val] !== ''){
                valid = false;
            }

        }

        return valid
        }

    const handleFormChange = (value:any,name:string) => {
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
    }

    const handleSubmit = async (form:any) => {
        const token:any = localStorage.getItem('token')
        const valid = validateAll(form) || false
        if(valid){
        const res = await addUtilityServiceType(form,`Token ${token.slice(1,-1)}`)
            if (res.status === "success"){
                setForm(startForm)
                setShowUtilityServiceTypeModal(false)
                setUtilityServiceTypes((prevState:any) => {
                    return [
                        ...prevState,
                        res.data
                    ]
                })
                NotificationPopUp('success','Service Type Added')

            }
            else{
                NotificationPopUp('Error',res.detail||res.ERROR,res.error)
            }
        }
    }

    return (
        <IonModal id="serviceTypeModal" isOpen={opened} onDidDismiss={()=>setShowUtilityServiceTypeModal(false)}>
                <IonHeader><IonTitle>Add Service Type Form</IonTitle></IonHeader>
                    <form>
                        <IonItem>
                            <IonLabel position="stacked"> Service Type Name:</IonLabel>
                            <IonInput className={errors.name === '' ? "valid":'invalid'} value={form.name} placeholder="Service Type Name" onIonChange={(e)=> handleFormChange(e.detail.value!,'name')}></IonInput>
                            <div className="error-detail">{errors.name}</div>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked"> Service Type Urjanet Name:</IonLabel>
                            <IonInput className={errors.urjanet_name === '' ? "valid":'invalid'} value={form.urjanet_name} placeholder="Service Type Urjanet Name" onIonChange={(e)=> handleFormChange(e.detail.value!,'urjanet_name')}></IonInput>
                            <div className="error-detail">{errors.urjanet_name}</div>
                        </IonItem>
                    </form>
                    <div className='buttons'>
                    <IonButton onClick={()=>handleSubmit(form)} >Add</IonButton>
                    <IonButton onClick={()=>setShowUtilityServiceTypeModal(false)} color='danger'>cancel</IonButton>
                    </div>
            </IonModal>
    )
}

export default ServiceTypeForm