import { IonPage, IonContent } from "@ionic/react"
import { useEffect } from "react"
import { API_URL } from "../../actions/settings"
import Ae2Header from "../../components/AE2/Ae2Header"
import QuicksightEmbed from "../../components/extras/QuicksightEmbed"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import { isStaff } from "../../components/extras/Functions"
import { Redirect } from "react-router"

async function getDashUrl(token:string) {
    return fetch(`${API_URL}/buildings/getDashUrl/13aff1fa-e3c2-4bd1-a031-2b89d89eabe2`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
     }



const REAPRHomepage: React.FC = () => {

    const user = useAppSelector(selectUser)


    const handleDash = async () =>{
        const token = localStorage.getItem('token') || ""
        const dashboard = new QuicksightEmbed()
        await dashboard.init()
        const res = await getDashUrl(`Token ${token.slice(1,-1)}`)
        dashboard.changeSettings({url:res[0],container:`#reapr-dashboard`,exportDash:true,undoRedo:true,reset:true})
        await dashboard.embedDash()

    }

    useEffect(()=>{
        handleDash()
    },[])

    if(user.first_name !== '' && !isStaff(user)){
        return <Redirect to='/403'/>
    }

    return (
        <IonPage className='ae2-home' >
            <Ae2Header/>
            <IonContent id="reapr-dashboard"></IonContent>
        </IonPage>
    )
}

export default REAPRHomepage