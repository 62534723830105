import React, { useState } from "react";
import {
  IonCol,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonRow,
} from "@ionic/react";
import { arrowForwardCircle } from "ionicons/icons";
import { API_URL } from "../../actions/settings";
import NotificationPopUp from "../extras/NotificationPopUp";
import { UnexpectedError } from "../../pages/Errors/Errors";

interface Props {
  email: string;
  setTab: any;
  setValidOTP: any;
}

async function verifyOTP(data: any) {
  return fetch(`${API_URL}/verify_email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        return "ok";
      } else if (res.status === 406) {
        return res.json();
      } else {
        throw new UnexpectedError();
      }
    })
    .catch((error) => {
      NotificationPopUp("error", error.message, "Could not verify email", 1200);
      return null;
    });
}

const OTPForm: React.FC<Props> = ({ email, setTab, setValidOTP }) => {
  const [OTP, setOTP] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    const res = await verifyOTP({
      email: email,
      token: OTP,
    });

    if (res === "ok") {
      setValidOTP(OTP);
      setOTP("");
      setTab("NewPassword");
      NotificationPopUp("success", "Email Verified");
    } else {
      setError(res.error || res);
    }
  };

  return (
    <>
      <IonRow className="ion-justify-content-center">
        <IonCol>
          <h4>
            {" "}
            Verify its you with the One-Time-Passcode sent to your email.
          </h4>
          <h5 className="error" hidden={!error}>
            {error}
          </h5>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonItem style={{ width: "100%" }}>
          <IonInput
            type="number"
            label="OTP"
            onIonInput={(e: any) => setOTP(e.detail.value!)}
          />
          <IonButton onClick={handleSubmit}>
            <IonIcon icon={arrowForwardCircle} />
          </IonButton>
        </IonItem>
      </IonRow>
      <IonRow
        className="ion-justify-content-center"
        style={{ width: "100%", marginTop: "15px" }}
      >
        <IonRow
          style={{ width: "100%" }}
          className="ion-justify-content-center"
        >
          <a style={{ color: "white" }} href="/login">
            Login
          </a>
        </IonRow>
        <IonRow
          style={{ width: "100%", marginTop: "5px" }}
          className="ion-justify-content-center"
        >
          <div onClick={() => setTab("email")} className="div-link">
            Go Back
          </div>
        </IonRow>
      </IonRow>
    </>
  );
};

export default OTPForm;
