
const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

interface settings {
url: string,
container: string,
singleSheet?: boolean,
height?: string,
width?: string,
exportDash?: boolean,
undoRedo?: boolean,
reset?: boolean,
resizeHeight?: boolean,
sheetID?:string | null
}


class QuicksightEmbed{
    frameOptions : any
    contentOptions : any
    dash : any

    constructor(){
        this.frameOptions = {
            url: "",
            container: "",
            height:"100%",
            width:"100%",
            withIframePlaceholder: true,
            resizeHeightOnSizeChangedEvent: false
        };
        this.contentOptions = {
            locale: 'en-US',
            parameters:[],
            toolbarOptions: {
              export: false,
              undoRedo: false,
              reset: false
            },
            fitToIframeWidth:true,
            sheetOptions:{
              singleSheet:true
            }

          }
    }

    public async init(){
        const embeddingContext = await QuickSightEmbedding.createEmbeddingContext()
        const {embedDashboard} = embeddingContext
        this.dash = embedDashboard
    }

    async embedDash(){
        return await this.dash(this.frameOptions,this.contentOptions)
    }

    changeSettings({url,container,singleSheet,height,width,exportDash=false, undoRedo=false,reset=false, resizeHeight=false, sheetID=null} : settings){
        this.frameOptions.url = url
        this.frameOptions.container = container
        this.contentOptions.toolbarOptions.export = exportDash
        this.contentOptions.toolbarOptions.undoRedo = undoRedo
        this.contentOptions.toolbarOptions.reset = reset
        if(singleSheet){
            this.contentOptions.sheetOptions.singleSheet=singleSheet
        }
        if(sheetID){
            this.contentOptions.sheetOptions.initialSheetId =  sheetID
        }
        if(height){
            this.frameOptions.height=height

        }
        if(width){
            this.frameOptions.width=width
        }
        this.contentOptions.resizeHeightOnSizeChangedEvent = resizeHeight
    }

    setParameters(params:{}){
        const parameters = []
        for(let [key,value] of Object.entries(params)){
            let param = {
                Name: key,
                Values: [String(value).split(",")]
            }
            parameters.push(param)
        }
        this.contentOptions.parameters = parameters
    }


}

export default QuicksightEmbed