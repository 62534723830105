import { useHistory } from "react-router"
import { useEffect } from "react";
import { IonContent, IonPage, IonRow } from "@ionic/react";
import Ae2Header from "../../components/AE2/Ae2Header";
import './errors.css'
import NotificationPopUp from "../../components/extras/NotificationPopUp";


const NotAuthorized:React.FC = ()=>{

    const history = useHistory()
    useEffect(() => {
        setTimeout(() => {
            history.push('/')
            NotificationPopUp('error','User Is Not Authorized To Access That Page','Not Authorized')
        }, 5000)
      }, [history])


    return (<IonPage>
            <Ae2Header/>
            <IonContent className="ae2-content">
                <IonRow className="ion-justify-content-center">
                    <div>
                    <h1 className="http-403">403</h1>
                    <h2 className="http-msg">Permission Denied</h2>
                    </div>
                </IonRow>
            </IonContent>
    </IonPage>)
}

export default NotAuthorized