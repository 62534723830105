import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../actions/settings";

interface Props {
  opened: boolean;
  setOpened: any;
  selectStory: any;
}

type Story = {
  id: string;
  market_segment: string;
  sub_market_segment: string;
  location: Location;
  representative: string;
  description: string;
  keywords: [];
  date: string;
};

type Location = {
  id: string;
  name: string;
};
async function getStories(token: any) {
  return fetch(`${API_URL}/success_story`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const handleStories = async (token: string | null, setStories: any) => {
  const stories = await getStories(`Token ${token!.slice(1, -1)}`);
  setStories(stories.data);
};

const AddSuccessStoryModal: React.FC<Props> = ({
  opened,
  setOpened,
  selectStory,
}) => {
  const [stories, setStories] = useState<Story[]>([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    handleStories(token, setStories);
  }, []);

  return (
    <IonModal
      id="addStoryModal"
      className="modal-background"
      isOpen={opened}
      onDidDismiss={() => {
        setOpened(false);
      }}
    >
      <IonToolbar>
        <IonTitle>Select Story To Add</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => setOpened(false)}>close</IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <p> Click on a story to add it to the proposal.</p>
        {stories.map((story: Story) => {
          return (
            <IonCard
              key={story.id}
              button={true}
              onClick={() => {
                selectStory(story.description);
              }}
            >
              <IonCardHeader>
                <IonCardTitle>{story.location.name}</IonCardTitle>
                <IonCardSubtitle>{story.market_segment}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>{story.description}</IonCardContent>
            </IonCard>
          );
        })}
      </IonContent>
    </IonModal>
  );
};

export default AddSuccessStoryModal;
