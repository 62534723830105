import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
    totalGrossFloorArea : "",
    totalGrossFloorArea_units : "",
    totalGrossFloorArea_date : "",
    enclosedFloorArea : "",
    enclosedFloorArea_units : "",
    enclosedFloorArea_date : "",
    numberOfSportingEventsPerYear : "",
    numberOfSportingEventsPerYear_date : "",
    numberOfConcertShowEventsPerYear : "",
    numberOfConcertShowEventsPerYear_date : "",
    numberOfSpecialOtherEventsPerYear : "",
    numberOfSpecialOtherEventsPerYear_date : "",
    sizeOfElectronicScoreBoards : "",
    sizeOfElectronicScoreBoards_units : "",
    sizeOfElectronicScoreBoards_date : "",
    iceEvents : "",
    iceEvents_date : "",
    numberOfComputers : "",
    numberOfComputers_date : "",
    numberOfWalkInRefrigerationUnits : "",
    numberOfWalkInRefrigerationUnits_date : "",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
}

const startErrors = {
    totalGrossFloorArea : "Field Required",
    totalGrossFloorArea_units : "Select Units",
    totalGrossFloorArea_date : "Date is required",
    numberOfSportingEventsPerYear : "",
    numberOfSportingEventsPerYear_date : "",
    enclosedFloorArea : "",
    enclosedFloorArea_units : "",
    enclosedFloorArea_date : "",
    numberOfConcertShowEventsPerYear : "",
    numberOfConcertShowEventsPerYear_date : "",
    numberOfSpecialOtherEventsPerYear : "",
    numberOfSpecialOtherEventsPerYear_date : "",
    sizeOfElectronicScoreBoards : "",
    sizeOfElectronicScoreBoards_units : "",
    sizeOfElectronicScoreBoards_date : "",
    iceEvents : "",
    iceEvents_date : "",
    numberOfComputers : "",
    numberOfComputers_date : "",
    numberOfWalkInRefrigerationUnits : "",
    numberOfWalkInRefrigerationUnits_date : "",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
}



const StadiumClosed: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('enclosedFloorArea'):
                if(value !== ''){
                    form.enclosedFloorArea_date === '' ? handleError('enclosedFloorArea_date','Date Cannot be Empty') : handleError('enclosedFloorArea_date',"")
                    form.enclosedFloorArea_units === '' ? handleError('enclosedFloorArea_units','Units Cannot be Empty') : handleError('enclosedFloorArea_units',"")
                    value < 0 ? handleError('enclosedFloorArea','Must be a Positive Number') : handleError('enclosedFloorArea',"")
                }
                else{
                    handleError('enclosedFloorArea','')
                    if(form.enclosedFloorArea_date === '' && form.enclosedFloorArea_units === ''){
                        handleError('enclosedFloorArea_date','')
                        handleError('enclosedFloorArea_units','')}
                    else if(form.enclosedFloorArea_date === ''){
                        handleError('enclosedFloorArea'," Field Cannot be empty")
                        handleError('enclosedFloorArea_date'," Date Cannot be empty")
                    }
                    else if(form.enclosedFloorArea_units === ''){
                        handleError('enclosedFloorArea'," Field Cannot be empty")
                        handleError('enclosedFloorArea_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('enclosedFloorArea_date'):
                if(value !== ''){
                    form.enclosedFloorArea === '' ? handleError('enclosedFloorArea','Field Cannot be Empty') : handleError('enclosedFloorArea',"")
                    form.enclosedFloorArea_units === '' ? handleError('enclosedFloorArea_units','Units Cannot be Empty') : handleError('enclosedFloorArea_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('enclosedFloorArea_date','Invalid Date') : handleError('enclosedFloorArea_date','')
                }
                else{
                    handleError('enclosedFloorArea_date','')
                    if(form.enclosedFloorArea === '' && form.enclosedFloorArea_units === ''){
                        handleError('enclosedFloorArea','')
                        handleError('enclosedFloorArea_units','')}
                    else if(form.enclosedFloorArea === ''){
                        handleError('enclosedFloorArea'," Field Cannot be empty")
                        handleError('enclosedFloorArea_date'," Date Cannot be empty")
                    }
                    else if(form.enclosedFloorArea_units === ''){
                        handleError('enclosedFloorArea_date'," Field Cannot be empty")
                        handleError('enclosedFloorArea'," Unit Cannot be empty")
                    }

                }
                break;
            case('enclosedFloorArea_units'):
                if(value !== ''){
                    form.enclosedFloorArea === '' ? handleError('enclosedFloorArea','Field Cannot be Empty') : handleError('enclosedFloorArea',"")
                    form.enclosedFloorArea_date === '' ? handleError('enclosedFloorArea_date','Date Cannot be Empty') : handleError('enclosedFloorArea_date',"")
                }
                else{
                    handleError('enclosedFloorArea_units','')
                    if(form.enclosedFloorArea === '' && form.enclosedFloorArea_date === ''){
                        handleError('enclosedFloorArea','')
                        handleError('enclosedFloorArea_date','')}
                    else if(form.enclosedFloorArea === ''){
                        handleError('enclosedFloorArea'," Field Cannot be empty")
                        handleError('enclosedFloorArea_units'," Date Cannot be empty")
                    }
                    else if(form.enclosedFloorArea === ''){
                        handleError('enclosedFloorArea_date'," Field Cannot be empty")
                        handleError('enclosedFloorArea'," Unit Cannot be empty")
                    }

                }
                break;
            case('numberOfConcertShowEventsPerYear'):
                if(value !==''){
                    form.numberOfConcertShowEventsPerYear_date === '' ? handleError('numberOfConcertShowEventsPerYear_date','Date cannot be empty') : handleError('numberOfConcertShowEventsPerYear_date','')
                    value < 0 ? handleError('numberOfConcertShowEventsPerYear','Must be a Positive number') : value > 12 ? handleError('numberOfConcertShowEventsPerYear','Max Value is 12') : handleError('numberOfConcertShowEventsPerYear','')
                }
                else{
                    handleError('numberOfConcertShowEventsPerYear','')
                    handleError('numberOfConcertShowEventsPerYear_date','')
                }
                break;
            case('numberOfConcertShowEventsPerYear_date'):
                if(value !==''){
                    form.numberOfConcertShowEventsPerYear === '' ? handleError('numberOfConcertShowEventsPerYear','Field cannot be empty') : handleError('numberOfConcertShowEventsPerYear','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfConcertShowEventsPerYear_date','Invalid Date') : handleError('numberOfConcertShowEventsPerYear_date','')
                }
                else{
                    handleError('numberOfConcertShowEventsPerYear','')
                    handleError('numberOfConcertShowEventsPerYear_date','')
                }
                break;
            case('numberOfSportingEventsPerYear'):
                if(value !==''){
                    form.numberOfSportingEventsPerYear_date === '' ? handleError('numberOfSportingEventsPerYear_date','Date cannot be empty') : handleError('numberOfSportingEventsPerYear_date','')
                    value < 0 ? handleError('numberOfSportingEventsPerYear','Must be a Positive number') : handleError('numberOfSportingEventsPerYear','')
                }
                else{
                    handleError('numberOfSportingEventsPerYear','')
                    handleError('numberOfSportingEventsPerYear_date','')
                }
                break;
            case('numberOfSportingEventsPerYear_date'):
                if(value !==''){
                    form.numberOfSportingEventsPerYear === '' ? handleError('numberOfSportingEventsPerYear','Field cannot be empty') : handleError('numberOfSportingEventsPerYear','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfSportingEventsPerYear_date','Invalid Date') : handleError('numberOfSportingEventsPerYear_date','')
                }
                else{
                    handleError('numberOfSportingEventsPerYear','')
                    handleError('numberOfSportingEventsPerYear_date','')
                }
                break;
            case('numberOfSpecialOtherEventsPerYear'):
                if(value !==''){
                    form.numberOfSpecialOtherEventsPerYear_date === '' ? handleError('numberOfSpecialOtherEventsPerYear_date','Date cannot be empty') : handleError('numberOfSpecialOtherEventsPerYear_date','')
                    value < 0 ? handleError('numberOfSpecialOtherEventsPerYear','Must be a Positive number') : handleError('numberOfSpecialOtherEventsPerYear','')
                }
                else{
                    handleError('numberOfSpecialOtherEventsPerYear','')
                    handleError('numberOfSpecialOtherEventsPerYear_date','')
                }
                break;
            case('numberOfSpecialOtherEventsPerYear_date'):
                if(value !==''){
                    form.numberOfSpecialOtherEventsPerYear === '' ? handleError('numberOfSpecialOtherEventsPerYear','Field cannot be empty') : handleError('numberOfSpecialOtherEventsPerYear','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfSpecialOtherEventsPerYear_date','Invalid Date') : handleError('numberOfSpecialOtherEventsPerYear_date','')
                }
                else{
                    handleError('numberOfSpecialOtherEventsPerYear','')
                    handleError('numberOfSpecialOtherEventsPerYear_date','')
                }
                break;
            case('numberOfComputers'):
                if(value !==''){
                    form.numberOfComputers_date === '' ? handleError('numberOfComputers_date','Date cannot be empty') : handleError('numberOfComputers_date','')
                    value < 0 ? handleError('numberOfComputers','Must be a Positive number') : handleError('numberOfComputers','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case('numberOfComputers_date'):
                if(value !==''){
                    form.numberOfComputers === '' ? handleError('numberOfComputers','Field cannot be empty') : handleError('numberOfComputers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfComputers_date','Invalid Date') : handleError('numberOfComputers_date','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case('numberOfWalkInRefrigerationUnits'):
                if(value !==''){
                    form.numberOfWalkInRefrigerationUnits_date === '' ? handleError('numberOfWalkInRefrigerationUnits_date','Date cannot be empty') : handleError('numberOfWalkInRefrigerationUnits_date','')
                    value < 0 ? handleError('numberOfWalkInRefrigerationUnits','Must be a Positive number') : handleError('numberOfWalkInRefrigerationUnits','')
                }
                else{
                    handleError('numberOfWalkInRefrigerationUnits','')
                    handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                break;
            case('numberOfWalkInRefrigerationUnits_date'):
                if(value !==''){
                    form.numberOfWalkInRefrigerationUnits === '' ? handleError('numberOfWalkInRefrigerationUnits','Field cannot be empty') : handleError('numberOfWalkInRefrigerationUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWalkInRefrigerationUnits_date','Invalid Date') : handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                else{
                    handleError('numberOfWalkInRefrigerationUnits','')
                    handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                break;
            case ('percentCooled'):
                if( value !== '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','')
                }
                else {
                    handleError('percentCooled','')
                }
                break;
            case ('percentCooled_date'):
                if (form.percentCooled !== ''){
                    if (value === ''){
                        handleError('percentCooled_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentCooled_date','Invalid Date')
                    }
                else {
                        handleError('percentCooled_date','')
                    }
                break;
            case ('percentHeated'):
                if( value !== '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','')
                }
                else {
                    handleError('percentHeated','')
                }
                break;
            case ('percentHeated_date'):
                if (form.percentHeated !== ''){
                    if (value === ''){
                        handleError('percentHeated_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentHeated_date','Invalid Date')
                    }
                else {
                        handleError('percentHeated_date','')
                    }
                break;
            case('sizeOfElectronicScoreBoards'):
                if(value !== ''){
                    form.sizeOfElectronicScoreBoards_date === '' ? handleError('sizeOfElectronicScoreBoards_date','Date Cannot be Empty') : handleError('sizeOfElectronicScoreBoards_date',"")
                    form.sizeOfElectronicScoreBoards_units === '' ? handleError('sizeOfElectronicScoreBoards_units','Units Cannot be Empty') : handleError('sizeOfElectronicScoreBoards_units',"")
                    value < 0 ? handleError('sizeOfElectronicScoreBoards','Must be a Positive Number') : handleError('sizeOfElectronicScoreBoards',"")
                }
                else{
                    handleError('sizeOfElectronicScoreBoards','')
                    if(form.sizeOfElectronicScoreBoards_date === '' && form.sizeOfElectronicScoreBoards_units === ''){
                        handleError('sizeOfElectronicScoreBoards_date','')
                        handleError('sizeOfElectronicScoreBoards_units','')}
                    else if(form.sizeOfElectronicScoreBoards_date === ''){
                        handleError('sizeOfElectronicScoreBoards'," Field Cannot be empty")
                        handleError('sizeOfElectronicScoreBoards_date'," Date Cannot be empty")
                    }
                    else if(form.sizeOfElectronicScoreBoards_units === ''){
                        handleError('sizeOfElectronicScoreBoards'," Field Cannot be empty")
                        handleError('sizeOfElectronicScoreBoards_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('sizeOfElectronicScoreBoards_date'):
                if(value !== ''){
                    form.sizeOfElectronicScoreBoards === '' ? handleError('sizeOfElectronicScoreBoards','Field Cannot be Empty') : handleError('sizeOfElectronicScoreBoards',"")
                    form.sizeOfElectronicScoreBoards_units === '' ? handleError('sizeOfElectronicScoreBoards_units','Units Cannot be Empty') : handleError('sizeOfElectronicScoreBoards_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('sizeOfElectronicScoreBoards_date','Invalid Date') : handleError('sizeOfElectronicScoreBoards_date','')
                }
                else{
                    handleError('sizeOfElectronicScoreBoards_date','')
                    if(form.sizeOfElectronicScoreBoards === '' && form.sizeOfElectronicScoreBoards_units === ''){
                        handleError('sizeOfElectronicScoreBoards','')
                        handleError('sizeOfElectronicScoreBoards_units','')}
                    else if(form.sizeOfElectronicScoreBoards === ''){
                        handleError('sizeOfElectronicScoreBoards'," Field Cannot be empty")
                        handleError('sizeOfElectronicScoreBoards_date'," Date Cannot be empty")
                    }
                    else if(form.sizeOfElectronicScoreBoards_units === ''){
                        handleError('sizeOfElectronicScoreBoards_date'," Field Cannot be empty")
                        handleError('sizeOfElectronicScoreBoards'," Unit Cannot be empty")
                    }
                }
                break;
            case('sizeOfElectronicScoreBoards_units'):
                if(value !== ''){
                    form.sizeOfElectronicScoreBoards === '' ? handleError('sizeOfElectronicScoreBoards','Field Cannot be Empty') : handleError('sizeOfElectronicScoreBoards',"")
                    form.sizeOfElectronicScoreBoards_date === '' ? handleError('sizeOfElectronicScoreBoards_date','Date Cannot be Empty') : handleError('sizeOfElectronicScoreBoards_date',"")
                }
                else{
                    handleError('sizeOfElectronicScoreBoards_units','')
                    if(form.sizeOfElectronicScoreBoards === '' && form.sizeOfElectronicScoreBoards_date === ''){
                        handleError('sizeOfElectronicScoreBoards','')
                        handleError('sizeOfElectronicScoreBoards_date','')}
                    else if(form.sizeOfElectronicScoreBoards === ''){
                        handleError('sizeOfElectronicScoreBoards'," Field Cannot be empty")
                        handleError('sizeOfElectronicScoreBoards_units'," Date Cannot be empty")
                    }
                    else if(form.sizeOfElectronicScoreBoards === ''){
                        handleError('sizeOfElectronicScoreBoards_date'," Field Cannot be empty")
                        handleError('sizeOfElectronicScoreBoards'," Unit Cannot be empty")
                    }

                }
                break;
            case('iceEvents'):
                if(value !==''){
                    form.iceEvents_date === '' ? handleError('iceEvents_date','Date cannot be empty') : handleError('iceEvents_date','')

                }
                else{
                    handleError('iceEvents','')
                    handleError('iceEvents_date','')
                }
                break;
            case('iceEvents_date'):
                if(value !==''){
                    form.iceEvents === '' ? handleError('iceEvents','Field cannot be empty') : handleError('iceEvents','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('iceEvents_date','Invalid Date') : handleError('iceEvents_date','')
                }
                else{
                    handleError('iceEvents','')
                    handleError('iceEvents_date','')
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Closed Stadium Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Enclosed Floor Area</IonLabel>
                        <IonInput type="number" className={errors.enclosedFloorArea === '' ? "valid":'invalid'}  value={form.enclosedFloorArea} placeholder='Enclosed Floor Area' onIonChange={e => (handleFormChange(e.detail.value!,'enclosedFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.enclosedFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.enclosedFloorArea_units === '' ? "valid":'invalid'} value={form.enclosedFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'enclosedFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.enclosedFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.enclosedFloorArea_date === '' ? "valid":'invalid'} value={form.enclosedFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'enclosedFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.enclosedFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Sporting Events Per Year</IonLabel>
                        <IonInput type="number" className={errors.numberOfSportingEventsPerYear === '' ? "valid":'invalid'}  value={form.numberOfSportingEventsPerYear} placeholder='# Sporting Events/Year' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfSportingEventsPerYear'))}></IonInput>
                        <div className="error-detail">{errors.numberOfSportingEventsPerYear}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfSportingEventsPerYear_date === '' ? "valid":'invalid'}  value={form.numberOfSportingEventsPerYear_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfSportingEventsPerYear_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfSportingEventsPerYear_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Concert Show Events Per Year</IonLabel>
                        <IonInput type="number" className={errors.numberOfConcertShowEventsPerYear === '' ? "valid":'invalid'}  value={form.numberOfConcertShowEventsPerYear} placeholder='# Concerts/Year' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfConcertShowEventsPerYear'))}></IonInput>
                        <div className="error-detail">{errors.numberOfConcertShowEventsPerYear}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfConcertShowEventsPerYear_date === '' ? "valid":'invalid'}  value={form.numberOfConcertShowEventsPerYear_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfConcertShowEventsPerYear_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfConcertShowEventsPerYear_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Special/Other Events Per Year</IonLabel>
                        <IonInput type="number" className={errors.numberOfSpecialOtherEventsPerYear === '' ? "valid":'invalid'}  value={form.numberOfSpecialOtherEventsPerYear} placeholder='# Special-Other/Year' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfSpecialOtherEventsPerYear'))}></IonInput>
                        <div className="error-detail">{errors.numberOfSpecialOtherEventsPerYear}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfSpecialOtherEventsPerYear_date === '' ? "valid":'invalid'}  value={form.numberOfSpecialOtherEventsPerYear_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfSpecialOtherEventsPerYear_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfSpecialOtherEventsPerYear_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Size of Electronic Score Boards</IonLabel>
                        <IonInput type="number" className={errors.sizeOfElectronicScoreBoards === '' ? "valid":'invalid'}  value={form.sizeOfElectronicScoreBoards} placeholder='Size Electronic Scoring Board' onIonChange={e => (handleFormChange(e.detail.value!,'sizeOfElectronicScoreBoards'))}></IonInput>
                        <div className="error-detail">{errors.sizeOfElectronicScoreBoards}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.sizeOfElectronicScoreBoards_units === '' ? "valid":'invalid'} value={form.sizeOfElectronicScoreBoards_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'sizeOfElectronicScoreBoards_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.sizeOfElectronicScoreBoards_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.sizeOfElectronicScoreBoards_date === '' ? "valid":'invalid'} value={form.sizeOfElectronicScoreBoards_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'sizeOfElectronicScoreBoards_date'))}></IonInput>
                        <div className="error-detail">{errors.sizeOfElectronicScoreBoards_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Has Ice Related Events</IonLabel>
                        <IonSelect className={errors.iceEvents === '' ? "valid":'invalid'}  value={form.iceEvents} interface='popover' placeholder='Ice Related Event?' onIonChange={e => (handleFormChange(e.detail.value!,'iceEvents'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.iceEvents}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.iceEvents_date === '' ? "valid":'invalid'}  value={form.iceEvents_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'iceEvents_date'))}></IonInput>
                        <div className="error-detail">{errors.iceEvents_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Computers</IonLabel>
                        <IonInput type="number" className={errors.numberOfComputers === '' ? "valid":'invalid'}  value={form.numberOfComputers} placeholder='# Computers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfComputers_date === '' ? "valid":'invalid'}  value={form.numberOfComputers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of WalkIn Refrigeration Units</IonLabel>
                        <IonInput type="number" className={errors.numberOfWalkInRefrigerationUnits === '' ? "valid":'invalid'}  value={form.numberOfWalkInRefrigerationUnits} placeholder='# Refrigeration Units' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWalkInRefrigerationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWalkInRefrigerationUnits_date === '' ? "valid":'invalid'}  value={form.numberOfWalkInRefrigerationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWalkInRefrigerationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Percent Cooled</IonLabel>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentCooled_date === '' ? "valid":'invalid'}  value={form.percentCooled_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></IonInput>
                        <div className="error-detail">{errors.percentCooled_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Percent Heated</IonLabel>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentHeated_date === '' ? "valid":'invalid'}  value={form.percentHeated_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></IonInput>
                        <div className="error-detail">{errors.percentHeated_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default StadiumClosed
