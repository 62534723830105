import { IonButton, IonItem, IonGrid, IonRow, IonLoading } from "@ionic/react"
import { useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { API_URL } from "../../actions/settings"
import SingleBillForm from "./SingleBillForm"
import CheckRole from "../extras/CheckRole"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import NotificationPopUp from "../extras/NotificationPopUp"
import LoadingSpinner from "../extras/LoadingSpinner"

interface Params{
    id:string
}

const startingForm = {
    startdate:"",
    enddate:"",
    unitofmeasure:"",
    usage:"",
    cost:"",
    demand:"",
    demandCost:"",
    estimatedvalue:false,
}
const startingErrors = {
    startdate:"",
    enddate:"",
    unitofmeasure:"",
    usage:"",
    cost:"",
    demand:"",
    demandCost:"",
    estimatedvalue:"",
}

async function getMeter(token:any,id:any) {

    return fetch(`${API_URL}/energystar/meters/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }



async function submitBills(token:any,id:string,data:any) {

    return fetch(`${API_URL}/energystar/meters/${id}/bills`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }

const validate = (errors:any) => {
    for (const res of errors){
        for(const val of Object.values(res)){
            if( val === false){
                return false
            }
        }
    }

    return true
}




const ConsumptionDataForm: React.FC = () =>{

    const { id } = useParams<Params>();
    const user = useAppSelector(selectUser)
    const history = useHistory()
    const [meter,setMeter] = useState<any>("")
    const handleDelete = (index:number) => {
        items.splice(index,1)
        forms.splice(index,1)
        setItems({items: [...items]})
        setForms([...forms])

    }

    const [forms, setForms] = useState([startingForm])
    const [errors, setErrors] = useState([startingErrors])
    const [{items}, setItems] = useState<any>({items:[]})

    const handleFormChange = (index:number,values:any) => {
        forms[index] = values
        setForms([...forms])
    }

    useEffect(()=>{
        const handleMeter = async (id:string) =>{
            const token = localStorage.getItem('token') || ""
            const res = await getMeter(`Token ${token.slice(1,-1)}`,id)
            setMeter(res.data)
            const startItem = <IonItem key={0}>
                <SingleBillForm index={0} onChange={handleFormChange} values={forms[0]} valid={errors} setValid={setErrors} handleDelete={handleDelete} meter_type={res.data?.type?.name}/>
            </IonItem>
            setItems({items:startItem})
        }

        handleMeter(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])

    if( !meter.type){
        return <LoadingSpinner/>
    }








    const handleAddButton = () => {
        forms.push(startingForm)
        setForms([...forms])
        errors.push(startingErrors)
        setErrors([...errors])
        items.push(<IonItem key={items.length}>
            <SingleBillForm index={items.length} onChange={handleFormChange} values={forms[items.length]} valid={errors} setValid={setErrors}  handleDelete={handleDelete} meter_type={meter.type.name}/>
        </IonItem>);
        setItems({ items: [...items] });

      };



    const handleSubmit = async() => {
        const token = localStorage.getItem('token') || ""
        const valid = validate(errors)
        if (valid){
        const res = await submitBills(`Token ${token.slice(1,-1)}`,id,forms)
        if (res.status === "success"){
            setItems({items:[]})
            setForms([startingForm])
            NotificationPopUp("success",'Bills Added to Meter')
            history.push(`/energystar/meters/${id}`)
        }
        else{
            NotificationPopUp('error',res.detail||res.error)
        }
        }
        else{
            NotificationPopUp('error','Please Fill Out Entire Form','Form Not Complete', 12000)
        }
    }

    if (user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={user.first_name===""}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }

    return (
        <IonGrid>
            <IonRow className="form-title-row" ><h2>Consumption Data</h2> <IonButton className="cancel-btn" size="small"  color="danger" href={`/energystar/meters/${id}`}> Cancel </IonButton></IonRow>
        {items}
        <IonRow className="buttons">
            <IonButton type="button" color="medium" onClick={handleAddButton}>Add Bill</IonButton>
            <IonButton type="submit" size="large" onClick={handleSubmit}>Submit</IonButton>
        </IonRow>
        </IonGrid>

    )
}

export default ConsumptionDataForm