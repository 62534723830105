import { IonItem, IonSelect, IonSelectOption } from '@ionic/react'
import React from 'react'

interface Props {
    form:any,
    handleFormChange:any,
    startYear:number,
    hidden?:boolean
}

const months = {
    1:"January",
    2:"February",
    3:"March",
    4:"April",
    5:"May",
    6:"June",
    7:"July",
    8:"August",
    9:"September",
    10:"October",
    11:"November",
    12:"December",
}

const MonthYearInput:React.FC<Props> = ({form,handleFormChange,startYear,hidden=false}) => {

    const todays_year = Number(new Date().getFullYear())

  return <div hidden={hidden}>
    <IonItem>
    <IonSelect label='Month' placeholder='Month' interface='popover' value={form.month.toString()} onIonChange={(e)=>{handleFormChange(e.detail.value,'month')}}>
        {Object.keys(months).map((key:any)=>{
            return (<IonSelectOption value={key} key={key}>
                {key} - {months[key as keyof typeof months]}
            </IonSelectOption>)
        })}
    </IonSelect>
    </IonItem>
    <IonItem>
    <IonSelect label='Year' placeholder='Year' interface='popover' value={form.year} onIonChange={(e)=>{handleFormChange(e.detail.value,'year')}}>
        {[...Array(todays_year-startYear).fill(todays_year)].map((key,val) => {
            return <IonSelectOption value={key-val} key={key-val}>
                {key-val}
            </IonSelectOption>
        })
        }
    </IonSelect>
    </IonItem>
  </div>
}

export default MonthYearInput