import React, { useState } from "react";
import { IonCol, IonButton, IonInput, IonItem, IonRow } from "@ionic/react";
import ReactPasswordChecklist from "react-password-checklist";
import { API_URL } from "../../actions/settings";
import { UnexpectedError } from "../../pages/Errors/Errors";
import NotificationPopUp from "../extras/NotificationPopUp";
import { useHistory } from "react-router";

interface Props {
  email: string;
  OTP: any;
}

async function changePassword(data: any) {
  return fetch(`${API_URL}/reset_password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        return "ok";
      } else if (res.status === 406) {
        return res.json();
      } else {
        throw new UnexpectedError();
      }
    })
    .catch((error) => {
      NotificationPopUp("error", "Error", "Could not Reset Password", 1200);
      return null;
    });
}

const NewPasswordForm: React.FC<Props> = ({ email, OTP }) => {
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  const handleSubmit = async () => {
    if (validPassword) {
      const res = await changePassword({
        email: email,
        token: OTP,
        password: password,
      });

      if (res === "ok") {
        setPassword("");
        setVerifyPassword("");
        setError("");
        history.push("/login");
        NotificationPopUp("success", "Password Changed");
      } else {
        setError(res.error || "Unexpected Error");
      }
    } else {
      setError("Password is not valid");
    }
  };

  return (
    <>
      <IonRow>
        <IonRow
          className="ion-justify-content-center"
          style={{ width: "100%" }}
        >
          <ReactPasswordChecklist
            className="password-checklist"
            rules={[
              "notEmpty",
              "minLength",
              "number",
              "lowercase",
              "capital",
              "match",
            ]}
            minLength={14}
            value={password}
            valueAgain={verifyPassword}
            onChange={(isValid) => {
              setValidPassword(isValid);
            }}
            style={{ marginBottom: "10px" }}
          />
        </IonRow>
        <IonRow
          style={{ width: "100%" }}
          className="ion-justify-content-center"
        >
          <IonCol>
            <h5 className="error" hidden={!error}>
              {error}
            </h5>
          </IonCol>
        </IonRow>
        <IonItem style={{ width: "100%" }}>
          <IonInput
            type="password"
            label="New Password:"
            aria-labelledby="new password"
            value={password}
            placeholder="New password"
            onIonInput={(e: any) => setPassword(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem style={{ width: "100%" }}>
          <IonInput
            type="password"
            label="Verify Password:"
            aria-labelledby=" verify password"
            value={verifyPassword}
            placeholder="verify password"
            onIonInput={(e: any) => setVerifyPassword(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonRow
          style={{ width: "100%" }}
          className="ion-justify-content-center"
        >
          <IonButton onClick={handleSubmit}>Reset Password</IonButton>
        </IonRow>

        <IonRow
          style={{ width: "100%" }}
          className="ion-justify-content-center"
        >
          <a style={{ color: "white" }} href="/login">
            Login
          </a>
        </IonRow>
      </IonRow>
    </>
  );
};

export default NewPasswordForm;
