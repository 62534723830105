import {
  IonCol,
  IonRow,
  IonIcon,
  IonButton,
  IonPopover,
  IonContent,
} from "@ionic/react";
import React from "react";
import MainThumbnailImage from "../../MainThumbnailImage";
import {
  REACT_APP_AWS_BUCKET_NAME,
  REACT_APP_AWS_REGION,
} from "../../../actions/settings";
import { Customer } from "../../../interfaces/Customer";
import { copy, copyOutline, createOutline } from "ionicons/icons";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import { isStaff } from "../../extras/Functions";
import CheckRole from "../../extras/CheckRole";
import RemoteAccessDataInfoComponent from "./RemoteAccessDataInfoComponent";
import ServerDataInfoComponent from "./ServerDataInfoComponent";

interface Props {
  customer: Customer;
  totals: any;
  style?: any;
  versioning?: any;
  vpn?: any;
}

const CustomerInfoCard: React.FC<Props> = ({
  customer,
  style,
  totals,
  versioning,
  vpn,
}) => {
  const user = useAppSelector(selectUser);

  const copyUUID = () => {
    navigator.clipboard.writeText(customer.uuid);
    const popup = document.getElementById("copied");
    popup?.classList.toggle("show");
    setTimeout(() => {
      const popup = document.getElementById("copied");
      popup?.classList.toggle("show");
    }, 3000);
  };

  return (
    <div style={style}>
      <div
        style={{
          width: "100%",
          height: "100%",
          border: "1.5px solid black",
          padding: "15px",
        }}
      >
        <IonRow className="ion-justify-content-center pc-only">
          <IonCol size="2">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <MainThumbnailImage
                style={{ maxWidth: "250px" }}
                src={`https://${REACT_APP_AWS_BUCKET_NAME}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/thumbnails/pics/${customer.uuid}-customer-img`}
              />
            </div>
          </IonCol>
          <IonCol>
            <div style={{display:'flex'}}>
            <h1>{customer.cust_name}</h1>
            <IonButton
              hidden={!CheckRole(user, ["Admin", "Supervisor"])}
              id="edit-customer-pc"
              fill="clear"
              color="light"
              slot="icon-only"
              // className="testing"
            >
              <IonIcon slot="icon-only" src={createOutline}></IonIcon>
            </IonButton>
            </div>

            {isStaff(user) ? (
              <p>
                <span className="popup">
                  <span className="popuptext" id="copied">
                    Copied
                  </span>
                  <IonButton
                    className="clipboardButton"
                    color="light"
                    slot="icon-only"
                    fill="clear"
                    onClick={copyUUID}
                  >
                    {" "}
                    <IonIcon md={copy} ios={copyOutline} />
                    COPY ID
                  </IonButton>{" "}
                </span>{" "}
              </p>
            ) : (
              ""
            )}

            <IonPopover
              trigger="edit-customer-pc"
              size="auto"
              side="right"
              alignment="start"
              showBackdrop={false}
            >
              <IonContent>
                <IonButton
                  color="dark"
                  fill="clear"
                  expand="full"
                  href={`/customer/${customer.uuid}/edit`}
                >
                  Edit Customer
                </IonButton>
                <IonButton
                  color="dark"
                  fill="clear"
                  expand="full"
                  href={`/customer/${customer.uuid}/addcampus`}
                >
                  Add Campus
                </IonButton>
                <IonButton
                  color="dark"
                  fill="clear"
                  expand="full"
                  href={`/customer/${customer.uuid}/addproperty`}
                >
                  Add Building
                </IonButton>
              </IonContent>
            </IonPopover>
            <p>
              {customer.cust_address}{" "}
              {customer.cust_address_2 ? customer.cust_address_2 : ""}
              {customer.cust_city}, {customer.cust_state},{" "}
              {customer.cust_zipcode}
            </p>
            <p>
              Technical Solutions Area:{" "}
              {customer.technical_solutions_area?.name || ""}
            </p>
            <p>
              Square Footage: {totals.sf.toLocaleString("en")}ft<sup>2</sup>
            </p>

            {isStaff(user) ? (
              <>
                <IonRow>
                  <IonCol
                    size="12"
                    sizeLg="6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IonButton
                      color="medium"
                      size="small"
                      href={`/customer_buildings/monthly_performance/${customer.uuid}`}
                    >
                      Monthly Performance
                    </IonButton>
                  </IonCol>
                  <IonCol
                    size="12"
                    sizeLg="6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IonButton
                      color="medium"
                      size="small"
                      href={`/buildings/performance/${customer.uuid}`}
                    >
                      Yearly Performance
                    </IonButton>
                  </IonCol>
                </IonRow>
              </>
            ) : (
              ""
            )}
          </IonCol>
          <IonCol>
            <ServerDataInfoComponent
              server={versioning}
              style={{ marginTop: "10px" }}
            />
          </IonCol>
          <IonCol>
            <RemoteAccessDataInfoComponent
              vpn={vpn}
              style={{ marginBottom: "20px", marginTop: "10px" }}
            />
          </IonCol>
        </IonRow>
        <div className="mobile-only">
          <IonRow style={{ minHeight: "50px" }}>
            <IonCol size="4" style={{ minHeight: "50px" }}>
              <MainThumbnailImage
                className="center-all"
                style={{ maxWidth: "120px" }}
                src={`https://${REACT_APP_AWS_BUCKET_NAME}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/thumbnails/pics/${customer.uuid}-customer-img`}
              />
            </IonCol>
            <IonCol size="8" style={{ padding: 0 }}>
              <div style={{ position: "absolute", right: 0, top: 0 }}>
                <IonButton
                  hidden={!CheckRole(user, ["Admin", "Supervisor"])}
                  id="edit-customer-mobile"
                  fill="clear"
                  color="light"
                  slot="icon-only"
                  style={{
                    "--padding-start": 0,
                    "--padding-end": 0,
                    minHeight: 0,
                  }}
                >
                  <IonIcon slot="icon-only" src={createOutline}></IonIcon>
                </IonButton>
                <IonPopover
                  trigger="edit-customer-mobile"
                  size="auto"
                  side="left"
                  alignment="center"
                  showBackdrop={false}
                >
                  <IonContent>
                    <IonButton
                      color="dark"
                      fill="clear"
                      expand="full"
                      href={`/customer/${customer.uuid}/edit`}
                    >
                      Edit Customer
                    </IonButton>
                    <IonButton
                      color="dark"
                      fill="clear"
                      expand="full"
                      href={`/customer/${customer.uuid}/addcampus`}
                    >
                      Add Campus
                    </IonButton>
                    <IonButton
                      color="dark"
                      fill="clear"
                      expand="full"
                      href={`/customer/${customer.uuid}/addproperty`}
                    >
                      Add Building
                    </IonButton>
                  </IonContent>
                </IonPopover>
              </div>
              <h2 style={{ padding: "5px" }}>{customer.cust_name}</h2>

              <div>
                {customer.cust_address}{" "}
                {customer.cust_address_2 ? customer.cust_address_2 : ""}
              </div>
              <div>
                {customer.cust_city}, {customer.cust_state},{" "}
                {customer.cust_zipcode}
              </div>
            </IonCol>
          </IonRow>
        </div>
        <IonRow class="mobile-only">
          <IonCol
            size="6"
            style={{ display: "flex", justifyContent: "center" }}
            title="Technical Solutions Area"
          >
            TSA: {customer.technical_solutions_area?.name || ""}
          </IonCol>
          <IonCol
            size="6"
            style={{ display: "flex", justifyContent: "center" }}
            title="Square Feet"
          >
            SFT: {totals.sf.toLocaleString("en")}ft<sup>2</sup>
          </IonCol>
        </IonRow>

        {vpn.type ? (
          <RemoteAccessDataInfoComponent
            vpn={vpn}
            style={{ marginBottom: "20px", marginTop: "10px" }}
            className="mobile-only"
          />
        ) : null}

        <ServerDataInfoComponent
          server={versioning}
          style={{ marginTop: "10px" }}
          className="mobile-only"
        />

        {isStaff(user) ? (
          <>
            <IonRow className="mobile-only">
              <IonCol
                size="12"
                sizeLg="6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IonButton
                  color="medium"
                  size="small"
                  href={`/customer_buildings/monthly_performance/${customer.uuid}`}
                >
                  Monthly Performance
                </IonButton>
              </IonCol>
              <IonCol
                size="12"
                sizeLg="6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IonButton
                  color="medium"
                  size="small"
                  href={`/buildings/performance/${customer.uuid}`}
                >
                  Yearly Performance
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CustomerInfoCard;
