import { IonGrid, IonContent, IonRow, IonCol, IonIcon, IonList, IonItem, IonLabel, IonButton, IonImg } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../actions/settings"
import estar_banner from '../../assets/estar/estar_banner.png'
import status from '../../assets/estar/status.svg'
import "./EStarHomepage.css"
import EstarHeader from "../../components/Energystar/EstarHeader"
import PropertiesTable from "../../components/Energystar/PropertiesTable"
import NotificationPopUp from "../../components/extras/NotificationPopUp"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import { isStaff } from "../../components/extras/Functions"
import { Redirect } from "react-router"

async function checkApi(token:any) {

    return fetch(`${API_URL}/energystar/check-estar-api`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function getRequests(token:any){

    return fetch(`${API_URL}/energystar/requests`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
}

async function postRequest(token:any,data:any){
    return fetch(`${API_URL}/energystar/requests`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
        },
        body: JSON.stringify(data)
    }).then(data => data.json())
}

async function overviewData(token:any){
    return fetch(`${API_URL}/energystar/overview`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
}

const EStarHomepage: React.FC = () => {

    const user = useAppSelector(selectUser)
    const [ estar, setEstar] = useState(false)
    const [ requests, setRequests] = useState([[],[],[]])
    const [ noRequests, setNoRequests] = useState(true)
    const [ token, setToken] = useState<any>()
    const [ overview, setOverview] = useState<any>({})

    const apiTest = async (token:any) => {
        const res = await checkApi(`Token ${token.slice(1,-1)}`)
        if (res['status'] && res['status'] === 'connected'){
            setEstar(true)
        }
        else{
            setEstar(false)
        }
    }

    const handleRequests = async (token:any) => {
        const res = await getRequests(`Token ${token.slice(1,-1)}`)
        const list = JSON.parse(res)
        setRequests(list)
        setNoRequests(list[0].length === 0 && list[1].length === 0 && list[2].length === 0)
    }

    const handleAccept = async (type:string,id:string,propMeter:any = 0) => {
        const data = {
            type:type,
            response :'accept',
            id : id,
            'prop-meter-id' : propMeter
        }
        const res = await postRequest(`Token ${token.slice(1,-1)}`, data)
        NotificationPopUp(res['status'],res['message'])
        handleRequests(token)
    }

    const handleReject = async (type:string,id:string,propMeter:any = 0) => {
        const data = {
            type:type,
            response :'reject',
            id : id,
            'prop-meter-id' : propMeter
        }
        const res = await postRequest(`Token ${token.slice(1,-1)}`, data)
        NotificationPopUp(res['status'],res['message'])
        handleRequests(token)
    }

    const handleOverview = async (token:any)=>{
        const res = await overviewData(`Token ${token.slice(1,-1)}`)
        setOverview(res)
    }

    useEffect(() => {
        const tkn = localStorage.getItem('token')
        setToken(tkn)
        apiTest(tkn)
        handleRequests(tkn)
        handleOverview(tkn)

      }, [])

      if(user.first_name !== '' && !isStaff(user)){
        return <Redirect to='/403'/>
      }


    return (
        <IonContent className='estar-home' >
            <EstarHeader className="mobile-only"/>

            <div className="estar-home-grid">
            <IonGrid>
            <IonRow>
                <IonCol size="12" className="es_banner_container">
                    <IonImg src={estar_banner} alt="energystar banner" className="estar_banner"/>
                    {/* <div className="connection-container">
                        <div>
                            <div>
                            <img className="estar_banner" src={estar_banner} alt="energystar banner"></img>
                            </div>
                            <div className="connection-status" >
                            <h5>Connection Status : &ensp;</h5> <IonIcon className= {estar ? "status-icon connected":"status-icon not-connected"} ios={status} md={status} />
                            </div>
                        </div>
                    </div> */}
                </IonCol>
                <IonCol  size="12" sizeLg="4" hidden >
                    <div className="request-container">
                        <h2>Connection Requests:</h2>
                        { noRequests ? <div className="noRequests">
                            There are no pending connection requests.
                        </div>:
                        <IonList className="requests-list">
                            {requests[0].map((customer:any) => {
                                return (<IonItem key={customer.id}>
                                    <IonLabel>{customer.username} -- {customer.firstName} {customer.lastName}  Customer
                                    </IonLabel>
                                    <IonButton color="success" style={{marginRight:'0.5em'}} onClick={() => handleAccept('customer',customer.id)}>Accept</IonButton>
                                    <IonButton color='danger' onClick={()=> handleReject('customer',customer.id)}>Reject</IonButton>
                                </IonItem>)
                            })}
                            {requests[1].map((property:any) => {
                                return (<IonItem key={property.accountId}>
                                    <IonLabel>{property.username} -- {property.address} Property
                                    </IonLabel>
                                    <IonButton color="success" style={{marginRight:'0.5em'}} onClick={() => handleAccept('property',property.accountId,property.propertyId)}>Accept</IonButton>
                                    <IonButton color='danger' onClick={()=> handleReject('property',property.accountId, property.propertyId)}>Reject</IonButton>
                                </IonItem>)
                            })}
                            {requests[2].map((meter:any) => {
                                return (<IonItem key={meter.accountId}>
                                    <IonLabel>{meter.name}  Meter
                                    </IonLabel>
                                    <IonButton color="success" style={{marginRight:'0.5em'}} onClick={() => handleAccept('meter',meter.accountId, meter.meterId)}>Accept</IonButton>
                                    <IonButton color='danger' onClick={()=> handleReject('meter',meter.accountId, meter.meterId)}>Reject</IonButton>
                                </IonItem>)
                            })}
                        </IonList>}
                    </div>

                </IonCol>
            </IonRow>
            <div>
            <IonRow class="ion-justify-content-center">
                {/* <IonCol size="5" sizeMd="2.5" class="overview-card">
                    <IonLabel>Total Properties: </IonLabel>
                    <p>{overview.properties}</p>
                </IonCol>
                <IonCol size="5" sizeMd="2.5" class="overview-card">
                    <IonLabel>Total Meters: </IonLabel>
                    <p>{overview.meters}</p>
                </IonCol>
                <IonCol size="5" sizeMd="2.5" class="overview-card">
                    <IonLabel>Electric Meters </IonLabel>
                    <p>{overview['electric-meters']}</p>
                </IonCol>
                <IonCol size="5" sizeMd="2.5" class="overview-card">
                    <IonLabel>Gas Meters: </IonLabel>
                    <p>{overview['gas-meters']}</p>
                </IonCol> */}
                <IonCol size="12" sizeLg="4" className="overview-cards">
                    <div className="overview-card-spacer"></div>
                    <IonItem  lines="none" className="overview-card">
                        <IonLabel>Total Properties: </IonLabel>
                        <p>{overview.properties}</p>
                    </IonItem>
                    <IonItem  lines="none" className="overview-card">
                        <IonLabel>Total Meters: </IonLabel>
                        <p>{overview.meters}</p>
                    </IonItem>
                    <IonItem  lines="none" className="overview-card">
                        <IonLabel>Electric Meters </IonLabel>
                        <p>{overview['electric-meters']}</p>
                    </IonItem>
                    <IonItem  lines="none" className="overview-card">
                        <IonLabel>Gas Meters: </IonLabel>
                        <p>{overview['gas-meters']}</p>
                    </IonItem>
                    <IonItem className="connection-status-mobile"  lines="none">
                    <h5>Connection Status : &ensp;</h5> <IonIcon className= {estar ? "status-icon connected":"status-icon not-connected"} ios={status} md={status} />
                    </IonItem>
                </IonCol>
                <IonCol className="homepage-properties-table" size="12" sizeLg="8">
                    <PropertiesTable/>
                </IonCol>
            </IonRow>
            </div>

            </IonGrid>
            </div>

            <IonItem className="connection-status-pc" lines="none">
            <h5>Connection Status : &ensp;</h5> <IonIcon className= {estar ? "status-icon connected":"status-icon not-connected"} ios={status} md={status} />
            </IonItem>
        </IonContent>
    )
}

export default EStarHomepage