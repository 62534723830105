import {  IonGrid, IonRow, IonContent } from "@ionic/react"
import React from "react"
import Ae2Header from "../../components/AE2/Ae2Header"
import AE2BuildingForm from "../../components/forms/AE2BuildingForm"

import './Building.css'

const Building: React.FC= () => {
    return (
    <IonContent className='ae2-building-content'>
    <Ae2Header/>

    <div className="ae2-building">
        <div className="form-container">
            <IonGrid >
                <IonRow className="ion-justify-content-center"><h1>Create a Building</h1></IonRow>
                <IonRow className="ion-justify-content-center">
                    <AE2BuildingForm/>
                </IonRow>
            </IonGrid>
        </div>
    </div>

    </IonContent>)
}

export default Building