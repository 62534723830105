import { IonLabel, IonItem, IonModal, IonTitle, IonButton, IonHeader, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../../actions/settings"
import useHandleState from "../../../utils/useHandleState"
import NotificationPopUp from "../../extras/NotificationPopUp"

interface Props{
    opened:boolean,
    setShowModal:any,
    handleTargets:any,

}

async function addTarget(data:any,token:any) {

    return fetch(`${API_URL}/add_monthly_target`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }

async function getCategories(token:any) {

    return fetch(`${API_URL}/ae2_eng_unit_categories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },

    })
      .then(data => data.json())
   }


const startForm = {
    'building':"",
    'unit':"",
}
const errorsForm:any = {
    'building':"",
    'unit':"",

}

const NewEnergyTargetModal:React.FC<Props> = ({opened,setShowModal,handleTargets}) =>{
    const [form, setForm] = useState<any>(startForm)
    const [errors, setErrors] = useState(errorsForm)
    let params =  new URLSearchParams(window.location.search);
    const id = params.get('id')

    const [categories, handleCategories] = useHandleState([])
    const [category,setCategory] = useState(null)
    const [units, setUnits] = useState([])

    useEffect(()=>{
        const token = localStorage.getItem('token')
        handleCategories(token,getCategories)
        setForm(
            {
                'building':id,
                'unit':''
            }
        )

         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])

    const validate = (name:string, val:any) => {
        switch (name) {
            case 'unit':
                if(val === null ){
                    return {name:'Unit Cannot Be Empty'}
                }
                else{
                    return {name:''}
                }

            default:
                break;
        }
    }

    const validateAll = (form:any) => {
        let validation = errorsForm
        let valid = true
        for (let item in form){

            validation = {...validation, ...validate(item,form[item])}

        }
        setErrors({...errors,...validation})

        for(let val in validation){
            if (validation[val] !== ''){
                valid = false;
            }

        }

        return valid
        }

    const handleFormChange = (value:any,name:string) => {
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
    }

    const handleSubmit = async (form:any) => {
        const token:any = localStorage.getItem('token')
        const valid = validateAll(form) || false
        form['building'] = id
        if(valid){
        const res = await addTarget(form,`Token ${token.slice(1,-1)}`)
            if (res.status === "success"){
                setForm(startForm)
                setShowModal(false)
                handleTargets()
                NotificationPopUp('success','Unit Added')

            }
            else{
                NotificationPopUp('Error',res.detail||res.ERROR,res.error)
            }
        }
    }

    const selectCategory = (category:any)=>{
        setCategory(category)
        setUnits(category.units)
    }

    return (
        <IonModal id="unitsModal" isOpen={opened} onDidDismiss={()=>{setShowModal(false);setForm(startForm)}}>
                <IonHeader><IonTitle>Add Monthly Target </IonTitle></IonHeader>
                    <form>
                        <IonItem>
                            <IonLabel position="stacked">Unit Category:</IonLabel>
                            <IonSelect placeholder="Unit Type" value={category} interface="popover" onIonChange={(e)=>selectCategory(e.detail.value!)}>
                                {categories.map((type:any) => {
                                    return (
                                        <IonSelectOption key={type['id']} value={type}>{type['name']}</IonSelectOption>
                                    )
                                })}
                            </IonSelect>
                            <div className="error-detail">{errors.category}</div>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Unit:</IonLabel>
                            <IonSelect placeholder="Unit Type" value={form.unit} interface="popover" onIonChange={(e)=>handleFormChange(e.detail.value!,'unit')}>
                                {units.map((unit:any) => {
                                    return (
                                        <IonSelectOption key={unit['id']} value={unit['id']}>{unit['name']} - {unit['description']}</IonSelectOption>
                                    )
                                })}
                            </IonSelect>
                            <div className="error-detail">{errors.category}</div>
                        </IonItem>


                    </form>
                    <div className='buttons'>
                    <IonButton onClick={()=>handleSubmit(form)} >Add</IonButton>
                    <IonButton onClick={()=>setShowModal(false)} color='danger'>cancel</IonButton>
                    </div>
            </IonModal>
    )
}

export default NewEnergyTargetModal