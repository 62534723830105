import { IonContent, IonHeader, IonItem, IonLabel, IonModal, IonSelect, IonButtons, IonButton, IonSelectOption, IonInput, IonRow } from "@ionic/react"
import { API_URL } from "../../actions/settings"
import { useEffect, useState } from "react";
import QuicksightEmbed from "../../components/extras/QuicksightEmbed";
import LoadingDots from "../../components/extras/LoadingDots";

interface Props {
    isOpened:any,
    editor:any,
    building:string
}

async function loadDashboardList(token:any) {

    return fetch(`${API_URL}/quicksightDashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function getDashScreenshot(token:any,id:any,form:any) {

    return fetch(`${API_URL}/getDashScreenshot/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(form)

    })
      .then(data => data.json())
   }

async function getDashUrl(id:any,token:string) {
    return fetch(`${API_URL}/buildings/getDashUrl/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }

  const startDashForm = {
    title:'',
    dashId: '',
    params: {}
  }

const InsertDashBoardImage:React.FC<Props> = ({isOpened,editor,building}) =>{
    const [opened,setOpened] = isOpened
    const [dashboardList, setDashboardList] = useState([])
    const [paramsList, setParamsList] = useState<any>([])
    const [dashForm, setDashForm] = useState(startDashForm)
    const [active,setActive] = useState(true)
    const [gettingImage, setGettingImage] = useState(false)

    const handleDashboardList = async () => {
        const token = localStorage.getItem('token') || ""
        const res = await loadDashboardList(`Token ${token.slice(1,-1)}`)
        if(res.status === 'success'){
          setDashboardList(res.data)
          return
        }
        console.log('Could not get List of Quicksight Dashboards')
      }

      useEffect(()=>{
        // handleFormChange({'BuildingUuid':'586f61ec-eadd-4d89-bcb3-0a13785d6a4e'},'params')
        handleDashboardList()
        handleParamChange(building,'BuildingUuid')
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


    const getDashboard = async() => {
      const dashboard = new QuicksightEmbed()
      await dashboard.init()
      const token:any = localStorage.getItem('token')
      const dash_url = await getDashUrl(dashForm['dashId'],`Token ${token.slice(1,-1)}`)
      dashboard.changeSettings({url:dash_url[0],container:`#previewDash`,height:'720px',width:'1280px'})
      dashboard.setParameters(dashForm.params)
      await dashboard.embedDash()
      setActive(false)
    }

    const handleFormChange = (value:any,name:string) => {
        setDashForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
      }
    const handleDashSelected = (val:string) => {
        let dash:any = dashboardList.filter((d:any)=>d.quicksight_id===val)[0]
        handleFormChange(val,'dashId')
        const paramsForm:any = {}
        for (let p of dash.params){
          paramsForm[p.name]=""
        }
        paramsForm['BuildingUuid'] = building
        handleFormChange(paramsForm,'params')
        setParamsList(dash.params || [])
      }

    const handleParamChange = (val:any,name:any) =>{
      const params:any = {...dashForm.params}
      params[name]=val
      setDashForm((prevState) => {
        return {
            ...prevState,
            'params':params
        }
    } )
    }

    const InsertDashImage = async ()=>{
        setGettingImage(true)
        const token:any = localStorage.getItem('token')
        const res = await getDashScreenshot(`Token ${token.slice(1,-1)}`,building,dashForm)
        const string_params = JSON.stringify(dashForm.params)
        if(res.status === 'success')
        {
            editor.insertContent(`<img src="${res.data}" class="dashboard-img" data-dashId="${dashForm['dashId']}" data-params='${string_params}' alt="dash-image">`)
            setOpened(false)
        }
        setGettingImage(false)
    }


    return (
        <IonModal isOpen={opened} className='dashModal modal-dark'  onDidDismiss={()=>setOpened(false)} style={{'--min-width':'60%','--min-height':'70%'}}>
          <IonHeader style={{'fontSize':'2em','textAlign':'center','padding':'0.5em'}}>Insert Dashboard</IonHeader>
          <IonContent style={{'--background':'transparent'}}>
          <form className="dashForm">
            <h3>Dashboard:</h3>
            <IonItem>
              <IonLabel>Title</IonLabel>
              <IonInput placeholder="Dashboard Title" value={dashForm.title} onIonChange={e => handleFormChange(e.detail.value,'title')}/>

            </IonItem>
            <IonItem>
              <IonLabel>Select Dashboard</IonLabel>
              <IonSelect placeholder="Dashboard" interface='alert' value={dashForm.dashId} onIonChange={e => {handleDashSelected(e.detail.value!)}}>
                {dashboardList.map((dash:any,index:number)=>{
                  return(<IonSelectOption value={dash.quicksight_id} key={dash.quicksight_id}>{dash.name}</IonSelectOption>)
                })}
              </IonSelect>

            </IonItem>
            {paramsList.map((param:any) =>{
              return(
                <IonItem key={param.id}>
                  <IonLabel>{param.display_name}</IonLabel>
                  {param.type.id === '0bca5ed6-f59c-46da-bedd-5f49d5a3e27d' ?
                  <IonSelect placeholder={param.display_name} interface="alert" onIonChange={e => {handleParamChange(e.detail.value!,param.name)}}>
                    {param.choices.map((choice:any)=>{
                      return (
                        <IonSelectOption value={choice.value} key={choice.id}>{choice.name}</IonSelectOption>
                      )
                    })}
                  </IonSelect>:
                  <IonInput placeholder={param.display_name} type={param.type.value} onIonChange={e => handleParamChange(e.detail.value!,param.name)}></IonInput>}
                </IonItem>
              )
            } )}
          </form>
            <IonRow className="ion-justify-content-center" >
              <div id="previewDash"/>
            </IonRow>
          </IonContent>
          <IonRow className="ion-justify-content-end">
          <IonButtons>
            <IonButton fill="solid" size="large" shape="round" color="medium" onClick={() => getDashboard()}>Preview</IonButton>
            <IonButton fill="solid" size="large" shape="round" color="primary" disabled={active || gettingImage} onClick={() => InsertDashImage()}>{gettingImage ? <LoadingDots/> : <>ADD</>}</IonButton>
            <IonButton fill="solid" size="large" shape="round" color="danger" onClick={()=>setOpened(false)}>Cancel</IonButton>
          </IonButtons>
          </IonRow>
      </IonModal>
    )
}

export default InsertDashBoardImage