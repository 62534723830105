import { useState } from "react";

export default function useForm(
  startForm: any,
  startErrors: any,
  validation: any
) {
  const [form, setForm] = useState(startForm);
  const [errors, setErrors] = useState(startErrors);

  function handleFormChange(value: any, name: any) {
    if (validation) {
      setErrors({ ...errors, ...validation(name, value) });
    }
    setForm((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    return;
  }

  function resetForm() {
    setForm(startForm);
  }

  function validateForm() {
    let validations = startErrors;
    let valid = true;

    for (let item in form) {
      validations = { ...validations, ...validation(item, form[item]) };
    }

    setErrors({ ...errors, ...validations });

    for (let res in validations) {
      if (validations[res] !== "") {
        valid = false;
      }
    }

    return valid;
  }

  function loadForm(form2load: any) {
    setForm(form2load);
  }

  return { form, handleFormChange, errors, resetForm, validateForm, loadForm };
}
