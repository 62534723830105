import { IonCard, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import "./User.css";
import { useAppSelector } from "../Hooks";
import { selectUser } from "../reducers/UserReducer";
import { useOnlineStatus } from "../utils/useOnlineStatus";

const User: React.FC = () => {
  const user = useAppSelector(selectUser);
  useOnlineStatus();

  return (
    <IonCard href="/account?tab=general" className="user-info">
      <IonCardTitle className="username" data-testid="menu-username">
        {user?.first_name.toUpperCase()} {user?.last_name.toUpperCase()}
      </IonCardTitle>
      <IonCardSubtitle className="email" data-testid="menu-email">
        {user?.email}
      </IonCardSubtitle>
    </IonCard>
  );
};

export default User;
