import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react'
import React from 'react'

interface Props {
    data:[ConsumptionRow]
}

interface ConsumptionData {
    consumptionid : string
    cost: string | null | undefined
    demand : string | null | undefined
    demandCost: string | null | undefined
    endDate : string | null | undefined
    estimatedValue : boolean | string | null
    in_energy_star : boolean | null
    meterId : string
    meter_name: string | null
    property_id: string | null
    property_name: string |null
    startDate : string | null | undefined
    usage : string | null | undefined
    type : string | null | undefined
    units : string | null | undefined
}

interface ConsumptionRow {
    Data:ConsumptionData
    Detail:string
    Status:string
}


const CSVConsumptionResult:React.FC<Props> = ({data}) => {
  return(
  <IonAccordionGroup>
        {data.map((row:ConsumptionRow,idx:Number)=>{
            return(
                <IonAccordion key={`${row.Data.meterId}-${idx}`} value={`${row.Data.meterId}-${idx}`}>
                    <IonItem slot='header'>
                        <IonLabel className={`${row.Detail.toLowerCase()}`}>
                            <span>
                                {row.Detail}
                            </span>
                            <div>
                                {row.Data.property_name}  --  {row.Data.meter_name}
                            </div>
                        </IonLabel>
                    </IonItem>
                    <div slot='content' className="ion-padding" style={{color:'black'}}>
                        <pre>
                            {JSON.stringify(row.Data,null,2)}
                        </pre>
                    </div>
                </IonAccordion>
            )
        })}
  </IonAccordionGroup>
  )
}

export default CSVConsumptionResult