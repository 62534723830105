import { IonButton, IonItem, IonGrid, IonRow, IonLoading } from "@ionic/react"
import { useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { API_URL } from "../../actions/settings"
import SingleMeterForm from "./SingleMeterForm"
import "./MeterForm.css"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import CheckRole from "../extras/CheckRole"
import NotificationPopUp from "../extras/NotificationPopUp"

interface Params{
    id:string
}

const startingForm = {
    type:"",
    name:"",
    unitofmeasure:"",
    metered:false,
    firstbilldate:"",
    inuse:false,
    inactivedate:null
}
const startingErrors = {
    type:false,
    name:false,
    unitofmeasure:false,
    metered:true,
    firstbilldate:false,
    inuse:true,
    inactivedate:false
}


async function submitMeters(token:any,id:string,data:any) {

    return fetch(`${API_URL}/energystar/properties/${id}/meter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }

const validate = (errors:any) => {
    for (const res of errors){
        for(const val of Object.values(res)){
            if( val === false){
                return false
            }
        }
    }

    return true
}




const MeterForm: React.FC = () =>{

    const { id } = useParams<Params>();
    const history = useHistory()
    const user = useAppSelector(selectUser)
    const handleDelete = (index:number) => {
        items.splice(index,1)
        forms.splice(index,1)
        setItems({items: [...items]})
        setForms([...forms])

    }

    const [forms, setForms] = useState([startingForm])
    const [errors, setErrors] = useState([startingErrors])

    const handleFormChange = (index:number,values:any) => {
        forms[index] = values
        setForms([...forms])
    }


    const startItem = <IonItem key={0}>
        <SingleMeterForm index={0} onChange={handleFormChange} values={forms[0]} valid={errors} setValid={setErrors} handleDelete={handleDelete}/>
    </IonItem>

    const [{items}, setItems] = useState<any>({items:[startItem]})




    const handleAddButton = () => {
        forms.push(startingForm)
        setForms([...forms])
        errors.push(startingErrors)
        setErrors([...errors])
        items.push(<IonItem key={items.length}>
            <SingleMeterForm index={items.length} onChange={handleFormChange} values={forms[items.length]} valid={errors} setValid={setErrors}  handleDelete={handleDelete}/>
        </IonItem>);
        setItems({ items: [...items] });

      };



    const handleSubmit = async() => {
        const token = localStorage.getItem('token') || ""
        const valid = validate(errors)
        if (valid){
        const res = await submitMeters(`Token ${token.slice(1,-1)}`,id,forms)
        if (res.status === "success"){
            setItems({items:[startItem]})
            setForms([startingForm])
            NotificationPopUp('success',`${res.data} Meter(s) Created`)
            history.push(`/energystar/properties/${id}`)
        }
        else{
            NotificationPopUp('error',res.detail||res.ERROR)
        }
        }
        else{
            NotificationPopUp('error',"Please Fill Out Entire Form","Form Not Complete")
        }
    }

    if (user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={user.first_name===""}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }

    return (
        <IonGrid>
            <IonRow className="form-title-row" ><h2>Add Meter(s)</h2> <IonButton className="cancel-btn" size="small"  color="danger" href={`/energystar/properties/${id}`}> Cancel </IonButton></IonRow>
        {items}
        <IonRow class="buttons">
            <IonButton type="button" color="medium" onClick={handleAddButton}>Add Meter</IonButton>
            <IonButton type="submit" onClick={handleSubmit}>Submit</IonButton>
        </IonRow>
        </IonGrid>

    )
}

export default MeterForm