import { IonLoading } from '@ionic/react';
import React, { useEffect } from 'react';
import { API_URL } from '../../actions/settings';

interface Params {
    setToken:any,
    code:string,
    state:string,
    session_state:any
}

async function ADLogin(data:any) {
    return fetch(`${API_URL}/msal/login_with_code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(data)

      })
        .then(data => {
            if(data.status===200){
                return data.json()
            }
            else{
                console.log('code',data.status)
                console.log(data)
                window.location.href = "/"
            }
        })
     }

const AzureLogin: React.FC<Params>= ({setToken,code,state,session_state}) =>{

    useEffect(() => {
        const body = {
            "code":code,
            "state":state,
            "session_state":session_state
        }

        const handleLogin = async () =>{
            const res = await ADLogin(body)
            if (res.token){
                setToken(res.token);}
            window.location.href = "/"
        }
        handleLogin()
        },[code,state,setToken,session_state])


    return(
        <IonLoading
    cssClass='my-custom-class'
    isOpen={true}
    message={'Signing In With Microsoft Account'}
    spinner='bubbles'
  />
    )
}

export default AzureLogin