import { IonCol, IonGrid, IonHeader, IonRow, IonTitle } from "@ionic/react"
import './PerformanceComparison.css'
import { calcInflationBaseline, calcPercent } from "../extras/Calculations"

interface Props {
    current:any,
    baseline:any
}

const PerformanceComparison:React.FC<Props> = ({current,baseline}) => {

    const percentChange = (base:any,current:any) =>{
        if (!base || !current){
            return 'N/A'
        }
        const percent = Math.round((current-base)/base * 100)
        return (`${percent}%`)
    }

    if(!baseline){
        return(
            <IonCol>
                <IonHeader>
                    <IonTitle><h1> Performance Comparison </h1></IonTitle>
                </IonHeader>
                <h4>** No Baseline Available For Performance Comparison **</h4>
            </IonCol>
        )
    }

    return(
        <IonCol>
            <IonHeader>
            <IonTitle><h1> Performance Comparison </h1></IonTitle>
            </IonHeader>

            <IonGrid className="performance-table">
                <IonRow className="performance-table-title">
                    <IonCol> </IonCol>
                    <IonCol className="text-center left-border">Baseline ({baseline.month}/{baseline.year})</IonCol>
                    <IonCol className="text-center">Progress as of {current.month}/{current.year}</IonCol>
                    <IonCol className="text-center">Percent Change</IonCol>
                    <IonCol className="text-center">Target</IonCol>
                </IonRow>
                <IonRow >
                    <IonCol className="text-center left-border">Energy Score </IonCol>
                    <IonCol className="text-center">{baseline.base_value}</IonCol>
                    <IonCol className="text-center">{current.score}</IonCol>
                    <IonCol className="text-center">{percentChange(baseline.base_value, current.score)}</IonCol>
                    <IonCol className="text-center">{baseline.target}</IonCol>
                </IonRow>
                <IonRow >
                    <IonCol className="text-center left-border">Site EUI </IonCol>
                    <IonCol className="text-center">{baseline.site_eui}</IonCol>
                    <IonCol className="text-center">{current.site_eui}</IonCol>
                    <IonCol className="text-center">{percentChange(baseline.site_eui, current.site_eui)}</IonCol>
                    <IonCol className="text-center">{baseline.score.target_site_eui || 'N/A'}</IonCol>
                </IonRow>
                <IonRow >
                    <IonCol className="text-center left-border">Energy Cost </IonCol>
                    <IonCol className="text-center">${Number(calcInflationBaseline(baseline)).toLocaleString('en')}</IonCol>
                    <IonCol className="text-center">${Number(current.energycost).toLocaleString('en')}</IonCol>
                    <IonCol className="text-center">{calcPercent(baseline)}</IonCol>
                    <IonCol className="text-center">${Number(baseline.score.target_energycost).toLocaleString('en')}</IonCol>
                </IonRow>
            </IonGrid>
        </IonCol>
    )
}

export default PerformanceComparison