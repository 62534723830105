import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonList } from "@ionic/react"
import { expandOutline, readerSharp } from "ionicons/icons"
import { Building } from "../../interfaces/Building"

interface props{
    campuses:any,
    buildings:any
}

const AE2CustomerCampusList:React.FC<props> = ({campuses,buildings}) => {

    const expand = <IonIcon src={expandOutline}/>

    const handleClick = (e:any) => {
        e.currentTarget.parentNode.parentNode.children[1].children[1].classList.toggle('subtitle-closed')
        e.currentTarget.parentNode.parentNode.children[2].classList.toggle('card-closed')

    }


    if (campuses.length <=0  ){
        return (<h3>There are no Campuses at this moment.</h3>)
    }

    return ( <IonList className="transparent">
        {campuses.map((campus:any) => {
                const buildings_filtered = buildings.filter((building:Building)=>{ return building.campus_uuid !== null && building.campus_uuid.uuid === campus.uuid })
                  return (
                    <IonCard key={campus.uuid} className='campus-card'>
                        <div className="top-right-align">
                        <IonButton  type="button" size="small" fill="clear" color="medium" title="Campus Info Page" href={`/?id=${campus.uuid}&sec=campuses`}><IonIcon src={readerSharp}/></IonButton>
                        <IonButton  type="button" size="small" fill="clear" color="medium" title="View Details" onClick={(e:any) => handleClick(e)}>{expand}</IonButton>
                        </div>
                        <IonCardHeader>
                            <IonCardTitle>{campus.campus_name}</IonCardTitle>
                            <IonCardSubtitle className="space-left m-0 subtitle-closed">{campus.campus_address} {campus.campus_address2 ? campus.campus_address2 : ""},</IonCardSubtitle>
                            <IonCardSubtitle className="space-left m-0">{campus.campus_city}, {campus.campus_state}, {campus.campus_zipcode}</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent className="card-closed" id={`${campus.uuid}-card`}>
                            <p className="space-left">{campus.campus_description}</p>
                            <h3 className="dark">Buildings Associated w/ This Campus: ({buildings_filtered.length})</h3>
                            {buildings_filtered.map((building:Building) => {
                                return(
                                    <IonCard key={`building-${building.uuid}`} className='campus-building-card'>
                                            <div className="top-right-align">
                                            <IonButton type="button" size="small" fill="clear" color="medium" title="Building Info Page" href={`/?id=${building.uuid}&sec=buildings`}><IonIcon src={readerSharp}/></IonButton>
                                            <IonButton type="button" size="small" fill="clear" title="View Details" color="medium" onClick={(e:any) => handleClick(e)}>{expand}</IonButton>
                                            </div>
                                            <IonCardHeader>
                                                <IonCardTitle>{building.building_name}</IonCardTitle>
                                                <IonCardSubtitle className="space-left m-0 subtitle-closed">{building.building_address} {building.building_address_2 ? building.building_address_2 : ""},</IonCardSubtitle>
                                                <IonCardSubtitle className="space-left m-0">{building.building_city}, {building.building_state}, {building.building_zipcode} </IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent className="card-closed card-content">
                                                {building.building_description ? <p className="dark"><span> Description: </span>{building.building_description}</p> : ""}
                                                <p className="dark"> <span>County: </span>{building.building_county}</p>
                                                <p className="dark"><span>Gross Floor Area: </span>{building.building_sq_ft?.toLocaleString('en')} ft<sup>2</sup></p>
                                                {building.building_type ? <p className="dark"><span> Primary Function: </span>{building.building_type.name}</p> : ""}
                                                {building.building_sub_type ? <p className="dark"><span> Building Sub-Type: </span>{building.building_sub_type.sub_type_name}</p> : ""}
                                                {building.building_incentive ? <p className="dark"><span> Building Incentive: </span>${building.building_incentive.toLocaleString('en')}</p> : ""}
                                            </IonCardContent>
                                    </IonCard>
                                )
                            })}
                        </IonCardContent>
                    </IonCard>
                  )
                })}
    </IonList>
    )
}

export default AE2CustomerCampusList