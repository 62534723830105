import { IonToolbar,IonHeader,IonTitle,IonButtons,IonMenuButton } from "@ionic/react"

interface props {
  title?:any,
  className?:string
}

const EstarHeader: React.FC<props> = ({title,className}) => {
    return (
        <IonHeader className={className}>
              <IonToolbar className='top-header'>
                {title ? <IonTitle className='site-title'>{title}</IonTitle> : <IonTitle className='site-title'>Energy Star Portfolio Manager</IonTitle>}
                <IonButtons slot="end">
                    <IonMenuButton />
                  </IonButtons>
              </IonToolbar>
            </IonHeader>
    )
}

export default EstarHeader