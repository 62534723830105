import { IonContent,IonPage } from '@ionic/react';
import Ae2Header from '../../components/AE2/Ae2Header';



import './Homepage.css'
import SuccessStories from '../../components/AE2/AdminSection/SuccessStories';



const AE2SuccessStories: React.FC = () => {


  return (
    <IonPage className='ae2-home' >
      <Ae2Header/>

      <IonContent className='ae2-content'>
        <SuccessStories/>
      </IonContent>
    </IonPage>
  );
};

export default AE2SuccessStories;
