import { useEffect, useState } from "react"
import { API_URL } from "../../actions/settings"
import { IonButton, IonCol, IonContent, IonIcon, IonRow } from "@ionic/react"
import {default as download_icon} from  "../../AE2/static/icons/download-icon.svg"

interface Props{
    refresh:boolean
}

async function getAllTasks(token:string) {
    return fetch(`${API_URL}/celery_tasks/EnergyStar Data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
     }

const contentStyles ={
    // border: 'solid black 2px',
    '--background':'grey'

}

const cardStyle ={
    border: 'solid black 2px',
    '--background':'#e0e0de',
    margin:'2em'

}


const EnergyStarTasks: React.FC<Props> = ({refresh}) =>{
    const [tasks,setTasks] = useState([])


    useEffect(()=>{
        const handleTasks = async () => {
            const tkn:any = localStorage.getItem('token')
            const token = `Token ${tkn.slice(1,-1)}`
            const res = await getAllTasks(token)
            if(res.status === 'success'){
                setTasks(res.data)
            }
        }
        handleTasks();
    },[refresh])

    const DownloadResults = (i:number) =>{
        console.log('Downloading JSON')
        const res:any = tasks[i]
        const blob = new Blob([JSON.stringify(res.task.result)],{type:"application/json;charset=utf-8;"})
        const a = document.createElement('a')
        a.download = `tasks-${res.task.date_created}.json`
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }



    return <IonContent style={contentStyles}>
        { tasks.length <= 0 ? <IonRow>
            <span>No Task History</span>
        </IonRow>: <div style={{borderRadius:'20%'}}>
            <h2>Tasks:</h2>
            {tasks.map((task:any,idx:number) =>{
                return <IonRow key={task.id} style={cardStyle}> <IonCol>
                    <div style={{display:'flex',justifyContent:'space-between'}}><span><b>{task.details}</b></span>
                    <IonButton size="small" fill="clear" color="light"  title="Download Results" onClick={()=>DownloadResults(idx)}><IonIcon src={download_icon} slot="icon-only" ></IonIcon></IonButton></div>
                    <IonRow><IonCol>Date: { new Date(task.task.date_created).toLocaleDateString("en-US")} </IonCol>
                    <IonCol> Status: {task.task.status}</IonCol></IonRow>
                    </IonCol>
                </IonRow>
            }  )}
            </div>}
    </IonContent>
}

export default EnergyStarTasks