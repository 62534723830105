import React from "react";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import { isStaff } from "../../extras/Functions";
import { IonCol, IonRow } from "@ionic/react";

interface Props {
  vpn: VPN;
  style?: any;
  className?: string;
}

interface VPN {
  type: Type | null;
  vpn_client: VPN_Client;
  location: string;
  remote_access: boolean;
  timestamp: string | Date;
}

interface Type {
  id: string;
  type: string;
}

interface VPN_Client {
  client: string;
  id: string;
}

const RemoteAccessDataInfoComponent: React.FC<Props> = ({
  vpn,
  style,
  className,
}) => {
  const user = useAppSelector(selectUser);
  if (isStaff(user)) {
    return (
      <div style={style} className={className}>
        <IonRow
          style={{
            border: "2px solid grey",
          }}
        >
          <IonCol
            size="12"
            style={{
              borderBottom: "2px solid grey",
              textAlign: "center",
              backgroundColor: "black",
              fontWeight: "bold",
            }}
          >
            {" "}
            Remote Access
          </IonCol>
          <IonCol
            size="4"
            style={{ backgroundColor: "#1b1c1b", fontWeight: "bold" }}
          >
            Type
          </IonCol>
          <IonCol
            size="4"
            style={{
              borderLeft: "2px solid grey",
              borderRight: "2px solid grey",
              backgroundColor: "#1b1c1b",
              fontWeight: "bold",
            }}
          >
            Client
          </IonCol>
          <IonCol
            size="4"
            style={{ backgroundColor: "#1b1c1b", fontWeight: "bold" }}
          >
            Info
          </IonCol>
          {vpn.type ? (
            <>
              <IonCol
                size="4"
                style={{ textAlign: "center", borderTop: "2px solid grey" }}
              >
                {vpn.type.type}
              </IonCol>
              <IonCol
                size="4"
                style={{
                  textAlign: "center",
                  borderTop: "2px solid grey",
                  borderLeft: "2px solid grey",
                  borderRight: "2px solid grey",
                }}
              >
                {vpn.vpn_client.client}
              </IonCol>
              <IonCol
                size="4"
                style={{ textAlign: "center", borderTop: "2px solid grey" }}
              >
                {vpn.location}
              </IonCol>
            </>
          ) : (
            <IonCol>
              {" "}
              <p style={{ textAlign: "center" }}> No VPN info available</p>
            </IonCol>
          )}
        </IonRow>
      </div>
    );
  }

  return null;
};

export default RemoteAccessDataInfoComponent;
