import React from "react";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import { isStaff } from "../../extras/Functions";
import { IonButton, IonCol, IonIcon, IonRow } from "@ionic/react";
import { openOutline } from "ionicons/icons";

interface Props {
  server: Server;
  style?: any;
  className?: string;
}

interface Server {
  cust_name: string;
  cust_uuid: string;
  ebo_servers: number;
  software_versions: string;
}

const ServerDataInfoComponent: React.FC<Props> = ({
  server,
  style,
  className,
}) => {
  const user = useAppSelector(selectUser);

  if (isStaff(user)) {
    return (
      <div style={style} className={className}>
        <IonRow
          style={{
            border: "2px solid grey",
          }}
        >
          <IonCol
            size="12"
            style={{
              borderBottom: "2px solid grey",
              textAlign: "center",
              backgroundColor: "black",
              fontWeight: "bold",
            }}
          >
            {" "}
            Server Data
          </IonCol>
          <IonCol
            size="4"
            style={{ backgroundColor: "#1b1c1b", fontWeight: "bold" }}
          >
            Server Count
          </IonCol>
          <IonCol
            size="4"
            style={{
              borderLeft: "2px solid grey",
              borderRight: "2px solid grey",
              backgroundColor: "#1b1c1b",
              fontWeight: "bold",
            }}
          >
            Versions
          </IonCol>
          <IonCol
            size="4"
            style={{ backgroundColor: "#1b1c1b", fontWeight: "bold" }}
          >
            Details
          </IonCol>
          {server ? (
            <>
              <IonCol
                size="4"
                style={{ textAlign: "center", borderTop: "2px solid grey" }}
              >
                {server ? server.ebo_servers : 0}
              </IonCol>
              <IonCol
                size="4"
                style={{
                  textAlign: "center",
                  borderTop: "2px solid grey",
                  borderLeft: "2px solid grey",
                  borderRight: "2px solid grey",
                }}
              >
                {server ? server.software_versions : ""}
              </IonCol>
              <IonCol
                size="4"
                style={{ textAlign: "center", borderTop: "2px solid grey" }}
              >
                {server ? (
                  <IonButton
                    fill="clear"
                    color="light"
                    size="small"
                    href={`/customer/${server.cust_uuid}/controls_versioning`}
                    title="Server Data"
                  >
                    <IonIcon slot="icon-only" src={openOutline} />
                  </IonButton>
                ) : (
                  ""
                )}
              </IonCol>
            </>
          ) : (
            <IonCol>
              {" "}
              <p style={{ textAlign: "center" }}>No server data available.</p>
            </IonCol>
          )}
        </IonRow>
      </div>
    );
  }

  return null;
};

export default ServerDataInfoComponent;
