import React from 'react'
import { IonButton, IonIcon } from "@ionic/react"
import { pencil, trashBin } from 'ionicons/icons'

interface Props {
    props:any,
    handleDelete:any,
    handleEdit:any
}

const EditDeleteCell:React.FC<Props> =  ({props,handleDelete,handleEdit}) => {
  return <div className='center-all'>
        <IonButton color="medium"
            size="small"
            slot="end"
            onClick={() => {handleEdit(props.getValue())}}
            >
            <IonIcon src={pencil} slot="icon-only"/>
        </IonButton>
        <IonButton color="danger"
            size="small"
            slot="end"
            onClick={() => {handleDelete(props.getValue())}}
            >
            <IonIcon src={trashBin} slot="icon-only"/>
        </IonButton>
  </div>
}

export default EditDeleteCell