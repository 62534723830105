import { IonRow } from "@ionic/react"
import "./LoadingDots.css"
interface props{
    bgcolor:string,
    completed:string
}

const ProgressBar:React.FC<props> = (props) => {
    const {bgcolor,completed} = props

    const containerStyles = {
        height: 20,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        margin: 50
      }

      const fillerStyles = {
        height: '100%',
        width: `${completed}%`|| "0%",
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right' as const,
        transition: 'width 1s ease-in-out',
      }

      const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
      }

    return (
        <IonRow className="ion-justify-content-center">
            <div style={containerStyles}>
                <div style={fillerStyles}>
                    <span style={labelStyles}>{`${completed}%`}</span>
                </div>
            </div>
        </IonRow>
    )
}

export default ProgressBar