import React, { useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { API_URL } from "../../../actions/settings";

async function getData(token: string, id: string) {
  return fetch(`${API_URL}/customer/${id}/annual_cost_summary`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return false;
    }
  });
}

interface Props {
  id: string;
}

const AnnualCostsSummary: React.FC<Props> = ({ id }) => {
  const [data, setData] = useState<any>({
    date:'n/a',
    annual_baseline: 0,
    annual_cost_avoidance: 0,
    annual_energycost: 0,
    graph_data:[]
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleData = async () => {
      const token = localStorage.getItem("token") || "";
      const res = await getData(`Token ${token.slice(1, -1)}`, id);
      if (res) {
        setData(res.data);
      } else {
        setError(true);
      }
    };
    handleData();
  }, [id]);

  return (
    <div className="center-all">

    <IonRow >
        <IonCol size="12" >
            <h3 style={{ textAlign: "center", margin:0, padding:0 }}>Annual Cost Summary </h3>
            <h5 style={{ textAlign: "center", margin:0, padding:0 }}>({data.date})</h5>
        </IonCol>
      <IonCol size="12">
        <h2 style={{ textAlign: "center" }}>Annual Utility Costs</h2>
        <div style={{ textAlign: "center" }}>
          <span>${error ? ' n/a' : data.annual_energycost.toLocaleString("en")}</span>
        </div>
      </IonCol>
      <IonCol size="12">
        <h2 style={{ textAlign: "center" }}>Baseline Utility Costs</h2>
        <div style={{ textAlign: "center" }}>
          <span>${error ? ' n/a' : data.annual_baseline.toLocaleString("en")}</span>
        </div>
      </IonCol>
      <IonCol size="12">
        <h2 style={{ textAlign: "center" }}>Annual Cost Avoidance</h2>
        <div style={{ textAlign: "center" }}>
          <span>${error ? ' n/a' : data.annual_cost_avoidance.toLocaleString("en")}</span>
        </div>
      </IonCol>
    </IonRow>

    </div>
  );
};

export default AnnualCostsSummary;
