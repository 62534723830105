import { useEffect, useState } from "react"
import { IonItem, IonLabel, IonInput, IonTextarea, IonPopover, IonContent, IonList, IonRow, IonLoading, IonCol, IonButton, IonImg } from "@ionic/react"
import "./AE2CampusForm.css"
import { API_URL, REACT_APP_GOOGLE_MAPS_KEY } from "../../actions/settings"
import { Redirect, useHistory, useParams } from "react-router";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import SearchSelectInput from "../extras/SearchSelectInput";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import CheckRole from "../extras/CheckRole";
import NotificationPopUp from "../extras/NotificationPopUp";
import useForm from "../../utils/useForm";
import useToggle from "../../utils/useToggle";
import S3Bucket from "../extras/S3Bucket";


interface Params {
    id:string
}


async function editCampus(data:any,token:string) {
    return fetch(`${API_URL}/campuses`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }

async function getCampusInfo(token:any,id:any) {


    return fetch(`${API_URL}/campus/${id}/form_info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }
    })
      .then(data => data.json())
}

const state_choices:string[][] = [
    ['IL', "Illinois"], ['WI', 'Wisconsin'],
    ['AL', 'Alabama'], ['AR', 'Arkansas'], ['AS', 'American Samoa'], ['AZ', 'Arizona'], ['CA', 'California'], ['CO', 'Colorado'],
    ['CT', 'Connecticut'], ['DC', 'District of Columbia'], ['DE', 'Delaware'], ['FL', 'Florida'], ['GA', 'Georgia'], ['GU', 'Guam'],
    ['HI', 'Hawaii'], ['IA', 'Iowa'], ['ID', 'Idaho'], ['IN', 'Indiana'], ['KS', 'Kansas'], ['KY', 'Kentucky'], ['LA', 'Louisiana'], ['MA', 'Massachusetts'],
    ['MD', 'Maryland'], ['ME', 'Maine'], ['MH', 'Marshall Islands'], ['MI', 'Michigan'], ['MN', 'Minnesota'], ['MO', 'Missouri'], ['MP', 'Northern Mariana Islands'],
    ['MS', 'Mississippi'], ['MT', 'Montana'], ['NC', 'North Carolina'], ['ND', 'North Dakota'], ['NE', 'Nebraska'], ['NH', 'New Hampshire'], ['NJ', 'New Jersey'],
    ['NN', 'Navajo Nation'], ['NV', 'Nevada'], ['NY', 'New York'], ['OH', 'Ohio'], ['OK', 'Oklahoma'], ['OR', 'Oregon'], ['PA', 'Pennsylvania'], ['PI', 'Pacific Island'],
    ['PR', 'Puerto Rico'], ['RI', 'Rhode Island'], ['SC', 'South Carolina'], ['SD', 'South Dakota'], ['TN', 'Tennessee'], ['TT', 'Trust Territories'],
    ['TX', 'Texas'], ['UM', 'U.S. Minor Outlying Islands'], ['UT', 'Utah'], ['VA', 'Virginia'], ['VI', 'Virgin Islands'], ['VT', 'Vermont'], ['WA', 'Washington'],
    ['WQ', 'Wake Island'], ['WV', 'West Virginia'], ['WY', 'Wyoming'], ['AB', 'Alberta'], ['BC', 'British Columbia'], ['MB', 'Manitoba'], ['NB', 'New Brunswick'],
    ['NL', 'Newfoundland'], ['NS', 'Nova Scotia'], ['NT', 'Northern Territories'], ['NU', 'Nunavut'], ['ON', 'Ontario'], ['PE', 'Prince Edward Island'],
    ['QC', 'Quebec'], ['SK', 'Saskatchewan'], ['YT', 'Yukon']]




const EditAE2CampusForm: React.FC = () => {

    const history = useHistory()
    const { id  }=useParams<Params>()
    const user = useAppSelector(selectUser)
    const [image,setImg] = useState<any>('')
    const [imageUrl,setImgUrl] = useState<any>('')

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
      } = usePlacesService({
        apiKey: REACT_APP_GOOGLE_MAPS_KEY,
      });

      const handlePlace = (item:any) => {
        placesService?.getDetails(
            {
              placeId: item.place_id,
            },
            (placeDetails:any) => {
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('postal_code'))[0].long_name,'campus_zipcode');
                handleFormChange(
                    `${placeDetails.address_components.filter((r:any) => r.types.includes('street_number'))[0].long_name} ${placeDetails.address_components.filter((r:any) => r.types.includes('route'))[0].short_name}`,'campus_address');
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('locality'))[0].long_name,'campus_city');
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('administrative_area_level_1'))[0].short_name,'campus_state');
                }
          );

    }

    const startingForm = {
        cust_uuid: '',
        campus_name: null,
        campus_description: '',
        campus_address: null,
        campus_address_2:null,
        campus_city: null,
        campus_state: "",
        campus_zipcode: null,
    }
    const startingErrors:any = {
        cust_uuid: '',
        campus_name : '',
        campus_description:'',
        campus_address: '',
        campus_address_2: '',
        campus_city: "",
        campus_state: "",
        campus_zipcode: "",
    }

    const validate = (name:string, val:any) => {
        const regExp = /[a-zA-Z]/g;
        switch (name) {
            case 'campus_name':
                if(val === null || val.length <=1){
                    return {campus_name:'Name Must Be longer than 2 letters'}
                }
                else{
                    return {campus_name:''}
                }

            case 'campus_address':
                if(val === null || val.length === 0 || val === " " ){
                    return {campus_address:'Address cannot be empty'}
                }
                else{
                    return {campus_address:''}
                }
            case 'campus_city':
                if(val === null || val.length === 0 ){
                    return {campus_city:'City cannot be empty'}
                }
                else{
                    return {campus_city:''}
                }

            case 'campus_state':
                if(val === null || val.length === 0 ){
                    return {campus_state:'Select A State'}
                }
                else if(!state_choices.some(st=> st.includes(val)) || val.length >=3  ){
                    return {campus_state:'Select State From The List'}
                }
                else{
                    return {campus_state:''}
                }
            case 'campus_zipcode':
                if(val === null || val.length === 0 ){
                    return {campus_zipcode:'Zip Code cannot be empty'}

                }
                else if (regExp.test(val)){
                    return {campus_zipcode:'Zip Code cannot contain letters'}
                }
                else{
                    return {campus_zipcode:''}
                }
                case 'cust_uuid':
                    if(val === null || val.length === 0 ){
                        return {cust_uuid:'Select A Customer'}
                    }
                    else{
                        return {cust_uuid:''}
                    }

            default:
                break;
        }
    }

    const {form, handleFormChange, errors, resetForm, validateForm, loadForm } = useForm(startingForm,startingErrors,validate)
    const [loaded, handleToggle] = useToggle()

    const getInfo = async (token:any, id:any, setLoaded:any, setForm:any) => {
        const campus = await getCampusInfo(`Token ${token.slice(1,-1)}`,id)
        setForm(
            {
                uuid: campus.data.uuid,
                cust_uuid: campus.data.cust_uuid,
                campus_name: campus.data.campus_name,
                campus_description: campus.data.campus_description,
                campus_address: campus.data.campus_address,
                campus_address_2: campus.data.campus_address_2 ? campus.data.campus_address_2 : "" ,
                campus_city: campus.data.campus_city,
                campus_state: campus.data.campus_state,
                campus_zipcode: campus.data.campus_zipcode,
            }
        )
        setLoaded()
    }

    useEffect(() => {
        const token = localStorage.getItem('token')
        getInfo(token, id, handleToggle, loadForm)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id])



    const handleSubmit = async () => {
        const token:any = localStorage.getItem('token')
        const valid = validateForm() || false
        if(valid){
        const res = await editCampus(form,`Token ${token.slice(1,-1)}`)

            if (res.status === "success"){
                if(image){
                    S3Bucket.UploadImage("thumbnails/pics",id,image,'campus')
                    }
                resetForm()
                history.push(`/?id=${id}&sec=campuses`)
                NotificationPopUp("success","Campus Edited")

            }
            else{
                NotificationPopUp('error',res.detail||res.ERROR,res.error||"Error")
            }
        }
    }

    if (!loaded || user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={!loaded}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }



    const clickAddress = (input:any,e:any) => {
        if (input.length === 1){
            e.target.click()
        }
    }

    const onImageChange = (e:any) =>{
        const img = e.target.files[0]
        setImg(img)
        setImgUrl(URL.createObjectURL(img))
    }

    return (
        <form>
            <IonRow className="form-title-row" ><h2> Edit Campus </h2> <IonButton className="cancel-btn" size="small"  color="danger" href={`/?id=${id}&sec=campuses`}> Cancel </IonButton></IonRow>
            <IonRow>
                <IonItem lines='none'>
                    <IonLabel position="stacked">Image</IonLabel>
                    <IonRow>
                        {imageUrl ? <IonImg className="preview-image" src={imageUrl}/>:''}
                        <input type="file" accept="image/*" onChange={onImageChange} />
                    </IonRow>
                </IonItem>
            </IonRow>
            <IonItem lines="none">
                <IonLabel position="stacked">Campus Name</IonLabel>
                <IonInput className={errors.campus_name === '' ? "valid":'invalid'} aria-labelledby="Campus Name" value={form.campus_name} placeholder="Name" onIonChange={e => (handleFormChange(e.detail.value!,'campus_name'))}> </IonInput>
                <div className="error-detail">{errors.campus_name}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Campus Description</IonLabel>
                <IonTextarea auto-grow value={form.campus_description} aria-labelledby="Campus Description" placeholder='Description' onIonChange={e => (handleFormChange(e.detail.value!,'campus_description'))}> </IonTextarea>
                <div className="error-detail">{errors.campus_description}</div>
            </IonItem>
            <IonItem lines="none">
                <IonRow className="address-row">
                    <IonCol size="6">
                    <IonLabel position="stacked">Address:</IonLabel>
                    <IonInput id="address" className={errors.campus_address === '' ? "valid":'invalid'} aria-labelledby="Address" value={form.campus_address} placeholder="Address" onIonChange={e => {handleFormChange(e.detail.value!,'campus_address'); getPlacePredictions({input:e.detail.value!});clickAddress(e.detail.value!,e)}}> </IonInput>
                    <IonPopover trigger="address" className='address-list' reference="trigger" dismissOnSelect showBackdrop={false} keyboardClose={false} size='cover'>
                                <IonContent>
                                    <IonList>
                            {placePredictions.map((item) => <IonItem className="address-item" button key={item.place_id} onClick={() => handlePlace(item)}><IonLabel color="dark">{item.description}</IonLabel></IonItem>)}
                        </IonList></IonContent>
                    </IonPopover>
                    <div className="error-detail">{errors.campus_address}</div>
                    </IonCol>
                    <IonCol >
                            <IonLabel position="stacked">Address 2:</IonLabel>
                            <IonInput className={errors.campus_address_2 === '' ? "valid":'invalid'} aria-labelledby="Address 2" value={form.campus_address_2} placeholder="Address 2" onIonChange={e => (handleFormChange(e.detail.value!,'campus_address_2'))}> </IonInput>
                            <div className="error-detail">{errors.campus_address_2}</div>
                    </IonCol>
                </IonRow>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">City</IonLabel>
                <IonInput className={errors.campus_city === '' ? "valid":'invalid'}value={form.campus_city} aria-labelledby="City" placeholder="City" onIonChange={e => (handleFormChange(e.detail.value!,'campus_city'))}> </IonInput>
                <div className="error-detail">{errors.campus_city}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">State</IonLabel>
                <SearchSelectInput list={state_choices} errors={errors.campus_state} placeholder={'State'} label="State" form={form.campus_state} handleFormChange={handleFormChange} formName={'campus_state'}/>
                    <div className="error-detail">{errors.campus_state}</div>
            </IonItem>
            <IonItem lines="none">
            <IonLabel position="stacked">Zip Code</IonLabel>
                <IonInput className={errors.campus_zipcode === '' ? "valid":'invalid'} aria-labelledby="Zip Code" value={form.campus_zipcode} placeholder="Zip Code" onIonChange={e => (handleFormChange(e.detail.value!,'campus_zipcode'))}> </IonInput>
                <div className="error-detail">{errors.campus_zipcode}</div>
            </IonItem>
            <div className="button-container">
            <IonItem className='form-submit-button' button color='primary' onClick={handleSubmit}>
                <IonLabel>
                  Submit
                </IonLabel>
            </IonItem>
            </div>


        </form>
    )
}

export default EditAE2CampusForm