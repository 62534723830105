import React, { useState } from "react";
import {
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonButton,
  IonInput,
  IonRippleEffect,
  IonRow,
} from "@ionic/react";
import { API_URL } from "../../actions/settings";
import "./LoginCard.css";
import SignInButton from "../auth/SignInButton";
import NotificationPopUp from "../extras/NotificationPopUp";

interface Props {
  setToken: any;
}

async function loginUser(credentials: any) {
  return fetch(`${API_URL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const LoginCard: React.FC<Props> = ({ setToken }) => {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const token = await loginUser({
        username,
        password,
      });
      let res = JSON.parse(token);
      if (res.error) {
        setErrorMessage(res.error);
      } else {
        setToken(res.token);
        NotificationPopUp("success", "Log In Successful");
      }
    } catch {
      setErrorMessage("Could Not Connect To API");
      console.log("Could Not Connect To API");
    }
  };

  return (
    <IonContent>
      <div className="login-container">
        <div className="login-card">
          <IonCard className="login">
            <IonCardHeader>
              <IonCardTitle className="login-card-title">Login</IonCardTitle>
              {errorMessage && (
                <IonCardSubtitle className="errors">
                  {errorMessage}
                </IonCardSubtitle>
              )}
            </IonCardHeader>

            <IonCardContent className="login-section">
              <form onSubmit={handleSubmit}>
                <IonItem>
                  <IonInput
                    placeholder="Username"
                    id="username"
                    className="username"
                    type="text"
                    autocomplete="username"
                    onIonChange={(e) => setUsername(e.detail.value!)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonInput
                    placeholder="Password"
                    id="password"
                    className="password"
                    type="password"
                    onIonChange={(e) => setPassword(e.detail.value!)}
                  ></IonInput>
                </IonItem>
                <input type="submit" hidden />
                <IonButton className="submit-btn" id="submit-btn" type="submit">
                  Login<IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
                <IonRow className="ion-justify-content-center">
                  <a style={{ color: "white" }} href="/reset_password">
                    {" "}
                    Reset Password
                  </a>
                </IonRow>
              </form>
            </IonCardContent>
          </IonCard>
          <div className="ms-div">
            <SignInButton setToken={setToken} />
          </div>
        </div>
      </div>
    </IonContent>
  );
};
