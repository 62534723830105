import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
} from "@ionic/react";
import { useState } from "react";
import "./GeneralSetting.css";
import { closeOutline } from "ionicons/icons";
import { API_URL } from "../../actions/settings";
import NotificationPopUp from "../extras/NotificationPopUp";
import ReactPasswordChecklist from "react-password-checklist";

async function editUser(data: any, token: string) {
  return fetch(`${API_URL}/user/password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

const startErrors = {
  old_password: "",
  password: "",
  confirm_password: "",
};

const SecuritySettings: React.FC = () => {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [errors, setErrors] = useState(startErrors);
  const [validPassword, setValidPassword] = useState(false);

  const startForm = {
    old_password: "",
    password: "",
    confirm_password: "",
  };

  const [form, setForm] = useState(startForm);

  const handleFormChange = (value: any, name: string) => {
    validate(name, value);
    setForm((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePasswordChange = async (e: any) => {
    e.preventDefault();
    if (
      errors.password === "" &&
      errors.old_password === "" &&
      errors.confirm_password === "" &&
      validPassword
    ) {
      const token: any = localStorage.getItem("token");
      const res = await editUser(form, `Token ${token.slice(1, -1)}`);
      if (res.status === "success") {
        NotificationPopUp("Success", "Password Changed");
        setShowPasswordModal(false);
      } else {
        NotificationPopUp(
          "Error",
          `Could not Change Password. \n ${res.detail || res.error}`
        );
      }
    }
  };

  const validate = (name: string, val: any) => {
    switch (name) {
      case "old_password":
        if (val === null || val.length <= 0) {
          setErrors({ ...errors, old_password: "Cannot Be Empty" });
        } else {
          setErrors({ ...errors, old_password: "" });
        }

        break;
      case "password":
        if (val === null || val.length <= 0) {
          setErrors({ ...errors, password: "Cannot Be Empty" });
        } else {
          setErrors({ ...errors, password: "" });
        }
        break;
      case "confirm_password":
        if (val === null || val.length <= 0) {
          setErrors({ ...errors, confirm_password: "Cannot Be Empty" });
        } else {
          setErrors({ ...errors, confirm_password: "" });
        }
        break;
    }
  };

  return (
    <>
      <IonHeader className="header">
        {" "}
        <h2>Security</h2>
      </IonHeader>
      <IonGrid fixed>
        <IonRow className="setting-row">
          <IonCol size="3">Password:</IonCol>
          <IonCol size="7">Change AE2 Account Password</IonCol>
          <IonCol size="2">
            <IonButton
              id="nameBtn"
              size="small"
              color="dark"
              onClick={() => setShowPasswordModal(true)}
            >
              Edit
            </IonButton>
          </IonCol>
          <IonModal
            isOpen={showPasswordModal}
            onDidDismiss={() => setShowPasswordModal(false)}
            className="modal-form"
          >
            <IonContent>
              <form onSubmit={handlePasswordChange}>
                <IonRow className="modal-close">
                  <IonButton
                    expand="block"
                    color="danger"
                    onClick={() => setShowPasswordModal(false)}
                  >
                    <IonIcon src={closeOutline}></IonIcon>
                  </IonButton>
                </IonRow>
                <IonItem>
                  <IonLabel position="stacked">Current Password:</IonLabel>
                  <IonInput
                    type="password"
                    className={errors.old_password === "" ? "valid" : "invalid"}
                    value={form.old_password}
                    onIonChange={(e) => {
                      handleFormChange(e.detail.value!, "old_password");
                    }}
                  />
                  <div className="error-detail">{errors.old_password}</div>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">New Password</IonLabel>
                  <IonInput
                    type="password"
                    className={errors.password === "" ? "valid" : "invalid"}
                    value={form.password}
                    onIonChange={(e) => {
                      handleFormChange(e.detail.value!, "password");
                    }}
                  />
                  <div className="error-detail">{errors.password}</div>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Confirm Password</IonLabel>
                  <IonInput
                    type="password"
                    className={
                      errors.confirm_password === "" ? "valid" : "invalid"
                    }
                    value={form.confirm_password}
                    onIonChange={(e) => {
                      handleFormChange(e.detail.value!, "confirm_password");
                    }}
                  />
                  <div className="error-detail">{errors.confirm_password}</div>
                </IonItem>
                <ReactPasswordChecklist
                  className="password-checklist"
                  rules={[
                    "notEmpty",
                    "minLength",
                    "number",
                    "lowercase",
                    "capital",
                    "match",
                  ]}
                  minLength={14}
                  value={form.password}
                  valueAgain={form.confirm_password}
                  onChange={(isValid) => {
                    setValidPassword(isValid);
                  }}
                  style={{ marginBottom: "10px", color: "white" }}
                />
                <IonRow className="modal-btns">
                  <IonButton expand="block" type="submit">
                    Save Changes
                  </IonButton>
                </IonRow>
              </form>
            </IonContent>
          </IonModal>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default SecuritySettings;
