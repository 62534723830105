import { IonContent, IonPage, IonRow } from "@ionic/react";
// import { useParams } from "react-router"
import Ae2Header from "../../components/AE2/Ae2Header";
import MonthlyBuildingPerformanceTable from "../../components/AE2/AnalyticsSection/MonthlyBuildingPerformanceTable";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import { isStaff } from "../../components/extras/Functions";
import { Redirect } from "react-router";

// interface Params {
//     id:any
// }
interface vars {
  all?: boolean;
  type?: any;
}

const AE2BuildingMonthlyPerformance: React.FC<vars> = ({
  all = false,
  type = "",
}) => {
  const user = useAppSelector(selectUser);

  if (user.first_name !== "" && !isStaff(user)) {
    return <Redirect to="/403" />;
  }

  return (
    <IonPage className="ae2-home">
      <Ae2Header />
      <IonContent className="main-background">
        <IonRow className="ion-justify-content-center">
          <h1 className="main-heading">Monthly Building Performance</h1>
        </IonRow>
        <MonthlyBuildingPerformanceTable all={all} type={type} />
      </IonContent>
    </IonPage>
  );
};

export default AE2BuildingMonthlyPerformance;
