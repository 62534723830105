import React, { useEffect, useState } from "react";
import { API_URL } from "../../actions/settings";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonInput,
  IonItem,
  IonRow,
  IonSearchbar,
} from "@ionic/react";
import Ae2Header from "../../components/AE2/Ae2Header";
import LoadingSpinner from "../../components/extras/LoadingSpinner";
import useToggle from "../../utils/useToggle";
import NotificationPopUp from "../../components/extras/NotificationPopUp";

interface Props {}

interface Customer {
  uuid: string;
  cust_name: string;
  buildings: [Building];
}

interface Building {
  id: string;
  name: string;
  meters: [MeterDict];
}

type MeterDict = {
  meter: Meter;
};

interface Meter {
  id: string;
  inuse: boolean;
  meter_id: string;
  name: "string";
  type: Type;
}

type Type = {
  id: number;
  name: string;
};

async function getCustomerBuildings(token: string) {
  return fetch(`${API_URL}/energystar/customers/buildings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

async function getTemplate(token: string,data:any) {
  return fetch(`${API_URL}/energystar/consumption_data_template`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data)
  }).then(async (data) => {
    const res:any ={status_code:data.status, data:''}
    if (data.status === 200){
      res.data = await data.blob()}
    else{
      res.data = data.json()
    }
    return res
  });
}

const ConsumptionDataUploadTemplate: React.FC<Props> = () => {
  const [loaded, setLoaded] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState<any>([]);
  const [selectedProperties, setSelectedProperties] = useState<any>([]);
  const [selectedMeters, setSelectedMeters] = useState<any>([]);
  const [months, setMonths] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [selectAll, toggleSelect] = useToggle(false)
  const [filteredCustomers, setFilteredCustomers] = useState([])

  useEffect(() => {
    const handleGetBuildings = async () => {
      const tkn: any = localStorage.getItem("token");
      const token = `Token ${tkn.slice(1, -1)}`;
      const res = await getCustomerBuildings(token);
      setCustomers(res.data);
      setFilteredCustomers(res.data);
      setLoaded(true);
    };
    handleGetBuildings();
  }, []);

  const validateMonths = (id: string, val: string) => {
    if (Number(val) <= 0 || Number(val) > 24) {
      setErrors((prev: any) => {
        return {
          ...prev,
          [id]: "Must be Between 1 and 24",
        };
      });
    } else {
      setErrors((prev: any) => {
        return {
          ...prev,
          [id]: "",
        };
      });
    }
  };

  const handleSelectAll = ()=>{
    toggleSelect()
    if(selectAll){
      setSelectedCustomers([])
      setSelectedProperties([])
      setSelectedMeters([])
      setMonths({})
      setErrors({})
    }else{
      const customer_ids = customers.map((c:Customer)=>c.uuid)
      setSelectedCustomers(customer_ids)
      const building_ids = customers.map((c:Customer)=>c.buildings.map((b:Building)=>b.id)).flat()
      setSelectedProperties(building_ids)
      const meter_ids = customers.map((c:Customer)=>c.buildings.map((b:Building)=>b.meters.map((m:MeterDict)=>m.meter.id)).flat()).flat()
      setSelectedMeters(meter_ids)
      const selected_months:{ [key: string]: any } = {};
      meter_ids.forEach((m:any)=>selected_months[m]=1)
      setMonths(selected_months)
      const selected_meters_errors: { [key: string]: any } = {};
          meter_ids.forEach((i: any) => {
            selected_meters_errors[i] = "";
          });
      setErrors(selected_meters_errors)

    }
  }

  const handleGetTemplate = async () =>{
    const tkn: any = localStorage.getItem("token");
    const token = `Token ${tkn.slice(1, -1)}`;
    const res = await getTemplate(token,months)
    if(res.status_code === 200){
      NotificationPopUp('success','Downloading','Success',500)
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Add_Bills_Template.xlsx`);
      document.body.appendChild(link);
      link.click()
      link.parentNode!.removeChild(link);
    }else{
      NotificationPopUp('Error','Error While Creating the Template','Could not Download Template')
    }

  }

  const handleCustomerSelect = (customer: Customer) => {
    setSelectedCustomers((prev: any) => {
      const existingIndex = prev.indexOf(customer.uuid);
      const building_ids = customer.buildings.map((b: Building) => b.id);
      const meter_ids = customer.buildings
        .map((b: Building) => b.meters.filter((m:MeterDict) => m.meter.type.id === 11 || m.meter.type.id === 19 ).map((m: MeterDict) => m.meter.id))
        .flat();

      if (existingIndex !== -1) {
        setSelectedProperties((prev: any) => {
          return prev.filter((i: any) => !building_ids.includes(i));
        });
        setSelectedMeters((prev: any) => {
          return prev.filter((i: any) => !meter_ids.includes(i));
        });
        setMonths((prev: any) => {
          meter_ids.forEach((i: any) => {
            delete prev[i];
          });
          return {
            ...prev,
          };
        });
        setErrors((prev: any) => {
          meter_ids.forEach((i: any) => {
            delete prev[i];
          });
          return {
            ...prev,
          };
        });

        return prev.filter((el: any) => el !== customer.uuid);
      } else {
        setSelectedProperties((prev: any) => {
          return [...prev, ...building_ids];
        });
        setSelectedMeters((prev: any) => {
          return [...prev, ...meter_ids];
        });
        setMonths((prev: any) => {
          const new_meters: { [key: string]: any } = {};
          meter_ids.forEach((i: any) => {
            new_meters[i] = 1;
          });
          return {
            ...prev,
            ...new_meters,
          };
        });
        setErrors((prev: any) => {
          const new_meters: { [key: string]: any } = {};
          meter_ids.forEach((i: any) => {
            new_meters[i] = "";
          });
          return {
            ...prev,
            ...new_meters,
          };
        });
        return [...prev, customer.uuid];
      }
    });
  };

  const handlePropertySelect = (building: Building) => {
    setSelectedProperties((prev: any) => {
      const existingIndex = prev.indexOf(building.id);
      const meter_ids = building.meters.filter((m:MeterDict) => m.meter.type.id === 11 || m.meter.type.id === 19 ).map((m: MeterDict) => m.meter.id)

      if (existingIndex !== -1) {
        setSelectedMeters((prev: any) => {
          return prev.filter((i: any) => !meter_ids.flat().includes(i));
        });
        setMonths((prev: any) => {
          meter_ids.forEach((i: any) => {
            delete prev[i];
          });
          return {
            ...prev,
          };
        });
        setErrors((prev: any) => {
          meter_ids.forEach((i: any) => {
            delete prev[i];
          });
          return {
            ...prev,
          };
        });
        return prev.filter((el: any) => el !== building.id);
      } else {
        setSelectedMeters((prev: any) => {
          return [...prev, ...meter_ids];
        });
        setMonths((prev: any) => {
          const new_meters: { [key: string]: any } = {};
          meter_ids.forEach((i: any) => {
            new_meters[i] = 1;
          });
          return {
            ...prev,
            ...new_meters,
          };
        });
        setErrors((prev: any) => {
          const new_meters: { [key: string]: any } = {};
          meter_ids.forEach((i: any) => {
            new_meters[i] = "";
          });
          return {
            ...prev,
            ...new_meters,
          };
        });
        return [...prev, building.id];
      }
    });
  };

  const handleMeterSelect = (meter: Meter) => {
    setSelectedMeters((prev: any) => {
      const existingIndex = prev.indexOf(meter.id);

      if (existingIndex !== -1) {
        setMonths((prev: any) => {
          delete prev[meter.id];
          return { ...prev };
        });
        setErrors((prev: any) => {
          delete prev[meter.id];
          return { ...prev };
        });
        return prev.filter((el: any) => el !== meter.id);
      } else {
        setMonths((prev: any) => {
          return {
            ...prev,
            [meter.id]: 1,
          };
        });
        setErrors((prev: any) => {
          return {
            ...prev,
            [meter.id]: "",
          };
        });
        return [...prev, meter.id];
      }
    });
  };

  const handleFilter = (searchTerm:string)=>{
    const searchTermLower = searchTerm.toLowerCase();
    setFilteredCustomers(customers.filter((customer:Customer) =>(
      customer.cust_name.toLowerCase().includes(searchTermLower) ||
      customer.buildings.some((building) =>
        building.name.toLowerCase().includes(searchTermLower)
      ) ||
      customer.buildings.some((building) =>
        building.meters.some((meter) =>
          meter.meter.name.toLowerCase().includes(searchTermLower)
        )
      )
    )))
  }

  function handleMonthChange(meter_id: string, value: string) {
    validateMonths(meter_id, value);
    setMonths((prevState: any) => {
      return {
        ...prevState,
        [meter_id]: value,
      };
    });
    return;
  }

  // console.log(filteredCustomers);
  // console.log("customers", selectedCustomers);
  // console.log("properties", selectedProperties);
  // console.log("meters", selectedMeters);
  // console.log("months", months);
  // console.log(errors);

  return (
    <IonContent className="main-background">
      <Ae2Header />
      {loaded ? (
        <div>
          <IonRow className="center-all">
            <h1> Consumption Data Template </h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <p>
              {" "}
              Select the meters that you want to include in the template. Select
              how many bills you will upload for each meter.
            </p>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton href="/energystar_data/?modal=consumption">
              Upload Template
            </IonButton>
            <IonButton
              fill="solid"
              color="medium"
              onClick={() => handleGetTemplate()}
            >
              Download Template
            </IonButton>
          </IonRow>
          <div style={{
            width:'100%',
            justifyContent:"center",
            display:'flex'
          }}>
          <IonRow className="button-bottom-right" style={{
            width: "80%",
          }}>
            <IonRow style={{minWidth:'50%'}}>
              <IonCol size="9"><IonSearchbar onIonInput={e=>handleFilter(e.detail.value!)}/></IonCol>
              <IonCol sizeMd="3">
              </IonCol>
            </IonRow>
            <IonButton onClick={() => handleSelectAll()} color={selectAll?'medium':'primary'}>
              {selectAll ? "Unselect All" : "Select All"}
            </IonButton>
          </IonRow>
          </div>

          <div style={{ width: "100%" }}>
            <IonRow
              className="ion-justify-content-center"
              style={{ width: "100%" }}
            >
              <div
                style={{
                  width: "80%",
                  maxHeight: "75vh",
                  overflowY: "auto",
                }}
              >
                {filteredCustomers.map((customer: Customer) => {
                  if (customer.buildings.length < 1) {
                    return "";
                  }
                  return (
                    <div key={customer.uuid}>
                      <IonItem className="header-list">
                        <IonCheckbox
                          labelPlacement="start"
                          mode="ios"
                          checked={selectedCustomers.includes(customer.uuid)}
                          onIonChange={() => handleCustomerSelect(customer)}
                        >
                          {customer.cust_name}
                        </IonCheckbox>
                      </IonItem>
                      <div>
                        {customer.buildings.map((building: Building) => {
                          if (building.meters.length < 1) {
                            return "";
                          }
                          return (
                            <div
                              key={building.id}
                              hidden={
                                !selectedCustomers.includes(customer.uuid)
                              }
                              style={{ paddingLeft: "15px" }}
                            >
                              <IonItem style={{ "--background": "grey" }}>
                                <IonCheckbox
                                  labelPlacement="start"
                                  mode="ios"
                                  checked={selectedProperties.includes(
                                    building.id
                                  )}
                                  onIonChange={() =>
                                    handlePropertySelect(building)
                                  }
                                >
                                  {building.name}
                                </IonCheckbox>
                              </IonItem>
                              {building.meters.map((meter: MeterDict) => {
                                return (
                                  <div
                                    key={meter.meter.id}
                                    hidden={
                                      !selectedProperties.includes(building.id)
                                    }
                                    style={{ paddingLeft: "2em" }}
                                  >
                                    <IonItem style={{ width: "100%" }}>
                                      <IonCheckbox
                                        labelPlacement="end"
                                        justify="start"
                                        mode="ios"
                                        checked={selectedMeters.includes(
                                          meter.meter.id
                                        )}
                                        onIonChange={() =>
                                          handleMeterSelect(meter.meter)
                                        }
                                      >
                                        {meter.meter.name} -{" "}
                                        {meter.meter.type.name}
                                      </IonCheckbox>
                                      <IonInput
                                        className={
                                          errors[meter.meter.id] === "" ||
                                          !months.hasOwnProperty(meter.meter.id)
                                            ? "valid"
                                            : "invalid"
                                        }
                                        label="Number of Months:"
                                        max={24}
                                        maxlength={2}
                                        min={0}
                                        value={months[meter.meter.id]}
                                        type="number"
                                        onIonChange={(e) =>
                                          handleMonthChange(
                                            meter.meter.id,
                                            e.detail.value!
                                          )
                                        }
                                      >
                                        <div className="error-detail">
                                          {errors[meter.meter.id]}
                                        </div>
                                      </IonInput>
                                    </IonItem>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </IonRow>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </IonContent>
  );
};

export default ConsumptionDataUploadTemplate;
