import { IonToolbar,IonHeader,IonTitle,IonButtons,IonMenuButton, IonText } from "@ionic/react"
import './Ae2Header.css'
import { ReactComponent as AE2Icon } from '../../assets/alpha.svg'

const Ae2Header: React.FC = () => {


    const handleClick = () =>{
      window.location.href = '/'
    }

    return (
      <>
        <IonHeader translucent={true}>
              <IonToolbar className='top-header'>
                <IonTitle className='mobile-title' onClick={e => handleClick()}>Alpha Enterprise Engine</IonTitle>
                <IonTitle className='pc-title'>
                  <IonText className="pc-title-content" onClick={e => handleClick()}> Alpha Enterprise Engine (AE2)</IonText>
                  <AE2Icon className="header-logo" />
                </IonTitle>
                <IonButtons slot="end">
                    <IonMenuButton />
                  </IonButtons>
              </IonToolbar>
          </IonHeader>
      </>
    )
}

export default Ae2Header