import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { API_URL } from "../../../actions/settings";

async function getData(token: string, id: string) {
  return fetch(`${API_URL}/graphs/yearly_utility_rate_inflation/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return false;
    }
  });
}

interface Props {
  id: string;
}

const CustomizedLabel = ({x,y,stroke,payload,value}:any) => {
  return <text x={x} y={y} dy={-4} fill={'white'} fontSize={20} textAnchor="middle">
  {value}
</text>
}


const UtilityRateInflationGraph: React.FC<Props> = ({ id }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleData = async () => {
      const token = localStorage.getItem("token") || "";
      const res = await getData(`Token ${token.slice(1, -1)}`, id);
      if (res) {
        setData(res.data);
      } else {
        setError(true);
      }
    };
    handleData();
  }, [id]);

  return (
    <ResponsiveContainer width="100%" height={500}>
      {error ? (
        <div className="center-all">Could Not Load Table</div>
      ) : (
        <LineChart width={500} height={300} data={data} style={{border:'2px dashed white'}}>
          {/* <CartesianGrid strokeDasharray="4 4" /> */}
          <XAxis tick={{ fill: "white" }} dataKey="year" padding={{ left: 30, right: 30 }} />
          {/* <YAxis tick={{ fill: "white" }} /> */}
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="therms" stroke="#ee6b6e" label={<CustomizedLabel/>}/>
          <Line
            type="monotone"
            dataKey="kwh"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            label={<CustomizedLabel/>}
          />
          {/* <Line type="monotone" dataKey="kw" stroke="#82ca9d" /> */}

        </LineChart>
      )}
    </ResponsiveContainer>
  );
};

export default UtilityRateInflationGraph;
