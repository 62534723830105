const CheckRole = (user:any,roles:any)=>{

    if (user.first_name !== ""){
        for (const role of roles){
            if (user.role.name === role){
                return true
            }
        }
        return false
    }
}


export default CheckRole