import { IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

interface Props {
  id: string;
  className?: string;
  style?: any;
  children?: React.ReactNode;
}

const SingleTimePopup: React.FC<Props> = ({
  id,
  className,
  style,
  children,
}) => {
  const [cookies, setCookie] = useCookies([id]);
  const [state, setState] = useState(cookies[id]);

  useEffect(() => {
    if (!cookies[id]) {
      setCookie(id, "opened");
      setState("opened");
    }
    // eslint-disable-next-line
  }, [id]);

  const close = () => {
    setCookie(id, "closed");
    setState("closed");
  };

  if (state === "opened") {
    return (
      <div id={id} className={className} style={style}>
        <IonRow className="ion-justify-content-end">
          <button className="close-btn" onClick={() => close()}>
            X
          </button>
        </IonRow>
        <div className="popup-content">{children}</div>
        <div className="map-popup-arrow"> </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SingleTimePopup;
