import { IonButton, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { Redirect, useParams } from "react-router"
import { API_URL } from "../../actions/settings"
import EstarHeader from "../../components/Energystar/EstarHeader"
import './Property.css'
import { format } from 'fecha';
import EScoreCard from "../../components/Energystar/EScoreCard"
import EstarMetersList from "../../components/Energystar/EstarMetersList"
import { addCircleOutline, createOutline, copy, copyOutline } from "ionicons/icons"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import CheckRole from "../../components/extras/CheckRole"
import PerformanceComparison from "../../components/Energystar/PerformanceComparison"
import { isStaff } from "../../components/extras/Functions"
import { DoesNoTExistsError, NotAuthorizedError, UnexpectedError } from "../Errors/Errors"
import NotificationPopUp from "../../components/extras/NotificationPopUp"


interface Params {
    propertyId:string
}

async function getProperty(token:any,id:any) {

    return fetch(`${API_URL}/energystar/properties/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(res => {
        if(res.ok){
            return res.json()
        }
        else if(res.status===404){
            throw new DoesNoTExistsError('Page Does Not Exists')
          }
          else if(res.status===401){
            throw new NotAuthorizedError('You Are Not Authorized to Access That Page')
          }
          else{
            throw new UnexpectedError()
          }
        })
        .catch(error => NotificationPopUp('error',error.message));
   }


const Property: React.FC = ()=>{

    const { propertyId } = useParams<Params>();
    const user = useAppSelector(selectUser)
    const role = user.role.name

    const [ property, setProperty ] = useState<any>({})
    const [baseline, setBaseline ] = useState<any>('')
    const [ loaded, setLoaded ] = useState<Boolean>(false)
    const [propertyUses, setPropertyUses] = useState<[]>([])
    const [useDetails, setUseDetails] = useState<[]>([])
    const [ meters, setMeters ] = useState<[]>([])
    // eslint-disable-next-line
    const [ buildings, setBuildings ] = useState<[]>([])
    const [ current, setCurrent ] = useState<any>('')
    const [ errorOccurred, setErrorOccurred ] = useState(false)


    const handleProperty = async (id:any) => {
        const token = localStorage.getItem('token') || ""
        const res = await getProperty(`Token ${token.slice(1,-1)}`,id)
        if(res){
        setProperty(res.property[0])
        setBaseline(res.baseline[0])
        setPropertyUses(res.property_uses)
        setUseDetails(res.use_details)
        setMeters(res.meters)
        setBuildings(res.buildings)
        setLoaded(true)
        setCurrent(res.current)
        }
        else{
            setErrorOccurred(true)
        }

    }

    const toTitle = (s:string) => {
        s = s.replace(/([A-Z])/g, ' $1').trim()
         s = s[0].toUpperCase() + s.substring(1)
        return s
    }

    useEffect(()=>{
        handleProperty(propertyId)
    }, [propertyId])

    const copyUUID = () =>{
        navigator.clipboard.writeText(propertyId);
        const popup = document.getElementById('copied')
        popup?.classList.toggle('show')
        setTimeout(()=>{
          const popup = document.getElementById('copied')
          popup?.classList.toggle('show')
        },3000)
      }

      if(errorOccurred){
        return <Redirect to="/home"/>
      }


    // console.log('prop',property)
    // console.log('base',baseline)

    return (
        <>{!loaded ?
            <IonLoading
                cssClass='my-custom-class'
                isOpen={!loaded}
                message={'Loading...'}
                spinner='bubbles'
            />
            :
        <IonContent className='estar-property' >
            <EstarHeader title='Energy Star Property Details'/>

            <div className="property-page-content">
                <IonGrid>
                    <IonRow>
                        <IonCol  size="12" sizeXl="8" >
                        <IonRow className="top-title">
                        <h1 className="align-bottom"><span>{property.name}</span>
                        <IonButton className="edit-button" hidden={!(CheckRole(user,['Supervisor','Admin']))} fill='clear' color="light"  slot="icon-only" size="large" href={`/energystar/properties/${propertyId}/edit`}><IonIcon slot="icon-only" src={createOutline}></IonIcon></IonButton></h1>
                        {/* <div hidden={!(CheckRole(user,['Supervisor','Admin','Employee']))}>
                            <IonButton href={`/energystar/property/${propertyId}/template`} size="small" color="medium"> Edit Report Template</IonButton>
                            <IonButton href={`/energystar/property/${propertyId}/report`} size="small" color="medium"> Generate Report</IonButton>
                        </div> */}
                        <div>
                            <IonButton href={`/?id=${property.ae2_building_id}&sec=buildings`}>Building Page</IonButton>
                        </div>
                    </IonRow>
                    <IonRow className="address-row">
                        <IonCol>
                            <h3>{property.address}, {property.city}, {property.state} {property.postalcode}</h3>
                            <h4>Energy Star ID: {property.property_id}</h4>
                            {isStaff(user) ?
                                <span className="popup"><span className="popuptext-building" id="copied">Copied</span><IonButton className="clipboardButton" color="light" slot="icon-only" fill="clear"  onClick={copyUUID}> <IonIcon md={copy} ios={copyOutline}/>COPY UUID</IonButton> </span>:
                            ""}

                        </IonCol>
                    </IonRow>


                            <div className="basic-info" hidden>
                            <h4 className="card-header" >Basic Info</h4>
                            <div className='ps-5 card-body'>
                                <p>Primary Function:  {property.primaryfunction.name}</p>
                                <p>Gross Floor Area:  {property.grossfloorarea.toLocaleString('en')} {property.units}</p>
                                <p>Property Manager ID:  {property.property_id}</p>
                                <p>Construction Status:   {property.constructionstatus}</p>
                                {baseline ? <><div>Baseline:</div> &emsp;Value: {baseline.base_value} Date: {baseline.month}/{baseline.year} Target: {baseline.target}</> :''}
                                <p>Occupancy:  {property.occupancypercentage}%</p>
                                <p>Last Updated: {format(new Date(property.last_updated),'MM/DD/YYYY')} </p>
                                {/* <p className="modal-button"><IonButton hidden={!(role==='Supervisor' || role==='Admin')} size='small' id='baseline-button' color="medium">Set Baseline/Target</IonButton></p> */}
                            </div>
                            </div>
                            <IonRow>
                                <PerformanceComparison current={current} baseline={baseline}/>
                            </IonRow>
                            <div className="prop-uses-container">
                                <div className="prop-uses-title">
                                <h4 className="card-header">Property Uses: <IonButton hidden={!(role==='Supervisor' || role==='Admin')} shape="round" fill="clear" slot="start" href={`/energystar/property/${property.id}/property_use`}><IonIcon src={addCircleOutline} size='large'></IonIcon></IonButton> </h4>
                                </div>
                                <div className="uses-cards">
                                { propertyUses.map((prop:any) => {
                                        return (
                                            <IonCard key = {prop.id} className='uses-card'>
                                            <IonCardTitle className="card-title">
                                                {prop.name}
                                            </IonCardTitle>
                                            <IonCardSubtitle className="card-subtitle">
                                                Property Type: {prop.propertytype.fullname} <br></br>
                                                Current As Of: {format(new Date(prop.currentasof),'MM/DD/YYYY')}
                                            </IonCardSubtitle>
                                            <IonCardContent className="card-content" >
                                            {/* <IonAccordionGroup className="accordion-group"> */}
                                            {/* <IonAccordion value="details" toggleIcon={arrowDownCircle} className="detail-list"> */}
                                            <IonItem slot="header" className="detail-header">
                                                    <IonLabel>Details:</IonLabel>
                                            </IonItem>
                                            <IonList slot="content" className="detail-ion-list">
                                            {useDetails.map((detail:any,index)=>{
                                                return (
                                                        prop.id === detail.usedata ? <IonItem key={detail.id} lines='none' className="desc-item">
                                                            {toTitle(detail.name)}: {detail.value} {detail.units !== null ? detail.units: ""}
                                                        </IonItem> : null
                                                )

                                            })}
                                            </IonList>
                                            {/* </IonAccordion> */}
                                            {/* </IonAccordionGroup> */}
                                            </IonCardContent>

                                            </IonCard>
                                        )
                                    })}
                                </div>

                            </div>
                        </IonCol>
                        <EScoreCard property={property} current={current}/>

                    </IonRow>
                    <IonRow>
                        <EstarMetersList meters={meters} id={propertyId}/>
                        {/* <EstarBuildingList buildings={buildings} id={propertyId}/> */}
                    </IonRow>
                </IonGrid>
            </div>

            {/* <IonModal trigger="baseline-button" ><BaselineTargetForm id={propertyId}/></IonModal> */}

        </IonContent>
        }</>
    )

}

export default Property