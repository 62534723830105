import { IonItem, IonList, IonSearchbar } from '@ionic/react';
import { useState } from 'react';
import { API_URL } from '../../actions/settings';
import { useEffect } from 'react';
import './ItemList.css'



interface props {
    title : String
}


async function getUserApi(token:any,title:String) {


    return fetch(`${API_URL}/${title.toLowerCase()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const getItems = async (
  token: any,
  title: String,
  setItems: any,
  setFilteredItems: any
) => {
  const res = await getUserApi(`Token ${token.slice(1, -1)}`, title);
  if (res.status=== "success") {
    setItems(res.data);
    setFilteredItems(res.data);
  }
};

const handleSearch = (items:any,term:string,name:string,setItems:any,setSearchText:any)=>{
    setSearchText(term)
    if(term===''){
        setItems(items)
    }
    else{
        setItems(items.filter((item:any)=> item[name].toLowerCase().includes(term.toLocaleLowerCase())));
    }

}


const ItemList: React.FC<props> = ({title}) => {

    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchText, setSearchText] = useState('');

    let name = ''
    if(title === 'Customers'){
        name = 'cust_name'
    }
    if(title === 'Buildings'){
        name = 'building_name'
    }
    if(title === 'Campuses'){
        name = 'campus_name'
    }

    useEffect(() => {
        const token = localStorage.getItem('token')
        let term = title
        if(title === 'Buildings'){
          term = 'menu_buildings'
        }

        getItems(token,term,setItems,setFilteredItems)
      }, [title])


    return (
                    <IonList className={title+'-list item-lists'}>
                        <IonSearchbar role={`list-search-${title}`} value={searchText} onIonInput={e => handleSearch(items, e.detail.value!, name, setFilteredItems, setSearchText)} animated></IonSearchbar>
                        {filteredItems.length > 0 ?
                            filteredItems.map((item,index) => {
                                return (<IonItem button key={title+"-"+index} href={'/?id='+item['uuid']+'&sec='+title.toLocaleLowerCase()} >
                                        {item[name]}

                                </IonItem>)
                            }) :
                            `No ${title}!`
                        }
                    </IonList>

    )
}

export default ItemList;
