import { IonAccordion, IonAccordionGroup, IonButton, IonCol, IonContent, IonItem, IonLabel, IonPage, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { Redirect, useParams } from "react-router"
import { API_URL } from "../../actions/settings"
import Ae2Header from "../../components/AE2/Ae2Header"
import { displayValue } from "../../components/extras/Calculations"
import './AE2ControlsVersioning.css'
import { useSelector } from "react-redux"
import { selectUser } from "../../reducers/UserReducer"
import { isStaff } from "../../components/extras/Functions"

interface Params {
    id:any
}
interface vars {
    all?:boolean
}

async function getServersData(token:any,id:string) {

    return fetch(`${API_URL}/customer/${id}/ebo_server_data`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function getAllServersData(token:any) {

    return fetch(`${API_URL}/all_server_data`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const handleGetData = async (all:boolean,token:any,id:any,setServers:any) => {
    if(all){
        const res = await getAllServersData(token)
        setServers(res.data)
    }else{
        const res = await getServersData(token,id)
        setServers(res.data)
    }

   }

function cpuUsage(val:string){
    const num = Number(val)
    if(num >= 50){
        return 'cpu-alert'
    }
    if(num < 50 && num >= 40){
        return 'cpu-warning'
    }
    return 'cpu-ok'
}


const AE2ControlsVersioning: React.FC<vars> = ({all=false}) => {

    const { id }=useParams<Params>()
    const [ servers, setServers] = useState([])
    const user = useSelector(selectUser)

    useEffect(()=>{
        const tkn:any = localStorage.getItem('token')
        const token = `Token ${tkn.slice(1,-1)}`
        handleGetData(all,token,id,setServers)
    }, [all,id])

    if(user.first_name !== '' && !isStaff(user)){
        return <Redirect to='/403'/>
    }

    return(
        <IonPage className='ae2-home' >
            <Ae2Header />
            <IonContent className="main-background">
                <IonRow className='ion-justify-content-center'><h1 className="main-heading">EBO Server Data</h1></IonRow>
                <IonRow hidden={all}> <IonButton size="small" href={`/?id=${id}&sec=customers`}>Customer Page</IonButton></IonRow>
                <IonRow className='ebo-table-row'>

                    {isMobile ? <div className="ebo-table-mobile">
                        {servers.map((server) => {
                            return(
                                <IonAccordionGroup key={server['server_name']} className='ebo-accordion'>
                                    <IonAccordion value="first">
                                        <IonItem slot="header" fill="outline" color="medium">
                                            <IonLabel>{server['server_name'] }</IonLabel>
                                        </IonItem>
                                        <div slot='content'>
                                            <ul>
                                                <li hidden={!all}>Customer: <a href={`/?id=${server['cust_uuid']}&sec=customers`}>{server['cust_name']}</a></li>
                                                <li>Server Type: {server['server_type']}</li>
                                                <li>Software Version: {server['software_version']}</li>
                                                <li>Hardware Version: {server['hardware_version']}</li>
                                                <li>Part Number: {server['part_number']}</li>
                                                <li>Date Updated: {displayValue(server['update_timestamp'],'date')}</li>
                                                <li className={cpuUsage(server['cpu_usage'])}>CPU Usage: {server['cpu_usage']}</li>
                                            </ul>
                                        </div>
                                    </IonAccordion>
                                </IonAccordionGroup>
                            )
                        })}
                    </div> :
                    <div style={{width:'100%', overflowX:'auto'}} className='ebo-table'>
                        <div className="ebo-table-container" style={{minWidth:'875px'}}>
                        <IonRow>
                            <IonCol hidden={!all} className="heading-col">Customer</IonCol>
                            <IonCol className="heading-col">Server Name</IonCol>
                            <IonCol className="heading-col">Server Type</IonCol>
                            <IonCol className="heading-col">Software Version</IonCol>
                            <IonCol className="heading-col">Hardware Version</IonCol>
                            <IonCol className="heading-col">Part Number</IonCol>
                            <IonCol className="heading-col">Date Updated</IonCol>
                            <IonCol className="heading-col">CPU Usage</IonCol>
                        </IonRow>
                        {servers.map((server) => {
                            return(
                                <IonRow key={server['server_name']}>
                                    <IonCol hidden={!all}><a href={`/?id=${server['cust_uuid']}&sec=customers`}>{server['cust_name']}</a></IonCol>
                                    <IonCol>{server['server_name']}</IonCol>
                                    <IonCol>{server['server_type']}</IonCol>
                                    <IonCol>{server['software_version']}</IonCol>
                                    <IonCol>{server['hardware_version']}</IonCol>
                                    <IonCol>{server['part_number']}</IonCol>
                                    <IonCol>{displayValue(server['update_timestamp'],'date')}</IonCol>
                                    <IonCol className={cpuUsage(server['cpu_usage'])}>{server['cpu_usage']}</IonCol>
                                </IonRow>
                            )
                        })}
                        </div>
                    </div>
}
                </IonRow>
            </IonContent>
        </IonPage>)
}

export default AE2ControlsVersioning