import { IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
    totalGrossFloorArea : "",
    totalGrossFloorArea_units : "",
    totalGrossFloorArea_date : "",
    openOnWeekends : "",
    openOnWeekends_date : "",
    gymnasiumFloorArea : "",
    gymnasiumFloorArea_units : "",
    gymnasiumFloorArea_date : "",
    monthsInUse : "",
    monthsInUse_date : "",
    schoolDistrict : "",
    schoolDistrict_date : "",
    studentSeatingCapacity : "",
    studentSeatingCapacity_date : "",
    grossFloorAreaUsedForFoodPreparation : "",
    grossFloorAreaUsedForFoodPreparation_units : "",
    grossFloorAreaUsedForFoodPreparation_date : "",
    cookingFacilities : "",
    cookingFacilities_date : "",
    isHighSchool : "",
    isHighSchool_date : "",
    numberOfComputers : "",
    numberOfComputers_date : "",
    numberOfWorkers : "",
    numberOfWorkers_date : "",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
    numberOfWalkInRefrigerationUnits : "",
    numberOfWalkInRefrigerationUnits_date : "",
}

const startErrors = {
    totalGrossFloorArea : "Field Required",
    totalGrossFloorArea_units : "Select Units",
    totalGrossFloorArea_date : "Date is required",
    openOnWeekends : "",
    openOnWeekends_date : "",
    monthsInUse : "",
    monthsInUse_date : "",
    gymnasiumFloorArea : "",
    gymnasiumFloorArea_units : "",
    gymnasiumFloorArea_date : "",
    schoolDistrict : "",
    schoolDistrict_date : "",
    studentSeatingCapacity : "",
    studentSeatingCapacity_date : "",
    grossFloorAreaUsedForFoodPreparation : "",
    grossFloorAreaUsedForFoodPreparation_units : "",
    grossFloorAreaUsedForFoodPreparation_date : "",
    cookingFacilities : "",
    cookingFacilities_date : "",
    numberOfComputers : "",
    numberOfComputers_date : "",
    numberOfWorkers : "",
    numberOfWorkers_date : "",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
    numberOfWalkInRefrigerationUnits : "",
    numberOfWalkInRefrigerationUnits_date : "",
    isHighSchool : "",
    isHighSchool_date : "",
}



const K12School: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('gymnasiumFloorArea'):
                if(value !== ''){
                    form.gymnasiumFloorArea_date === '' ? handleError('gymnasiumFloorArea_date','Date Cannot be Empty') : handleError('gymnasiumFloorArea_date',"")
                    form.gymnasiumFloorArea_units === '' ? handleError('gymnasiumFloorArea_units','Units Cannot be Empty') : handleError('gymnasiumFloorArea_units',"")
                    value < 0 ? handleError('gymnasiumFloorArea','Must be a Positive Number') : handleError('gymnasiumFloorArea',"")
                }
                else{
                    handleError('gymnasiumFloorArea','')
                    if(form.gymnasiumFloorArea_date === '' && form.gymnasiumFloorArea_units === ''){
                        handleError('gymnasiumFloorArea_date','')
                        handleError('gymnasiumFloorArea_units','')}
                    else if(form.gymnasiumFloorArea_date === ''){
                        handleError('gymnasiumFloorArea'," Field Cannot be empty")
                        handleError('gymnasiumFloorArea_date'," Date Cannot be empty")
                    }
                    else if(form.gymnasiumFloorArea_units === ''){
                        handleError('gymnasiumFloorArea'," Field Cannot be empty")
                        handleError('gymnasiumFloorArea_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('gymnasiumFloorArea_date'):
                if(value !== ''){
                    form.gymnasiumFloorArea === '' ? handleError('gymnasiumFloorArea','Field Cannot be Empty') : handleError('gymnasiumFloorArea',"")
                    form.gymnasiumFloorArea_units === '' ? handleError('gymnasiumFloorArea_units','Units Cannot be Empty') : handleError('gymnasiumFloorArea_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('gymnasiumFloorArea_date','Invalid Date') : handleError('gymnasiumFloorArea_date','')
                }
                else{
                    handleError('gymnasiumFloorArea_date','')
                    if(form.gymnasiumFloorArea === '' && form.gymnasiumFloorArea_units === ''){
                        handleError('gymnasiumFloorArea','')
                        handleError('gymnasiumFloorArea_units','')}
                    else if(form.gymnasiumFloorArea === ''){
                        handleError('gymnasiumFloorArea'," Field Cannot be empty")
                        handleError('gymnasiumFloorArea_date'," Date Cannot be empty")
                    }
                    else if(form.gymnasiumFloorArea_units === ''){
                        handleError('gymnasiumFloorArea_date'," Field Cannot be empty")
                        handleError('gymnasiumFloorArea'," Unit Cannot be empty")
                    }

                }
                break;
            case('gymnasiumFloorArea_units'):
                if(value !== ''){
                    form.gymnasiumFloorArea === '' ? handleError('gymnasiumFloorArea','Field Cannot be Empty') : handleError('gymnasiumFloorArea',"")
                    form.gymnasiumFloorArea_date === '' ? handleError('gymnasiumFloorArea_date','Date Cannot be Empty') : handleError('gymnasiumFloorArea_date',"")
                }
                else{
                    handleError('gymnasiumFloorArea_units','')
                    if(form.gymnasiumFloorArea === '' && form.gymnasiumFloorArea_date === ''){
                        handleError('gymnasiumFloorArea','')
                        handleError('gymnasiumFloorArea_date','')}
                    else if(form.gymnasiumFloorArea === ''){
                        handleError('gymnasiumFloorArea'," Field Cannot be empty")
                        handleError('gymnasiumFloorArea_units'," Date Cannot be empty")
                    }
                    else if(form.gymnasiumFloorArea === ''){
                        handleError('gymnasiumFloorArea_date'," Field Cannot be empty")
                        handleError('gymnasiumFloorArea'," Unit Cannot be empty")
                    }

                }
                break;
            case('schoolDistrict'):
                if(value !==''){
                    form.schoolDistrict_date === '' ? handleError('schoolDistrict_date','Date cannot be empty') : handleError('schoolDistrict_date','')
                    value < 0 ? handleError('schoolDistrict','Must be a Positive number') : handleError('schoolDistrict','')
                }
                else{
                    handleError('schoolDistrict','')
                    handleError('schoolDistrict_date','')
                }
                break;
            case('schoolDistrict_date'):
                if(value !==''){
                    form.schoolDistrict === '' ? handleError('schoolDistrict','Field cannot be empty') : handleError('schoolDistrict','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('schoolDistrict_date','Invalid Date') : handleError('schoolDistrict_date','')
                }
                else{
                    handleError('schoolDistrict','')
                    handleError('schoolDistrict_date','')
                }
                break;
            case('monthsInUse'):
                if(value !==''){
                    form.monthsInUse_date === '' ? handleError('monthsInUse_date','Date cannot be empty') : handleError('monthsInUse_date','')
                    value < 0 ? handleError('monthsInUse','Must be a Positive number') : value > 12 ? handleError('monthsInUse','Max Value is 12') : handleError('monthsInUse','')
                }
                else{
                    handleError('monthsInUse','')
                    handleError('monthsInUse_date','')
                }
                break;
            case('monthsInUse_date'):
                if(value !==''){
                    form.monthsInUse === '' ? handleError('monthsInUse','Field cannot be empty') : handleError('monthsInUse','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('monthsInUse_date','Invalid Date') : handleError('monthsInUse_date','')
                }
                else{
                    handleError('monthsInUse','')
                    handleError('monthsInUse_date','')
                }
                break;
            case('studentSeatingCapacity'):
                if(value !==''){
                    form.studentSeatingCapacity_date === '' ? handleError('studentSeatingCapacity_date','Date cannot be empty') : handleError('studentSeatingCapacity_date','')
                    value < 0 ? handleError('studentSeatingCapacity','Must be a Positive number') : handleError('studentSeatingCapacity','')
                }
                else{
                    handleError('studentSeatingCapacity','')
                    handleError('studentSeatingCapacity_date','')
                }
                break;
            case('studentSeatingCapacity_date'):
                if(value !==''){
                    form.studentSeatingCapacity === '' ? handleError('studentSeatingCapacity','Field cannot be empty') : handleError('studentSeatingCapacity','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('studentSeatingCapacity_date','Invalid Date') : handleError('studentSeatingCapacity_date','')
                }
                else{
                    handleError('studentSeatingCapacity','')
                    handleError('studentSeatingCapacity_date','')
                }
                break;
            case('numberOfWalkInRefrigerationUnits'):
                if(value !==''){
                    form.numberOfWalkInRefrigerationUnits_date === '' ? handleError('numberOfWalkInRefrigerationUnits_date','Date cannot be empty') : handleError('numberOfWalkInRefrigerationUnits_date','')
                    value < 0 ? handleError('numberOfWalkInRefrigerationUnits','Must be a Positive number') : handleError('numberOfWalkInRefrigerationUnits','')
                }
                else{
                    handleError('numberOfWalkInRefrigerationUnits','')
                    handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                break;
            case('numberOfWalkInRefrigerationUnits_date'):
                if(value !==''){
                    form.numberOfWalkInRefrigerationUnits === '' ? handleError('numberOfWalkInRefrigerationUnits','Field cannot be empty') : handleError('numberOfWalkInRefrigerationUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWalkInRefrigerationUnits_date','Invalid Date') : handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                else{
                    handleError('numberOfWalkInRefrigerationUnits','')
                    handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                break;
            case('numberOfComputers'):
                if(value !==''){
                    form.numberOfComputers_date === '' ? handleError('numberOfComputers_date','Date cannot be empty') : handleError('numberOfComputers_date','')
                    value < 0 ? handleError('numberOfComputers','Must be a Positive number') : handleError('numberOfComputers','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case('numberOfComputers_date'):
                if(value !==''){
                    form.numberOfComputers === '' ? handleError('numberOfComputers','Field cannot be empty') : handleError('numberOfComputers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfComputers_date','Invalid Date') : handleError('numberOfComputers_date','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case('numberOfWorkers'):
                if(value !==''){
                    form.numberOfWorkers_date === '' ? handleError('numberOfWorkers_date','Date cannot be empty') : handleError('numberOfWorkers_date','')
                    value < 0 ? handleError('numberOfWorkers','Must be a Positive number') : handleError('numberOfWorkers','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('numberOfWorkers_date'):
                if(value !==''){
                    form.numberOfWorkers === '' ? handleError('numberOfWorkers','Field cannot be empty') : handleError('numberOfWorkers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWorkers_date','Invalid Date') : handleError('numberOfWorkers_date','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('openOnWeekends'):
                if(value !==''){
                    form.openOnWeekends_date === '' ? handleError('openOnWeekends_date','Date cannot be empty') : handleError('openOnWeekends_date','')
                }
                else{
                    handleError('openOnWeekends','')
                    handleError('openOnWeekends_date','')
                }
                break;
            case('openOnWeekends_date'):
                if(value !==''){
                    form.openOnWeekends === '' ? handleError('openOnWeekends','Field cannot be empty') : handleError('openOnWeekends','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('openOnWeekends_date','Invalid Date') : handleError('openOnWeekends_date','')
                }
                else{
                    handleError('openOnWeekends','')
                    handleError('openOnWeekends_date','')
                }
                break;
            case('isHighSchool'):
                if(value !==''){
                    form.isHighSchool_date === '' ? handleError('isHighSchool_date','Date cannot be empty') : handleError('isHighSchool_date','')
                }
                else{
                    handleError('isHighSchool','')
                    handleError('isHighSchool_date','')
                }
                break;
            case('isHighSchool_date'):
                if(value !==''){
                    form.isHighSchool === '' ? handleError('isHighSchool','Field cannot be empty') : handleError('isHighSchool','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('isHighSchool_date','Invalid Date') : handleError('isHighSchool_date','')
                }
                else{
                    handleError('isHighSchool','')
                    handleError('isHighSchool_date','')
                }
                break;
            case ('percentCooled'):
                if( value !== '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','')
                }
                else {
                    handleError('percentCooled','')
                }
                break;
            case ('percentCooled_date'):
                if (form.percentCooled !== ''){
                    if (value === ''){
                        handleError('percentCooled_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentCooled_date','Invalid Date')
                    }
                else {
                        handleError('percentCooled_date','')
                    }
                break;
            case ('percentHeated'):
                if( value !== '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','')
                }
                else {
                    handleError('percentHeated','')
                }
                break;
            case ('percentHeated_date'):
                if (form.percentHeated !== ''){
                    if (value === ''){
                        handleError('percentHeated_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentHeated_date','Invalid Date')
                    }
                else {
                        handleError('percentHeated_date','')
                    }
                break;
            case('grossFloorAreaUsedForFoodPreparation'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation_date === '' ? handleError('grossFloorAreaUsedForFoodPreparation_date','Date Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_date',"")
                    form.grossFloorAreaUsedForFoodPreparation_units === '' ? handleError('grossFloorAreaUsedForFoodPreparation_units','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_units',"")
                    value < 0 ? handleError('grossFloorAreaUsedForFoodPreparation','Must be a Positive Number') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation','')
                    if(form.grossFloorAreaUsedForFoodPreparation_date === '' && form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date','')
                        handleError('grossFloorAreaUsedForFoodPreparation_units','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation_date === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaUsedForFoodPreparation_date'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation === '' ? handleError('grossFloorAreaUsedForFoodPreparation','Field Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                    form.grossFloorAreaUsedForFoodPreparation_units === '' ? handleError('grossFloorAreaUsedForFoodPreparation_units','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('grossFloorAreaUsedForFoodPreparation_date','Invalid Date') : handleError('grossFloorAreaUsedForFoodPreparation_date','')
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation_date','')
                    if(form.grossFloorAreaUsedForFoodPreparation === '' && form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation','')
                        handleError('grossFloorAreaUsedForFoodPreparation_units','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation'," Unit Cannot be empty")
                    }
                }
                break;
            case('grossFloorAreaUsedForFoodPreparation_units'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation === '' ? handleError('grossFloorAreaUsedForFoodPreparation','Field Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                    form.grossFloorAreaUsedForFoodPreparation_date === '' ? handleError('grossFloorAreaUsedForFoodPreparation_date','Date Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_date',"")
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation_units','')
                    if(form.grossFloorAreaUsedForFoodPreparation === '' && form.grossFloorAreaUsedForFoodPreparation_date === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation','')
                        handleError('grossFloorAreaUsedForFoodPreparation_date','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation'," Unit Cannot be empty")
                    }

                }
                break;
            case('cookingFacilities'):
                if(value !==''){
                    form.cookingFacilities_date === '' ? handleError('cookingFacilities_date','Date cannot be empty') : handleError('cookingFacilities_date','')

                }
                else{
                    handleError('cookingFacilities','')
                    handleError('cookingFacilities_date','')
                }
                break;
            case('cookingFacilities_date'):
                if(value !==''){
                    form.cookingFacilities === '' ? handleError('cookingFacilities','Field cannot be empty') : handleError('cookingFacilities','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('cookingFacilities_date','Invalid Date') : handleError('cookingFacilities_date','')
                }
                else{
                    handleError('cookingFacilities','')
                    handleError('cookingFacilities_date','')
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>K-12 School Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} />Open on Weekends</IonLabel>
                        <IonSelect className={errors.openOnWeekends === '' ? "valid":'invalid'}  value={form.openOnWeekends} interface='popover' placeholder='Opened Weekends?' onIonChange={e => (handleFormChange(e.detail.value!,'openOnWeekends'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.openOnWeekends}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.openOnWeekends_date === '' ? "valid":'invalid'}  value={form.openOnWeekends_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'openOnWeekends_date'))}></IonInput>
                        <div className="error-detail">{errors.openOnWeekends_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number Of Walk-In Refrigeration Units</IonLabel>
                        <IonInput type="number" className={errors.numberOfWalkInRefrigerationUnits === '' ? "valid":'invalid'}  value={form.numberOfWalkInRefrigerationUnits} placeholder='# Walk-In Refrigeration Units' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWalkInRefrigerationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWalkInRefrigerationUnits_date === '' ? "valid":'invalid'}  value={form.numberOfWalkInRefrigerationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWalkInRefrigerationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Cooled</IonLabel>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentCooled_date === '' ? "valid":'invalid'}  value={form.percentCooled_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></IonInput>
                        <div className="error-detail">{errors.percentCooled_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Heated</IonLabel>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentHeated_date === '' ? "valid":'invalid'}  value={form.percentHeated_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></IonInput>
                        <div className="error-detail">{errors.percentHeated_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Computers</IonLabel>
                        <IonInput type="number" className={errors.numberOfComputers === '' ? "valid":'invalid'}  value={form.numberOfComputers} placeholder='# Computers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfComputers_date === '' ? "valid":'invalid'}  value={form.numberOfComputers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} />Cooking Facilities?</IonLabel>
                        <IonSelect className={errors.cookingFacilities === '' ? "valid":'invalid'}  value={form.cookingFacilities} interface='popover' placeholder='Cooking Facilities' onIonChange={e => (handleFormChange(e.detail.value!,'cookingFacilities'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.cookingFacilities}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.cookingFacilities_date === '' ? "valid":'invalid'}  value={form.cookingFacilities_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'cookingFacilities_date'))}></IonInput>
                        <div className="error-detail">{errors.cookingFacilities_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} />Is this a High School?</IonLabel>
                        <IonSelect className={errors.isHighSchool === '' ? "valid":'invalid'}  value={form.isHighSchool} interface='popover' placeholder='High School?' onIonChange={e => (handleFormChange(e.detail.value!,'isHighSchool'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.isHighSchool}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.isHighSchool_date === '' ? "valid":'invalid'}  value={form.isHighSchool_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'isHighSchool_date'))}></IonInput>
                        <div className="error-detail">{errors.isHighSchool_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} />Number of Months In Use</IonLabel>
                        <IonInput type="number" className={errors.monthsInUse === '' ? "valid":'invalid'}  value={form.monthsInUse} placeholder='Months in Use' onIonChange={e => (handleFormChange(e.detail.value!,'monthsInUse'))}></IonInput>
                        <div className="error-detail">{errors.monthsInUse}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.monthsInUse_date === '' ? "valid":'invalid'}  value={form.monthsInUse_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'monthsInUse_date'))}></IonInput>
                        <div className="error-detail">{errors.monthsInUse_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> School District</IonLabel>
                        <IonInput type="number" className={errors.schoolDistrict === '' ? "valid":'invalid'}  value={form.schoolDistrict} placeholder='School District' onIonChange={e => (handleFormChange(e.detail.value!,'schoolDistrict'))}></IonInput>
                        <div className="error-detail">{errors.schoolDistrict}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.schoolDistrict_date === '' ? "valid":'invalid'}  value={form.schoolDistrict_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'schoolDistrict_date'))}></IonInput>
                        <div className="error-detail">{errors.schoolDistrict_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Student Seating Capacity</IonLabel>
                        <IonInput type="number" className={errors.studentSeatingCapacity === '' ? "valid":'invalid'}  value={form.studentSeatingCapacity} placeholder='Student Seating Capacity' onIonChange={e => (handleFormChange(e.detail.value!,'studentSeatingCapacity'))}></IonInput>
                        <div className="error-detail">{errors.studentSeatingCapacity}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.studentSeatingCapacity_date === '' ? "valid":'invalid'}  value={form.studentSeatingCapacity_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'studentSeatingCapacity_date'))}></IonInput>
                        <div className="error-detail">{errors.studentSeatingCapacity_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Number of Workers</IonLabel>
                        <IonInput type="number" className={errors.numberOfWorkers === '' ? "valid":'invalid'}  value={form.numberOfWorkers} placeholder='# Workers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWorkers_date === '' ? "valid":'invalid'}  value={form.numberOfWorkers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Gymnasium Floor Area</IonLabel>
                        <IonInput type="number" className={errors.gymnasiumFloorArea === '' ? "valid":'invalid'}  value={form.gymnasiumFloorArea} placeholder='Gymnasium Floor Area' onIonChange={e => (handleFormChange(e.detail.value!,'gymnasiumFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.gymnasiumFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.gymnasiumFloorArea_units === '' ? "valid":'invalid'} value={form.gymnasiumFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'gymnasiumFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.gymnasiumFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.gymnasiumFloorArea_date === '' ? "valid":'invalid'} value={form.gymnasiumFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'gymnasiumFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.gymnasiumFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Gross Floor Area Used For Food Preparation</IonLabel>
                        <IonInput type="number" className={errors.grossFloorAreaUsedForFoodPreparation === '' ? "valid":'invalid'}  value={form.grossFloorAreaUsedForFoodPreparation} placeholder='GFA for Food Preparation' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation'))}></IonInput>
                        <div className="error-detail">{errors.grossFloorAreaUsedForFoodPreparation}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.grossFloorAreaUsedForFoodPreparation_units === '' ? "valid":'invalid'} value={form.grossFloorAreaUsedForFoodPreparation_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.grossFloorAreaUsedForFoodPreparation_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.grossFloorAreaUsedForFoodPreparation_date === '' ? "valid":'invalid'} value={form.grossFloorAreaUsedForFoodPreparation_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation_date'))}></IonInput>
                        <div className="error-detail">{errors.grossFloorAreaUsedForFoodPreparation_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default K12School
