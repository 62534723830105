import { IonContent } from "@ionic/react"
import Ae2Header from "../../components/AE2/Ae2Header"
import AE2BuildingUtilityForm from "../../components/forms/AE2BuildingUtilityForm"
import AE2CampusForm from "../../components/forms/AE2CampusForm"
import BuildingForm from "../../components/forms/BuildingForm"
import ConsumptionDataForm from "../../components/forms/ConsumptionDataForm"
import EditAE2Building from "../../components/forms/EditAE2Building"
import EditAE2BuildingUtilityForm from "../../components/forms/EditAE2BuildingUtilityForm"
import EditAE2CampusForm from "../../components/forms/EditAE2CampusForm"
import EditAE2CustomerForm from "../../components/forms/EditAE2Customer"
import EditEstarProperty from "../../components/forms/EditEstarProperty"
import EditMeterForm from "../../components/forms/EditMeterForm"
import MeterForm from "../../components/forms/MeterForm"
import PropertyForm from "../../components/forms/PropertyForm"
import PropertyUseForm from "../../components/forms/PropertyUseForm"
import './Form.css'

interface Props{
    type:string
}

const Form: React.FC<Props> = ({type}) =>{
    return(
        <IonContent className="container">
            <Ae2Header/>
            <div className="form-container">
            {type === 'property' ?
            <PropertyForm/> : '' }
            {type === 'campus' ?
            <AE2CampusForm /> : '' }
            {type === 'building' ?
            <BuildingForm/> : '' }
            {type === 'meter' ?
            <MeterForm/> : '' }
            {type === 'EditMeter' ?
            <EditMeterForm/> : '' }
            {type === 'propertyuse' ?
            <PropertyUseForm/> : '' }
            {type === 'Consumption' ?
            <ConsumptionDataForm/> : '' }
            {type === 'editEstarProperty' ?
            <EditEstarProperty/> : '' }
            {type === 'editAE2Building' ?
            <EditAE2Building/> : '' }
            {type === 'editAE2Campus' ?
            <EditAE2CampusForm/> : '' }
            {type === 'editAE2Customer' ?
            <EditAE2CustomerForm/> : '' }
            {type === 'utility_service' ?
            <AE2BuildingUtilityForm/> : '' }
            {type === 'edit_utility_service' ?
            <EditAE2BuildingUtilityForm/> : '' }
            </div>
        </IonContent>
    )
}

export default Form