import { IonGrid, IonRow, IonContent } from "@ionic/react"
import React from "react"
import Ae2Header from "../../components/AE2/Ae2Header"
import AE2CustomerForm from "../../components/forms/AE2CustomerForm"
import './Customer.css'

const Customer: React.FC= () => {
    return (
    <IonContent className='ae2-customer-content'>
    <Ae2Header/>

    <div className="ae2-customer">
        <div className="form-container">
            <IonGrid className="ae2-grid" >
                <IonRow className="ion-justify-content-center">
                    <AE2CustomerForm />
                </IonRow>
            </IonGrid>
        </div>
    </div>

    </IonContent>)
}

export default Customer