import { IonLoading, } from "@ionic/react"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import "./GeneralSetting.css"
import UtilityServiceSettings from "./AdminSection/UtilityServiceSettings"
import { Redirect } from "react-router"
import CheckRole from "../extras/CheckRole"


const AdminSettings:React.FC = () => {
    const user = useAppSelector(selectUser)

    if (user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={!(user.first_name==="")}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }



    return (
            <UtilityServiceSettings/>
    )
}

export default AdminSettings