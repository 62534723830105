import { IonContent } from "@ionic/react"
import "./Properties.css"
import EstarHeader from "../../components/Energystar/EstarHeader"
import PropertiesTable from "../../components/Energystar/PropertiesTable"


const Properties: React.FC = () => {



    return (
        <IonContent className='estar-properties' >
            <EstarHeader/>

            <div className="estar-properties-content">
                <PropertiesTable/>
            </div>

        </IonContent>
    )
}

export default Properties