import { IonButton, IonCol, IonGrid, IonIcon, IonModal, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../../actions/settings"
import './SuccessStoriesTable.css'
import { caretDownCircle, caretUpCircle} from "ionicons/icons"
import NotificationPopUp from "../../extras/NotificationPopUp"
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel, getSortedRowModel, VisibilityState } from '@tanstack/react-table'
import KeywordCell from "./KeywordCell"
import FilterSuccessStoriesTable from "./FilterSuccessStoriesTable"
import EditDeleteCell from "./EditDeleteCell"
import SuccessStoryForm from "./forms/SuccessStoryForm"
import { useAppSelector } from "../../../Hooks"
import { selectUserGroups } from "../../../reducers/UserReducer"
type Story = {
    market_segment:string,
    sub_market_segment:string,
    location:string,
    representative:string,
    description:string,
    keywords:[],
    date:string
}

interface Props{
    stories:Story[],
    setStories:any
}

async function deleteStory(id:any,token:any) {

  return fetch(`${API_URL}/success_story/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }


const marketSegmentSorting = (rowA: any, rowB: any, columnId: any) =>
rowA.getValue(columnId).market_segment < rowB.getValue(columnId).market_segment ? 1 : -1;

const locationSorting = (rowA: any, rowB: any, columnId: any) =>
rowA.getValue(columnId).name < rowB.getValue(columnId).name ? 1 : -1;

const representativeSorting = (rowA: any, rowB: any, columnId: any) =>
rowA.getValue(columnId).name < rowB.getValue(columnId).name ? 1 : -1;


const SuccessStoriesTable: React.FC<Props> = ({stories,setStories}) => {

    const user_groups = useAppSelector(selectUserGroups)
    const [opened,setOpened] = useState(false)
    const [openEditModal,setOpenEditModal] = useState(false)
    const [toDeleteID,setToDeleteID] = useState<any>()
    const [toEditID,setToEditID] = useState<any>(null)
    const [filter, setFilter] = useState("");
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

    useEffect(()=>{
        if(!user_groups.includes('SuccessStoriesEditDelete')){
            setColumnVisibility({
                'id':false
            })
        }else{
            setColumnVisibility({
                'id':true
            })
        }
    },[user_groups])


    const customGlobalFilter = (row:any, id:any, filterValue:string) => {
        for (let col of columns){
            if (col.filterFn? col.filterFn(row,col.accessorKey,filterValue) : false){
                return true
            }
        }
        return false
      };


    const columns = [
        {
            accessorKey:'market_segment',
            header:'Market Segment',
            cell:(props:any)=>{
                if(props.getValue().market_segment === 'K-12 School'){
                    return <div className="center-all">
                        <div>
                        <div>{props.getValue().market_segment}</div>
                        <div>({props.getValue().sub_segment})</div>
                        </div>
                    </div>
                }
                else{
                    return <div className="center-all">
                    <div>{props.getValue().market_segment}</div>
                </div>
                }
            },
            size:150,
            enableColumnFilter:true,
            enableGlobalFilter: true,
            accessorFn: (row:any)=>  {
                return {
                    market_segment:row.market_segment,
                    sub_segment:row.market_sub_segment
                }
            },
            filterFn: ((row:any,columnId:any,value:any)=>{
                if (row.getValue(columnId).market_segment?.toString().toLowerCase().includes(value.toLowerCase())){
                    return true
                }
                if (row.getValue(columnId).sub_segment?.toString().toLowerCase().includes(value.toLowerCase())){
                    return true
                }
                return false
            }),
            sortingFn:marketSegmentSorting

        },
        {
            accessorKey:'location',
            header:'Location',
            cell:(props:any)=><div className="center-all">{props.getValue().name}</div>,
            size:200,
            enableColumnFilter:true,
            enableGlobalFilter: true,
            filterFn: ((row:any,columnId:any,value:any)=>{
                return row.getValue(columnId).name.toString().toLowerCase().includes(value.toLowerCase())
            }),
            sortingFn:locationSorting
        },
        {
            accessorKey:'representative',
            header:'Representative',
            cell:(props:any)=><div className="center-all">{props.getValue().name}</div>,
            size:150,
            enableColumnFilter:true,
            enableGlobalFilter: true,
            filterFn: ((row:any,columnId:any,value:any)=>{
                return row.getValue(columnId).name.toString().toLowerCase().includes(value.toLowerCase())
            }),
            sortingFn:representativeSorting

        },
        {
            accessorKey:'description',
            header:'Description',
            cell:(props:any)=><div>{props.getValue()}</div>,
            size:500,
            enableSorting:false,
            enableGlobalFilter:false


        },
        {
            accessorKey:'keywords',
            header:'Keywords',
            cell:KeywordCell,
            size:200,
            enableSorting:false,
            filterFn: ((row:any,columnId:any,value:any)=>{
                const keywords = row.getValue(columnId) || []
                for (let keyword of keywords){
                    if (keyword.keyword?.toLowerCase().includes(value.toLowerCase())){
                        return true
                    }
                    if (keyword.abbreviation?.toLowerCase().includes(value.toLowerCase())){
                        return true
                    }
                }
                return false
            })

        },
        {
            accessorKey:'date',
            header:'Date Added',
            cell:(props:any)=><div className="center-all">{props.getValue().toLocaleString()}</div>,
            size:200,
            filterFn:((row:any,columnId:any,value:any)=>{
                return row.getValue(columnId).toString().toLowerCase().includes(value.toLowerCase())
            })

        },
        {
            accessorKey:'id',
            header:'Edit/Delete',
            cell:(props:any)=><EditDeleteCell props={props} handleDelete={handleDelete} handleEdit={handleEdit}/>,
            size:150


        },
    ]


    const table = useReactTable({
        data:stories,
        columns:columns,
        state:{
            globalFilter:filter,
            columnVisibility
        },
        getCoreRowModel:getCoreRowModel(),
        getFilteredRowModel:getFilteredRowModel(),
        getSortedRowModel:getSortedRowModel(),
        columnResizeMode:"onChange",
        enableColumnFilters:true,
        enableFilters:true,
        enableHiding:true,
        getColumnCanGlobalFilter: (column) => {
            return column.columnDef.enableGlobalFilter? true : false ;
          },
        globalFilterFn:customGlobalFilter,
        sortingFns:{
            marketSegmentSorting,
            locationSorting,
            representativeSorting
        }

    })


    const handleDelete = async (id:any) =>{
        setOpened(true)
        setToDeleteID(id)
    }
    const handleEdit = async (id:any) =>{
        setToEditID(id)
        setOpenEditModal(true)
    }

    const confirmDelete = async () =>{
        const token = localStorage.getItem('token')
        const res = await deleteStory(toDeleteID,`Token ${token!.slice(1,-1)}`)


        if (res.status === "success"){
            const filtered = stories.filter((story:any) => story.id !== toDeleteID)
            setStories(filtered)

            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('Success','Story Deleted')
        }

        else{
            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('error',res.detail||res.ERROR,res.error)
        }
    }

    return (
        <div id='success-stories-table-wrapper' >
            <SuccessStoryForm opened={openEditModal} setShowModal={setOpenEditModal} setData={(setStories)} edit={toEditID} setEdit={setToDeleteID} />
            <FilterSuccessStoriesTable
                filter={filter}
                setFilter={setFilter}
            />
        <div className="stories-table"  style={{width:table.getTotalSize()}}>
            {table.getHeaderGroups().map((headerGroup:any)=>
            <div key={headerGroup.id} className="tr" >
                {headerGroup.headers.map((header:any)=>{
                    return (
                    <div key={header.id} className="th" style={{width:header.getSize()}} onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler():null}>
                        {header.column.columnDef.header}
                        {header.column.getIsSorted() === 'desc' ? <IonIcon style={{marginLeft:'0.2em'}} icon={caretDownCircle} slot="icon-only" />:''}
                        {header.column.getIsSorted() === 'asc' ? <IonIcon style={{marginLeft:'0.2em'}} icon={caretUpCircle} slot="icon-only" />:''}
                        {/* {header.column.getCanSort() && <IonIcon icon={caretUpOutline} slot="icon-only" onClick={header.column.getToggleSortingHandler()}/>} */}
                        <div
                        onMouseDown={ header.getResizeHandler()}
                        onTouchStart={ header.getResizeHandler()}
                        className={`resizer ${
                            header.column.getIsResizing() ? 'isResizing' : ''
                        }`}></div>
                    </div>)
                }
                )}
            </div>
            )}
            {table.getRowModel().rows.map((row:any)=>
                <div key={row.id} className="tr" style={{width:table.getTotalSize()}}>
                    {row.getVisibleCells().map((cell:any)=>{
                        return (
                        <div key={cell.id} className="td" style={{width:cell.column.getSize()}}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>)}
                    )}
                </div>
            )}

        <IonModal isOpen={opened} onDidDismiss={()=>setOpened(false)} className="confirmDelete">
            <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="delete-warning">
                            Are You Sure You Want To Delete This?
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonButton color="danger" onClick={()=> confirmDelete()} >Delete</IonButton>
                        <IonButton color="medium" onClick={ ()=> setOpened(false)} >Cancel</IonButton>
                    </IonRow>
            </IonGrid>
        </IonModal>
            </div>
        </div>

    )
}

export default SuccessStoriesTable