import { IonBadge } from '@ionic/react'
import React from 'react'

interface Props {
  getValue:any,
}

type keyword = {
  id:string,
  keyword:string,
  abbreviation:string
}

const KeywordCell:React.FC<Props> = ({getValue}) => {
  const keywords = getValue() || []
  return <div style={{display:'flex'}}>
    {
      keywords.map((keyword:keyword)=>
        <IonBadge className='keyword-box' key={keyword.id} title={keyword.keyword}>{keyword?.abbreviation || keyword.keyword}</IonBadge>)
    }

  </div>
}

export default KeywordCell