import { IonCol, IonContent, IonRow } from "@ionic/react"
import EstarHeader from "../../components/Energystar/EstarHeader"
import "./TemplateReport.css"
import { Editor } from '@tinymce/tinymce-react';
import { useHistory, useParams } from "react-router";
import { API_URL, TINYMCE_API_KEY } from "../../actions/settings";
import { useEffect, useState } from "react";
import NotificationPopUp from "../../components/extras/NotificationPopUp";
import  {dash} from '../../AE2/static/icons/dashboard-report';
import  {reloadDash} from '../../AE2/static/icons/reload-dash';
import { download_pdf } from "../../AE2/static/icons/download-pdf-icon";
import { space_between } from "../../AE2/static/icons/space-between";
import { page_break } from "../../AE2/static/icons/page-break";
import InsertDashBoardImage from "./InsertDashBoardImage";
import LoadingSpinner from "../../components/extras/LoadingSpinner";
import S3Bucket from "../../components/extras/S3Bucket";


interface Params {
    propertyId:string
}

declare const tinymce: any;

async function loadTemplate(token:any,propertyId:any) {

    return fetch(`${API_URL}/energystar/property/${propertyId}/template`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }
async function downloadPDF(token:any,propertyId:any) {

    return fetch(`${API_URL}/energystar/property/${propertyId}/report/download`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.blob())
   }
async function saveTemplate(token:any,propertyId:any,temp:any) {

    return fetch(`${API_URL}/energystar/property/${propertyId}/template/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify({'data':temp})

    })
      .then(data => data.json())
   }

  async function getDashScreenshot(token:any,id:any,form:any) {
    return fetch(`${API_URL}/getDashScreenshot/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(form)

    })
      .then(data => data.json())
   }

   async function getProperty(token:any,id:any) {

    return fetch(`${API_URL}/energystar/properties/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const Template:React.FC = () => {
    const history = useHistory()
    const { propertyId } = useParams<Params>();
    const [template, setTemplate] = useState('')
    const [tinyMCE,setTinyMCE] = useState<any>(null)
    const [opened,setOpened] = useState(false)
    const [reloading,setReloading] = useState(false)
    const [totalDash,setTotalDash] = useState(0)
    const [completeDash,setCompleteDash] = useState(0)
    const [ae2Id,setAE2Id] = useState<any>(null)
    const [property,setProperty] = useState<any>(null)
    const handleUpload = async (file:any, filename:any) => {
        const res:any = await S3Bucket.UploadImage("templates/pics",propertyId, file,filename)
        return res['Location']
    }
    const handleSaveTemplate = async (e:any) => {
        const token = localStorage.getItem('token') || ""
        const res = await saveTemplate(`Token ${token.slice(1,-1)}`,propertyId, e.contentDocument.body.innerHTML)
        if (res.status === "success"){
          NotificationPopUp('success',res.detail)


      }
      else{
        NotificationPopUp('error',res.detail)
      }
    }
    const handleDownload = async (e:any) => {
      e.notificationManager.open({
        text:'Downloading PDF',
        timeout:3000,
        type:'success'
      })
        const token = localStorage.getItem('token') || ""
        const res = await downloadPDF(`Token ${token.slice(1,-1)}`,propertyId)
        if(res){
          const url = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${property.property[0].name}-report.pdf`|| 'report.pdf');
          document.body.appendChild(link);
          link.click()
          link.parentNode!.removeChild(link);
        }

    }


    useEffect(()=>{
      const handleTemplate = async () => {
        const token = localStorage.getItem('token') || ""
        const res = await loadTemplate(`Token ${token.slice(1,-1)}`,propertyId)
        setTemplate(res.report)
        const prop = await getProperty(`Token ${token.slice(1,-1)}`,propertyId)
        setProperty(prop)
        setAE2Id(prop.ae2.uuid)
    }
        handleTemplate();
    },[propertyId])

    const reloadDashboardImages = async (e:any,propertyId:any) => {
      setReloading(true)
      let images = e.contentDocument.body.getElementsByClassName('dashboard-img')
      const token:any = localStorage.getItem('token')
      let reloaded = 0
      setTotalDash(images.length)

      for(let img of Array.from(images) as any){
        let id = img.dataset.dashid
        let params = img.dataset.params

        const filename = img.currentSrc.split('/').pop()
        const res = await getDashScreenshot(`Token ${token.slice(1,-1)}`,ae2Id,
        {
          title:filename.slice(0,-4),
          dashId: id,
          params: JSON.parse(params),
          reload: true
        })
        if(res.status === 'success')
        {
          img.setAttribute('key',Date.now())
          reloaded++
          setCompleteDash(reloaded)
        }

      }
      setReloading(false)
      setCompleteDash(0)

    }

    if(reloading){
      return (
        <IonContent className='estar-template'>
          <IonCol size="12">
            <IonRow className="ion-justify-content-center"><h1>Reloading Dashboard Images</h1></IonRow>
            <IonRow className="ion-justify-content-center">This might take a minute</IonRow>
            <IonRow className="ion-justify-content-center">{completeDash}/{totalDash} Complete</IonRow>
          </IonCol>
            <LoadingSpinner/>
            <IonRow className="ion-justify-content-center">Do not reload this page</IonRow>
        </IonContent>)
    }

    if(property){
    return (
        <IonContent className='estar-template'>
        <EstarHeader/>
        <IonRow className="ion-justify-content-center"style={{height:'100%'}}>
        <Editor
            apiKey={TINYMCE_API_KEY}
            initialValue={template}
            init={{
           height: '100%',
           width: '1240px',
           max_width:1240,
           plugins: 'save image pagebreak lists',
           toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify alignspacebetween newPage | bullist numlist outdent indent | ' +
           'removeformat | image insertDashboard save print download help reloadDashboards',
           menu: {
            property: { title: 'Property', items: 'name address gfa primaryfunction yearBuilt occupancy score score_date' },
            baseline: {title: 'Baseline', items: 'baseline_score baseline_energycost baseline_dailycost baseline_yearlycost baseline_siteEUI baseline_nationalEUI'},
            current: {title: 'Current' , items: 'current_score current_score_date current_energycost current_dailycost current_yearlycost current_siteEUI'},
            customer: {title: 'Customer', items: 'customer_firstname customer_lastname customer_address customer_email customer_phone customer_orgName'},
            math: {title:"Math",items:'add subtract divide multiply intdivide modulo round'},
            formulas:{title:"Formulas",items:'formula1 formula2 formula3 formula4 formula5'},
            generate: {title: 'Report', items: "generate"}
          },
          // pagebreak_separator: "<!-- my page break -->",
          menubar: 'file edit view insert format tools table help customer property baseline current math formulas generate ',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
           image_title: true,
           automatic_uploads: true,
           file_picker_types: 'image',

           file_picker_callback: (cb, value, meta) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.addEventListener('change', (e:any) => {
              const file = e.target.files[0];

              const reader:any = new FileReader();
              reader.addEventListener('load', async () => {
                const id = 'blobid' + (new Date()).getTime();
                const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                const url= await handleUpload(file,file.name)

                /* call the callback and populate the Title field with the file name */
                cb(url, { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },


           save_onsavecallback: (e:any) =>  {
             handleSaveTemplate(e)
           },


           setup: function (editor) {
            setTinyMCE(editor);
            editor.ui.registry.addIcon('embed-dash', dash);
            editor.ui.registry.addIcon('reload-dash', reloadDash);
            editor.ui.registry.addIcon('download-pdf', download_pdf);
            editor.ui.registry.addIcon('alignspacebetween', space_between);
            editor.ui.registry.addIcon('page_break', page_break);
            editor.ui.registry.addButton('insertDashboard', {
              icon: 'embed-dash',
              tooltip:'Insert Dashboard Image',
              onAction: () => setOpened(true)
            });
            editor.ui.registry.addButton('reloadDashboards', {
              icon: 'reload-dash',
              tooltip:'Reload All Dashboard Images',
              onAction: () => reloadDashboardImages(editor,propertyId)
            });
            editor.ui.registry.addButton('download', {
              icon: 'download-pdf',
              tooltip:'Download PDF',
              onAction: () => handleDownload(editor)
            });
             editor.ui.registry.addMenuItem('name', {
               text: 'Name',
               onAction: function () {
                 editor.insertContent('{{property.name}}');
               }
             });
             editor.ui.registry.addButton('alignspacebetween', {
               icon: 'alignspacebetween',
               tooltip: 'Space Between',
               onAction: function () {
                 editor.insertContent('<div style="display:flex; display: -webkit-box; justify-content: space-between; -webkit-box-pack: justify; max-width:100%" data-mce-style="display: flex; justify-content: space-between;"> <div><p style="margin: 0px; padding: 0px;">Left</p></div><div><p style="margin: 0px; padding: 0px;">Right</p></div></div>');
               }
             });
             editor.ui.registry.addButton('newPage', {
               icon: 'page_break',
               tooltip: 'Page Break',
               onAction: function () {
                 editor.insertContent('<p class="pagebreak"><img class="mce-pagebreak" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-mce-resize="false" data-mce-placeholder=""></p>');
               }
             });
             editor.ui.registry.addNestedMenuItem('address', {
                 text: 'Address',
                 getSubmenuItems: function () {
                   return [
                     {
                       type: 'menuitem',
                       text: 'Address',
                       onAction: function () {
                         editor.insertContent('{{property.address}}');
                       }
                     },
                     {
                       type: 'menuitem',
                       text: 'Address 2 / APT',
                       onAction: function () {
                         editor.insertContent('{{property.address2}}');
                       }
                     },
                     {
                       type: 'menuitem',
                       text: 'City',
                       onAction: function () {
                         editor.insertContent('{{property.city}}');
                       }
                     },
                     {
                       type: 'menuitem',
                       text: 'State',
                       onAction: function () {
                         editor.insertContent('{{property.state}}');
                       }
                     },
                     {
                       type: 'menuitem',
                       text: 'Postal Code',
                       onAction: function () {
                         editor.insertContent('{{property.postalcode}}');
                       }
                     },
                     {
                       type: 'menuitem',
                       text: 'Country',
                       onAction: function () {
                         editor.insertContent('{{property.country}}');
                       }
                     }
                   ];
                 }
               });
             editor.ui.registry.addMenuItem('gfa', {
               text: 'Gross Floor Area',
               onAction: function () {
                 editor.insertContent('{{property.grossfloorarea}}');
               }
             });
             editor.ui.registry.addMenuItem('primaryfunction',{
                 text: 'Primary Function',
                 onAction: function(){
                     editor.insertContent('{{property.primaryfunction}}')
                 }
             });
             editor.ui.registry.addMenuItem('yearBuilt',{
                 text: 'Year Built',
                 onAction: function(){
                     editor.insertContent('{{property.yearbuilt}}')
                 }
             });
             editor.ui.registry.addMenuItem('occupancy',{
                 text: 'Occupancy',
                 onAction: function(){
                     editor.insertContent('{{property.occupancypercentage}}')
                 }
             });
             editor.ui.registry.addMenuItem('score',{
                 text: 'Energy Star Score',
                 onAction: function(){
                     editor.insertContent('{{property.score}}')
                 }
             });
             editor.ui.registry.addMenuItem('score_date',{
                 text: 'Score Date',
                 onAction: function(){
                     editor.insertContent('{{property.score_date}}')
                 }
             });
             editor.ui.registry.addMenuItem('baseline_score',{
                 text: 'Score',
                 onAction: function(){
                     editor.insertContent('{{property.baselineandtarget.score}}')
                 }
             });
             editor.ui.registry.addMenuItem('baseline_energycost',{
                 text: 'Energy Cost ($/Square Foot)',
                 onAction: function(){
                     editor.insertContent('{{baseline.enercyCostIntensity}}')
                 }
             });
             editor.ui.registry.addMenuItem('baseline_score',{
                 text: 'Score',
                 onAction: function(){
                     editor.insertContent('{{property.baselineandtarget.score}}')
                 }
             });
             editor.ui.registry.addMenuItem('baseline_yearlycost',{
                 text: 'Energy Cost ($/Year)',
                 onAction: function(){
                     editor.insertContent("{{baseline.energyCost|div:1|stringformat:'0.2f'|intcomma}}")
                 }
             });
             editor.ui.registry.addMenuItem('baseline_dailycost',{
                 text: 'Energy Cost ($/Day)',
                 onAction: function(){
                     editor.insertContent("{{baseline.energyCost|div:365|stringformat:'0.2f'|intcomma}}")
                 }
             });
             editor.ui.registry.addMenuItem('baseline_siteEUI',{
                 text: 'Site Energy Use Intensity (kBtu/Square Foot)',
                 onAction: function(){
                     editor.insertContent('{{baseline.siteIntensity}}')
                 }
             });
             editor.ui.registry.addMenuItem('baseline_nationalEUI',{
                 text: 'National Median Site Energy Use Intensity (kBtu/Square Foot)',
                 onAction: function(){
                     editor.insertContent('{{baseline.medianSiteIntensity}}')
                 }
             });
             editor.ui.registry.addMenuItem('current_score',{
               text: 'Score',
               onAction: function(){
                   editor.insertContent('{{current.score}}')
               }
           });
             editor.ui.registry.addMenuItem('current_score_date',{
               text: 'Score Date',
               onAction: function(){
                   editor.insertContent('{{current.month}}/{{current.year}}')
               }
           });
           editor.ui.registry.addMenuItem('current_energycost',{
               text: 'Energy Cost ($/Square Foot)',
               onAction: function(){
                   editor.insertContent('{{current.enercyCostIntensity}}')
               }
           });
           editor.ui.registry.addMenuItem('current_yearlycost',{
               text: 'Energy Cost ($/Year)',
               onAction: function(){
                   editor.insertContent("{{current.energyCost|div:1|stringformat:'0.2f'|intcomma}}")
               }
           });
           editor.ui.registry.addMenuItem('current_dailycost',{
               text: 'Energy Cost ($/Day)',
               onAction: function(){
                   editor.insertContent("{{current.energyCost|div:365|stringformat:'0.2f'|intcomma}}")
               }
           });
           editor.ui.registry.addMenuItem('current_siteEUI',{
               text: 'Site Energy Use Intensity (kBtu/Square Foot)',
               onAction: function(){
                   editor.insertContent('{{current.siteIntensity}}')
               }
           });
           editor.ui.registry.addMenuItem('customer_firstname', {
             text: 'First Name',
             onAction: function () {
               editor.insertContent('{{customer.firstname}}');
             }
           });
           editor.ui.registry.addMenuItem('customer_lastname', {
             text: 'Last Name',
             onAction: function () {
               editor.insertContent('{{customer.lastname}}');
             }
           });
           editor.ui.registry.addNestedMenuItem('customer_address', {
               text: 'Address',
               getSubmenuItems: function () {
                 return [
                   {
                     type: 'menuitem',
                     text: 'Address',
                     onAction: function () {
                       editor.insertContent('{{customer.address}}');
                     }
                   },
                   {
                     type: 'menuitem',
                     text: 'Address 2 / APT',
                     onAction: function () {
                       editor.insertContent('{{customer.address2}}');
                     }
                   },
                   {
                     type: 'menuitem',
                     text: 'City',
                     onAction: function () {
                       editor.insertContent('{{customer.city}}');
                     }
                   },
                   {
                     type: 'menuitem',
                     text: 'State',
                     onAction: function () {
                       editor.insertContent('{{customer.state}}');
                     }
                   },
                   {
                     type: 'menuitem',
                     text: 'Postal Code',
                     onAction: function () {
                       editor.insertContent('{{customer.postalcode}}');
                     }
                   },
                   {
                     type: 'menuitem',
                     text: 'Country',
                     onAction: function () {
                       editor.insertContent('{{customer.country}}');
                     }
                   }
                 ];
               }
             });
           editor.ui.registry.addMenuItem('customer_email', {
             text: 'Email',
             onAction: function () {
               editor.insertContent('{{customer.email}}');
             }
           });
           editor.ui.registry.addMenuItem('customer_phone', {
             text: 'Phone',
             onAction: function () {
               editor.insertContent('{{customer.phone}}');
             }
           });
           editor.ui.registry.addMenuItem('customer_jobtitle', {
             text: 'Job Title',
             onAction: function () {
               editor.insertContent('{{customer.jobtitle}}');
             }
           });
           editor.ui.registry.addMenuItem('customer_orgname', {
             text: 'Organization',
             onAction: function () {
               editor.insertContent('{{customer.orgname}}');
             }
           });
           editor.ui.registry.addMenuItem('generate', {
             text: 'Generate Report',
             onAction: function () {
                history.push(`/energystar/property/${propertyId}/report`)
             }
           });
           editor.ui.registry.addMenuItem('add', {
             text: 'Addition',
             onAction: function () {
              editor.insertContent('|add:');
             }
           });
           editor.ui.registry.addMenuItem('subtract', {
             text: 'Subtraction',
             onAction: function () {
              editor.insertContent('|sub:');
             }
           });
           editor.ui.registry.addMenuItem('multiply', {
             text: 'Multiplication',
             onAction: function () {
              editor.insertContent('|mul:');
             }
           });
           editor.ui.registry.addMenuItem('divide', {
             text: 'Division',
             onAction: function () {
              editor.insertContent('|div:');
             }
           });
           editor.ui.registry.addMenuItem('intdivide', {
             text: 'Integer Division',
             onAction: function () {
              editor.insertContent('|intdiv:');
             }
           });
           editor.ui.registry.addMenuItem('modulo', {
             text: 'Modulo',
             onAction: function () {
              editor.insertContent('|mod:');
             }
           });
           editor.ui.registry.addMenuItem('round', {
             text: 'Round to 2 Decimal Places',
             onAction: function () {
              editor.insertContent("|stringformat:'0.2f'");
             }
           });
           editor.ui.registry.addMenuItem('formula1', {
             text: 'Energy Cost / SF',
             onAction: function () {
              editor.insertContent("{{formulas.energycostPerSF}}");
             }
           });
           editor.ui.registry.addMenuItem('formula2', {
             text: 'Target Energy Cost / SF',
             onAction: function () {
              editor.insertContent("{{formulas.targetEnergycostPerSF}}");
             }
           });
           editor.ui.registry.addMenuItem('formula3', {
             text: 'Current EUI / Median EUI',
             onAction: function () {
              editor.insertContent("{{formulas.currentDivMedianEUI}}");
             }
           });
           editor.ui.registry.addMenuItem('formula4', {
             text: 'Target EUI / Current EUI',
             onAction: function () {
              editor.insertContent("{{formulas.targetDivCurrentEUI}}");
             }
           });
           editor.ui.registry.addMenuItem('formula5', {
             text: 'Baseline Energy Cost - Target Energy Cost',
             onAction: function () {
              editor.insertContent("{{formulas.reductionCost}}");
             }
           });
         }
          }}
       />
       </IonRow>
       <InsertDashBoardImage isOpened={[opened,setOpened]} editor={tinyMCE} building={ae2Id}/>

        </IonContent>
    )
  }

  return (
    <LoadingSpinner/>
  )
}

export default Template