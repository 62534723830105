import { IonContent, IonRow } from "@ionic/react"
import EstarHeader from "../../components/Energystar/EstarHeader"
import "./TemplateReport.css"
import { Editor } from '@tinymce/tinymce-react';
import { useHistory, useParams } from "react-router";
import { API_URL, TINYMCE_API_KEY } from "../../actions/settings";
import { useEffect, useState } from "react";
import { download_pdf } from "../../AE2/static/icons/download-pdf-icon";
import LoadingSpinner from "../../components/extras/LoadingSpinner";

interface Params {
    propertyId:string
}

async function loadTemplate(token:any,propertyId:any) {

    return fetch(`${API_URL}/energystar/property/${propertyId}/report`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

  async function downloadPDF(token:any,propertyId:any) {

    return fetch(`${API_URL}/energystar/property/${propertyId}/report/download`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.blob())
   }

  async function getProperty(token:any,id:any) {

    return fetch(`${API_URL}/energystar/properties/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const Report:React.FC = () => {
    const { propertyId } = useParams<Params>();
    const [template, setTemplate] = useState('')
    const [property,setProperty] = useState<any>(null)
    const history = useHistory()

    useEffect(()=>{
      const handleTemplate = async () => {
        const token = localStorage.getItem('token') || ""
        const res = await loadTemplate(`Token ${token.slice(1,-1)}`,propertyId)
        setTemplate(res.report)
        const prop = await getProperty(`Token ${token.slice(1,-1)}`,propertyId)
        setProperty(prop)
    }
        handleTemplate();
    },[propertyId])

    const handleDownload = async (e:any) => {
      e.notificationManager.open({
        text:'Downloading PDF',
        timeout:3000,
        type:'success'
      })
        const token = localStorage.getItem('token') || ""
        const res = await downloadPDF(`Token ${token.slice(1,-1)}`,propertyId)
        if(res){
          const url = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${property.property[0].name}-report.pdf`|| 'report.pdf');
          document.body.appendChild(link);
          link.click()
          link.parentNode!.removeChild(link);
        }

    }

    if(property){
    return (
        <IonContent className='estar-template'>
        <EstarHeader/>
        <IonRow className="ion-justify-content-center"style={{height:'100%'}}>
        <Editor
            apiKey={TINYMCE_API_KEY}
            initialValue={template}
            init={{
           height: '100%',
           width: '1200px',
           plugins: 'image',
           toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | image download print help',
           menu: {
            template: {title: 'Template', items: "edit-template"}
          },
          menubar: 'file edit view insert format tools table template help',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          setup: function (editor) {
            editor.ui.registry.addIcon('download-pdf', download_pdf);
            editor.ui.registry.addButton('download', {
              icon: 'download-pdf',
              tooltip:'Download PDF',
              onAction: () => handleDownload(editor)
            });
            editor.ui.registry.addMenuItem('edit-template', {
              text: 'Edit Template',
              onAction: function () {
                 history.push(`/energystar/property/${propertyId}/template`)
              }
            });
           }
         }}
       />
       </IonRow>

        </IonContent>
    )
  }
  return (
    <LoadingSpinner/>
  )
}

export default Report