import { useState } from 'react';
import StoreUser from './storeUser';
import Cookies from 'universal-cookie';


const useToken = () => {
  const getToken = () => {
    const tokenString:any = localStorage.getItem('token');
    if (tokenString===''|| !tokenString){
      if (window.location.pathname === '/dash_screenshot'){
        const cookies = new Cookies()
        const tknString = cookies.get('tkn')
        if(tknString){
          const userToken = tknString
          return userToken
        }

      }
      localStorage.removeItem('token')
      return null
    }
    const userToken = JSON.parse(tokenString);

    return userToken
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: String) => {
    if(userToken){
      localStorage.setItem('token', JSON.stringify(userToken));
      setToken(userToken);
      return true;
    }
    localStorage.removeItem('token')
    return false;
  };

  if(token){
    StoreUser(token,setToken)
  }

  return {
    setToken: saveToken,
    token
  }
};

export default useToken;