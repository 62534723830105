import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../actions/settings";
import AE2CustomerBuildingList from "./AE2CustomerBuildingList";
import "./AE2CustomerInfo.css";
import CustomerInfoCard from "./CustomerPageComponents/CustomerInfoCard";
import OverviewSummary from "./CustomerPageComponents/OverviewSummary";
import CostsSummary from "./CustomerPageComponents/AnnualCostsSummary";
import ThreeMonthScoreTrendGraph from "./CustomerPageComponents/ThreeMonthScoreTrendGraph";
import UtilityCostTrendGraph from "./CustomerPageComponents/UtilityCostTrendGraph";
import UtilityRateInflationGraph from "./CustomerPageComponents/UtilityRateInflationGraph";
import { isStaff } from "../extras/Functions";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import CostsSummaryPieChart from "./CustomerPageComponents/CostsSummaryPieChart";

interface props {
  item: any;
}

interface cust {
  customer: any;
  campuses: any;
  buildings: any;
  totals: any;
}

interface vpn {
  type: {
    id: any;
    type: any;
  };
  vpn_client: {
    id: any;
    client: any;
  };
  location: any;
  remote_access: boolean;
  timestamp: any;
}

const startVPN = {
  type: {
    id: "",
    type: "",
  },
  vpn_client: {
    id: "",
    client: "",
  },
  location: "",
  remote_access: false,
  timestamp: "",
};
const startVersioning = {
  cust_uuid: "",
  cust_name: "",
  ebo_servers: 0,
  software_versions: "",
};

async function getCustomerData(token: any, id: string) {
  return fetch(`${API_URL}/customer/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

async function getVPNData(token: any, id: string) {
  return fetch(`${API_URL}/customer/${id}/vpn_info`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

async function getEBOVersioning(token: any, id: string) {
  return fetch(`${API_URL}/customer/${id}/ebo_versioning`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const handleGetData = async (
  token: any,
  id: any,
  setVPNData: any,
  setVersioning: any
) => {
  const res = await getVPNData(token, id);
  setVPNData(res);
  const res2 = await getEBOVersioning(token, id);
  setVersioning(res2.data);
};

const AE2CustomerInfo: React.FC<props> = ({ item }) => {
  const user = useAppSelector(selectUser);
  const [data, setData] = useState<cust>({
    customer: "",
    campuses: [],
    buildings: [],
    totals: { sf: 0, incentive: 0 },
  });
  const [loaded, setLoaded] = useState(false);
  const [VPNData, setVPNData] = useState<vpn>(startVPN);
  const [versioning, setVersioning] = useState(startVersioning);

  useEffect(() => {
    const handleCustomer = async () => {
      const tkn: any = localStorage.getItem("token");
      const token = `Token ${tkn.slice(1, -1)}`;
      const res = await getCustomerData(token, item.uuid);
      handleGetData(token, item.uuid, setVPNData, setVersioning);
      setData(res);
      setLoaded(true);
    };

    if (item.uuid) {
      handleCustomer();
    }
  }, [item.uuid]);

  return (
    <>
      {!loaded ? (
        <IonLoading
          cssClass="my-custom-class"
          isOpen={!loaded}
          message={"Loading..."}
          spinner="bubbles"
          duration={10000}
        />
      ) : (
        <IonGrid>
          {isStaff(user) ? (
            <IonRow>
              <IonCol size="12">
                <CustomerInfoCard
                  customer={data.customer}
                  totals={data.totals}
                  versioning={versioning}
                  vpn={VPNData}
                />
              </IonCol>
            </IonRow>
          ) : (
            ""
          )}
          <IonRow>
            <IonCol size="12" sizeMd="6" sizeXl="2">
              <OverviewSummary id={data.customer.uuid} />
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeXl="3">
              <h2 style={{ textAlign: "center" }}>Energy Star Scores</h2>
              <AE2CustomerBuildingList
                style={{ maxHeight: "650px", overflowY: "auto" }}
                buildings={data.buildings}
              />
            </IonCol>
            <IonCol
              size="12"
              sizeMd="6"
              sizeXl="4"
              style={{ maxHeight: "100%" }}
            >
              <h2 style={{ textAlign: "center" }}>3 Month Score Trends</h2>
              <div style={{ height: "600px" }}>
                <ThreeMonthScoreTrendGraph id={data.customer.uuid} />
              </div>
            </IonCol>
            <IonCol
              size="12"
              sizeMd="6"
              sizeXl="3"
              style={{ maxHeight: "100%" }}
            >
              <CostsSummary id={data.customer.uuid} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" sizeMd="6" sizeXl="4">
              <h2 style={{ textAlign: "center" }}> Utility Cost Trends</h2>
              <div style={{ minHeight: "500px" }}>
                <UtilityCostTrendGraph id={data.customer.uuid} />
              </div>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeXl="4">
              <h2 style={{ textAlign: "center" }}>
                Year Utility Rate Inflation
              </h2>
              <div style={{ minHeight: "500px", width: "100%" }}>
                <UtilityRateInflationGraph id={data.customer.uuid} />
              </div>
            </IonCol>
            <IonCol size="12" sizeXl="4">
            <h2 style={{ textAlign: "center" }}>
                Annual Costs Breakdown
              </h2>
              <div style={{ minHeight: "500px", width: "100%" }}>
              <CostsSummaryPieChart id={data.customer.uuid} />
              </div>
            </IonCol>

          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default AE2CustomerInfo;
