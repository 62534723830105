import { IonButton, IonButtons, IonCol, IonGrid, IonList, IonListHeader, IonModal, IonRow, IonTitle } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../../actions/settings"
import './UtilityServiceSettings.css'
import NotificationPopUp from "../../extras/NotificationPopUp"
import useHandleState from "../../../utils/useHandleState"
import EnergyCategoryForm from "./forms/EnergyCategoryForm"
import EnergyUnitsForm from "./forms/EnergyUnitsForm"
import CategoryItem from "./CategoryItem"
import { isMobile } from "react-device-detect"


async function getCategories(token:any) {

  return fetch(`${API_URL}/ae2_eng_unit_categories`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }
async function deleteCategory(id:any,token:any) {

  return fetch(`${API_URL}/ae2_eng_unit_categories`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    },
    body:JSON.stringify({id:id})

  })
    .then(data => data.json())
 }

 async function deleteUnit(ids:any,token:any) {

    return fetch(`${API_URL}/ae2_energy_target_units`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body:JSON.stringify(ids)

    })
      .then(data => data.json())
   }


const EnergyCategorySettings:React.FC = () => {

    const [categories, handleCategories] = useHandleState([])
    const [showCategoryModal, setShowCategoryModal] = useState(false)
    const [showUnitsModal, setShowUnitsModal] = useState(false)
    const [opened,setOpened] = useState(false)
    const [toDeleteID,setToDeleteID] = useState<any>()
    const [toEdit, setToEdit] = useState(null)

    useEffect(() => {
        const token = localStorage.getItem('token')
        handleCategories(token,getCategories)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const updateCategories = ()=>{
        const token = localStorage.getItem('token')
        handleCategories(token,getCategories)
    }

    const handleDelete = async (id:any,category?:any,deleteUnit=false) =>{
        setOpened(true)
        if(deleteUnit){
            setToDeleteID({'id':id,'category':category})
        }
        else{
            setToDeleteID(id)
        }

    }

    const confirmDelete = async () =>{
        const token = localStorage.getItem('token')
        let res:any = {status:'none',detail:'none'}
        if(toDeleteID.category){
             res = await deleteUnit(toDeleteID,`Token ${token!.slice(1,-1)}`)
        }
        else{
            res = await deleteCategory(toDeleteID,`Token ${token!.slice(1,-1)}`)
        }

        if (res.status === "success"){
            updateCategories()
            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('Success','Item Deleted')
        }

        else{
            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('error',res.detail||res.ERROR,res.error)
        }
    }

    return (
        <IonRow className="ion-justify-content-center utility-settings">
                <EnergyCategoryForm opened={showCategoryModal} setShowModal={setShowCategoryModal} updateCategories={updateCategories} edit={[toEdit,setToEdit]}/>
                <EnergyUnitsForm opened={showUnitsModal} setShowUnitsModal={setShowUnitsModal} updateCategories={updateCategories} edit={[toEdit,setToEdit]}/>
            <IonCol>
                <IonListHeader className="admin-utility">
                    <IonTitle>Unit Categories</IonTitle>
                <IonButtons hidden={isMobile}>
                    <IonButton fill='solid' slot="end" color="primary" id="addCategory" onClick={()=>setShowCategoryModal(true)}>Add Category</IonButton>
                    <IonButton fill='solid' color="primary" id="addUnit" onClick={()=>setShowUnitsModal(true)}>Add Unit</IonButton>
                </IonButtons>
                </IonListHeader>
                <IonRow hidden={!isMobile} className="ion-justify-content-center">
                    <IonButtons>
                    <IonButton fill='solid' slot="end" color="primary" id="addCategory" onClick={()=>setShowCategoryModal(true)}>Add Category</IonButton>
                    <IonButton fill='solid' color="primary" id="addUnit" onClick={()=>setShowUnitsModal(true)}>Add Unit</IonButton>
                    </IonButtons>
                </IonRow>
                <IonList  style={{maxHeight:'80vh',overflowY:'scroll'}}>
                    {categories.map((unit:any)=>{
                        return(
                            <CategoryItem  key={'category'+unit.id} unit={unit} setToEdit={setToEdit} setShowCategoryModal={setShowCategoryModal} setShowUnitsModal={setShowUnitsModal} handleDelete={handleDelete}/>
                        )
                    })}
                </IonList>

            </IonCol>
            <IonModal isOpen={opened} onDidDismiss={()=>setOpened(false)} className="confirmDelete">
                <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="delete-warning">
                                Are You Sure You Want To Delete This? <br/>
                                *You Must Delete All Units Before Deleting The Category*
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonButton color="danger" onClick={()=> confirmDelete()} >Delete</IonButton>
                            <IonButton color="medium" onClick={ ()=> setOpened(false)} >Cancel</IonButton>
                        </IonRow>
                </IonGrid>
            </IonModal>
        </IonRow>
    )
}

export default EnergyCategorySettings