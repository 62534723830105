import { IonRow } from "@ionic/react"

const LoadingSpinner:React.FC = () => {
    return (
        <IonRow className="ion-justify-content-center">
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </IonRow>
    )
}

export default LoadingSpinner