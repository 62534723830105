import { IonSearchbar } from "@ionic/react";
import React from "react";
import "./FilterSuccessStoriesTable.css"

interface IProps {
    filter:any
    setFilter:any
};

const FilterSuccessStoriesTable:React.FC<IProps> = ({filter, setFilter}) => {

    return <div style={{display:"flex",width:'100%',justifyContent:'center'}}>
        <IonSearchbar id='success-stories-search' showClearButton="focus" value={filter} onIonInput={(e:any)=>setFilter(e.target.value)}></IonSearchbar>
    </div>
};

export default FilterSuccessStoriesTable;