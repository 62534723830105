import { useEffect, useState } from "react";
import { API_URL } from "../actions/settings";

async function setOnlineStatus(token: string, online: boolean) {
  return fetch(`${API_URL}/user/online_status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ online: online }),
  });
}

export function useOnlineStatus() {
  /* Set the online status of the user. The user will show online when in the AE2 Tab and offline when the tab is changed or closed */
  const [online, setOnline] = useState(window.navigator.onLine);
  const token = localStorage.getItem("token") || "";
  useEffect(() => {
    async function handleOnline() {
      setOnline(true);
      await setOnlineStatus(`Token ${token.slice(1, -1)}`, true);
    }
    async function handleOffline() {
      setOnline(false);
      await setOnlineStatus(`Token ${token.slice(1, -1)}`, false);
    }
    function handleVisibility() {
      if (document.visibilityState === "visible") {
        handleOnline();
      } else {
        handleOffline();
      }
    }
    document.addEventListener("visibilitychange", handleVisibility);
    setOnlineStatus(`Token ${token.slice(1, -1)}`, window.navigator.onLine);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibility);
    };
  }, [token]);
  return online;
}
