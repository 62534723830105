import { IonButton, IonCol, IonContent, IonGrid, IonHeader ,IonIcon,IonInput,IonItem,IonLabel,IonModal, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../Hooks"
import { selectUser, setStateUser } from "../../reducers/UserReducer"
import "./GeneralSetting.css"
import { closeOutline } from "ionicons/icons"
import { API_URL } from "../../actions/settings"
import NotificationPopUp from "../extras/NotificationPopUp"

async function editUser(data:any,token:string) {
    return fetch(`${API_URL}/user`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }

const startErrors = {
    first_name:"",
    last_name:"",
    username:"",
    email:"",
}


const GeneralSettings:React.FC = () => {
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()
    const [showNameModal, setShowNameModal] = useState(false)
    const [showUsernameModal, setShowUsernameModal] = useState(false)
    const [showEmailModal, setShowEmailModal] = useState(false)
    const [errors, setErrors] = useState(startErrors)

    const startNameForm = {
        first_name : "",
        last_name : "",
    }
    const startUsernameForm = {
        username : ""
    }
    const startEmailForm = {
        email : ""
    }

    const [nameForm, setNameForm] = useState(startNameForm)
    const [usernameForm, setUsernameForm] = useState(startUsernameForm)
    const [emailForm, setEmailForm] = useState(startEmailForm)

    const getDate = (fullDate:any) =>{
        const date = new Date(fullDate)

        const string = `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`
        return string
    }

    const handleFormChange = (value:any,name:string,setForm:any) => {
        validate(name,value)
        setForm((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    useEffect(()=>{
        setNameForm({
            first_name: user.first_name,
            last_name: user.last_name
        })
        setUsernameForm({
            username : user.username
        })
        setEmailForm({
            email: user.email
        })
    },[user])


    const handleNameChange = async (e:any) => {
        e.preventDefault()
        if(errors.first_name === "" && errors.last_name === "")
        {
            const token:any = localStorage.getItem('token')
            const res = await editUser(nameForm,`Token ${token.slice(1,-1)}`)
            if (res.status === "success"){
                dispatch(setStateUser(res.user))
                NotificationPopUp('success','Name was Edited')
            }
            else{
                NotificationPopUp('error',`Name could not be edited. \n ${res.error}`)
            }
            setShowNameModal(false)
        }

    }
    const handleUsernameChange = async (e:any) => {
        e.preventDefault()
        if (errors.username === ""){
            const token:any = localStorage.getItem('token')
            const res = await editUser(usernameForm,`Token ${token.slice(1,-1)}`)
            if (res.status === "success"){
                dispatch(setStateUser(res.user))
                NotificationPopUp('success','Username was Edited')
            }
            else{
                NotificationPopUp('error',`Username could not be edited. \n ${res.error}`)
            }
            setShowUsernameModal(false)
        }

    }
    const handleEmailChange = async (e:any) => {
        e.preventDefault()
        if (errors.email === ""){
            const token:any = localStorage.getItem('token')
            const res = await editUser(emailForm,`Token ${token.slice(1,-1)}`)
            if (res.status === "success"){
                dispatch(setStateUser(res.user))
                NotificationPopUp('success','Email was Edited')
            }
            else{
                NotificationPopUp('error',`Email could not be edited. \n ${res.error}`)
            }
            setShowEmailModal(false)
        }

    }

    const validate = (name:string, val:any) =>{
        const regExp = /\s/;
        const emailExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        switch (name) {
            case 'first_name':
                if(val === null || val.length <=1){
                    setErrors({...errors, first_name:"Not a Valid Name"})
                }
                else{
                    setErrors({...errors, first_name:""})
                }

                break;
            case 'last_name':
                if(val === null || val.length <=1){
                    setErrors({...errors, last_name:"Not a Valid Last Name"})
                }
                else{
                    setErrors({...errors, last_name:""})
                }
                break;
            case 'username':
                if(val === null || val.length <=1){
                    setErrors({...errors, username:"Not a Valid username"})
                }
                else if (regExp.test(val)){
                    setErrors({...errors, username:"Cannot Contain Spaces"})
                }
                else{
                    setErrors({...errors, username:""})
                }
                break;
            case 'email':
                if(val === null || val.length <=1){
                    setErrors({...errors, email:"Email To Short"})
                }
                else if (regExp.test(val)){
                    setErrors({...errors, email:"Cannot Contain Spaces"})
                }
                else if (!emailExp.test(val)){
                    setErrors({...errors, email:"Not A Valid Email"})
                }
                else{
                    setErrors({...errors, email:""})
                }
                break;

            }
    }


    return (
        <div>
            <IonHeader className="header"> <h2>General Account Info and Settings</h2></IonHeader>
                <IonGrid fixed>
                    <IonRow className="setting-row">
                        <IonCol size="3">Name:</IonCol>
                        <IonCol size="7">{user.first_name!} {user.last_name!}</IonCol>
                        <IonCol size="2"><IonButton id="nameBtn" size="small" color="dark" onClick={() => setShowNameModal(true)}>Edit</IonButton></IonCol>
                        <IonModal
                            isOpen={showNameModal}
                            onDidDismiss={() => setShowNameModal(false)}
                            className="modal-form">
                                <IonContent>
                                    <form onSubmit={handleNameChange}>
                                        <IonRow className="modal-close">
                                            <IonButton expand="block" color="danger" size="large" onClick={() => setShowNameModal(false)}>
                                              <IonIcon src={closeOutline}></IonIcon>
                                            </IonButton>
                                        </IonRow>
                                        <IonItem>
                                            <IonLabel position="stacked">First Name:</IonLabel>
                                            <IonInput color='dark' className={errors.first_name === '' ? "valid":'invalid'} value={nameForm.first_name} onIonChange={e => {handleFormChange(e.detail.value!,'first_name',setNameForm)}}/>
                                            <div className="error-detail">{errors.first_name}</div>
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel position="stacked">Last Name:</IonLabel>
                                            <IonInput color='dark' className={errors.last_name === '' ? "valid":'invalid'} value={nameForm.last_name} onIonChange={e => {handleFormChange(e.detail.value!,'last_name',setNameForm)}} />
                                            <div className="error-detail">{errors.last_name}</div>
                                        </IonItem>
                                        <IonRow className="modal-btns">
                                            <IonButton expand="block" type="submit">
                                              Save Changes
                                            </IonButton>
                                        </IonRow>
                                    </form>
                                </IonContent>
                            </IonModal>
                    </IonRow>
                    <IonRow className="setting-row">
                        <IonCol size="3">Username:</IonCol>
                        <IonCol size="7">{user.username!}</IonCol>
                        <IonCol size="2"><IonButton id="emailBtn" size="small" color="dark" onClick={()=> {setShowUsernameModal(true)}}>Edit</IonButton></IonCol>
                        <IonModal
                            isOpen={showUsernameModal}
                            onDidDismiss={() => setShowUsernameModal(false)}
                            className="modal-form">
                                <IonContent>
                                    <form  onSubmit={handleUsernameChange}>
                                        <IonRow className="modal-close">
                                            <IonButton expand="block" color="danger" size="large" onClick={() => setShowUsernameModal(false)}>
                                              <IonIcon src={closeOutline}></IonIcon>
                                            </IonButton>
                                        </IonRow>
                                        <IonItem>
                                            <IonLabel position="stacked">Username:</IonLabel>
                                            <IonInput color='dark' className={errors.username === '' ? "valid":'invalid'} value={usernameForm.username} onIonChange={e => {handleFormChange(e.detail.value!,'username',setUsernameForm)}}/>
                                            <div className="error-detail">{errors.username}</div>
                                        </IonItem>
                                        <IonRow className="modal-btns">
                                            <IonButton expand="block" type="submit">
                                              Save Changes
                                            </IonButton>
                                        </IonRow>
                                    </form>
                                </IonContent>
                            </IonModal>
                    </IonRow>
                    <IonRow className="setting-row">
                        <IonCol size="3">Email:</IonCol>
                        <IonCol size="7">{user.email!}</IonCol>
                        <IonCol size="2"><IonButton id="usernameBtn" size="small" color="dark" onClick={() => {setShowEmailModal(true)}}>Edit</IonButton></IonCol>
                        <IonModal
                            isOpen={showEmailModal}
                            onDidDismiss={() => setShowEmailModal(false)}
                            className="modal-form">
                                <IonContent>
                                    <form onSubmit={handleEmailChange}>
                                        <IonRow className="modal-close">
                                            <IonButton expand="block" color="danger" size="large" onClick={() => setShowEmailModal(false)}>
                                              <IonIcon src={closeOutline}></IonIcon>
                                            </IonButton>
                                        </IonRow>
                                        <IonItem>
                                            <IonLabel position="stacked">Email:</IonLabel>
                                            <IonInput color='dark' className={errors.email === '' ? "valid":'invalid'} value={emailForm.email} onIonChange={e => {handleFormChange(e.detail.value!,'email',setEmailForm)}}/>
                                            <div className="error-detail">{errors.email}</div>
                                        </IonItem>
                                        <IonRow className="modal-btns">
                                            <IonButton expand="block"  type="submit">
                                              Save Changes
                                            </IonButton>
                                        </IonRow>
                                    </form>
                                </IonContent>
                            </IonModal>
                    </IonRow>
                    <IonRow className="setting-row">
                        <IonCol size="3">Joined Date:</IonCol>
                        <IonCol size="7">{getDate(user.date_joined)}</IonCol>
                    </IonRow>
                </IonGrid>


        </div>
    )
}

export default GeneralSettings