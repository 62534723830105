import { IonCol, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonLoading, IonRow, useIonViewWillEnter } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../actions/settings"
import "./PropertiesTable.css"
import Search from "../Search"


async function getProperties(token:any) {

    return fetch(`${API_URL}/energystar/properties`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }


const PropertiesTable: React.FC = () =>{

    const [ properties, setProperties] = useState<any>([])
    const [ filtered, setFiltered] = useState<any>([])
    const [ data, setData ] = useState<any>([])
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
    const [ loaded, setLoaded ] = useState(false)



    useEffect(() => {
        const handleProperties = async (token:any) => {
            const res = await getProperties(`Token ${token.slice(1,-1)}`)
            setProperties(res)
            const startData = []
            for (let i = 0; i < 30; i++) {
                startData.push(res[i]);
              }
            setData(res.slice(0,30))
            setFiltered(res)
            setLoaded(true)
        }

        const tkn = localStorage.getItem('token')
        handleProperties(tkn)


      }, [])

    const pushData = () => {
        const end = filtered.length
        const max = data.length + 20;
        const min = max - 20;
        const newData = [];
        for (let i = min; i < max && i<end; i++) {
          newData.push(filtered[i]);
        }

        setData([
          ...data,
          ...newData
        ]);
        // setLoaded(true)
      }
      const loadData = (ev: any) => {
        setTimeout(() => {
          pushData();
          ev.target.complete();
          if (data.length === 1000) {
            setInfiniteDisabled(true);
          }
        }, 500);
      }

    useIonViewWillEnter(() => {
        pushData();
      });



    return (
        <IonGrid className="prop-table">
          <IonLoading
            cssClass='my-custom-class'
            isOpen={!loaded}
            message={'Loading...'}
            spinner='bubbles'
          />
            <IonRow className="ion-justify-content-center table-container">
                <div className="title">Properties</div>
                <IonList>
                <IonRow className="top-row">
                    <IonCol size="12" sizeXl="6" className="search"> <Search list={properties} setFiltered={setFiltered} setData={setData}/> </IonCol>
                    {/* <IonCol size="4"><IonButton expand='block' href="/customer/281aecb5-74a9-4a1c-a342-6366a2f8e0f0/addproperty">Add Property</IonButton></IonCol> */}
                </IonRow>
                <IonRow className="prop-row-title">
                        <IonCol>Name:</IonCol>
                        {/* <IonCol>Address:</IonCol> */}
                        {/* <IonCol>Property ID:</IonCol> */}
                        <IonCol>GFA:(ft<sup>2</sup>)</IonCol>
                        <IonCol>Score:</IonCol>
                </IonRow>
                {data.map((item:any, index:any) => {
                    return (
                        <a href={`/energystar/properties/${item.id}`} key={index}>
                        <IonRow className="prop-row">
                            <IonCol>{item.name}</IonCol>
                            {/* <IonCol>{item.address}</IonCol> */}
                            {/* <IonCol>{item.property_id}</IonCol> */}
                            <IonCol>{item.grossfloorarea.toLocaleString('en')}</IonCol>
                            <IonCol>{ item.score !== null ? `${item.score} (${item.score_date})` : "---"}</IonCol>
                        </IonRow>
                        </a>

                )
                })}
                </IonList>

                <IonInfiniteScroll
                    onIonInfinite={loadData}
                    threshold="100px"
                    disabled={isInfiniteDisabled}>
                <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more properties..."
                ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonRow>
        </IonGrid>
        )

}

export default PropertiesTable