import { IonButton, IonCol, IonInput, IonItem, IonRow} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { API_URL,TINYMCE_API_KEY } from "../../../actions/settings";
import './AE2Software.css'
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from "react-router";
import NotificationPopUp from "../../extras/NotificationPopUp";
import S3Bucket from "../../extras/S3Bucket"

interface vars{
  id:string,
  ce:any
}

const startForm = {
  title:'',
  html:'<p>Add Documentation Here</p>'
}
const startErrors:any = {
  title:'',
  html:''
}

declare const tinymce: any;

async function getDocs(token:any,id:any) {

  return fetch(`${API_URL}/ae2_software_docs/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

async function saveDocs(token:any,id:any,data:any) {

  return fetch(`${API_URL}/ae2_software_docs/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    },
    body: JSON.stringify(data)

  })
    .then(data => data.json())
 }

const AE2SoftwareDocsForm: React.FC<vars> = ({id,ce}) => {
    const [form, setForm] = useState(startForm)
    const [errors, setErrors] = useState(startErrors)
    const editorRef = useRef<any>(null);
    const history = useHistory()

    useEffect(()=>{
      const handleDocs = async () =>{
        const token = localStorage.getItem('token') || ""
        const res = await getDocs(`Token ${token.slice(1,-1)}`, id)
        setForm({
          title:res.data[0].title,
          html:res.data[0].html
        })
      }
      if(ce === 'edit'){
        handleDocs()
      }

    },[id,ce])

    const handleFormChange = (value:any,name:string) => {
      setErrors({...errors, ...validate(name,value) })
      setForm((prevState) => {
          return {
              ...prevState,
              [name]:value
          }
      } )

  }

  const validate = (name:string, val:any) => {
      switch(name){
          case 'title':
              if(val === null){
                  return {title:'Title Cannot Be Empty'}
              }
              else{
                  return {title:''}
              }
          default:
              break;
      }
  }

  const validateAll = (form:any) => {
    let validation = startErrors
    let valid = true

    for (let item in form){

        validation = {...validation, ...validate(item,form[item])}

    }

    setErrors({...errors,...validation})
    // console.log(errors)
    for(let res in validation){

        if (validation[res] !== ''){
            valid = false;
        }

    }

    return valid
    }



  const handleSave = async ()=>{
    const valid = validateAll(form)
    if (valid){
      form.html = editorRef.current!.getContent()
      const token = localStorage.getItem('token') || ""
      const res = await saveDocs(`Token ${token.slice(1,-1)}`,id,form)
      if(res.status === "success"){
        setForm(startForm)
        history.push(`/account?tab=software_docs&software_id=${id}&doc_id=${res.data.id}`)
        NotificationPopUp('success','Documentation Uploaded')
        // history.go(0)
        return
    }
    }
    NotificationPopUp('Error',"Could Not Save Documentation")
  }

  const handleUpload = async (file:any, filename:any) => {
    const res:any = await S3Bucket.UploadImage("software_docs/pics",id, file,filename)
    return res['Location']
  }


    return (
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <IonRow className="ion-justify-content-center">
                {ce === 'create' ? <h1>Add Documentation</h1> : <h1>Edit Documentation</h1>}
            </IonRow>
          </IonCol>
          <IonCol size="12">
            <IonRow className="ion-justify-content-between">
            <IonItem>
                <IonInput label="Title" className={errors.title === '' ? "valid":'invalid'} style={{minWidth:'800px'}} value={form.title} placeholder="Title" onIonChange={e => (handleFormChange(e.detail.value!,'title'))}> </IonInput>
                <div className="error-detail">{errors.title}</div>
            </IonItem>
            <IonButton size="large" onClick={()=>handleSave()}>Save</IonButton>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <div className="editor" id='editor'>
      <Editor
          apiKey={TINYMCE_API_KEY}
          initialValue= {form.html}
          onInit={(evt, editor) => editorRef.current = editor}
          init={{
          height: '1080px',
          plugins: 'image',
          toolbar: 'undo redo | formatselect | ' +
         'bold italic backcolor | alignleft aligncenter ' +
         'alignright alignjustify | bullist numlist outdent indent | ' +
         'removeformat | image print help',
         menu: {
          template: {title: 'Template', items: "edit-template"}
        },
        menubar: 'file edit view insert format',
         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
         image_title: true,
           automatic_uploads: true,
           file_picker_types: 'image',

           file_picker_callback: (cb, value, meta) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.addEventListener('change', (e:any) => {
              const file = e.target.files[0];

              const reader:any = new FileReader();
              reader.addEventListener('load', async () => {
                const id = 'blobid' + (new Date()).getTime();
                const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                const url= await handleUpload(file,file.name)

                /* call the callback and populate the Title field with the file name */
                cb(url, { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
       }}
     />

      </div>
            </IonRow>
          </IonCol>
        </IonRow>
    );
  };

  export default AE2SoftwareDocsForm;
