import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { API_URL } from "../../../actions/settings"
import  { selectUser } from "../../../reducers/UserReducer"
import { isStaff } from "../../extras/Functions"
import EnergyTargetForm from "./EnergyTargetForm"
import './EnergyTargets.css'
import NewEnergyTargetModal from "./NewEnergyTargetModal"

const months = [
    {number:1,string:'January'},
    {number:2,string:'February'},
    {number:3,string:'March'},
    {number:4,string:'April'},
    {number:5,string:'May'},
    {number:6,string:'June'},
    {number:7,string:'July'},
    {number:8,string:'August'},
    {number:9,string:'September'},
    {number:10,string:'October'},
    {number:11,string:'November'},
    {number:12,string:'December'},
]

async function getTargets(token:any,id:any) {

    return fetch(`${API_URL}/ae2_energy_targets/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const EnergyTargets: React.FC= () => {
    const user = useSelector(selectUser)
    const [targets,setTargets]= useState<any>([])
    const [noData, setNoData]= useState(true)
    const [showForm,setShowForm] = useState(false)
    const [showModal,setShowModal] = useState(false)
    let params =  new URLSearchParams(window.location.search);
    const id = params.get('id')

    useEffect(() => {
        const handleEnergyUnits = async (token:string|null) => {
            const targets = await getTargets(`Token ${token!.slice(1,-1)}`,id)
            if (targets.status === 'success'){
                setTargets(targets.data)
            }
            Object.keys(targets.data).length === 0 ? setNoData(true) : setNoData(false)
        }

        const token = localStorage.getItem('token')
        handleEnergyUnits(token)

    },[id])

    const handleTargets = async () => {
        const token = localStorage.getItem('token')
        const targets = await getTargets(`Token ${token!.slice(1,-1)}`,id)
        setTargets(targets.data)
        Object.keys(targets.data).length === 0 ? setNoData(true) : setNoData(false)
    }

    return(
        <div>
        <h2 style={{display:'flex',alignItems:'center',marginLeft:'5px'}}>Monthly Target Table  { isStaff(user) ?
        <>
            <IonButton size="small" style={{marginLeft:'10px'}} color="medium" onClick={()=>setShowModal(!showForm)}>Add Target</IonButton>
            <IonButton size="small" style={{marginLeft:'10px'}} color={showForm?'danger':'primary'} onClick={()=>setShowForm(!showForm)}>{showForm ? 'Cancel' : 'Edit'}</IonButton>
        </>

        : ''}</h2>
        <IonRow>
            <IonCol sizeMd="6">
                <IonGrid>
                    <IonRow className="table-heading">
                        <IonCol >Month</IonCol>
                        {Object.keys(targets).map((target:any)=>{
                            return(
                                <IonCol key={targets[target][0].unit.id} className="left-border-grey"><span>{targets[target][0].unit.name}</span> ({targets[target][0].unit.category.name})</IonCol>
                            )
                        })}
                    </IonRow>
                    { showForm ?
                    <EnergyTargetForm months={months} targets={targets} noData={noData} handleTargets={handleTargets} setShowForm={setShowForm}/> :
                    months.map((month:any)=>{
                        return(
                            <IonRow className="table-row" key={month.number}>
                                <IonCol>{month.string}</IonCol>
                                {Object.keys(targets).map((target:any)=>{
                                    return(
                                        <IonCol key={'month'+targets[target][month.number-1].id} className="left-border-grey">
                                             {targets[target][month.number-1] && targets[target][month.number-1].value ? targets[target][month.number-1].value : 'N/A'}
                                        </IonCol>
                                    )
                                })}
                            </IonRow>
                        )
                    })}
                </IonGrid>
            </IonCol>
        </IonRow>
        <NewEnergyTargetModal opened={showModal} setShowModal={setShowModal} handleTargets={handleTargets}/>
        </div>
    )
}

export default EnergyTargets