import { IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"


interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
grossFloorAreaUsedForFoodPreparation : "",
grossFloorAreaUsedForFoodPreparation_units : "",
grossFloorAreaUsedForFoodPreparation_date : "",
licensedBedCapacity : "",
licensedBedCapacity_date : "",
numberOfStaffedBeds : "",
numberOfStaffedBeds_date : "",
numberOfFTEWorkers : "",
numberOfFTEWorkers_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
numberOfMriMachines:"",
numberOfMriMachines_date:"",
numberOfSterilizationUnits:"",
numberOfSterilizationUnits_date:"",
onSiteLaundryFacility:"",
onSiteLaundryFacility_date:"",
hasLaboratory:"",
hasLaboratory_date:"",
isTertiaryCare:"",
isTertiaryCare_date:"",
maximumNumberOfFloors:"",
maximumNumberOfFloors_date:"",
ownedBy:"",
ownedBy_date:"",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : ""
}


const startErrors = {
totalGrossFloorArea : "Field Required",
totalGrossFloorArea_units : "Select Units",
totalGrossFloorArea_date : "Date is required",
grossFloorAreaUsedForFoodPreparation : "",
grossFloorAreaUsedForFoodPreparation_units : "",
grossFloorAreaUsedForFoodPreparation_date : "",
licensedBedCapacity : "",
licensedBedCapacity_date : "",
numberOfStaffedBeds : "",
numberOfStaffedBeds_date : "",
numberOfFTEWorkers : "",
numberOfFTEWorkers_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
numberOfMriMachines:"",
numberOfMriMachines_date:"",
numberOfSterilizationUnits:"",
numberOfSterilizationUnits_date:"",
onSiteLaundryFacility:"",
onSiteLaundryFacility_date:"",
hasLaboratory:"",
hasLaboratory_date:"",
isTertiaryCare:"",
isTertiaryCare_date:"",
maximumNumberOfFloors:"",
maximumNumberOfFloors_date:"",
ownedBy:"",
ownedBy_date:"",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : ""
}

const Hospital: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])
    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('grossFloorAreaUsedForFoodPreparation'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation_date === '' ? handleError('grossFloorAreaUsedForFoodPreparation_date','Date Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_date',"")
                    form.grossFloorAreaUsedForFoodPreparation_units === '' ? handleError('grossFloorAreaUsedForFoodPreparation_units','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_units',"")
                    value < 0 ? handleError('grossFloorAreaUsedForFoodPreparation','Must be a Positive Number') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation','')
                    if(form.grossFloorAreaUsedForFoodPreparation_date === '' && form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date','')
                        handleError('grossFloorAreaUsedForFoodPreparation_units','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation_date === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaUsedForFoodPreparation_date'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation === '' ? handleError('grossFloorAreaUsedForFoodPreparation','Field Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                    form.grossFloorAreaUsedForFoodPreparation_units === '' ? handleError('grossFloorAreaUsedForFoodPreparation_units','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_units',"")
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation_date','')
                    if(form.grossFloorAreaUsedForFoodPreparation === '' && form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation','')
                        handleError('grossFloorAreaUsedForFoodPreparation_units','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaUsedForFoodPreparation_units'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation === '' ? handleError('grossFloorAreaUsedForFoodPreparation','Field Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                    form.grossFloorAreaUsedForFoodPreparation_date === '' ? handleError('grossFloorAreaUsedForFoodPreparation_date','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_date',"")
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation_units','')
                    if(form.grossFloorAreaUsedForFoodPreparation === '' && form.grossFloorAreaUsedForFoodPreparation_date === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation','')
                        handleError('grossFloorAreaUsedForFoodPreparation_date','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation'," Unit Cannot be empty")
                    }

                }
                break;
            case('licensedBedCapacity'):
                if(value !==''){
                    form.licensedBedCapacity_date === '' ? handleError('licensedBedCapacity_date','Date cannot be empty') : handleError('licensedBedCapacity_date','')
                    value < 0 ? handleError('licensedBedCapacity','Must be a Positive number') : handleError('licensedBedCapacity','')
                }
                else{
                    handleError('licensedBedCapacity','')
                    handleError('licensedBedCapacity_date','')
                }
                break;
            case('licensedBedCapacity_date'):
                if(value !==''){
                    form.licensedBedCapacity === '' ? handleError('licensedBedCapacity','Field cannot be empty') : handleError('licensedBedCapacity','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('licensedBedCapacity_date','Invalid Date') : handleError('licensedBedCapacity_date','')
                }
                else{
                    handleError('licensedBedCapacity','')
                    handleError('licensedBedCapacity_date','')
                }
                break;
            case('numberOfStaffedBeds'):
                if(value !==''){
                    form.numberOfStaffedBeds_date === '' ? handleError('numberOfStaffedBeds_date','Date cannot be empty') : handleError('numberOfStaffedBeds_date','')
                    value < 0 ? handleError('numberOfStaffedBeds','Must be a Positive number') : handleError('numberOfStaffedBeds','')
                }
                else{
                    handleError('numberOfStaffedBeds','')
                    handleError('numberOfStaffedBeds_date','')
                }
                break;
            case('numberOfStaffedBeds_date'):
                if(value !==''){
                    form.numberOfStaffedBeds === '' ? handleError('numberOfStaffedBeds','Field cannot be empty') : handleError('numberOfStaffedBeds','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfStaffedBeds_date','Invalid Date') : handleError('numberOfStaffedBeds_date','')
                }
                else{
                    handleError('numberOfStaffedBeds','')
                    handleError('numberOfStaffedBeds_date','')
                }
                break;
            case('numberOfFTEWorkers'):
                if(value !==''){
                    form.numberOfFTEWorkers_date === '' ? handleError('numberOfFTEWorkers_date','Date cannot be empty') : handleError('numberOfFTEWorkers_date','')
                    value < 0 ? handleError('numberOfFTEWorkers','Must be a Positive number') : handleError('numberOfFTEWorkers','')
                }
                else{
                    handleError('numberOfFTEWorkers','')
                    handleError('numberOfFTEWorkers_date','')
                }
                break;
            case('numberOfFTEWorkers_date'):
                if(value !==''){
                    form.numberOfFTEWorkers === '' ? handleError('numberOfFTEWorkers','Field cannot be empty') : handleError('numberOfFTEWorkers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfFTEWorkers_date','Invalid Date') : handleError('numberOfFTEWorkers_date','')
                }
                else{
                    handleError('numberOfFTEWorkers','')
                    handleError('numberOfFTEWorkers_date','')
                }
                break;
            case('numberOfWorkers'):
                if(value !==''){
                    form.numberOfWorkers_date === '' ? handleError('numberOfWorkers_date','Date cannot be empty') : handleError('numberOfWorkers_date','')
                    value < 0 ? handleError('numberOfWorkers','Must be a Positive number') : handleError('numberOfWorkers','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('numberOfWorkers_date'):
                if(value !==''){
                    form.numberOfWorkers === '' ? handleError('numberOfWorkers','Field cannot be empty') : handleError('numberOfWorkers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWorkers_date','Invalid Date') : handleError('numberOfWorkers_date','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('numberOfMriMachines'):
                if(value !==''){
                    form.numberOfMriMachines_date === '' ? handleError('numberOfMriMachines_date','Date cannot be empty') : handleError('numberOfMriMachines_date','')
                    value < 0 ? handleError('numberOfMriMachines','Must be a Positive number') : handleError('numberOfMriMachines','')
                }
                else{
                    handleError('numberOfMriMachines','')
                    handleError('numberOfMriMachines_date','')
                }
                break;
            case('numberOfMriMachines_date'):
                if(value !==''){
                    form.numberOfMriMachines === '' ? handleError('numberOfMriMachines','Field cannot be empty') : handleError('numberOfMriMachines','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfMriMachines_date','Invalid Date') : handleError('numberOfMriMachines_date','')
                }
                else{
                    handleError('numberOfMriMachines','')
                    handleError('numberOfMriMachines_date','')
                }
                break;
            case('numberOfSterilizationUnits'):
                if(value !==''){
                    form.numberOfSterilizationUnits_date === '' ? handleError('numberOfSterilizationUnits_date','Date cannot be empty') : handleError('numberOfSterilizationUnits_date','')
                    value < 0 ? handleError('numberOfSterilizationUnits','Must be a Positive number') : handleError('numberOfSterilizationUnits','')
                }
                else{
                    handleError('numberOfSterilizationUnits','')
                    handleError('numberOfSterilizationUnits_date','')
                }
                break;
            case('numberOfSterilizationUnits_date'):
                if(value !==''){
                    form.numberOfSterilizationUnits === '' ? handleError('numberOfSterilizationUnits','Field cannot be empty') : handleError('numberOfSterilizationUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfSterilizationUnits_date','Invalid Date') : handleError('numberOfSterilizationUnits_date','')
                }
                else{
                    handleError('numberOfSterilizationUnits','')
                    handleError('numberOfSterilizationUnits_date','')
                }
                break;
            case('onSiteLaundryFacility'):
                if(value !==''){
                    form.onSiteLaundryFacility_date === '' ? handleError('onSiteLaundryFacility_date','Date cannot be empty') : handleError('onSiteLaundryFacility_date','')

                }
                else{
                    handleError('onSiteLaundryFacility','')
                    handleError('onSiteLaundryFacility_date','')
                }
                break;
            case('onSiteLaundryFacility_date'):
                if(value !==''){
                    form.onSiteLaundryFacility === '' ? handleError('onSiteLaundryFacility','Field cannot be empty') : handleError('onSiteLaundryFacility','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('onSiteLaundryFacility_date','Invalid Date') : handleError('onSiteLaundryFacility_date','')
                }
                else{
                    handleError('onSiteLaundryFacility','')
                    handleError('onSiteLaundryFacility_date','')
                }
                break;
            case('hasLaboratory'):
                if(value !==''){
                    form.hasLaboratory_date === '' ? handleError('hasLaboratory_date','Date cannot be empty') : handleError('hasLaboratory_date','')

                }
                else{
                    handleError('hasLaboratory','')
                    handleError('hasLaboratory_date','')
                }
                break;
            case('hasLaboratory_date'):
                if(value !==''){
                    form.hasLaboratory === '' ? handleError('hasLaboratory','Field cannot be empty') : handleError('hasLaboratory','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('hasLaboratory_date','Invalid Date') : handleError('hasLaboratory_date','')
                }
                else{
                    handleError('hasLaboratory','')
                    handleError('hasLaboratory_date','')
                }
                break;
            case('isTertiaryCare'):
                if(value !==''){
                    form.isTertiaryCare_date === '' ? handleError('isTertiaryCare_date','Date cannot be empty') : handleError('isTertiaryCare_date','')

                }
                else{
                    handleError('isTertiaryCare','')
                    handleError('isTertiaryCare_date','')
                }
                break;
            case('isTertiaryCare_date'):
                if(value !==''){
                    form.isTertiaryCare === '' ? handleError('isTertiaryCare','Field cannot be empty') : handleError('isTertiaryCare','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('isTertiaryCare_date','Invalid Date') : handleError('isTertiaryCare_date','')
                }
                else{
                    handleError('isTertiaryCare','')
                    handleError('isTertiaryCare_date','')
                }
                break;
            case('maximumNumberOfFloors'):
                if(value !==''){
                    form.maximumNumberOfFloors_date === '' ? handleError('maximumNumberOfFloors_date','Date cannot be empty') : handleError('maximumNumberOfFloors_date','')
                    value < 0 ? handleError('maximumNumberOfFloors','Must be a Positive number') : handleError('maximumNumberOfFloors','')
                }
                else{
                    handleError('maximumNumberOfFloors','')
                    handleError('maximumNumberOfFloors_date','')
                }
                break;
            case('maximumNumberOfFloors_date'):
                if(value !==''){
                    form.maximumNumberOfFloors === '' ? handleError('maximumNumberOfFloors','Field cannot be empty') : handleError('maximumNumberOfFloors','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('maximumNumberOfFloors_date','Invalid Date') : handleError('maximumNumberOfFloors_date','')
                }
                else{
                    handleError('maximumNumberOfFloors','')
                    handleError('maximumNumberOfFloors_date','')
                }
                break;
            case('ownedBy'):
                if(value !==''){
                    form.ownedBy_date === '' ? handleError('ownedBy_date','Date cannot be empty') : handleError('ownedBy_date','')

                }
                else{
                    handleError('ownedBy','')
                    handleError('ownedBy_date','')
                }
                break;
            case('ownedBy_date'):
                if(value !==''){
                    form.ownedBy === '' ? handleError('ownedBy','Field cannot be empty') : handleError('ownedBy','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('ownedBy_date','Invalid Date') : handleError('ownedBy_date','')
                }
                else{
                    handleError('ownedBy','')
                    handleError('ownedBy_date','')
                }
                break;
            case('percentCooled'):
                if(value !==''){
                    form.percentCooled_date === '' ? handleError('percentCooled_date','Date cannot be empty') : handleError('percentCooled_date','')

                }
                else{
                    handleError('percentCooled','')
                    handleError('percentCooled_date','')
                }
                break;
            case('percentCooled_date'):
                if(value !==''){
                    form.percentCooled === '' ? handleError('percentCooled','Field cannot be empty') : handleError('percentCooled','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('percentCooled_date','Invalid Date') : handleError('percentCooled_date','')
                }
                else{
                    handleError('percentCooled','')
                    handleError('percentCooled_date','')
                }
                break;
            case('percentHeated'):
                if(value !==''){
                    form.percentHeated_date === '' ? handleError('percentHeated_date','Date cannot be empty') : handleError('percentHeated_date','')

                }
                else{
                    handleError('percentHeated','')
                    handleError('percentHeated_date','')
                }
                break;
            case('percentHeated_date'):
                if(value !==''){
                    form.percentHeated === '' ? handleError('percentHeated','Field cannot be empty') : handleError('percentHeated','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('percentHeated_date','Invalid Date') : handleError('percentHeated_date','')
                }
                else{
                    handleError('percentHeated','')
                    handleError('percentHeated_date','')
                }
                break;
            default:
                break;
        }
    }

    return(
        <div>
            <h1>Hospital (General Medical & Surgical) Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Gross floor area used for food preparation</IonLabel>
                        <IonInput type="number" className={errors.grossFloorAreaUsedForFoodPreparation === '' ? "valid":'invalid'}  value={form.grossFloorAreaUsedForFoodPreparation} placeholder='Floor Area For Food Prep' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation'))}></IonInput>
                        <div className="error-detail">{errors.grossFloorAreaUsedForFoodPreparation}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Units</IonLabel>
                        <IonSelect className={errors.grossFloorAreaUsedForFoodPreparation_units === '' ? "valid":'invalid'} value={form.grossFloorAreaUsedForFoodPreparation_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.grossFloorAreaUsedForFoodPreparation_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.grossFloorAreaUsedForFoodPreparation_date === '' ? "valid":'invalid'} value={form.grossFloorAreaUsedForFoodPreparation_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation_date'))}></IonInput>
                        <div className="error-detail">{errors.grossFloorAreaUsedForFoodPreparation_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Licensed Bed Capacity</IonLabel>
                        <IonInput type="number" className={errors.licensedBedCapacity === '' ? "valid":'invalid'}  value={form.licensedBedCapacity} placeholder='Licensed Bed Capacity' onIonChange={e => (handleFormChange(e.detail.value!,'licensedBedCapacity'))}></IonInput>
                        <div className="error-detail">{errors.licensedBedCapacity}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.licensedBedCapacity_date === '' ? "valid":'invalid'}  value={form.licensedBedCapacity_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'licensedBedCapacity_date'))}></IonInput>
                        <div className="error-detail">{errors.licensedBedCapacity_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} />Number of Staffed Beds</IonLabel>
                        <IonInput type="number" className={errors.numberOfStaffedBeds === '' ? "valid":'invalid'}  value={form.numberOfStaffedBeds} placeholder='# Staffed Beds' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfStaffedBeds'))}></IonInput>
                        <div className="error-detail">{errors.numberOfStaffedBeds}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfStaffedBeds_date === '' ? "valid":'invalid'}  value={form.numberOfStaffedBeds_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfStaffedBeds_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfStaffedBeds_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} />Number of FTE Workers</IonLabel>
                        <IonInput type="number" className={errors.numberOfFTEWorkers === '' ? "valid":'invalid'}  value={form.numberOfFTEWorkers} placeholder='# FTE Workers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfFTEWorkers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfFTEWorkers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfFTEWorkers_date === '' ? "valid":'invalid'}  value={form.numberOfFTEWorkers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfFTEWorkers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfFTEWorkers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Workers</IonLabel>
                        <IonInput type="number" className={errors.numberOfWorkers === '' ? "valid":'invalid'}  value={form.numberOfWorkers} placeholder='# Workers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWorkers_date === '' ? "valid":'invalid'}  value={form.numberOfWorkers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Number of MRI Machines</IonLabel>
                        <IonInput type="number" className={errors.numberOfMriMachines === '' ? "valid":'invalid'}  value={form.numberOfMriMachines} placeholder='# MRI Machines' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfMriMachines'))}></IonInput>
                        <div className="error-detail">{errors.numberOfMriMachines}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfMriMachines_date === '' ? "valid":'invalid'}  value={form.numberOfMriMachines_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfMriMachines_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfMriMachines_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number of Sterilization Units</IonLabel>
                        <IonInput type="number" className={errors.numberOfSterilizationUnits === '' ? "valid":'invalid'}  value={form.numberOfSterilizationUnits} placeholder='# Sterilization Units' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfSterilizationUnits'))}></IonInput>
                        <div className="error-detail">{errors.numberOfSterilizationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfSterilizationUnits_date === '' ? "valid":'invalid'}  value={form.numberOfSterilizationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfSterilizationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfSterilizationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">On Site Laundry Facility?</IonLabel>
                        <IonSelect className={errors.onSiteLaundryFacility === '' ? "valid":'invalid'}  value={form.onSiteLaundryFacility} interface='popover' placeholder='Site Laundry Facility' onIonChange={e => (handleFormChange(e.detail.value!,'onSiteLaundryFacility'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.onSiteLaundryFacility}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.onSiteLaundryFacility_date === '' ? "valid":'invalid'}  value={form.onSiteLaundryFacility_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'onSiteLaundryFacility_date'))}></IonInput>
                        <div className="error-detail">{errors.onSiteLaundryFacility_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Has a Laboratory</IonLabel>
                        <IonSelect className={errors.hasLaboratory === '' ? "valid":'invalid'}  value={form.hasLaboratory} interface='popover' placeholder='Laboratory?' onIonChange={e => (handleFormChange(e.detail.value!,'hasLaboratory'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.hasLaboratory}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.hasLaboratory_date === '' ? "valid":'invalid'}  value={form.hasLaboratory_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'hasLaboratory_date'))}></IonInput>
                        <div className="error-detail">{errors.hasLaboratory_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Is Tertiary Care</IonLabel>
                        <IonSelect className={errors.isTertiaryCare === '' ? "valid":'invalid'}  value={form.isTertiaryCare} interface='popover' placeholder='Tertiary Care?' onIonChange={e => (handleFormChange(e.detail.value!,'isTertiaryCare'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.isTertiaryCare}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.isTertiaryCare_date === '' ? "valid":'invalid'}  value={form.isTertiaryCare_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'isTertiaryCare_date'))}></IonInput>
                        <div className="error-detail">{errors.isTertiaryCare_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Maximum Number of Floors</IonLabel>
                        <IonInput type="number" className={errors.maximumNumberOfFloors === '' ? "valid":'invalid'}  value={form.maximumNumberOfFloors} placeholder='Max # Floors' onIonChange={e => (handleFormChange(e.detail.value!,'maximumNumberOfFloors'))}></IonInput>
                        <div className="error-detail">{errors.maximumNumberOfFloors}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.maximumNumberOfFloors_date === '' ? "valid":'invalid'}  value={form.maximumNumberOfFloors_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'maximumNumberOfFloors_date'))}></IonInput>
                        <div className="error-detail">{errors.maximumNumberOfFloors_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Owned By</IonLabel>
                        <IonSelect className={errors.ownedBy === '' ? "valid":'invalid'}  value={form.ownedBy} interface='popover' placeholder='Owned By' onIonChange={e => (handleFormChange(e.detail.value!,'ownedBy'))}>
                            <IonSelectOption>For Profit</IonSelectOption>
                            <IonSelectOption>Non Profit</IonSelectOption>
                            <IonSelectOption>Governmental</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.ownedBy}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.ownedBy_date === '' ? "valid":'invalid'}  value={form.ownedBy_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'ownedBy_date'))}></IonInput>
                        <div className="error-detail">{errors.ownedBy_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Percent Cooled</IonLabel>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentCooled_date === '' ? "valid":'invalid'}  value={form.percentCooled_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></IonInput>
                        <div className="error-detail">{errors.percentCooled_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Percent Heated</IonLabel>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentHeated_date === '' ? "valid":'invalid'}  value={form.percentHeated_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></IonInput>
                        <div className="error-detail">{errors.percentHeated_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>

        </div>
    )
}

export default Hospital