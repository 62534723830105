export const DEBUG = process.env.REACT_APP_DEBUG
export const API_URL = DEBUG === '1' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_PROD_API_URL
export const REACT_APP_GOOGLE_MAPS_KEY=process.env.REACT_APP_GOOGLE_MAPS_KEY
export const REACT_APP_AWS_KEY_BUCKET    = process.env.REACT_APP_AWS_KEY_BUCKET
export const REACT_APP_AWS_SECRET_BUCKET = process.env.REACT_APP_AWS_SECRET_BUCKET
export const REACT_APP_AWS_BUCKET_NAME   = DEBUG === '1' ? process.env.REACT_APP_AWS_BUCKET_NAME : process.env.REACT_APP_PROD_AWS_BUCKET_NAME
export const REACT_APP_AWS_REGION = "us-east-2"
export const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY
export const GOOGLE_MAPS_MAP_ID = process.env.REACT_APP_GOOGLE_MAPS_MAP_ID

