import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Customer from './pages/AE2/Customer';
import Homepage from './pages/AE2/Homepage';
import EStarHomepage from './pages/Energystar/EStarHomepage';
import Properties from './pages/Energystar/Properties';
import REAPRHomepage from './pages/REAPR/REAPRHomepage';
import Building from './pages/AE2/Building';
import Property from './pages/Energystar/Property';
import Form from './pages/AE2/Form';
import Meter from './pages/Energystar/Meter';
import Report from './pages/Energystar/Report';
import Template from './pages/Energystar/Template';
// import {default as AE2Template} from './pages/AE2/Template';
import Account from './pages/AE2/Account';
import NotAuthorized from './pages/Errors/NotAuthorized';
import NotFound from './pages/Errors/NotFound';
import Customers from './pages/AE2/Customers';
import AE2BuildingPerformance from './pages/AE2/AE2BuildingPerformance';
import AE2ControlsVersioning from './pages/AE2/AE2ControlsVersioning';
import REAPRSystemStatus from './pages/AE2/REAPRSystemStatus';
import AE2BuildingMonthlyPerformance from './pages/AE2/AE2BuildingMonthlyPerformance';
import AnalyticsPage from './pages/AE2/AnalyticsPage';
import EnergyPerformance from './pages/AE2/EnergyPerformance';
import FullPageDashboard from './pages/AE2/FullPageDashboard';
import AE2SuccessStories from './pages/AE2/AE2SuccessStories';
import EnergyStarDataPage from './pages/Energystar/EnergyStarDataPage';
import ConsumptionDataUploadTemplate from './pages/Energystar/ConsumptionDataUploadTemplate';
import OnePageProposal from './pages/AE2/OnePageProposal';
import ResetPassword from './pages/ResetPassword';


interface Props{
    setToken: any
  }
const RoutesList: React.FC<Props> = ({setToken}) => {
    return (
            <IonRouterOutlet id="main">
              <Switch>
              <Route path="/login" exact={true}>
                <Redirect to='/'/>
              </Route>
              <Route path="/reset_password" exact={true}>
                <ResetPassword/>
              </Route>
              <Route path="/403" exact={true}>
                <NotAuthorized/>
              </Route>
              <Route path="/404" exact={true}>
                <NotFound/>
              </Route>
              <Route path="/microsoft-login" exact={true}>
                <Redirect to='/'/>
              </Route>
              <Route path="/home" exact={true}>
                <Redirect to='/'/>
              </Route>
              <Route path="/customer" exact={true}>
                <Customer />
              </Route>
              <Route path="/customers" exact={true}>
                <Customers/>
              </Route>
              <Route path="/account" exact={true}>
                <Account />
              </Route>
              <Route path="/analytics" exact={true}>
                <AnalyticsPage />
              </Route>
              <Route path="/customer/:id/addproperty" exact={true}>
                <Form type='property'/>
              </Route>
              {/* <Route path="/building/:id/template" exact={true}>
                <AE2Template />
              </Route> */}
              <Route path="/customer/:id/addcampus" exact={true}>
                <Form type='campus'/>
              </Route>
              <Route path="/customer/:id/controls_versioning" exact={true}>
                <AE2ControlsVersioning/>
              </Route>
              <Route path="/server_data" exact={true}>
                <AE2ControlsVersioning all={true}/>
              </Route>
              <Route path="/building" exact={true}>
                <Building />
              </Route>
              <Route path="/buildings_performance" exact={true}>
                <AE2BuildingPerformance all={true}/>
              </Route>
              <Route path="/customer_buildings/monthly_performance/:id" exact={true}>
                <AE2BuildingMonthlyPerformance type={'customer'}/>
              </Route>
              <Route path="/building/monthly_performance/:id" exact={true}>
                <AE2BuildingMonthlyPerformance type={'building'}/>
              </Route>
              <Route path="/buildings_monthly_performance" exact={true}>
                <AE2BuildingMonthlyPerformance all={true}/>
              </Route>
              <Route path="/building/:propertyId/edit" exact={true}>
                 <Form type="editAE2Building" />
              </Route>
              <Route path="/buildings/performance/:id" exact={true}>
                 <AE2BuildingPerformance/>
              </Route>
              <Route path="/building/:id/utility_service" exact={true}>
                 <Form type="utility_service" />
              </Route>
              <Route path="/building/utility_service/:id/edit" exact={true}>
                 <Form type="edit_utility_service" />
              </Route>
              <Route path="/campus/:id/edit" exact={true}>
                <Form type="editAE2Campus" />
              </Route>
              <Route path="/customer/:id/edit" exact={true}>
                <Form type="editAE2Customer" />
              </Route>
              <Route path="/energystar_data" exact={true}>
                <EnergyStarDataPage/>
              </Route>
              <Route path="/energystar/consumption_data_template" exact={true}>
                <ConsumptionDataUploadTemplate/>
              </Route>
              <Route path="/energystar/properties/:propertyId" exact={true}>
                <Property/>
              </Route>
              <Route path="/energystar/properties/:id/addbuilding" exact={true}>
                 <Form type='building'/>
              </Route>
              <Route path="/energystar/properties/:propertyId/edit" exact={true}>
                 <Form type='editEstarProperty'/>
              </Route>
              <Route path="/energystar/properties" exact={true}>
                <Properties/>
              </Route>
              <Route path="/energystar/property/:id/meter" exact={true}>
               <Form type="meter" />
              </Route>
              <Route path="/energystar/property/:propertyId/property_use" exact={true}>
               <Form type="propertyuse" />
              </Route>
              <Route path="/energystar/property/:propertyId/template" exact={true}>
              <Template/>
              </Route>
              <Route path="/energystar/property/:propertyId/report" exact={true}>
                <Report/>
              </Route>
              <Route path="/energystar/meters/:meterId" exact={true}>
                <Meter/>
              </Route>
              <Route path="/energystar/meters/:id/bills" exact={true}>
               <Form type="Consumption" />
              </Route>
              <Route path="/energystar/meters/:id/edit" exact={true}>
               <Form type="EditMeter" />
              </Route>
              <Route path="/reapr_status" exact={true}>
               <REAPRSystemStatus/>
              </Route>
              <Route path="/energystar" exact={true}>
                <EStarHomepage/>
              </Route>
              <Route path="/energy_performance" exact={true}>
                <EnergyPerformance/>
              </Route>
              <Route path="/one_page_proposal" exact={true}>
                <OnePageProposal/>
              </Route>
              <Route path="/performance_portfolio" exact={true}>
                <FullPageDashboard dash='PerformancePortfolio'/>
              </Route>
              <Route path="/cost_avoidance_dash" exact={true}>
                <FullPageDashboard dash='CostAvoidance'/>
              </Route>
              <Route path="/reapr" exact={true}>
                <REAPRHomepage/>
              </Route>
              <Route path="/success_stories" exact={true}>
                <AE2SuccessStories/>
              </Route>
              <Route path="/" exact={true}>
                <Homepage />
              </Route>
              <Redirect to="/404"/>
              </Switch>
            </IonRouterOutlet>
            )
  };

  export default RoutesList;
