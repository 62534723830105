import { selectUserGroups } from '../reducers/UserReducer'
import { useAppSelector } from '../Hooks'


export default function usePermissionGroups(){

    const user_groups = useAppSelector(selectUserGroups)

    const hasPermission = (permissions:string[]) =>{
        for( let permission of permissions){
            if(user_groups.includes(permission) || permission === 'all'){
                return true
            }
        }
        return false
    }

    return {user_groups,hasPermission}
}