import React from 'react'

type Error = String | null

interface Props {
    errors:[Error]
}


const ConsumptionDataTemplateErrors:React.FC<Props> = ({errors}) => {
  return (
    <div>
        <span>Found The Following Errors in the Excel Template:</span>
        <ul>
            {errors.map((error,i:Number)=>{
                return (
                    <li key={`error-${i}`}>
                        {error}
                    </li>
                )
            })}
        </ul>
    </div>)
}

export default ConsumptionDataTemplateErrors