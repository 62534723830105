import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react'
import React from 'react'

interface Props {
    data:any
}

// Result = [Name,Status,Detail]
type Result = [string, string, any]

const YearlyUtilityResult:React.FC<Props> = ({data}) => {
  return <div>
        {data.map((row:Result,i:Number)=>{
            return (
                row.length > 0 ?
                    <IonAccordionGroup key={`yearly-${i}`}>
                        <IonAccordion>
                            <IonItem slot='header'>
                                <IonLabel className={`${row[0].toLowerCase()} estar-result-title`}>
                                    <span>
                                        {row[0]}
                                    </span>: {row[1]}
                                </IonLabel>
                            </IonItem>
                            <div slot='content' style={{color:'black'}}>
                                <pre>
                                    {JSON.stringify(row,null,2)}
                                </pre>
                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                :
                null
            )
        })}
  </div>
}

export default YearlyUtilityResult