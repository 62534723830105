import React from "react";
import AE2AccountForm from "../../forms/AE2AccountForm";
import { IonCol, IonRow } from "@ionic/react";

const NewAE2Account = () => {
  return (
    <IonRow className="ion-justify-content-center">
      <IonCol sizeMd="5">
        <h1 style={{ textAlign: "center" }}>Create a new AE2 account</h1>
        <AE2AccountForm />
      </IonCol>
    </IonRow>
  );
};

export default NewAE2Account;
