import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../Store'
interface TaskState {
    value: {
        task_id:string,
        status:string,
        result:any
    }
}

export const initialState: TaskState = {
    value: {
        task_id:"",
        status:"",
        result:null
    }
}

export const TaskReducer = createSlice({
    name: "task",
    initialState,
    reducers:{
        setTaskState: (state, action: PayloadAction<any>) => {
            state.value =  action.payload
        }
    }
})

export const {setTaskState} = TaskReducer.actions
export const selectTask = (state: RootState) => state.task.value
export default TaskReducer.reducer