import { IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
percentUsedForColdStorage : "",
percentUsedForColdStorage_date : "",
numberOfWalkInRefrigerationUnits : "",
numberOfWalkInRefrigerationUnits_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : "",
clearHeight : "",
clearHeight_units : "",
clearHeight_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
percentUsedForColdStorage : "",
percentUsedForColdStorage_date : "",
numberOfWalkInRefrigerationUnits : "",
numberOfWalkInRefrigerationUnits_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : "",
clearHeight : "",
clearHeight_units : "",
clearHeight_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
}

const SelfStorage: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('weeklyOperatingHours'):
                if (value < 0){
                    handleError('weeklyOperatingHours','Hours must be a positive number')
                }
                else if (value > 168){
                    handleError('weeklyOperatingHours','Hours mush be less than 168')
                }
                else {
                    handleError('weeklyOperatingHours','')
                }
                if( value !== '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','Date cannot be empty')
                }
                if( value === '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case ('weeklyOperatingHours_date'):

                if (form.weeklyOperatingHours !== ''){
                    if (value === ''){
                        handleError('weeklyOperatingHours_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('weeklyOperatingHours_date','Invalid Date')
                    }
                    else {
                        handleError('weeklyOperatingHours_date','')
                    }
                }
                break;
            case ('numberOfWalkInRefrigerationUnits'):

                if (value < 0){
                    handleError('numberOfWalkInRefrigerationUnits','Must be a positive number')
                }
                else {
                    handleError('numberOfWalkInRefrigerationUnits','')
                }
                if( value !== '' && form.numberOfWalkInRefrigerationUnits_date === ''){
                    handleError('numberOfWalkInRefrigerationUnits_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfWalkInRefrigerationUnits_date === ''){
                    handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                break;
            case ('numberOfWalkInRefrigerationUnits_date'):
                if (form.numberOfWalkInRefrigerationUnits !== ''){
                    if (value === ''){
                        handleError('numberOfWalkInRefrigerationUnits_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfWalkInRefrigerationUnits_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfWalkInRefrigerationUnits_date','')
                    }
                }
                break;
            case ('percentUsedForColdStorage'):
                if (value < 0){
                    handleError('percentUsedForColdStorage','Must be a positive number')
                }
                else if (value > 100){
                    handleError('percentUsedForColdStorage','Max value is 100')
                }
                else {
                    handleError('percentUsedForColdStorage','')
                }

                if( value !== '' && form.percentUsedForColdStorage_date === ''){
                    handleError('percentUsedForColdStorage_date','Date cannot be empty')
                }
                else if( value === '' && form.percentUsedForColdStorage_date === ''){
                    handleError('percentUsedForColdStorage_date','')
                }
                else {
                    handleError('percentUsedForColdStorage','')
                }
                break;
            case ('percentUsedForColdStorage_date'):
                if (form.percentUsedForColdStorage !== ''){
                    if (value === ''){
                        handleError('percentUsedForColdStorage_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentUsedForColdStorage_date','Invalid Date')
                    }
                    else {
                        handleError('percentUsedForColdStorage_date','')
                    }
                }
                break;
            case ('percentCooled'):
                if( value !== '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentUsedForColdStorage_date === ''){
                    handleError('percentCooled_date','')
                }
                else {
                    handleError('percentCooled','')
                }
                break;
            case ('percentCooled_date'):
                if (form.percentUsedForColdStorage !== ''){
                    if (value === ''){
                        handleError('percentCooled_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentCooled_date','Invalid Date')
                    }
                else {
                        handleError('percentCooled_date','')
                    }

                break;
            case ('percentHeated'):
                if( value !== '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentUsedForColdStorage_date === ''){
                    handleError('percentHeated_date','')
                }
                else {
                    handleError('percentHeated','')
                }
                break;
            case ('percentHeated_date'):
                if (form.percentUsedForColdStorage !== ''){
                    if (value === ''){
                        handleError('percentHeated_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentHeated_date','Invalid Date')
                    }
                else {
                        handleError('percentHeated_date','')
                    }

                break;
            case ('clearHeight'):
                if( value !== ''){
                    value < 0 ? handleError("clearHeight",'Must be a positive number') : handleError('clearHeight','')
                    form.clearHeight_date === '' ? handleError('clearHeight_date','Date cannot be empty') : handleError('clearHeight_date','')
                    form.clearHeight_units === '' ? handleError('clearHeight_units','Select Units') : handleError('clearHeight_units','')
                }
                else {
                    handleError('clearHeight','')
                    handleError('clearHeight_units','')
                    handleError('clearHeight_date','')
                }
                break;
                case('numberOfWorkers'):
                if(value !==''){
                    form.numberOfWorkers_date === '' ? handleError('numberOfWorkers_date','Date cannot be empty') : handleError('numberOfWorkers_date','')
                    value < 0 ? handleError('numberOfWorkers','Must be a Positive number') : handleError('numberOfWorkers','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('numberOfWorkers_date'):
                if(value !==''){
                    form.numberOfWorkers === '' ? handleError('numberOfWorkers','Field cannot be empty') : handleError('numberOfWorkers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWorkers_date','Invalid Date') : handleError('numberOfWorkers_date','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case ('clearHeight_units'):
                if( value !== ''){
                    form.clearHeight_date === '' ? handleError('clearHeight_date','Date cannot be empty') : handleError('clearHeight_date','')
                    form.clearHeight === '' ? handleError('clearHeight',' Cannot be empty') : handleError('clearHeight','')
                    handleError('clearHeight_units','')
                }
                else {
                    handleError('clearHeight','')
                }
                break;
            case ('clearHeight_date'):
                if( value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('clearHeight_date','Invalid Date') : handleError('clearHeight_date','')
                    form.clearHeight_units === '' ? handleError('clearHeight_units','Select Units') : handleError('clearHeight_units','')
                    form.clearHeight === '' ? handleError('clearHeight',' Cannot be empty') : handleError('clearHeight','')
                }
                else {
                    handleError('clearHeight','')
                    handleError('clearHeight_units','')
                    handleError('clearHeight_date','')
                }
                break;
            case ('numberOfComputers'):
                if (value < 0){
                    handleError('numberOfComputers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfComputers','')
                }
                if( value !== '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','')
                }
                break;
            case ('numberOfComputers_date'):
                if (form.numberOfComputers !== ''){
                    if (value === ''){
                        handleError('numberOfComputers_date','Date cannot be empty')
                    }
                    else {
                        handleError('numberOfComputers_date','')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfComputers_date','Invalid Date')
                    }
                else {
                        handleError('numberOfComputers_date','')
                    }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Self Storage Facility Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Workers</IonLabel>
                        <IonInput type="number" className={errors.numberOfWorkers === '' ? "valid":'invalid'}  value={form.numberOfWorkers} placeholder='# Workers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWorkers_date === '' ? "valid":'invalid'}  value={form.numberOfWorkers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Percent used for cold storage</IonLabel>
                        <IonInput type="number" className={errors.percentUsedForColdStorage === '' ? "valid":'invalid'}  value={form.percentUsedForColdStorage} placeholder='% Cold Storage' onIonChange={e => (handleFormChange(e.detail.value!,'percentUsedForColdStorage'))}></IonInput>
                        <div className="error-detail">{errors.percentUsedForColdStorage}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentUsedForColdStorage_date === '' ? "valid":'invalid'}  value={form.percentUsedForColdStorage_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentUsedForColdStorage_date'))}></IonInput>
                        <div className="error-detail">{errors.percentUsedForColdStorage_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number of Walk-In Refrigeration Units:</IonLabel>
                        <IonInput type="number" className={errors.numberOfWalkInRefrigerationUnits === '' ? "valid":'invalid'}  value={form.numberOfWalkInRefrigerationUnits} placeholder='# Walk-In Refrigeration Units' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWalkInRefrigerationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWalkInRefrigerationUnits_date === '' ? "valid":'invalid'}  value={form.numberOfWalkInRefrigerationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWalkInRefrigerationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Weekly Operating Hours</IonLabel>
                        <IonInput type="number" className={errors.weeklyOperatingHours === '' ? "valid":'invalid'}  value={form.weeklyOperatingHours} placeholder='Weekly Hours' onIonChange={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours'))}></IonInput>
                        <div className="error-detail">{errors.weeklyOperatingHours}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.weeklyOperatingHours_date === '' ? "valid":'invalid'}  value={form.weeklyOperatingHours_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours_date'))}></IonInput>
                        <div className="error-detail">{errors.weeklyOperatingHours_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Percent Cooled</IonLabel>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentCooled_date === '' ? "valid":'invalid'}  value={form.percentCooled_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></IonInput>
                        <div className="error-detail">{errors.percentCooled_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Percent Heated</IonLabel>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentHeated_date === '' ? "valid":'invalid'}  value={form.percentHeated_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></IonInput>
                        <div className="error-detail">{errors.percentHeated_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Clear Height </IonLabel>
                        <IonInput type="number" className={errors.clearHeight === '' ? "valid":'invalid'}  value={form.clearHeight} placeholder='Clear Height' onIonChange={e => (handleFormChange(e.detail.value!,'clearHeight'))}></IonInput>
                        <div className="error-detail">{errors.clearHeight}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Units</IonLabel>
                        <IonSelect className={errors.clearHeight_units === '' ? "valid":'invalid'} value={form.clearHeight_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'clearHeight_units'))}>
                            <IonSelectOption value={'Feet'}>Feet</IonSelectOption>
                            <IonSelectOption value={'Meters'}>Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.clearHeight_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.clearHeight_date === '' ? "valid":'invalid'} value={form.clearHeight_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'clearHeight_date'))}></IonInput>
                        <div className="error-detail">{errors.clearHeight_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Number Of Computers</IonLabel>
                        <IonInput type="number" className={errors.numberOfComputers === '' ? "valid":'invalid'}  value={form.numberOfComputers} placeholder='# Computers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfComputers_date === '' ? "valid":'invalid'}  value={form.numberOfComputers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default SelfStorage