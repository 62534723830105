import { IonCol, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonLoading, IonRow, IonSearchbar, useIonViewWillEnter } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../actions/settings"

async function getCustomers(token:any) {

    return fetch(`${API_URL}/customers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const handleSearch = (items:any,term:string,name:string,setItems:any,setData:any,setSearchText:any)=>{
    setSearchText(term)
    if(term===''){
        setItems(items)
        setData(items)
    }
    else{
        setItems(items.filter((item:any)=> item[name].toLowerCase().includes(term.toLocaleLowerCase())));
        setData(items.filter((item:any)=> item[name].toLowerCase().includes(term.toLocaleLowerCase())));
    }

}

const CustomersTable:React.FC = () =>{
    const [customers, setCustomers] = useState<any>([])
    const [filtered, setFiltered] = useState<any>([])
    const [ data, setData ] = useState<any>([])
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
    const [ loaded, setLoaded ] = useState(false)
    const [searchText, setSearchText] = useState('');



    useEffect(() => {
        const handleProperties = async (token:any) => {
            const res = await getCustomers(`Token ${token.slice(1,-1)}`)
            // const res_list = JSON.parse(res)
            if (res.status=== "success") {
              setCustomers(res.data)
              setData(res.data.slice(0,30))
              setFiltered(res.data)
              setLoaded(true)
            }
        }

        const tkn = localStorage.getItem('token')
        handleProperties(tkn)


      }, [])

    const pushData = () => {
        const end = filtered.length
        const max = data.length + 20;
        const min = max - 20;
        const newData = [];
        for (let i = min; i < max && i<end; i++) {
          newData.push(filtered[i]);
        }

        setData([
          ...data,
          ...newData
        ]);
        setLoaded(true)
      }
      const loadData = (ev: any) => {
        setTimeout(() => {
          pushData();
          ev.target.complete();
          if (data.length === 1000) {
            setInfiniteDisabled(true);
          }
        }, 500);
      }

    useIonViewWillEnter(() => {
        pushData();
      });


    return (
      <>{ !loaded ?
        <IonLoading
          cssClass='my-custom-class'
          isOpen={!loaded}
          message={'Loading...'}
          spinner='bubbles'
          />
        :
        <IonGrid className="prop-table">

            <IonRow className="ion-justify-content-center table-container">
            <IonList>
            <IonRow className="top-row">
                <IonSearchbar role='list-search' value={searchText} onIonInput={e => handleSearch(customers, e.detail.value!, 'cust_name', setFiltered,setData, setSearchText)} animated></IonSearchbar>
            </IonRow>

            <IonRow className="prop-row-title">
                        <IonCol>Name:</IonCol>
                        <IonCol>Address:</IonCol>
                        <IonCol>City:</IonCol>
                        <IonCol>State:</IonCol>
                </IonRow>
                {data.map((item:any, index:any) => {
                    return (
                        <a href={` /?id=${item.uuid}&sec=customers`} key={index}>
                        <IonRow className="prop-row">
                            <IonCol>{item.cust_name}</IonCol>
                            <IonCol>{item.cust_address}</IonCol>
                            <IonCol>{item.cust_city}</IonCol>
                            <IonCol>{item.cust_state}</IonCol>
                        </IonRow>
                        </a>

                )
                })}
                </IonList>

                <IonInfiniteScroll
                    onIonInfinite={loadData}
                    threshold="100px"
                    disabled={isInfiniteDisabled}>
                <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more properties..."
                ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonRow>
        </IonGrid>
      }</>
    )
}

export default CustomersTable