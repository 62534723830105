import { IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import {star, starOutline} from 'ionicons/icons'

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
numberOfCashRegisters : "",
numberOfCashRegisters_date : "",
numberOfWalkInRefrigerationUnits : "",
numberOfWalkInRefrigerationUnits_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfOpenClosedRefrigerationUnits : "",
numberOfOpenClosedRefrigerationUnits_date : "",
cookingFacilities : "",
cookingFacilities_date : "",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : "",
areaOfAllWalkInRefrigerationUnits : "",
areaOfAllWalkInRefrigerationUnits_units : "",
areaOfAllWalkInRefrigerationUnits_date : "",
lengthOfAllOpenClosedRefrigerationUnits : "",
lengthOfAllOpenClosedRefrigerationUnits_units : "",
lengthOfAllOpenClosedRefrigerationUnits_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
singleStore : "",
singleStore_date : "",
exteriorEntranceToThePublic : "",
exteriorEntranceToThePublic_date : "",
}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
numberOfCashRegisters : "",
numberOfCashRegisters_date : "",
numberOfWalkInRefrigerationUnits : "",
numberOfWalkInRefrigerationUnits_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfOpenClosedRefrigerationUnits : "",
numberOfOpenClosedRefrigerationUnits_date : "",
cookingFacilities : "",
cookingFacilities_date : "",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : "",
areaOfAllWalkInRefrigerationUnits : "",
areaOfAllWalkInRefrigerationUnits_units : "",
areaOfAllWalkInRefrigerationUnits_date : "",
lengthOfAllOpenClosedRefrigerationUnits : "",
lengthOfAllOpenClosedRefrigerationUnits_units : "",
lengthOfAllOpenClosedRefrigerationUnits_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
singleStore : "",
singleStore_date : "",
exteriorEntranceToThePublic : "",
exteriorEntranceToThePublic_date : "",
}

const Retail: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('weeklyOperatingHours'):
                if (value < 0){
                    handleError('weeklyOperatingHours','Hours must be a positive number')
                }
                else if (value > 168){
                    handleError('weeklyOperatingHours','Hours mush be less than 168')
                }
                else {
                    handleError('weeklyOperatingHours','')
                }
                if( value !== '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','Date cannot be empty')
                }
                if( value === '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case ('weeklyOperatingHours_date'):

                if (form.weeklyOperatingHours !== ''){
                    if (value === ''){
                        handleError('weeklyOperatingHours_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('weeklyOperatingHours_date','Invalid Date')
                    }
                    else {
                        handleError('weeklyOperatingHours_date','')
                    }
                }
                break;
            case ('numberOfComputers'):
                if (value < 0){
                    handleError('numberOfComputers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfComputers','')
                }
                if( value !== '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','')
                }
                break;
            case ('numberOfComputers_date'):
                if (form.numberOfComputers !== ''){
                    if (value === ''){
                        handleError('numberOfComputers_date','Date cannot be empty')
                    }
                    else {
                        handleError('numberOfComputers_date','')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfComputers_date','Invalid Date')
                    }
                else {
                        handleError('numberOfComputers_date','')
                    }

                break;
            case ('numberOfOpenClosedRefrigerationUnits'):
                if (value < 0){
                    handleError('numberOfOpenClosedRefrigerationUnits','Must be a positive number')
                }
                else {
                    handleError('numberOfOpenClosedRefrigerationUnits','')
                }
                if( value !== '' && form.numberOfOpenClosedRefrigerationUnits_date === ''){
                    handleError('numberOfOpenClosedRefrigerationUnits_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfOpenClosedRefrigerationUnits_date === ''){
                    handleError('numberOfOpenClosedRefrigerationUnits_date','')
                }
                break;
            case ('numberOfOpenClosedRefrigerationUnits_date'):
                if (form.numberOfOpenClosedRefrigerationUnits !== ''){
                    if (value === ''){
                        handleError('numberOfOpenClosedRefrigerationUnits_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfOpenClosedRefrigerationUnits_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfOpenClosedRefrigerationUnits_date','')
                    }
                }
                break;
            case ('numberOfWalkInRefrigerationUnits'):

                if (value < 0){
                    handleError('numberOfWalkInRefrigerationUnits','Must be a positive number')
                }
                else {
                    handleError('numberOfWalkInRefrigerationUnits','')
                }
                if( value !== '' && form.numberOfWalkInRefrigerationUnits_date === ''){
                    handleError('numberOfWalkInRefrigerationUnits_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfWalkInRefrigerationUnits_date === ''){
                    handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                break;
            case ('numberOfWalkInRefrigerationUnits_date'):
                if (form.numberOfWalkInRefrigerationUnits !== ''){
                    if (value === ''){
                        handleError('numberOfWalkInRefrigerationUnits_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfWalkInRefrigerationUnits_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfWalkInRefrigerationUnits_date','')
                    }
                }
                break;
            case ('numberOfCashRegisters'):
                if (value < 0){
                    handleError('numberOfCashRegisters','Must be a positive number')
                }
                else {
                    handleError('numberOfCashRegisters','')
                }

                if( value !== '' && form.numberOfCashRegisters_date === ''){
                    handleError('numberOfCashRegisters_date','Date cannot be empty')
                }
                else if( value === '' && form.numberOfCashRegisters_date === ''){
                    handleError('numberOfCashRegisters_date','')
                }
                else {
                    handleError('numberOfCashRegisters','')
                }
                break;
            case ('numberOfCashRegisters_date'):
                if (form.numberOfCashRegisters !== ''){
                    if (value === ''){
                        handleError('numberOfCashRegisters_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfCashRegisters_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfCashRegisters_date','')
                    }
                }
                break;
            case ('numberOfWorkers'):
                if (value < 0){
                    handleError('numberOfWorkers','Must be a positive number')
                }
                else {
                    handleError('numberOfWorkers','')
                }

                if( value !== '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','Date cannot be empty')
                }
                else if( value === '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','')
                }
                else {
                    handleError('numberOfWorkers','')
                }
                break;
            case ('numberOfWorkers_date'):
                if (form.numberOfWorkers !== ''){
                    if (value === ''){
                        handleError('numberOfWorkers_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfWorkers_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfWorkers_date','')
                    }
                }
                break;
            case ('cookingFacilities'):
                if( value !== '' && form.cookingFacilities_date === ''){
                    handleError('cookingFacilities_date','Date cannot be empty')
                }
                else if( value === '' && form.cookingFacilities_date === ''){
                    handleError('cookingFacilities_date','')
                }
                else {
                    handleError('cookingFacilities','')
                }
                break;
            case ('cookingFacilities_date'):
                if (form.cookingFacilities !== ''){
                    if (value === ''){
                        handleError('cookingFacilities_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('cookingFacilities_date','Invalid Date')
                    }
                else {
                        handleError('cookingFacilities_date','')
                    }

                break;
            case ('exteriorEntranceToThePublic'):
                if( value !== '' && form.exteriorEntranceToThePublic_date === ''){
                    handleError('exteriorEntranceToThePublic_date','Date cannot be empty')
                }
                else if( value === '' && form.exteriorEntranceToThePublic_date === ''){
                    handleError('exteriorEntranceToThePublic_date','')
                }
                else {
                    handleError('exteriorEntranceToThePublic','')
                }
                break;
            case ('exteriorEntranceToThePublic_date'):
                if (form.exteriorEntranceToThePublic !== ''){
                    if (value === ''){
                        handleError('exteriorEntranceToThePublic_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('exteriorEntranceToThePublic_date','Invalid Date')
                    }
                else {
                        handleError('exteriorEntranceToThePublic_date','')
                    }
                break;
            case ('singleStore'):
                if( value !== '' && form.singleStore_date === ''){
                    handleError('singleStore_date','Date cannot be empty')
                }
                else if( value === '' && form.singleStore_date === ''){
                    handleError('singleStore_date','')
                }
                else {
                    handleError('singleStore','')
                }
                break;
            case ('singleStore_date'):
                if (form.singleStore !== ''){
                    if (value === ''){
                        handleError('singleStore_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('singleStore_date','Invalid Date')
                    }
                else {
                        handleError('singleStore_date','')
                    }

                break;
            case ('percentCooled'):
                if( value !== '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','')
                }
                else {
                    handleError('percentCooled','')
                }
                break;
            case ('percentCooled_date'):
                if (form.percentCooled !== ''){
                    if (value === ''){
                        handleError('percentCooled_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentCooled_date','Invalid Date')
                    }
                else {
                        handleError('percentCooled_date','')
                    }

                break;
            case ('percentHeated'):
                if( value !== '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','')
                }
                else {
                    handleError('percentHeated','')
                }
                break;
            case ('percentHeated_date'):
                if (form.percentHeated !== ''){
                    if (value === ''){
                        handleError('percentHeated_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentHeated_date','Invalid Date')
                    }
                else {
                        handleError('percentHeated_date','')
                    }

                break;
            case ('areaOfAllWalkInRefrigerationUnits'):
                if( value !== ''){
                    value < 0 ? handleError("areaOfAllWalkInRefrigerationUnits",'Must be a positive number') : handleError('areaOfAllWalkInRefrigerationUnits','')
                    form.areaOfAllWalkInRefrigerationUnits_date === '' ? handleError('areaOfAllWalkInRefrigerationUnits_date','Date cannot be empty') : handleError('areaOfAllWalkInRefrigerationUnits_date','')
                    form.areaOfAllWalkInRefrigerationUnits_units === '' ? handleError('areaOfAllWalkInRefrigerationUnits_units','Select Units') : handleError('areaOfAllWalkInRefrigerationUnits_units','')
                }
                else {
                    handleError('areaOfAllWalkInRefrigerationUnits','')
                    handleError('areaOfAllWalkInRefrigerationUnits_units','')
                    handleError('areaOfAllWalkInRefrigerationUnits_date','')
                }
                break;
            case ('areaOfAllWalkInRefrigerationUnits_units'):
                if( value !== ''){
                    form.areaOfAllWalkInRefrigerationUnits_date === '' ? handleError('areaOfAllWalkInRefrigerationUnits_date','Date cannot be empty') : handleError('areaOfAllWalkInRefrigerationUnits_date','')
                    form.areaOfAllWalkInRefrigerationUnits === '' ? handleError('areaOfAllWalkInRefrigerationUnits',' Cannot be empty') : handleError('areaOfAllWalkInRefrigerationUnits','')
                    handleError('areaOfAllWalkInRefrigerationUnits_units','')
                }
                else {
                    handleError('areaOfAllWalkInRefrigerationUnits','')
                }
                break;
            case ('areaOfAllWalkInRefrigerationUnits_date'):
                if( value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('areaOfAllWalkInRefrigerationUnits_date','Invalid Date') : handleError('areaOfAllWalkInRefrigerationUnits_date','')
                    form.areaOfAllWalkInRefrigerationUnits_units === '' ? handleError('areaOfAllWalkInRefrigerationUnits_units','Select Units') : handleError('areaOfAllWalkInRefrigerationUnits_units','')
                    form.areaOfAllWalkInRefrigerationUnits === '' ? handleError('areaOfAllWalkInRefrigerationUnits',' Cannot be empty') : handleError('areaOfAllWalkInRefrigerationUnits','')
                }
                else {
                    handleError('areaOfAllWalkInRefrigerationUnits','')
                    handleError('areaOfAllWalkInRefrigerationUnits_units','')
                    handleError('areaOfAllWalkInRefrigerationUnits_date','')
                }
                break;
            case ('lengthOfAllOpenClosedRefrigerationUnits'):
                if( value !== ''){
                    value < 0 ? handleError("lengthOfAllOpenClosedRefrigerationUnits",'Must be a positive number') : handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                    form.lengthOfAllOpenClosedRefrigerationUnits_date === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits_date','Date cannot be empty') : handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                    form.lengthOfAllOpenClosedRefrigerationUnits_units === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits_units','Select Units') : handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')

                }
                else {
                    handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                }
                break;
            case ('lengthOfAllOpenClosedRefrigerationUnits_units'):
                if( value !== ''){
                    form.lengthOfAllOpenClosedRefrigerationUnits_date === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits_date','Date cannot be empty') : handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                    form.lengthOfAllOpenClosedRefrigerationUnits === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits',' Cannot be empty') : handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')
                }
                else {
                    handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                }
                break;
            case ('lengthOfAllOpenClosedRefrigerationUnits_date'):
                if( value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('lengthOfAllOpenClosedRefrigerationUnits_date','Invalid Date') : handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                    form.lengthOfAllOpenClosedRefrigerationUnits_units === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits_units','Select Units') : handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')
                    form.lengthOfAllOpenClosedRefrigerationUnits === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits',' Cannot be empty') : handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                }
                else {
                    handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Retail Store Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} />  Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Number of Workers</IonLabel>
                        <IonInput type="number" className={errors.numberOfWorkers === '' ? "valid":'invalid'}  value={form.numberOfWorkers} placeholder='# Workers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWorkers_date === '' ? "valid":'invalid'}  value={form.numberOfWorkers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Number of Cash Register</IonLabel>
                        <IonInput type="number" className={errors.numberOfCashRegisters === '' ? "valid":'invalid'}  value={form.numberOfCashRegisters} placeholder='# Cash Registers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfCashRegisters'))}></IonInput>
                        <div className="error-detail">{errors.numberOfCashRegisters}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfCashRegisters_date === '' ? "valid":'invalid'}  value={form.numberOfCashRegisters_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfCashRegisters_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfCashRegisters_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} />Number of Walk-In Refrigeration Units:</IonLabel>
                        <IonInput type="number" className={errors.numberOfWalkInRefrigerationUnits === '' ? "valid":'invalid'}  value={form.numberOfWalkInRefrigerationUnits} placeholder='# Walk-In Refrigeration Units' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWalkInRefrigerationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWalkInRefrigerationUnits_date === '' ? "valid":'invalid'}  value={form.numberOfWalkInRefrigerationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWalkInRefrigerationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Number Of Open-Closed Refrigeration Units</IonLabel>
                        <IonInput type="number" className={errors.numberOfOpenClosedRefrigerationUnits === '' ? "valid":'invalid'}  value={form.numberOfOpenClosedRefrigerationUnits} placeholder='# Open-Closed Refrigeration Units' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfOpenClosedRefrigerationUnits'))}></IonInput>
                        <div className="error-detail">{errors.numberOfOpenClosedRefrigerationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfOpenClosedRefrigerationUnits_date === '' ? "valid":'invalid'}  value={form.numberOfOpenClosedRefrigerationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfOpenClosedRefrigerationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfOpenClosedRefrigerationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Weekly Operating Hours</IonLabel>
                        <IonInput type="number" className={errors.weeklyOperatingHours === '' ? "valid":'invalid'}  value={form.weeklyOperatingHours} placeholder='Weekly Hours' onIonChange={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours'))}></IonInput>
                        <div className="error-detail">{errors.weeklyOperatingHours}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.weeklyOperatingHours_date === '' ? "valid":'invalid'}  value={form.weeklyOperatingHours_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours_date'))}></IonInput>
                        <div className="error-detail">{errors.weeklyOperatingHours_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Number Of Computers</IonLabel>
                        <IonInput type="number" className={errors.numberOfComputers === '' ? "valid":'invalid'}  value={form.numberOfComputers} placeholder='# Computers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfComputers_date === '' ? "valid":'invalid'}  value={form.numberOfComputers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Does Property Have Cooking Facilities?</IonLabel>
                        <IonSelect className={errors.cookingFacilities === '' ? "valid":'invalid'}  value={form.cookingFacilities} interface='popover' placeholder='Cooking Facilities?' onIonChange={e => (handleFormChange(e.detail.value!,'cookingFacilities'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.cookingFacilities}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.cookingFacilities_date === '' ? "valid":'invalid'}  value={form.cookingFacilities_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'cookingFacilities_date'))}></IonInput>
                        <div className="error-detail">{errors.cookingFacilities_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} /> Single Store?</IonLabel>
                        <IonSelect className={errors.singleStore === '' ? "valid":'invalid'}  value={form.singleStore} interface='popover' placeholder='Single Store?' onIonChange={e => (handleFormChange(e.detail.value!,'singleStore'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.singleStore}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.singleStore_date === '' ? "valid":'invalid'}  value={form.singleStore_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'singleStore_date'))}></IonInput>
                        <div className="error-detail">{errors.singleStore_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} /> Is There An Exterior Entrance To The Public?</IonLabel>
                        <IonSelect className={errors.exteriorEntranceToThePublic === '' ? "valid":'invalid'}  value={form.exteriorEntranceToThePublic} interface='popover' placeholder='Exterior Public Entrance?' onIonChange={e => (handleFormChange(e.detail.value!,'exteriorEntranceToThePublic'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.exteriorEntranceToThePublic}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.exteriorEntranceToThePublic_date === '' ? "valid":'invalid'}  value={form.exteriorEntranceToThePublic_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'exteriorEntranceToThePublic_date'))}></IonInput>
                        <div className="error-detail">{errors.exteriorEntranceToThePublic_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Cooled</IonLabel>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <IonLabel position="stacked">Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentCooled_date === '' ? "valid":'invalid'}  value={form.percentCooled_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></IonInput>
                        <div className="error-detail">{errors.percentCooled_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Heated</IonLabel>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentHeated_date === '' ? "valid":'invalid'}  value={form.percentHeated_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></IonInput>
                        <div className="error-detail">{errors.percentHeated_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Area of All Walk-In Refrigeration Units:</IonLabel>
                        <IonInput type="number" className={errors.areaOfAllWalkInRefrigerationUnits === '' ? "valid":'invalid'}  value={form.areaOfAllWalkInRefrigerationUnits} placeholder='Walk-In Refrigeration Area' onIonChange={e => (handleFormChange(e.detail.value!,'areaOfAllWalkInRefrigerationUnits'))}></IonInput>
                        <div className="error-detail">{errors.areaOfAllWalkInRefrigerationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Units</IonLabel>
                        <IonSelect className={errors.areaOfAllWalkInRefrigerationUnits_units === '' ? "valid":'invalid'} value={form.areaOfAllWalkInRefrigerationUnits_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'areaOfAllWalkInRefrigerationUnits_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.areaOfAllWalkInRefrigerationUnits_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.areaOfAllWalkInRefrigerationUnits_date === '' ? "valid":'invalid'} value={form.areaOfAllWalkInRefrigerationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'areaOfAllWalkInRefrigerationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.areaOfAllWalkInRefrigerationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Length Of All Open-Closed Refrigeration Units</IonLabel>
                        <IonInput type="number" className={errors.lengthOfAllOpenClosedRefrigerationUnits === '' ? "valid":'invalid'}  value={form.lengthOfAllOpenClosedRefrigerationUnits} placeholder='Length Open-Close Refrigeration Units' onIonChange={e => (handleFormChange(e.detail.value!,'lengthOfAllOpenClosedRefrigerationUnits'))}></IonInput>
                        <div className="error-detail">{errors.lengthOfAllOpenClosedRefrigerationUnits}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Units</IonLabel>
                        <IonSelect className={errors.lengthOfAllOpenClosedRefrigerationUnits_units === '' ? "valid":'invalid'} value={form.lengthOfAllOpenClosedRefrigerationUnits_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'lengthOfAllOpenClosedRefrigerationUnits_units'))}>
                            <IonSelectOption value={'Feet'}>Feet</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.lengthOfAllOpenClosedRefrigerationUnits_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">  Current as of:</IonLabel>
                        <IonInput type="date" className={errors.lengthOfAllOpenClosedRefrigerationUnits_date === '' ? "valid":'invalid'} value={form.lengthOfAllOpenClosedRefrigerationUnits_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'lengthOfAllOpenClosedRefrigerationUnits_date'))}></IonInput>
                        <div className="error-detail">{errors.lengthOfAllOpenClosedRefrigerationUnits_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default Retail