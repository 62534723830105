import { useEffect, useState } from "react"
import { IonItem, IonLabel, IonInput, IonTextarea, IonCheckbox, IonPopover, IonContent, IonList, IonRow, IonButton, IonCol, IonLoading, IonImg, IonSelect, IonSelectOption } from "@ionic/react"
import "./AE2CustomerForm.css"
import { API_URL, REACT_APP_GOOGLE_MAPS_KEY } from "../../actions/settings"
import { Redirect, useHistory, useParams } from "react-router";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import SearchSelectInput from "../extras/SearchSelectInput";
import CheckRole from "../extras/CheckRole";
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import IDSearchSelectInput from "../extras/IDSearchSelectInput";
import NotificationPopUp from "../extras/NotificationPopUp";
import useForm from "../../utils/useForm";
import useToggle from "../../utils/useToggle";
import useHandleState from "../../utils/useHandleState";
import S3Bucket from "../extras/S3Bucket";

const startSelections = {
    types:[],
    vpn_clients:[],
    technical_solutions:[]
}

interface Params {
    id:string
}


async function editCustomer(data:any,token:string) {
    return fetch(`${API_URL}/customers`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }


async function getCustomerInfo(token:any,id:any) {
    return fetch(`${API_URL}/customer/${id}/form_info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }
    })
      .then(data => data.json())
}

async function getSelections(token:string) {
    return fetch(`${API_URL}/customer/form_selections`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
     }

async function getVPN(token:string, id:any) {
    return fetch(`${API_URL}/customer/${id}/vpn_info`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
     }

const state_choices:string[][] = [
    ['IL', "Illinois"], ['WI', 'Wisconsin'],
    ['AL', 'Alabama'], ['AR', 'Arkansas'], ['AS', 'American Samoa'], ['AZ', 'Arizona'], ['CA', 'California'], ['CO', 'Colorado'],
    ['CT', 'Connecticut'], ['DC', 'District of Columbia'], ['DE', 'Delaware'], ['FL', 'Florida'], ['GA', 'Georgia'], ['GU', 'Guam'],
    ['HI', 'Hawaii'], ['IA', 'Iowa'], ['ID', 'Idaho'], ['IN', 'Indiana'], ['KS', 'Kansas'], ['KY', 'Kentucky'], ['LA', 'Louisiana'], ['MA', 'Massachusetts'],
    ['MD', 'Maryland'], ['ME', 'Maine'], ['MH', 'Marshall Islands'], ['MI', 'Michigan'], ['MN', 'Minnesota'], ['MO', 'Missouri'], ['MP', 'Northern Mariana Islands'],
    ['MS', 'Mississippi'], ['MT', 'Montana'], ['NC', 'North Carolina'], ['ND', 'North Dakota'], ['NE', 'Nebraska'], ['NH', 'New Hampshire'], ['NJ', 'New Jersey'],
    ['NN', 'Navajo Nation'], ['NV', 'Nevada'], ['NY', 'New York'], ['OH', 'Ohio'], ['OK', 'Oklahoma'], ['OR', 'Oregon'], ['PA', 'Pennsylvania'], ['PI', 'Pacific Island'],
    ['PR', 'Puerto Rico'], ['RI', 'Rhode Island'], ['SC', 'South Carolina'], ['SD', 'South Dakota'], ['TN', 'Tennessee'], ['TT', 'Trust Territories'],
    ['TX', 'Texas'], ['UM', 'U.S. Minor Outlying Islands'], ['UT', 'Utah'], ['VA', 'Virginia'], ['VI', 'Virgin Islands'], ['VT', 'Vermont'], ['WA', 'Washington'],
    ['WQ', 'Wake Island'], ['WV', 'West Virginia'], ['WY', 'Wyoming'], ['AB', 'Alberta'], ['BC', 'British Columbia'], ['MB', 'Manitoba'], ['NB', 'New Brunswick'],
    ['NL', 'Newfoundland'], ['NS', 'Nova Scotia'], ['NT', 'Northern Territories'], ['NU', 'Nunavut'], ['ON', 'Ontario'], ['PE', 'Prince Edward Island'],
    ['QC', 'Quebec'], ['SK', 'Saskatchewan'], ['YT', 'Yukon']]


const EditAE2CustomerForm: React.FC = () => {

    const history = useHistory()
    const { id  }=useParams<Params>()
    const user = useAppSelector(selectUser)
    const [image,setImg] = useState<any>('')
    const [imageUrl,setImgUrl] = useState<any>('')

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
      } = usePlacesService({
        apiKey: REACT_APP_GOOGLE_MAPS_KEY,
      });

      const handlePlace = (item:any) => {
        placesService?.getDetails(
            {
              placeId: item.place_id,
            },
            (placeDetails:any) => {
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('postal_code'))[0].long_name,'cust_zipcode');
                handleFormChange(
                    `${placeDetails.address_components.filter((r:any) => r.types.includes('street_number'))[0].long_name} ${placeDetails.address_components.filter((r:any) => r.types.includes('route'))[0].short_name}`,'cust_address');
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('locality'))[0].long_name,'cust_city');
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('administrative_area_level_1'))[0].short_name,'cust_state');
                }
          );

    }

    const startingForm = {
        cust_name: null,
        cust_description: '',
        cust_address: null,
        cust_address_2: null,
        cust_city: null,
        cust_state: "",
        cust_zipcode: null,
        estar_in_energy_star: false,
        estar_account_id: null,
        remote_access: false,
        remote_access_type: '',
        remote_access_vpn_client:'',
        remote_access_info_location:'',
        technical_solutions_area:'d02e1e75-e735-4bc4-bdf9-e44b827e4c1c'
    }
    const startingErrors:any = {
        cust_name : '',
        cust_description:'',
        cust_address: '',
        cust_address_2: '',
        cust_city: "",
        cust_state: "",
        cust_zipcode: "",
        estar_in_energy_star: '',
        estar_account_id: '',
        remote_access: '',
        remote_access_type: '',
        remote_access_vpn_client:'',
        remote_access_info_location:'',
        technical_solutions_area:''
    }

    const validate = (name:string, val:any) => {
        const regExp = /[a-zA-Z]/g;
        switch (name) {
            case 'cust_name':
                if(val === null || val.length <=1){
                    return {cust_name:'Name Must Be longer than 2 letters'}
                }
                else{
                    return {cust_name:''}
                }

            case 'cust_address':
                if(val === null || val.length === 0 || val === " " ){
                    return {cust_address:'Address cannot be empty'}
                }
                else{
                    return {cust_address:''}
                }
            case 'cust_city':
                if(val === null || val.length === 0 ){
                    return {cust_city:'City cannot be empty'}
                }
                else{
                    return {cust_city:''}
                }

            case 'cust_state':
                if(val === null || val.length === 0 ){
                    return {cust_state:'Select A State'}
                }
                else if(!state_choices.some(st=> st.includes(val)) || val.length >=3  ){
                    return {cust_state:'Select State From The List'}
                }
                else{
                    return {cust_state:''}
                }
            case 'cust_zipcode':
                if(val === null || val.length === 0 ){
                    return {cust_zipcode:'Zip Code cannot be empty'}

                }
                else if (regExp.test(val)){
                    return {cust_zipcode:'Zip Code cannot contain letters'}
                }
                else{
                    return {cust_zipcode:''}
                }
            case 'remote_access_type':
                if(form.remote_access && (val === null || val.length === 0)){
                    return {remote_access_type:'Select type from list'}
                }
                else if( form.remote_access && !selections.types.some((st:any)=> st.id === val)){
                    return {remote_access_type:'Select type from list.'}
                }
                else{
                    return {remote_access_type:''}
                }
            case 'remote_access_vpn_client':
                if(form.remote_access && (val === null || val.length === 0)){
                    return {remote_access_vpn_client:'Select VPN client from list'}
                }
                else if( form.remote_access && !selections.vpn_clients.some((st:any)=> st.id === val)){
                    return {remote_access_vpn_client:'Select VPN client from list.'}
                }
                else{
                    return {remote_access_vpn_client:''}
                }

            default:
                break;
        }
    }


    const {form, handleFormChange, errors, resetForm, validateForm, loadForm } = useForm(startingForm,startingErrors,validate)
    const [loaded, toggleLoaded] = useToggle()
    const [selections, handleSelections] = useHandleState(startSelections,true)


    const getInfo = async (token:any, id:any, setForm:any) => {
        const customer = await getCustomerInfo(`Token ${token.slice(1,-1)}`,id)
        const vpn = await getVPN(`Token ${token.slice(1,-1)}`,id)
        setForm(
            {
                uuid: customer.data.uuid,
                cust_name: customer.data.cust_name,
                cust_description: customer.data.cust_description,
                cust_address: customer.data.cust_address,
                cust_address_2: customer.data.cust_address_2 ? customer.data.cust_address_2 : "" ,
                estar_account_id: customer.data.estar_account_id ? customer.data.estar_account_id : null ,
                cust_city: customer.data.cust_city,
                cust_state: customer.data.cust_state,
                cust_zipcode: customer.data.cust_zipcode,
                estar_in_energy_star: customer.data.estar_in_energy_star,
                remote_access: vpn.remote_access ? vpn.remote_access : false,
                remote_access_type: vpn.type ? vpn.type.id : "",
                remote_access_vpn_client:vpn.vpn_client ? vpn.vpn_client.id : "",
                remote_access_info_location:vpn.location ? vpn.location : "",
                technical_solutions_area:customer.data.technical_solutions_area ? customer.data.technical_solutions_area : "",
            }

        )
    }

    useEffect(() => {
        const token = localStorage.getItem('token')
        getInfo(token, id, loadForm)
        handleSelections(token,getSelections)
        toggleLoaded()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id])

    const handleSubmit = async () => {
        const token:any = localStorage.getItem('token')
        const valid = validateForm() || false
        if(valid){
        const res = await editCustomer(form,`Token ${token.slice(1,-1)}`)

            if (res.status === "success"){
                if(image){
                S3Bucket.UploadImage("thumbnails/pics",id,image,'customer')
                }
                resetForm()
                history.push(`/?id=${id}&sec=customers`)
                NotificationPopUp("success","Customer Edited")

            }
            else{
                NotificationPopUp('error',res.detail||res.ERROR,res.error||"Error")
            }
        }
    }

const clickAddress = (input:any,e:any) => {
    if (input.length === 1){
        e.target.click()
    }
}

const onImageChange = (e:any) =>{
    const img = e.target.files[0]
    setImg(img)
    setImgUrl(URL.createObjectURL(img))
}


if (!loaded || user.first_name===""){
    return(
        <IonLoading
    cssClass='my-custom-class'
    isOpen={!loaded}
    message={'Loading...'}
    spinner='bubbles'
  />
    )
}

if(!CheckRole(user,['Admin','Supervisor'])){
    return <Redirect to="/403"/>
}
    return (
        <form>
            <IonRow className="form-title-row" ><h2> Edit Customer </h2><IonButton className="cancel-btn" size="small"  color="danger" href={`/?id=${id}&sec=customers`}> Cancel </IonButton></IonRow>
            <IonRow>
                <IonItem lines='none'>
                    <IonLabel position="stacked">Image</IonLabel>
                    <IonRow>
                        {imageUrl ? <IonImg className="preview-image" src={imageUrl}/>:''}
                        <input type="file" accept="image/*" onChange={onImageChange} />
                    </IonRow>
                </IonItem>
            </IonRow>
            <IonItem lines="none">
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput className={errors.cust_name === '' ? "address valid":' invalid'} aria-labelledby="Name" value={form.cust_name} placeholder="Name" onIonChange={e => (handleFormChange(e.detail.value!,'cust_name'))}> </IonInput>
                <div className="error-detail">{errors.cust_name}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Customer Description</IonLabel>
                <IonTextarea auto-grow value={form.cust_description} aria-labelledby="Customer Description" placeholder='Description' onIonChange={e => (handleFormChange(e.detail.value!,'cust_description'))}> </IonTextarea>
                <div className="error-detail">{errors.cust_description}</div>
            </IonItem>
            <IonItem lines="none">
                <IonRow className="address-row">
                    <IonCol size="6">
                    <IonLabel position="stacked">Address:</IonLabel>
                    <IonInput id="address" className={errors.cust_address === '' ? "valid":'invalid'} aria-labelledby="Address" value={form.cust_address} placeholder="Address" onIonChange={e => {handleFormChange(e.detail.value!,'cust_address'); getPlacePredictions({input:e.detail.value!});clickAddress(e.detail.value!,e)}}> </IonInput>
                    <IonPopover trigger="address" className='address-list' reference="trigger" dismissOnSelect showBackdrop={false} keyboardClose={false} size='cover'>
                                <IonContent>
                                    <IonList>
                            {placePredictions.map((item) => <IonItem className="address-item" button key={item.place_id} onClick={() => handlePlace(item)}><IonLabel color="dark">{item.description}</IonLabel></IonItem>)}
                        </IonList></IonContent>
                    </IonPopover>
                    <div className="error-detail">{errors.cust_address}</div>
                    </IonCol>
                    <IonCol >
                            <IonLabel position="stacked">Address 2:</IonLabel>
                            <IonInput className={errors.cust_address_2 === '' ? "valid":'invalid'} aria-labelledby="Address 2"  value={form.cust_address_2} placeholder="Address 2" onIonChange={e => (handleFormChange(e.detail.value!,'cust_address_2'))}> </IonInput>
                            <div className="error-detail">{errors.cust_address_2}</div>
                    </IonCol>
                </IonRow>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">City</IonLabel>
                <IonInput className={errors.cust_city === '' ? "valid":'invalid'}value={form.cust_city} aria-labelledby="City" placeholder="City" onIonChange={e => (handleFormChange(e.detail.value!,'cust_city'))}> </IonInput>
                <div className="error-detail">{errors.cust_city}</div>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">State</IonLabel>
                <SearchSelectInput list={state_choices} errors={errors.cust_state} form={form.cust_state} label='State' placeholder={'State'} handleFormChange={handleFormChange} formName={'cust_state'}/>
                    <div className="error-detail">{errors.cust_state}</div>
            </IonItem>
            <IonItem lines="none">
            <IonLabel position="stacked">Zip Code</IonLabel>
                <IonInput className={errors.cust_zipcode === '' ? "valid":'invalid'} value={form.cust_zipcode} aria-labelledby="Zip code" placeholder="Zip Code" onIonChange={e => (handleFormChange(e.detail.value!,'cust_zipcode'))}> </IonInput>
                <div className="error-detail">{errors.cust_zipcode}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel>Technical Solutions Area:</IonLabel>
                <IonSelect className={errors.technical_solutions_area === '' ? "valid":'invalid'} interface='popover' label="Technical Solutions Area" value={form.technical_solutions_area} placeholder="TS Area" onIonChange={e => (handleFormChange(e.detail.value!,'technical_solutions_area'))}>
                    {selections['technical_solutions'].map((e:any) => {
                    return (<IonSelectOption key={e['id']} value={e['id']} >
                        {e['name']}
                    </IonSelectOption>)
                })}

                </IonSelect>
            </IonItem>
            <IonItem lines="none">
                <IonLabel> Remote Access Set Up?</IonLabel>
                <IonCheckbox checked={form.remote_access} mode='ios' slot="start" aria-label="Remote Access Set Up" onIonChange={e => (handleFormChange(e.detail.checked,'remote_access'))} />
                <div className="error-detail">{errors.remote_access}</div>
            </IonItem>
            { form.remote_access ?
            <IonItem lines="none">
                <IonRow className="remote-access-row">
                    <IonCol size="12" sizeMd="4">
                    <IonLabel position="stacked">Remote Access Type</IonLabel>
                        <IDSearchSelectInput dict={selections['types']} dict_key='id' value='type' label='Remote Access Type' errors={errors.remote_access_type} form={form} handleFormChange={handleFormChange} formName={'remote_access_type'} placeholder='Access Type'/>
                        {/* <SearchSelectInput list={selections.types} errors={errors.remote_access_type} form={form.remote_access_type} placeholder={'Type'} handleFormChange={handleFormChange} formName={'remote_access_type'}/> */}
                        <div className="error-detail">{errors.remote_access_type}</div>
                    </IonCol>
                    <IonCol size="12" sizeMd="4">
                        <IonLabel position="stacked">VPN Client</IonLabel>
                        <IDSearchSelectInput dict={selections['vpn_clients']} dict_key='id' value='client' label="VPN Client" errors={errors.remote_access_vpn_client} form={form} handleFormChange={handleFormChange} formName={'remote_access_vpn_client'} placeholder='VPN Client'/>
                        <div className="error-detail">{errors.remote_access_vpn_client}</div>
                    </IonCol>
                    <IonCol size="12" sizeMd="4">
                        <IonLabel position="stacked">Info Location</IonLabel>
                        <IonInput className={errors.remote_access_info_location === '' ? "valid":'invalid'} aria-labelledby="Info Location" value={form.remote_access_info_location} placeholder="VPN Info Location" onIonChange={e => (handleFormChange(e.detail.value!,'remote_access_info_location'))}> </IonInput>
                        <div className="error-detail">{errors.remote_access_info_location}</div>
                    </IonCol>
                </IonRow>
            </IonItem>
            : ''}
            <IonItem lines="none">
                <IonLabel> Has Energy Star Account?</IonLabel>
                <IonCheckbox slot='start' mode='ios' color='primary' aria-label="Energy Star Account"  checked={form.estar_in_energy_star} onIonChange={e => handleFormChange(e.detail.checked,'estar_in_energy_star')} />
                <div className="error-detail">{errors.estar_in_energy_star}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Energy Star Account ID</IonLabel>
                <IonInput className={errors.estar_account_id === '' ? "valid":'invalid'} type='number' aria-labelledby="Energy Star ID" auto-grow value={form.estar_account_id} placeholder='Energy Star ID' onIonChange={e => (handleFormChange(e.detail.value!,'estar_account_id'))}> </IonInput>
                <div className="error-detail">{errors.estar_account_id}</div>
            </IonItem>
            <div className="button-container">
            <IonItem className='form-submit-button' button color='primary' onClick={handleSubmit}>
                <IonLabel>
                  Submit
                </IonLabel>
            </IonItem>
            </div>
        </form>
        )
}

export default EditAE2CustomerForm