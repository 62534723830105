import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
numberOfBedrooms : "",
numberOfBedrooms_date : "",
numberOfPeople : "",
numberOfPeople_date : "",

}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
numberOfBedrooms : "",
numberOfBedrooms_date : "",
numberOfPeople : "",
numberOfPeople_date : "",
}

const SingleFamilyHome: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('numberOfPeople'):
                if(value !==''){
                    form.numberOfPeople_date === '' ? handleError('numberOfPeople_date','Date cannot be empty') : handleError('numberOfPeople_date','')
                    value < 0 ? handleError('numberOfPeople','Must be a Positive number') : handleError('numberOfPeople','')
                }
                else{
                    handleError('numberOfPeople','')
                    handleError('numberOfPeople_date','')
                }
                break;
            case('numberOfPeople_date'):
                if(value !==''){
                    form.numberOfPeople === '' ? handleError('numberOfPeople','Field cannot be empty') : handleError('numberOfPeople','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfPeople_date','Invalid Date') : handleError('numberOfPeople_date','')
                }
                else{
                    handleError('numberOfPeople','')
                    handleError('numberOfPeople_date','')
                }
                break;
            case('numberOfBedrooms'):
                if(value !==''){
                    form.numberOfBedrooms_date === '' ? handleError('numberOfBedrooms_date','Date cannot be empty') : handleError('numberOfBedrooms_date','')
                    value < 0 ? handleError('numberOfBedrooms','Must be a Positive number') : handleError('numberOfBedrooms','')
                }
                else{
                    handleError('numberOfBedrooms','')
                    handleError('numberOfBedrooms_date','')
                }
                break;
            case('numberOfBedrooms_date'):
                if(value !==''){
                    form.numberOfBedrooms === '' ? handleError('numberOfBedrooms','Field cannot be empty') : handleError('numberOfBedrooms','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfBedrooms_date','Invalid Date') : handleError('numberOfBedrooms_date','')
                }
                else{
                    handleError('numberOfBedrooms','')
                    handleError('numberOfBedrooms_date','')
                }
                break;

            default:
                break;
        }
    }

    return (
        <div>
            <h1>Single Family Home Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number of Bedrooms</IonLabel>
                        <IonInput type="number" className={errors.numberOfBedrooms === '' ? "valid":'invalid'}  value={form.numberOfBedrooms} placeholder='# Bedrooms' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfBedrooms'))}></IonInput>
                        <div className="error-detail">{errors.numberOfBedrooms}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfBedrooms_date === '' ? "valid":'invalid'}  value={form.numberOfBedrooms_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfBedrooms_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfBedrooms_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Number of People</IonLabel>
                        <IonInput type="number" className={errors.numberOfPeople === '' ? "valid":'invalid'}  value={form.numberOfPeople} placeholder='# People' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfPeople'))}></IonInput>
                        <div className="error-detail">{errors.numberOfPeople}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfPeople_date === '' ? "valid":'invalid'}  value={form.numberOfPeople_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfPeople_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfPeople_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default SingleFamilyHome