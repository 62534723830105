export const page_break = `<?xml version="1.0" encoding="iso-8859-1"?>
<svg fill="#000000" height="24px" width="24px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	 viewBox="0 0 408.105 408.105" xml:space="preserve">
<g>
	<path d="M364.479,0c-4.143,0-7.5,3.357-7.5,7.5v159.924H91.065V7.5c0-4.143-3.358-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v167.424
		c0,4.143,3.358,7.5,7.5,7.5h280.914c4.143,0,7.5-3.357,7.5-7.5V7.5C371.979,3.357,368.621,0,364.479,0z"/>
	<path d="M152.219,15h20c4.142,0,7.5-3.358,7.5-7.5c0-4.143-3.358-7.5-7.5-7.5h-20c-4.142,0-7.5,3.357-7.5,7.5
		C144.719,11.642,148.077,15,152.219,15z"/>
	<path d="M112.219,15h20c4.142,0,7.5-3.358,7.5-7.5c0-4.143-3.358-7.5-7.5-7.5h-20c-4.142,0-7.5,3.357-7.5,7.5
		C104.719,11.642,108.077,15,112.219,15z"/>
	<path d="M192.219,15h20c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-20c-4.142,0-7.5,3.357-7.5,7.5
		C184.719,11.642,188.077,15,192.219,15z"/>
	<path d="M272.219,15h20c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-20c-4.143,0-7.5,3.357-7.5,7.5
		C264.719,11.642,268.076,15,272.219,15z"/>
	<path d="M232.219,15h20c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-20c-4.143,0-7.5,3.357-7.5,7.5
		C224.719,11.642,228.076,15,232.219,15z"/>
	<path d="M312.219,15h20c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-20c-4.143,0-7.5,3.357-7.5,7.5
		C304.719,11.642,308.076,15,312.219,15z"/>
	<path d="M364.479,225.68H83.565c-4.142,0-7.5,3.357-7.5,7.5v167.426c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5V240.68h265.914
		v159.926c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5V233.18C371.979,229.037,368.621,225.68,364.479,225.68z"/>
	<path d="M252.824,393.105h-20c-4.143,0-7.5,3.357-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h20c4.143,0,7.5-3.357,7.5-7.5
		C260.324,396.463,256.967,393.105,252.824,393.105z"/>
	<path d="M292.824,393.105h-20c-4.143,0-7.5,3.357-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h20c4.143,0,7.5-3.357,7.5-7.5
		C300.324,396.463,296.967,393.105,292.824,393.105z"/>
	<path d="M332.824,393.105h-20c-4.143,0-7.5,3.357-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h20c4.143,0,7.5-3.357,7.5-7.5
		C340.324,396.463,336.967,393.105,332.824,393.105z"/>
	<path d="M132.824,393.105h-20c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h20c4.142,0,7.5-3.357,7.5-7.5
		C140.324,396.463,136.966,393.105,132.824,393.105z"/>
	<path d="M212.824,393.105h-20c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h20c4.143,0,7.5-3.357,7.5-7.5
		C220.324,396.463,216.967,393.105,212.824,393.105z"/>
	<path d="M172.824,393.105h-20c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h20c4.142,0,7.5-3.357,7.5-7.5
		C180.324,396.463,176.966,393.105,172.824,393.105z"/>
	<path d="M81.573,204.053c0-2.348-1.1-4.561-2.971-5.979L48.156,175.01c-2.27-1.719-5.318-2.006-7.867-0.737
		c-2.55,1.267-4.162,3.868-4.162,6.716v46.129c0,2.848,1.612,5.449,4.162,6.716c1.057,0.525,2.2,0.784,3.337,0.784
		c1.606,0,3.201-0.516,4.53-1.521l30.446-23.064C80.474,208.613,81.573,206.4,81.573,204.053z M51.127,212.026v-15.947l10.526,7.974
		L51.127,212.026z"/>
</g>
</svg>`