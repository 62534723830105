export const calcInflationBaseline = (baseline:any) =>{
    const baseline_electricity = (baseline.electricity_use && baseline.score.electricity_use) ? (baseline.electricity_use * (baseline.score.electricity_cost/baseline.score.electricity_use)) : 0
    const baseline_gas = (baseline.gas_use && baseline.score.gas_use) ? Math.round(baseline.gas_use * (baseline.score.gas_cost/baseline.score.gas_use)) : 0
    const baseline_cost = baseline_electricity + baseline_gas
    return Math.round(baseline_cost *100)/100
}

export const calc15yr = (baseline:any) =>{

    if (!baseline.energycost || !baseline.score.energycost ){
        return ("N/A")
    }

    const savings = Math.round(((calcInflationBaseline(baseline) - baseline.score.energycost) * 15)*100)/100
    if (savings < 0 )
    { return ("N/A")}
    return '$'+ savings.toLocaleString('en')
}
export const calcPercent = (baseline:any) =>{
    if (!baseline || !baseline.score || !baseline.score.energycost){
        return ("N/A")
    }
    const baseline_cost = calcInflationBaseline(baseline)
    if(baseline_cost===0){
        return ("N/A")
    }
    const percent = Math.round((baseline_cost - baseline.score.energycost)/baseline_cost * 100)
    return percent.toLocaleString('en')+'%'
}
export const calcKBTU = (baseline:any) => {
    if (!baseline.score.electricity_use || !baseline.score.gas_use){
        return ("N/A")
    }
    const kbtu =(3.412*baseline.score.electricity_use)+(100*baseline.score.gas_use)
    return Math.round(kbtu * 100)/100
}

export const displayValue = (x:any,type?:any,sf?:any) =>{
     if(!x){
        return('N/A')
     }
     if(type==='cash'){
        return('$'+x.toLocaleString('en'))
     }
     if(type==='area'){
        return (<>{x.toLocaleString('en')}ft<sup>2</sup></>)
     }
     if(type==='date'){
        return new Date(x).toLocaleDateString()
     }
     if(type==='per-sf'){
         return Math.round((x/sf) * 100)/100
     }
     return (x.toLocaleString('en'))
}

export {}