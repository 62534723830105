import { IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import {star, starOutline} from 'ionicons/icons'

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
percentOfficeCooled : "",
percentOfficeCooled_date : "",
percentOfficeHeated : "",
percentOfficeHeated_date : "",
hasComputerLab : "",
hasComputerLab_date : "",
hasDinningHall : "",
hasDinningHall_date : "",
numberOfRooms : "",
numberOfRooms_date : ""

}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
percentOfficeCooled : "",
percentOfficeCooled_date : "",
percentOfficeHeated : "",
percentOfficeHeated_date : "",
hasComputerLab : "",
hasComputerLab_date : "",
hasDinningHall : "",
hasDinningHall_date : "",
numberOfRooms : "",
numberOfRooms_date : ""
}

const ResidenceHall: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('hasComputerLab'):
                if( value !== '' && form.hasComputerLab_date === ''){
                    handleError('hasComputerLab_date','Date cannot be empty')
                }
                if( value === '' && form.hasComputerLab_date === ''){
                    handleError('hasComputerLab_date','')
                }
                break;
            case ('hasComputerLab_date'):
                if (form.hasComputerLab !== ''){
                    if (value === ''){
                        handleError('hasComputerLab_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('hasComputerLab_date','Invalid Date')
                    }
                    else {
                        handleError('hasComputerLab_date','')
                    }
                }
                break;
            case ('hasDinningHall'):
                if( value !== '' && form.hasDinningHall_date === ''){
                    handleError('hasDinningHall_date','Date cannot be empty')
                }
                if( value === '' && form.hasDinningHall_date === ''){
                    handleError('hasDinningHall_date','')
                }
                break;
            case ('hasDinningHall_date'):
                if (form.hasDinningHall !== ''){
                    if (value === ''){
                        handleError('hasDinningHall_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('hasDinningHall_date','Invalid Date')
                    }
                    else {
                        handleError('hasDinningHall_date','')
                    }
                }
                break;
            case ('numberOfRooms'):
                if (value < 0){
                    handleError('numberOfRooms','Number of rooms must be positive')
                }
                else {
                    handleError('numberOfRooms','')
                }
                if( value !== '' && form.numberOfRooms_date === ''){
                    handleError('numberOfRooms_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfRooms_date === ''){
                    handleError('numberOfRooms_date','')
                }
                break;
            case ('numberOfRooms_date'):
                if (form.numberOfRooms !== ''){
                    if (value === ''){
                        handleError('numberOfRooms_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfRooms_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfRooms_date','')
                    }
                }
                break;
            case ('percentOfficeHeated'):

                if( value !== '' && form.percentOfficeHeated_date === ''){
                    handleError('percentOfficeHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentOfficeHeated_date === ''){
                    handleError('percentOfficeHeated_date','')
                }
                else {
                    handleError('percentOfficeHeated','')
                }
                break;
            case ('percentOfficeHeated_date'):
                if (form.percentOfficeHeated !== ''){
                    if (value === ''){
                        handleError('percentOfficeHeated_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentOfficeHeated_date','Invalid Date')
                    }
                    else {
                        handleError('percentOfficeHeated_date','')
                    }
                }
                break;
            case ('percentOfficeCooled'):

                if( value !== '' && form.percentOfficeCooled_date === ''){
                    handleError('percentOfficeCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentOfficeCooled_date === ''){
                    handleError('percentOfficeCooled_date','')
                }
                else {
                    handleError('percentOfficeCooled','')
                }
                break;
            case ('percentOfficeCooled_date'):
                if (form.percentOfficeCooled !== ''){
                    if (value === ''){
                        handleError('percentOfficeCooled_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentOfficeCooled_date','Invalid Date')
                    }
                    else {
                        handleError('percentOfficeCooled_date','')
                    }
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Barracks Form</h1>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Percent Office Cooled</IonLabel>
                        <IonSelect className={errors.percentOfficeCooled === '' ? "valid":'invalid'}  value={form.percentOfficeCooled} interface='popover' placeholder='% Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentOfficeCooled'))}>
                            <IonSelectOption>50% or more</IonSelectOption>
                            <IonSelectOption>Less than 50%</IonSelectOption>
                            <IonSelectOption>Not Cooled</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentOfficeCooled}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentOfficeCooled_date === '' ? "valid":'invalid'}  value={form.percentOfficeCooled_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentOfficeCooled_date'))}></IonInput>
                        <div className="error-detail">{errors.percentOfficeCooled_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Percent Office Heated</IonLabel>
                        <IonSelect className={errors.percentOfficeHeated === '' ? "valid":'invalid'}  value={form.percentOfficeHeated} interface='popover' placeholder='% Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentOfficeHeated'))}>
                            <IonSelectOption>50% or more</IonSelectOption>
                            <IonSelectOption>Less than 50%</IonSelectOption>
                            <IonSelectOption>Not Heated</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentOfficeHeated}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.percentOfficeHeated_date === '' ? "valid":'invalid'}  value={form.percentOfficeHeated_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'percentOfficeHeated_date'))}></IonInput>
                        <div className="error-detail">{errors.percentOfficeHeated_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Does Property Have a Computer Lab?</IonLabel>
                        <IonSelect className={errors.hasComputerLab === '' ? "valid":'invalid'}  value={form.hasComputerLab} interface='popover' placeholder='Has Computer Lab?' onIonChange={e => (handleFormChange(e.detail.value!,'hasComputerLab'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.hasComputerLab}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.hasComputerLab_date === '' ? "valid":'invalid'}  value={form.hasComputerLab_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'hasComputerLab_date'))}></IonInput>
                        <div className="error-detail">{errors.hasComputerLab_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Does Property Have a Dining Hall?</IonLabel>
                        <IonSelect className={errors.hasDinningHall === '' ? "valid":'invalid'}  value={form.hasDinningHall} interface='popover' placeholder='Has Dining hall?' onIonChange={e => (handleFormChange(e.detail.value!,'hasDinningHall'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.hasDinningHall}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.hasDinningHall_date === '' ? "valid":'invalid'}  value={form.hasDinningHall_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'hasDinningHall_date'))}></IonInput>
                        <div className="error-detail">{errors.hasDinningHall_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> <IonIcon slot="start" ios={starOutline} md={star} /> Number Of Rooms</IonLabel>
                        <IonInput type="number" className={errors.numberOfRooms === '' ? "valid":'invalid'}  value={form.numberOfRooms} placeholder='Computers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfRooms'))}></IonInput>
                        <div className="error-detail">{errors.numberOfRooms}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfRooms_date === '' ? "valid":'invalid'}  value={form.numberOfRooms_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfRooms_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfRooms_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default ResidenceHall