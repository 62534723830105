import { IonList, IonItem, IonLabel, IonIcon } from '@ionic/react';
import '../AE2/Ae2Navs.css'

import { businessOutline, businessSharp, gitPullRequestOutline, gitPullRequestSharp } from 'ionicons/icons';
import { isStaff } from '../extras/Functions';
import { useAppSelector } from '../../Hooks';
import { selectUser } from '../../reducers/UserReducer';
import Logout from '../auth/Logout';

const buttons = [
  {
      title:'EnergyStar Data',
      url:'/energystar_data',
      iosIcon: gitPullRequestOutline,
      mdIcon: gitPullRequestSharp,
      hidden: true
  },
  {
    title: "Properties",
    url:'/energystar/properties',
    iosIcon: businessOutline,
    mdIcon: businessSharp,
    hidden:false
  },
];




const EstarNavs: React.FC = () => {
  const user = useAppSelector(selectUser)


    return (
      <>
      <IonList id="labels-list" className='labels-list'>

          {buttons.map((button,index) => (
            <div key={'list'+index} className={button.title+'-block'} hidden={button.hidden ? !isStaff(user):false}>

              <IonItem button key={index} href={button.url}>
              <IonIcon slot="start" ios={button.iosIcon} md={button.mdIcon} />
              <IonLabel>{button.title}</IonLabel>
              </IonItem>

            </div>


          ))}

        </IonList>

        <Logout/>
        </>
    );
  };

  export default EstarNavs;
