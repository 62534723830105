import { IonButton, IonCol, IonInput, IonItem, IonLabel, IonLoading, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { API_URL } from "../../actions/settings"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import CheckRole from "../extras/CheckRole"
import IDSearchSelectInput from "../extras/IDSearchSelectInput"
import NotificationPopUp from "../extras/NotificationPopUp"
import useForm from "../../utils/useForm"
import useToggle from "../../utils/useToggle"

interface Params {
    id:string
}

async function getProviders(token:string) {
    return fetch(`${API_URL}/utility_provider`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
    }

async function getBuildingUtility(id:any,token:string) {
    return fetch(`${API_URL}/buildings/utility_service/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
    }

async function getServiceTypes(token:string) {
    return fetch(`${API_URL}/utility_service_type`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
    }

async function editBuildingUtility(data:any,id:any, token:string) {
    return fetch(`${API_URL}/buildings/utility_service/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
    }

const EditAE2BuildingUtilityForm:React.FC = () =>{
    const { id }=useParams<Params>()
    const user = useAppSelector(selectUser)
    const [utilityProviders, setUtilityProviders] = useState([])
    const [utilityServiceType, setUtilityServiceType] = useState([])
    const [loaded, toggleLoaded] = useToggle()

    const startingForm = {
        name:"",
        building:"",
        utility_service_type:"",
        utility_provider:"",
        account_number:"",
        account_group_number:"",
        third_party_provider:"",
        third_party_account_number:"",
        podid:"",
        meter_number:""
    }

    const startingErrors:any = {
        name:"",
        utility_service_type:"",
        utility_provider:"",
        account_number:"",
        account_group_number:"",
        third_party_provider:"",
        third_party_account_number:"",
        podid:"",
        meter_number:""
    }

    const validate = (name:string, val:any) => {
        // const regExp = /[a-zA-Z]/g;
        switch (name) {
            case 'name':
                if(val === null || val.length <=1){
                    return {name:'Name Must Be longer than 2 letters'}
                }
                else{
                    return {name:''}
                }
            case 'utility_service_type':
                if(val === null || val.length === ''){
                    return {utility_service_type:'Cannot Be None'}
                }
                if(!utilityServiceType.some((i:any)=> i.id === val)){
                    return {utility_service_type:'Select A Type From List'}
                }
                else{
                    return {utility_service_type:''}
                }
            case 'utility_provider':
                if(val === null || val.length === ''){
                    return {utility_provider:'Cannot Be None'}
                }
                if(!utilityProviders.some((i:any)=> i.id === val)){
                    return {utility_provider:'Select A Type From List'}
                }
                else{
                    return {utility_provider:''}
                }
            case 'account_number':
                if(val === null || val.length === 0 ){
                    return {account_number:'Account number cannot be empty'}

                }
                else{
                    return {account_number:''}
                }
            case 'third_party_provider':
                if(val !== '' && !utilityProviders.some((i:any)=> i.id === val)){
                    return {third_party_provider:'Select A Type From List'}
                }
                else{
                    return {third_party_provider:''}
                }
            default:
                break;
        }
    }

    const {form, handleFormChange, errors, resetForm, validateForm,loadForm} = useForm(startingForm,startingErrors,validate)
    const history = useHistory()


    useEffect(() => {
        const token = localStorage.getItem('token')
        handleUtilityProvider(token)
        handleUtilityServiceTypes(token)
        handleBuildingUtility(token)
        toggleLoaded()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleUtilityProvider = async (token:string|null) => {
        const providers = await getProviders(`Token ${token!.slice(1,-1)}`)
        setUtilityProviders(providers.data)
    }

    const handleBuildingUtility= async (token:string|null) => {
        const building_utility = await getBuildingUtility(id,`Token ${token!.slice(1,-1)}`)
        loadForm( {
            name:building_utility.data.name,
            building:building_utility.data.building,
            utility_service_type:building_utility.data.utility_service_type.id,
            utility_provider:building_utility.data.utility_provider.id,
            account_number:building_utility.data.account_number,
            account_group_number:building_utility.data.account_group_number,
            third_party_provider: building_utility.data.third_party_provider ? building_utility.data.third_party_provider.id : '',
            third_party_account_number:building_utility.data.third_party_account_number,
            podid:building_utility.data.podid,
            meter_number:building_utility.data.meter_number
        })
    }

    const handleUtilityServiceTypes = async (token:string|null) => {
        const serviceTypes = await getServiceTypes(`Token ${token!.slice(1,-1)}`)
        setUtilityServiceType(serviceTypes.data)
    }

    const handleSubmit = async () => {
        const token:any = localStorage.getItem('token')
        const valid = validateForm() || false
        if(valid){
        const res = await editBuildingUtility(form, id, `Token ${token.slice(1,-1)}`)
        if (res.status === "success"){
            const building_id = form.building
            resetForm()
            history.push(`/?id=${building_id}&sec=buildings`)
            NotificationPopUp("success","Building Service Utility Edited")
        }

        else{
            NotificationPopUp('error',res.detail||res.ERROR,res.error||"Error")
        }
        }
    }

    if (!loaded || user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={!(user.first_name==="")}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }

    return(
        <form>
            <IonRow className="form-title-row"><h2>Edit Utility Service Form</h2><IonButton className="cancel-btn" size="small"  color="danger" href={`/?id=${form.building}&sec=buildings`}> Cancel </IonButton></IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="edit-warning">
                <span>WARNING:</span>  Only use this page to fix data entry errors for this Utility Service!  If any of the fields have been changed by the utility provider(s) please create a new Utility Service with the updated information.
                Editing an existing Utility Service with updated data will remove the associations between any historical billing data.
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                <IonItem lines="none">
                    <IonLabel position="stacked">Name</IonLabel>
                    <IonInput className={errors.name === '' ? "valid":' invalid'} aria-labelledby="Name" value={form.name} placeholder="Name" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'name'))}> </IonInput>
                    <div className="error-detail">{errors.name}</div>
                </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IonLabel position="stacked"> Utility Provider</IonLabel>
                        <IDSearchSelectInput dict={utilityProviders} dict_key="id" value="name" label='Utility Provider' handleFormChange={handleFormChange} errors={errors.utility_provider} form={form} formName={'utility_provider'} placeholder='Utility Provider'/>
                        <div className="error-detail">{errors.utility_provider}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IonLabel position="stacked"> Utility Service Type</IonLabel>
                        <IDSearchSelectInput dict={utilityServiceType} dict_key="id" value="name" label='Utility Service Type' handleFormChange={handleFormChange} errors={errors.utility_service_type} form={form} formName={'utility_service_type'} placeholder='Utility Service Type'/>
                        <div className="error-detail">{errors.utility_service_type}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IonLabel position="stacked">Utility Service Account Number</IonLabel>
                        <IonInput className={errors.account_number === '' ? "valid":' invalid'} aria-labelledby="Utility Service Account Number" value={form.account_number} placeholder="Account Number" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'account_number'))}> </IonInput>
                        <div className="error-detail">{errors.account_number}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IonLabel position="stacked">Utility Service Account Group Number</IonLabel>
                        <IonInput className={errors.account_group_number === '' ? "valid":' invalid'} aria-labelledby="Utility Service Account Group Number" value={form.account_group_number} placeholder="Account Group Number" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'account_group_number'))}> </IonInput>
                        <div className="error-detail">{errors.account_group_number}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IonLabel position="stacked">Third Party Utility Provider</IonLabel>
                        <IDSearchSelectInput dict={utilityProviders} dict_key="id" value="name" label='Third Party Utility Provider' handleFormChange={handleFormChange} errors={errors.third_party_provider} form={form} formName={'third_party_provider'} placeholder='Third Party Utility Provider'/>
                        <div className="error-detail">{errors.third_party_provider}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IonLabel position="stacked">Third Party Account Number</IonLabel>
                        <IonInput className={errors.third_party_account_number === '' ? "valid":' invalid'} aria-labelledby="Third Party Account Number" value={form.third_party_account_number} placeholder="Third Party Account Number" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'third_party_account_number'))}> </IonInput>
                        <div className="error-detail">{errors.third_party_account_number}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IonLabel position="stacked">PODID</IonLabel>
                        <IonInput className={errors.podid === '' ? "valid":' invalid'} aria-labelledby="PODID" value={form.podid} placeholder="PODID" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'podid'))}> </IonInput>
                        <div className="error-detail">{errors.podid}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IonLabel position="stacked">Meter Number</IonLabel>
                        <IonInput className={errors.meter_number === '' ? "valid":' invalid'} aria-labelledby="Meter Number" value={form.meter_number} placeholder="Meter Number" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'meter_number'))}> </IonInput>
                        <div className="error-detail">{errors.meter_number}</div>
                    </IonItem>
                </IonCol>
            </IonRow>

            <div className="button-container">
            <IonItem className='form-submit-button' button color='primary' onClick={handleSubmit}>
                <IonLabel>
                  Submit
                </IonLabel>
            </IonItem>
            </div>

        </form>
    )
}

export default EditAE2BuildingUtilityForm