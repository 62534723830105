import { useEffect, useState } from "react"
import { IonItem, IonLabel, IonInput, IonTextarea, IonSelect, IonSelectOption, IonLoading } from "@ionic/react"
import "./AE2BuildingForm.css"
import { API_URL } from "../../actions/settings"
import { Redirect, useHistory } from "react-router";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import CheckRole from "../extras/CheckRole";
import NotificationPopUp from "../extras/NotificationPopUp";


// ############################################## DEPRECATED #################################


interface apiRes {
    model: string,
    pk: string,
    fields: any
}

async function createBuilding(data:any,token:string) {
    return fetch(`${API_URL}/buildings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }

async function getAllCustomers(token:any) {


        return fetch(`${API_URL}/customers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }

async function getAllCampuses(token:any) {


        return fetch(`${API_URL}/campuses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }


const state_choices:string[][] = [
    ['IL', "Illinois"], ['WI', 'Wisconsin'],
    ['AL', 'Alabama'], ['AR', 'Arkansas'], ['AS', 'American Samoa'], ['AZ', 'Arizona'], ['CA', 'California'], ['CO', 'Colorado'],
    ['CT', 'Connecticut'], ['DC', 'District of Columbia'], ['DE', 'Delaware'], ['FL', 'Florida'], ['GA', 'Georgia'], ['GU', 'Guam'],
    ['HI', 'Hawaii'], ['IA', 'Iowa'], ['ID', 'Idaho'], ['IN', 'Indiana'], ['KS', 'Kansas'], ['KY', 'Kentucky'], ['LA', 'Louisiana'], ['MA', 'Massachusetts'],
    ['MD', 'Maryland'], ['ME', 'Maine'], ['MH', 'Marshall Islands'], ['MI', 'Michigan'], ['MN', 'Minnesota'], ['MO', 'Missouri'], ['MP', 'Northern Mariana Islands'],
    ['MS', 'Mississippi'], ['MT', 'Montana'], ['NC', 'North Carolina'], ['ND', 'North Dakota'], ['NE', 'Nebraska'], ['NH', 'New Hampshire'], ['NJ', 'New Jersey'],
    ['NN', 'Navajo Nation'], ['NV', 'Nevada'], ['NY', 'New York'], ['OH', 'Ohio'], ['OK', 'Oklahoma'], ['OR', 'Oregon'], ['PA', 'Pennsylvania'], ['PI', 'Pacific Island'],
    ['PR', 'Puerto Rico'], ['RI', 'Rhode Island'], ['SC', 'South Carolina'], ['SD', 'South Dakota'], ['TN', 'Tennessee'], ['TT', 'Trust Territories'],
    ['TX', 'Texas'], ['UM', 'U.S. Minor Outlying Islands'], ['UT', 'Utah'], ['VA', 'Virginia'], ['VI', 'Virgin Islands'], ['VT', 'Vermont'], ['WA', 'Washington'],
    ['WQ', 'Wake Island'], ['WV', 'West Virginia'], ['WY', 'Wyoming'], ['AB', 'Alberta'], ['BC', 'British Columbia'], ['MB', 'Manitoba'], ['NB', 'New Brunswick'],
    ['NL', 'Newfoundland'], ['NS', 'Nova Scotia'], ['NT', 'Northern Territories'], ['NU', 'Nunavut'], ['ON', 'Ontario'], ['PE', 'Prince Edward Island'],
    ['QC', 'Quebec'], ['SK', 'Saskatchewan'], ['YT', 'Yukon']]


const getCustomers = async (token:any, setCustomers:any) => {
        const customers = await getAllCustomers(`Token ${token.slice(1,-1)}`)
        setCustomers(JSON.parse(customers))
    }
const getBuildings = async (token:any, setCampuses:any) => {
        const customers = await getAllCampuses(`Token ${token.slice(1,-1)}`)
        setCampuses(JSON.parse(customers))
    }


const AE2BuildingForm: React.FC = () => {

    const history = useHistory()
    const user = useAppSelector(selectUser)

    const startingForm = {
        cust_uuid: null,
        campus_uuid: null,
        building_name: null,
        building_description: '',
        building_address: null,
        building_city: null,
        building_state: null,
        building_zipcode: null,
    }
    const startingErrors:any = {
        cust_uuid: '',
        campus_uuid:'',
        building_name : '',
        building_description:'',
        building_address: '',
        building_city: "",
        building_state: "",
        building_zipcode: "",
    }

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = useState(startingErrors)
    const [customers, setCustomers] = useState([])
    const [campuses, setCampuses] = useState([])

    useEffect(() => {
        const token = localStorage.getItem('token')
        getCustomers(token,setCustomers)
        getBuildings(token,setCampuses)
      }, [])


    const handleFormChange = (value:any,name:string) => {
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const handleSubmit = async () => {
        const token:any = localStorage.getItem('token')
        const valid = validateAll(form) || false
        if(valid){
        const res = await createBuilding(form,`Token ${token.slice(1,-1)}`)

            if (res.Message === "Building Created"){
                setForm(startingForm)
                history.push(`/?id=${res.data.uuid}&sec=buildings`)
                NotificationPopUp('success','Building Created')

            }
            else{
                NotificationPopUp('error',res.details||res.ERROR,res.error||"Error")
            }
        }
    }


    const validate = (name:string, val:any) => {
        const regExp = /[a-zA-Z]/g;
        switch (name) {
            case 'building_name':
                if(val === null || val.length <=1){
                    return {building_name:'Name Must Be longer than 2 letters'}
                }
                else{
                    return {building_name:''}
                }

            case 'building_address':
                if(val === null || val.length === 0 || val === " " ){
                    return {building_address:'Address cannot be empty'}
                }
                else{
                    return {building_address:''}
                }
            case 'building_city':
                if(val === null || val.length === 0 ){
                    return {building_city:'City cannot be empty'}
                }
                else{
                    return {building_city:''}
                }

            case 'building_state':
                if(val === null || val.length === 0 ){
                    return {building_state:'Select A State'}
                }
                else{
                    return {building_state:''}
                }
            case 'building_zipcode':
                if(val === null || val.length === 0 ){
                    return {building_zipcode:'Zip Code cannot be empty'}

                }
                else if (regExp.test(val)){
                    return {building_zipcode:'Zip Code cannot contain letters'}
                }
                else{
                    return {building_zipcode:''}
                }
                case 'cust_uuid':
                    if(val === null || val.length === 0 ){
                        return {cust_uuid:'Select A Customer'}
                    }
                    else{
                        return {cust_uuid:''}
                    }

            default:
                break;
        }
    }




    const validateAll = (form:any) => {
    let validation = startingErrors
    let valid = true

    for (let item in form){

        validation = {...validation, ...validate(item,form[item])}

    }

    setErrors({...errors,...validation})

    for(let res in validation){

        if (validation[res] !== ''){
            valid = false;
        }

    }

    return valid
    }

    if (user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={!(user.first_name==="")}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }

    return (
        <form>
            <IonItem lines="none">
                <IonLabel position="stacked"> Customer Associated with This Building</IonLabel>
                <IonSelect className={errors.cust_uuid === '' ? "valid":'invalid'} interface='popover' value={form.cust_uuid} placeholder="Customer" onIonChange={e => (handleFormChange(e.detail.value!,'cust_uuid'))}>
                    {customers.map((cust:apiRes) => {
                        return (
                            <IonSelectOption key={cust.pk} value={cust.pk}>{cust.fields.cust_name}</IonSelectOption>
                        )
                    })}
                    </IonSelect>
                    <div className="error-detail">{errors.cust_uuid}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked"> Campus Associated with This Building</IonLabel>
                <IonSelect className={errors.campus_uuid === '' ? "valid":'invalid'} interface='popover' value={form.campus_uuid} placeholder="Campus" onIonChange={e => (handleFormChange(e.detail.value!,'campus_uuid'))}>
                    <IonSelectOption value={null}>None</IonSelectOption>
                    {campuses.map((campus:apiRes) => {
                        return (
                            <IonSelectOption key={campus.pk} value={campus.pk}>{campus.fields.campus_name}</IonSelectOption>
                        )
                    })}
                    </IonSelect>
                    <div className="error-detail">{errors.campus_uuid}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Building Name</IonLabel>
                <IonInput className={errors.building_name === '' ? "valid":'invalid'} value={form.building_name} placeholder="Name" onIonChange={e => (handleFormChange(e.detail.value!,'building_name'))}> </IonInput>
                <div className="error-detail">{errors.building_name}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Building Description</IonLabel>
                <IonTextarea auto-grow value={form.building_description} placeholder='Description' onIonChange={e => (handleFormChange(e.detail.value!,'building_description'))}> </IonTextarea>
                <div className="error-detail">{errors.building_description}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Address</IonLabel>
                <IonInput className={errors.building_address === '' ? "valid":'invalid'} value={form.building_address} placeholder="Address" onIonChange={e => (handleFormChange(e.detail.value!,'building_address'))}> </IonInput>
                <div className="error-detail">{errors.building_address}</div>
                <IonInput className={errors.building_city === '' ? "valid":'invalid'}value={form.building_city} placeholder="City" onIonChange={e => (handleFormChange(e.detail.value!,'building_city'))}> </IonInput>
                <div className="error-detail">{errors.building_city}</div>
                <IonLabel position="stacked">State</IonLabel>
                <IonSelect className={errors.building_state === '' ? "valid":'invalid'} interface='popover' value={form.building_state} placeholder="State" onIonChange={e => (handleFormChange(e.detail.value!,'building_state'))}>
                    {state_choices.map((st) => {
                        return (
                            <IonSelectOption key={st[0]} value={st[0]}>{st[1]}</IonSelectOption>
                        )
                    })}
                    </IonSelect>
                    <div className="error-detail">{errors.building_state}</div>
            </IonItem>
            <IonItem lines="none">
            <IonLabel position="stacked">Zip Code</IonLabel>
                <IonInput className={errors.building_zipcode === '' ? "valid":'invalid'} value={form.building_zipcode} placeholder="Zip Code" onIonChange={e => (handleFormChange(e.detail.value!,'building_zipcode'))}> </IonInput>
                <div className="error-detail">{errors.building_zipcode}</div>
            </IonItem>
            <div className="button-container">
            <IonItem className='form-submit-button' button color='primary' onClick={handleSubmit}>
                <IonLabel>
                  Submit
                </IonLabel>
            </IonItem>
            </div>

        </form>
    )
}

export default AE2BuildingForm