import { IonContent, IonRow, IonButton } from "@ionic/react"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { API_URL } from "../../../actions/settings"
import QuicksightEmbed from "../../extras/QuicksightEmbed"

interface Params {
    id:any
}

interface vars {
    all?:boolean
    type?:string
}

async function getDashUrl(token:string,id:any) {
    return fetch(`${API_URL}/buildings/getDashUrl/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
     }

async function getCustomerData(token:any,id:string) {
        return fetch(`${API_URL}/customer/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
    }

async function getBuildingData(token:any,id:string) {
        return fetch(`${API_URL}/buildings/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
    }

const handleCustomer = async (id:any,handleDash:any,setTitle:any) =>{
    const token = localStorage.getItem('token') || ""
    const res = await getCustomerData(`Token ${token.slice(1,-1)}`,id)
    const params = {
        CustUuid:id,
        Customer:res.customer.cust_name
    }
    setTitle(res.customer.cust_name)
    handleDash('ebf67337-d09d-4d15-a7b3-3bf963395b03',params)

}

const handleBuilding= async (id:any,handleDash:any,setTitle:any) =>{
    const token = localStorage.getItem('token') || ""
    const res = await getBuildingData(`Token ${token.slice(1,-1)}`,id)
    const params = {
        BuildingUuid:id,
        Building:res.data.building_name
    }
    setTitle(res.building_name)
    handleDash('c1496147-a95c-4b40-952c-e2cf20645f7d',params)
}



const MonthlyBuildingPerformanceTable: React.FC<vars> = ({all=false,type=''}) => {
    const { id }=useParams<Params>()
    const [title, setTitle]=useState('All Buildings')

    const handleDash = async (dashID:string,params:any) =>{
        const dashboard = new QuicksightEmbed()
        await dashboard.init()
        const token = localStorage.getItem('token') || ""
        const res = await getDashUrl(`Token ${token.slice(1,-1)}`,dashID)
        dashboard.changeSettings({
          url:res[0],
          container:`#estar-monthly-usage-overview-dashboard`,
          exportDash:true,
          undoRedo:true,
          reset:true
        })
        dashboard.setParameters(params)
        await dashboard.embedDash()

    }

    useEffect(()=>{
        if(all){
            handleDash('c1496147-a95c-4b40-952c-e2cf20645f7d',{})
        }
        if(!all && type==='customer'){
            handleCustomer(id,handleDash,setTitle)


        }
        if(!all && type==='building'){
           handleBuilding(id,handleDash,setTitle)
        }

    },[id,all,type])

    return (
        <>
        <IonRow className="ion-justify-content-center"><h1><span>{title}</span></h1></IonRow>
        <IonRow hidden={!(type==='customer')} className='ion-justify-content-center'><IonButton size="small" href={`/?id=${id}&sec=customers`}>Customer Page</IonButton></IonRow>
        <IonRow hidden={!(type==='building')} className='ion-justify-content-center'><IonButton size="small" href={`/?id=${id}&sec=buildings`}>Building Page</IonButton></IonRow>
        <IonContent className='estar-monthly-usage-overview' >
            <IonContent id="estar-monthly-usage-overview-dashboard"></IonContent>
        </IonContent>
        </>
    )
}

export default MonthlyBuildingPerformanceTable