import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { API_URL } from "../../../actions/settings";

interface Props {
  id: string;
}

const formatValue = (value:number)=> {
  const formatter = new Intl.NumberFormat('en-US',{notation:'compact',maximumFractionDigits: 1 })
  return formatter.format(value)
}

async function getData(token: string, id: string) {
  return fetch(`${API_URL}/graphs/utility_cost_trends/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return false;
    }
  });
}

const UtilityCostTrendGraph: React.FC<Props> = ({ id }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleData = async () => {
      const token = localStorage.getItem("token") || "";
      const res = await getData(`Token ${token.slice(1, -1)}`, id);
      if (res) {
        setData(res.data);
      } else {
        setError(true);
      }
    };

    handleData();
  }, [id]);

  if (!data) {
    return null;
  }
  return (
    <ResponsiveContainer width="100%" height={500}>
      {error ? (
        <div className="center-all">Could Not Load Table</div>
      ) : (
        <BarChart  data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fill: "white" }} dataKey="year" />
          <YAxis tick={{fill:'white'}} tickFormatter={formatValue} width={40}/>
          <Tooltip />
          <Legend />
          <Bar dataKey="gas" stackId="a" fill="#8884d8" />
          <Bar dataKey="electricity" stackId="a" fill="#82ca9d" />
          <Bar dataKey="demand" stackId="a" fill="#ee6b6e" />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default UtilityCostTrendGraph;
