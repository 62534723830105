import { IonContent } from "@ionic/react"
import { API_URL } from "../../../actions/settings";
import QuicksightEmbed from "../../extras/QuicksightEmbed";
import { useEffect } from "react";

async function getDashUrl(id:any,token:string) {
    return fetch(`${API_URL}/buildings/getDashUrl/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }

const EnergyPerformanceDefault: React.FC= () => {
    let params =  new URLSearchParams(window.location.search);
    const id = params.get('id')

    const getDashboard = async() =>{
        const dashboard = new QuicksightEmbed()
        await dashboard.init()
        const token:any = localStorage.getItem('token')
        const dash_url = await getDashUrl('a31f6a27-47a8-4be2-a369-1123bc1e28d3',`Token ${token.slice(1,-1)}`)
        // console.log(dash_url)
        dashboard.changeSettings({url:dash_url[0],container:`#performance-dash`,sheetID:'a31f6a27-47a8-4be2-a369-1123bc1e28d3_6392ce60-eb98-4621-94ba-3ab450c13c63'})
        dashboard.setParameters({'BuildingUuid':id})
        // console.log(dashboard.contentOptions)
        await dashboard.embedDash()
    }

    useEffect(()=>{
        getDashboard()
    })

    return(
        <IonContent id="performance-dash" style={{minHeight:'100%'}}>

        </IonContent>
    )
}

export default EnergyPerformanceDefault