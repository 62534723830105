import { IonContent,IonPage } from '@ionic/react';
import Ae2Header from '../../components/AE2/Ae2Header';
import Analytics from '../../components/AE2/AnalyticsSection/Analytics';


import './Homepage.css'
import { useAppSelector } from '../../Hooks';
import { selectUser } from '../../reducers/UserReducer';
import { isStaff } from '../../components/extras/Functions';
import { Redirect } from 'react-router';



const AnalyticsPage: React.FC = () => {
  const user = useAppSelector(selectUser)

  if(user.first_name !== '' && !isStaff(user)){
    return <Redirect to='/403'/>
  }

  return (
    <IonPage className='ae2-home' >
      <Ae2Header/>

      <IonContent className='ae2-content'>
        <Analytics/>
      </IonContent>
    </IonPage>
  );
};

export default AnalyticsPage;
