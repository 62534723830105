import { IonButton, IonContent, IonIcon, IonLoading, IonText } from "@ionic/react"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { API_URL } from "../../actions/settings"
import EstarHeader from "../../components/Energystar/EstarHeader"
import './Meter.css'
import { createOutline, create } from "ionicons/icons"
import EstarBills from "../../components/Energystar/EstarBills"

interface Params {
    meterId:string
}

async function getMeter(token:any,id:any) {

    return fetch(`${API_URL}/energystar/meters/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }


const Meter: React.FC = ()=>{

    const { meterId } = useParams<Params>();

    const [ meter, setMeter ] = useState<any>({})
    const [ loaded, setLoaded ] = useState<boolean>(false)


    const handleMeter = async (id:any) => {
        const token = localStorage.getItem('token') || ""
        const res = await getMeter(`Token ${token.slice(1,-1)}`,id)
        setMeter(res.data)
        setLoaded(true)

    }

    const getDate = (fullDate:any) =>{
        const date = new Date(fullDate)

        const string = `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`
        return string
    }

    useEffect(()=>{
        handleMeter(meterId)
    }, [meterId])
    useEffect(()=>{
        if (Object.keys(meter).length !== 0){
            setLoaded(true)
        }
    }, [meter])




    return (
        <IonContent>
            {!loaded ?
            <IonLoading
                cssClass='my-custom-class'
                isOpen={!loaded}
                message={'Loading...'}
                spinner='bubbles'
                /> :
                <>
            <EstarHeader/>
            <IonContent className="estar-meter ion-justify-content-center">
                <h1>{meter.name} {meter.inuse ? '': `(Inactive:${getDate(meter.inactivedate)})`} <IonButton fill="clear" href={`/energystar/meters/${meterId}/edit`}><IonIcon color="danger" size="large" md={create} ios={createOutline}></IonIcon></IonButton></h1>
                <IonText className="meter-info">
                    <ul>
                        <IonButton size='small' color="medium" href={`/energystar/properties/${meter.property.id}`}>Building Page</IonButton>
                        <li>Energy Star ID: {meter.meter_id}</li>
                        <li>Type of Meter: {meter.type.name}</li>
                        <li>Date of First Bill: {getDate(meter.firstbilldate)}</li>
                        <li>Metered: {meter.metered ? "Yes": "No"}</li>
                        <li>Currently In Use: {meter.inuse ? "Yes":"No"}</li>
                        {meter.inuse ? "": <li> Inactive Date: {getDate(meter.inactivedate)}</li>}
                    </ul>

                </IonText>
                <div className="Bills">
                    <EstarBills id={meterId} name={meter.name} data={meter.bills}/>
                </div>

            </IonContent></>
        }

        </IonContent>
    )

}

export default Meter