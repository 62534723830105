import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonRow } from "@ionic/react"
import { useEffect, useRef, useState } from "react"
import { API_URL } from "../../../actions/settings"
import ProviderForm from "./forms/ProviderForm"
import ServiceTypeForm from "./forms/ServiceTypeForm"
import './UtilityServiceSettings.css'
import { trashBin} from "ionicons/icons"
import TSAreaSettings from "./TSAreaSettings"
import NotificationPopUp from "../../extras/NotificationPopUp"
import EnergyCategorySettings from "./EnergyCategorySettings"
import { isMobile } from "react-device-detect"
import QuicksightDashboardsSettings from "./QuicksightDashboardsSettings"
import SuccessStories from "./SuccessStories"

async function getUtilityProvider(token:any) {

  return fetch(`${API_URL}/utility_provider`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

async function getUtilityServiceTypes(token:any) {

  return fetch(`${API_URL}/utility_service_type`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

async function deleteUtility(type:string, id:any,token:any) {

  return fetch(`${API_URL}/${type}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }



const UtilityServiceSettings:React.FC = () => {

    const [utilityProviders, setUtilityProviders] = useState([])
    const [utilityServiceType, setUtilityServiceType] = useState([])
    const [showUtilityProviderModal, setShowUtilityProviderModal] = useState(false)
    const [showUtilityServiceModal, setShowUtilityServiceTypeModal] = useState(false)
    const [opened,setOpened] = useState(false)
    const [toDelete,setToDelete] = useState<any>()
    const [tab,setTab] = useState('utility')
    const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
  const toggleAccordion = () => {
    if (!accordionGroup.current) {
      return;
    }
    const nativeEl = accordionGroup.current;

    if (nativeEl.value === 'tabs') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'tabs';
    }
  };

    useEffect(() => {
        const token = localStorage.getItem('token')
        handleUtilityProvider(token)
        handleUtilityServiceTypes(token)

    },[])

    const handleUtilityProvider = async (token:string|null) => {
        const providers = await getUtilityProvider(`Token ${token!.slice(1,-1)}`)
        setUtilityProviders(providers.data)
    }

    const handleUtilityServiceTypes = async (token:string|null) => {
        const serviceTypes = await getUtilityServiceTypes(`Token ${token!.slice(1,-1)}`)
        setUtilityServiceType(serviceTypes.data)
    }

    const handleDelete = async (type:string,id:any) =>{
        setOpened(true)
        setToDelete({type,id})
    }

    const confirmDelete = async () =>{
        const token = localStorage.getItem('token')
        const res = await deleteUtility(toDelete?.type,toDelete?.id,`Token ${token!.slice(1,-1)}`)
        if (res.status === "success"){
            if(toDelete.type==='utility_provider'){
                const filtered = utilityProviders.filter((provider:any) => provider.id !== toDelete.id)
                setUtilityProviders(filtered)
            }
            if(toDelete.type==='utility_service_type'){
                const filtered = utilityServiceType.filter((service:any) => service.id !== toDelete.id)
                setUtilityServiceType(filtered)
            }
            setToDelete('')
            setOpened(false)
            NotificationPopUp('success','Utility Service Deleted')
        }

        else{
            setToDelete('')
            setOpened(false)
            NotificationPopUp('Error',res.detail||res.ERROR,res.error)
        }
    }

    const handleSelectTab = (tab:string) => {
        setTab(tab)
    }

    return (
        <IonRow className="ion-justify-content-center utility-settings">
            <IonCol size="12" >
                <IonRow style={{borderBottom:'2px solid grey'}}>
                    <IonButtons hidden={isMobile} >
                        <IonButton style={{borderRight:'2px solid grey','--color':'white'}} color={tab==='utility' ? 'secondary':''} onClick={()=>handleSelectTab('utility')}>Utility Providers</IonButton>
                        <IonButton style={{borderRight:'2px solid grey','--color':'white'}} color={tab==='units' ? 'secondary':''} onClick={()=>handleSelectTab('units')}>Units</IonButton>
                        <IonButton style={{borderRight:'2px solid grey','--color':'white'}} color={tab==='TSA' ? 'secondary':''} onClick={()=>handleSelectTab('TSA')}>Technical Solution Areas</IonButton>
                        <IonButton style={{borderRight:'2px solid grey','--color':'white'}} color={tab==='Dashboards' ? 'secondary':''} onClick={()=>handleSelectTab('Dashboards')}>Quicksight Dashboards</IonButton>
                        <IonButton style={{borderRight:'2px solid grey','--color':'white'}} color={tab==='SuccessStories' ? 'secondary':''} onClick={()=>handleSelectTab('SuccessStories')}>Success Stories</IonButton>
                    </IonButtons>
                    <IonCol size='12' hidden={!isMobile}>
                    <IonAccordionGroup style={{minWidth:'100% !important'}} ref={accordionGroup}>
                        <IonAccordion value="tabs" >
                            <IonItem slot="header">
                                <IonLabel>Pages</IonLabel>
                            </IonItem>
                            <div slot="content" style={{'display':'block'}} >

                                    <IonButton expand="block" fill={tab==='utility' ? 'solid':'outline'} style={{'--color':'black','minWidth':'80%'}} color={tab==='utility' ? 'secondary':''} onClick={()=>{handleSelectTab('utility');toggleAccordion()}}>Utility Providers</IonButton>
                                    <IonButton expand="block" fill={tab==='units' ? 'solid':'outline'} style={{'--color':'black','minWidth':'80%'}} color={tab==='units' ? 'secondary':''} onClick={()=>{handleSelectTab('units');toggleAccordion()}}>Units</IonButton>
                                    <IonButton expand="block" fill={tab==='TSA' ? 'solid':'outline'} style={{'--color':'black','minWidth':'80%'}} color={tab==='TSA' ? 'secondary':''} onClick={()=>{handleSelectTab('TSA');toggleAccordion()}}>Technical Solution Areas</IonButton>
                                    <IonButton expand="block" fill={tab==='Dashboards' ? 'solid':'outline'} style={{'--color':'black','minWidth':'80%'}} color={tab==='Dashboards' ? 'secondary':''} onClick={()=>{handleSelectTab('Dashboards');toggleAccordion()}}>Quicksight Dashboards</IonButton>
                                    <IonButton expand="block" fill={tab==='SuccessStories' ? 'solid':'outline'} style={{'--color':'black','minWidth':'80%'}} color={tab==='SuccessStories' ? 'secondary':''} onClick={()=>{handleSelectTab('SuccessStories');toggleAccordion()}}>Success Stories</IonButton>

                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                    </IonCol>
                </IonRow>
            </IonCol>
            <IonCol sizeMd="12" hidden={tab!=='utility'}>
                <IonRow>
                    <ProviderForm opened={showUtilityProviderModal} setShowUtilityProviderModal={setShowUtilityProviderModal} setUtilityProviders={setUtilityProviders}/>
                    <ServiceTypeForm opened={showUtilityServiceModal} setShowUtilityServiceTypeModal={setShowUtilityServiceTypeModal} setUtilityServiceTypes={setUtilityServiceType}/>
                <IonCol sizeMd="6">
                <IonListHeader className="admin-utility">Utility Providers</IonListHeader>
                <IonList>
                    {utilityProviders.map((provider:any)=>{
                        return(
                            <IonItem key={'prov'+provider.id}>{provider.name} <IonButton color="danger" slot="end" onClick={() => handleDelete('utility_provider',provider.id)}><IonIcon src={trashBin}></IonIcon></IonButton></IonItem>
                        )
                    })}
                </IonList>
                <IonButton expand="full" color="primary" id="addProvider" onClick={()=>setShowUtilityProviderModal(true)}>Add Utility Provider</IonButton>
                </IonCol> <IonCol sizeMd="6">
                <IonListHeader className="admin-utility">Utility Service Types</IonListHeader>
                <IonList>
                    {utilityServiceType.map((service:any)=>{
                        return(
                            <IonItem key={'service'+service.id} >{service.name}<IonButton color="danger" slot="end" onClick={() => handleDelete('utility_service_type',service.id)}><IonIcon src={trashBin}></IonIcon></IonButton></IonItem>
                        )
                    })}
                </IonList>
                <IonButton expand="full" color="primary" id="addService" onClick={()=>setShowUtilityServiceTypeModal(true)}> Add Utility Service Type</IonButton>
                </IonCol>
                </IonRow>
            </IonCol>
            <IonCol sizeMd="6" hidden={tab!=='TSA'}>
                <TSAreaSettings/>
            </IonCol>
            <IonCol sizeMd="6" hidden={tab!=='units'}>
                        <EnergyCategorySettings />
            </IonCol>
            <IonCol sizeMd="10" hidden={tab!=='Dashboards'}>
                        <QuicksightDashboardsSettings/>
            </IonCol>
            <IonCol sizeMd="10" hidden={tab!=='SuccessStories'}>
                <SuccessStories/>
            </IonCol>
            <IonModal isOpen={opened} onDidDismiss={()=>setOpened(false)} className="confirmDelete">
                <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="delete-warning">
                                Are You Sure You Want To Delete This?
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonButton color="danger" onClick={()=> confirmDelete()} >Delete</IonButton>
                            <IonButton color="medium" onClick={ ()=> setOpened(false)} >Cancel</IonButton>
                        </IonRow>
                </IonGrid>
            </IonModal>
        </IonRow>
    )
}

export default UtilityServiceSettings