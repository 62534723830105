import { IonRow, IonCol } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../actions/settings";

async function getData(token: string, id: string) {
  return fetch(`${API_URL}/customer/${id}/savings_overview`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return false;
    }
  });
}


interface Props {
  id:string
}

const OverviewSummary: React.FC<Props> = ({ id }) => {
  const [data, setData] = useState<any>({
    cost_avoidance:0,
    incentives:0,
    ghg:0,
    cars:0
  });

  useEffect(() => {
    const handleData = async () => {
      const token = localStorage.getItem("token") || "";
      const res = await getData(`Token ${token.slice(1, -1)}`, id);
      if (res) {
        setData(res.data);
      }
    };
    handleData();
  }, [id]);

  return (
    <IonRow style={{ height: "100%" }}>
      <IonCol size="12">
        <h2 style={{ textAlign: "center" }}>Energy Waste Avoided</h2>
        <div style={{ textAlign: "center" }}>${data.cost_avoidance.toLocaleString('en')}</div>
      </IonCol>
      <IonCol size="12">
        <h2 style={{ textAlign: "center" }}>Utility Grants Received</h2>
        <div style={{ textAlign: "center" }}>
          ${data.incentives.toLocaleString("en")}
        </div>
      </IonCol>
      <IonCol size="12">
        <h2 style={{ textAlign: "center" }}>Energy Star Certified</h2>
        <div style={{ textAlign: "center" }}>{data.certification ? `${data.certification}%` : 'n/a'}</div>
      </IonCol>
      <IonCol size="12">
        <h2 style={{ textAlign: "center" }}>Carbon Emission Reduced</h2>
        <div style={{ textAlign: "center" }}>{data.ghg ? `${data.ghg.toLocaleString('en')}%` : 'n/a'}</div>
      </IonCol>
      <IonCol size="12">
        <h2 style={{ textAlign: "center" }}>Equivalent Cars Off the Road</h2>
        <div style={{ textAlign: "center" }}>{data.cars ? `${data.cars.toLocaleString('en')}` : 'n/a'}</div>
      </IonCol>
    </IonRow>
  );
};

export default OverviewSummary;
