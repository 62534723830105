import { IonContent,IonPage } from '@ionic/react';
import AdminSettings from '../../components/AE2/AdminSettings';
import Ae2Header from '../../components/AE2/Ae2Header';
import AE2Software from '../../components/AE2/AE2Software/AE2Software';
import AE2SoftwareDocs from '../../components/AE2/AE2Software/AE2SoftwareDocs';
import AE2SoftwareDocsList from '../../components/AE2/AE2Software/AE2SoftwareDocsList';
import AE2SoftwareReleases from '../../components/AE2/AE2Software/AE2SoftwareReleases';
import Analytics from '../../components/AE2/AnalyticsSection/Analytics';
import GeneralSettings from '../../components/AE2/GeneralSettings';
import SecuritySettings from '../../components/AE2/SecuritySettings';

import './Homepage.css'
import EnergyStarData from '../../components/Energystar/EnergyStarData';
import { useAppSelector } from '../../Hooks';
import { selectUser } from '../../reducers/UserReducer';
import { isStaff } from '../../components/extras/Functions';
import NewAE2Account from '../../components/AE2/AdminSection/NewAE2Account';
import usePermissionGroups from '../../utils/usePermissionGroups';
// async function getItemApi(token:any,id:any,section:String) {

//   return fetch(`${API_URL}/${section}/${id}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//       'Authorization':token
//     }

//   })
//     .then(data => data.json())
//  }

// const getItemData = async  (token:any,id:any,section:string,setItem:any) => {
//   const item = await getItemApi(`Token ${token.slice(1,-1)}`,id,section)
//   setItem(item)
// }



const Account: React.FC = () => {

  let params =  new URLSearchParams(window.location.search);
  const tab = params.get('tab')
  const software_id = params.get('software_id')
  const user = useAppSelector(selectUser)
  const {hasPermission} = usePermissionGroups()


  if(user.username !== ""){


  return (
    <IonPage className='ae2-home' >
      <Ae2Header/>

      <IonContent className='ae2-content'>
        { tab === 'general' ? <GeneralSettings/> : '' }
        { tab === 'security' ? <SecuritySettings/> : '' }
        { tab === 'admin' ? <AdminSettings/> : '' }
        { tab === 'analytics' ? <Analytics/>:''}
        { tab === 'software' ? software_id ? <AE2SoftwareReleases/> :<AE2Software/>:''}
        { tab === 'software_docs' ? <AE2SoftwareDocs/> : ''}
        { tab === 'software_docs_all' ? <AE2SoftwareDocsList/> : ''}
        { tab === 'estar-data' ? (isStaff(user) ? <EnergyStarData/> :'You Do Not Have Permission To Access This Tab.') : null}
        { tab === 'new_user' ? (hasPermission(['createAE2CustomerAccount']) ? <NewAE2Account/> :'You Do Not Have Permission To Access This Tab.') : null}


      </IonContent>

    </IonPage>
  );
  }
  return null
};

export default Account;
