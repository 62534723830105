import { IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
estimatesApplied : "",
estimatesApplied_date : "",
coolingEquipmentRedundancy : "",
coolingEquipmentRedundancy_date : "",
itEnergyMeterConfiguration : "",
itEnergyMeterConfiguration_date : "",
upsSystemRedundancy:"",
upsSystemRedundancy_date:""

}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
estimatesApplied : "",
estimatesApplied_date : "",
coolingEquipmentRedundancy : "",
coolingEquipmentRedundancy_date : "",
itEnergyMeterConfiguration : "",
itEnergyMeterConfiguration_date : "",
upsSystemRedundancy:"",
upsSystemRedundancy_date:""
}

const DataCenter: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('estimatesApplied'):
                if (value !== ''){
                    form.estimatesApplied_date === '' ? handleError('estimatesApplied_date', 'Date cannot be empty') : handleError('estimatesApplied_date', ''); handleError('itEnergyMeterConfiguration', '')
                }
                break;
            case ('estimatesApplied_date'):
                if (value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('estimatesApplied_date', 'Invalid Date') : handleError('estimatesApplied_date', '')
                    form.estimatesApplied === '' ? handleError('estimatesApplied', 'Field Required') : handleError('estimatesApplied', '')
                }
                break;
            case ('coolingEquipmentRedundancy'):
                if (value !== ''){
                    form.coolingEquipmentRedundancy_date === '' ? handleError('coolingEquipmentRedundancy_date', 'Date cannot be empty') : handleError('coolingEquipmentRedundancy_date', ''); handleError('itEnergyMeterConfiguration', '')
                }
                break;
            case ('coolingEquipmentRedundancy_date'):
                if (value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('coolingEquipmentRedundancy_date', 'Invalid Date') : handleError('coolingEquipmentRedundancy_date', '')
                    form.coolingEquipmentRedundancy === '' ? handleError('coolingEquipmentRedundancy', 'Field Required') : handleError('coolingEquipmentRedundancy', '')
                }
                break;
            case ('itEnergyMeterConfiguration'):
                if (value !== ''){
                    form.itEnergyMeterConfiguration_date === '' ? handleError('itEnergyMeterConfiguration_date', 'Date cannot be empty') : handleError('itEnergyMeterConfiguration_date', ''); handleError('itEnergyMeterConfiguration', '')
                }
                break;
            case ('itEnergyMeterConfiguration_date'):
                if (value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('itEnergyMeterConfiguration_date', 'Invalid Date') : handleError('itEnergyMeterConfiguration_date', '')
                    form.itEnergyMeterConfiguration === '' ? handleError('itEnergyMeterConfiguration', 'Field Required') : handleError('itEnergyMeterConfiguration', '')
                }
                break;
            case ('upsSystemRedundancy'):
                if (value !== ''){
                    form.upsSystemRedundancy === '' ? handleError('upsSystemRedundancy_date', 'Date cannot be empty') : handleError('upsSystemRedundancy_date', ''); handleError('itEnergyMeterConfiguration', '')
                }
                break;
            case ('upsSystemRedundancy_date'):
                if (value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('upsSystemRedundancy_date', 'Invalid Date') : handleError('upsSystemRedundancy_date', '')
                    form.upsSystemRedundancy === '' ? handleError('upsSystemRedundancy', 'Field Required') : handleError('upsSystemRedundancy', '')
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Data Center Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" color="white" ios={starOutline} md={star}/> Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Estimates Applied?</IonLabel>
                        <IonSelect className={errors.estimatesApplied === '' ? "valid":'invalid'}  value={form.estimatesApplied} interface='popover' placeholder='Estimates?' onIonChange={e => (handleFormChange(e.detail.value!,'estimatesApplied'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.estimatesApplied}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.estimatesApplied_date === '' ? "valid":'invalid'}  value={form.estimatesApplied_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'estimatesApplied_date'))}></IonInput>
                        <div className="error-detail">{errors.estimatesApplied_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Cooling Equipment Redundancy</IonLabel>
                        <IonSelect className={errors.coolingEquipmentRedundancy === '' ? "valid":'invalid'}  value={form.coolingEquipmentRedundancy} interface='popover' onIonChange={e => (handleFormChange(e.detail.value!,'coolingEquipmentRedundancy'))}>
                            <IonSelectOption>N</IonSelectOption>
                            <IonSelectOption>N + 1</IonSelectOption>
                            <IonSelectOption>N + 2</IonSelectOption>
                            <IonSelectOption>2N</IonSelectOption>
                            <IonSelectOption>Greater than 2N</IonSelectOption>
                            <IonSelectOption>None of the Above</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.coolingEquipmentRedundancy}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.coolingEquipmentRedundancy_date === '' ? "valid":'invalid'}  value={form.coolingEquipmentRedundancy_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'coolingEquipmentRedundancy_date'))}></IonInput>
                        <div className="error-detail">{errors.coolingEquipmentRedundancy_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"><IonIcon slot="start" color="white" ios={starOutline} md={star}/> IT Energy Meter Configuration</IonLabel>
                        <IonSelect className={errors.itEnergyMeterConfiguration === '' ? "valid":'invalid'}  value={form.itEnergyMeterConfiguration} interface='popover' onIonChange={e => (handleFormChange(e.detail.value!,'itEnergyMeterConfiguration'))}>
                            <IonSelectOption>UPS Supports Only IT Equipment</IonSelectOption>
                            <IonSelectOption>UPS Include Non IT Load Less Than 10%</IonSelectOption>
                            <IonSelectOption>UPS Include Non-IT Load Greater Than 10% Load Submetered</IonSelectOption>
                            <IonSelectOption>UPS Include Non IT Load Greater Than 10% Load Not Submetered</IonSelectOption>
                            <IonSelectOption>Facility Has No UPS</IonSelectOption>
                            <IonSelectOption>No IT Energy Configuration Selected</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.itEnergyMeterConfiguration}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.itEnergyMeterConfiguration_date === '' ? "valid":'invalid'}  value={form.itEnergyMeterConfiguration_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'itEnergyMeterConfiguration_date'))}></IonInput>
                        <div className="error-detail">{errors.itEnergyMeterConfiguration_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> UPS System Redundancy</IonLabel>
                        <IonSelect className={errors.upsSystemRedundancy === '' ? "valid":'invalid'}  value={form.upsSystemRedundancy} interface='popover' onIonChange={e => (handleFormChange(e.detail.value!,'upsSystemRedundancy'))}>
                            <IonSelectOption>N</IonSelectOption>
                            <IonSelectOption>N + 1</IonSelectOption>
                            <IonSelectOption>N + 2</IonSelectOption>
                            <IonSelectOption>2N</IonSelectOption>
                            <IonSelectOption>Greater than 2N</IonSelectOption>
                            <IonSelectOption>None of the Above</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.upsSystemRedundancy}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.upsSystemRedundancy_date === '' ? "valid":'invalid'}  value={form.upsSystemRedundancy_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'upsSystemRedundancy_date'))}></IonInput>
                        <div className="error-detail">{errors.upsSystemRedundancy_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default DataCenter