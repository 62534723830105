import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import randomColor from 'randomcolor'
import { API_URL } from "../../../actions/settings";

interface Props {
    id:string
}

async function getData(token: string, id: string) {
  return fetch(`${API_URL}/customer/${id}/annual_cost_summary`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return false;
    }
  });
}

function getAbbreviation(name: string): string {
  const words = name.split(' ');
  const initials = words.map(word => word[0].toUpperCase());
  return initials.join('');
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={450} dy={0} textAnchor="middle" fill={fill} >
          {payload.name}
        </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="white"
      >{`${getAbbreviation(payload.name)}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        ${value.toLocaleString('en')}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={36}
        textAnchor={textAnchor}
        fill="#999"
      >
        {Math.round(percent * 10000)/100}%
      </text>
    </g>
  );
};

const CostsSummaryPieChart: React.FC<Props> = ({id}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [COLORS, setCOLORS] = useState<any>([])
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState(false);

  const onPieEnter = useCallback(
    (_: any, index: any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  useEffect(() => {
    const handleData = async () => {
      const colors:any = []
      const token = localStorage.getItem("token") || "";
      const res = await getData(`Token ${token.slice(1, -1)}`, id);
      if (res) {
        setData(res.data.graph_data);
      } else {
        setError(true);
      }
      console.log(res.data.graph_data.length)
      for( var i=0;i<res.data.graph_data.length;i++){
        colors.push(randomColor())
      }
      setCOLORS(colors)
    };

    handleData();
  }, [id]);

  if(error){
    return <div className="center-all">Could Not Load Table</div>
  }


  return (
    <ResponsiveContainer width="100%" height={500}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          outerRadius='50%'
          fill="#8884d8"
          dataKey="energycost"
          onMouseEnter={onPieEnter}
          cy={215}
        >
          {data.map((entry:any, index:any) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CostsSummaryPieChart;
