import S3 from 'aws-sdk/clients/s3';
import { REACT_APP_AWS_KEY_BUCKET, REACT_APP_AWS_REGION, REACT_APP_AWS_SECRET_BUCKET } from '../../actions/settings';

async function AE2SoftwareUpload(bucket_name:string,filename:string,file:any,contentType:any) {

    const bucket = new S3(
      {
        accessKeyId: REACT_APP_AWS_KEY_BUCKET,
        secretAccessKey: REACT_APP_AWS_SECRET_BUCKET,
        region: REACT_APP_AWS_REGION
      }
    );
    const params = {
      Bucket: bucket_name || '',
      Key: filename,
      Body: file,
      ContentType: contentType
    };

    return bucket.upload(params, function (err:any, data:any) {

      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      console.log('Successfully uploaded file.', data);
      return true;
    }).promise();

}

export default AE2SoftwareUpload