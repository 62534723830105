import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import {LoginForm} from '../components/LoginForm';
import './Login.css';

interface FuncProps{
  setToken: any
}

const Login: React.FC<FuncProps> = (FuncProps) => {
  const {setToken} = FuncProps

  return (
    <IonPage className='login-pg'>
      <IonHeader>
        <IonToolbar>
          <div className='toolbar'>
          <IonTitle >Alpha Enterprise Server (AE2)</IonTitle>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className='login-content' data-testid="login-card">
        <LoginForm setToken={setToken} />
      </IonContent>
    </IonPage>
  );
};

export default Login;
