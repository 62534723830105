import { IonButton, IonItem, IonGrid, IonRow, IonLoading } from "@ionic/react"
import { useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { API_URL } from "../../actions/settings"
import SingleMeterForm from "./SingleMeterForm"
import CheckRole from "../extras/CheckRole"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import NotificationPopUp from "../extras/NotificationPopUp"

interface Params{
    id:string
}

const startingForm = {
    type:"",
    name:"",
    unitofmeasure:"",
    metered:false,
    firstbilldate:"",
    inuse:false,
    inactivedate:null
}
const startingErrors = {
    type:true,
    name:true,
    unitofmeasure:true,
    metered:true,
    firstbilldate:true,
    inuse:true,
    inactivedate:true
}


async function getMeter(token:any,id:string) {

    return fetch(`${API_URL}/energystar/properties/${id}/meter`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },

    })
      .then(data => data.json())
   }

async function editMeter(token:any,id:string,data:any) {

    return fetch(`${API_URL}/energystar/properties/${id}/meter`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data[0])

    })
      .then(data => data.json())
   }

const validate = (errors:any) => {
    for (const res of errors){
        for(const val of Object.values(res)){
            if( val === false){
                return false
            }
        }
    }

    return true
}




const EditMeterForm: React.FC = () =>{

    const { id } = useParams<Params>();
    const history = useHistory()
    const handleDelete = false
    const user = useAppSelector(selectUser)
    const [ forms, setForms ] = useState([startingForm])
    const [ errors, setErrors ] = useState([startingErrors])
    const [ loaded, setLoaded ] = useState(false)

    useEffect(()=>{
        const handleGetMeter = async () =>{
            const token = localStorage.getItem('token') || ""
            const meter = await getMeter(`Token ${token.slice(1,-1)}`,id)
            const fDate = new Date(meter.data.firstbilldate)
            setForms([{
                type:meter.data.type.id,
                name:meter.data.name,
                unitofmeasure:meter.data.unitofmeasure,
                metered:meter.data.metered,
                firstbilldate:`${fDate.getFullYear()}-${( '0'+fDate.getMonth()+1).slice(-2)}-${('0'+fDate.getDate()).slice(-2)}`,
                inuse:meter.data.inuse,
                inactivedate:meter.data.inactivedate
            }])
            setLoaded(true)

        }

        handleGetMeter()
    },[id])





    const handleFormChange = (index:number,values:any) => {
        forms[index] = values
        setForms([...forms])
    }




    const handleSubmit = async() => {
        const token = localStorage.getItem('token') || ""
        const valid = validate(errors)
        if (valid){
        const res = await editMeter(`Token ${token.slice(1,-1)}`,id,forms)
        if (res.status === "success"){
            setForms([startingForm])
            NotificationPopUp('success','Meter Edited')

            history.push(`/energystar/meters/${id}`)
        }
        else{
            NotificationPopUp('error',res.detail||res.error)
        }
        }
        else{
            NotificationPopUp('error','Please Fill Out Entire Form','Form Not Complete')
        }
    }



    if (!loaded || user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={!loaded}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }


    return (
        <IonGrid>
            <IonRow className="form-title-row" ><h2>Edit Meter</h2> <IonButton className="cancel-btn" size="small"  color="danger" href={`/energystar/meters/${id}`}> Cancel </IonButton></IonRow>
        <IonItem key={0}>
        <SingleMeterForm index={0} onChange={handleFormChange} values={forms[0]} valid={errors} setValid={setErrors} handleDelete={handleDelete}/>
        </IonItem>
        <IonRow>
            <IonButton type="submit" onClick={handleSubmit}>Submit</IonButton>
        </IonRow>
        </IonGrid>

    )
}

export default EditMeterForm