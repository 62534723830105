import React from 'react'
import Logout from '../auth/Logout'


const CustomerNavs: React.FC  = () => {
  return <>
  <div>Savings</div>
  <Logout/></>
}

export default CustomerNavs