import { IonButton, IonHeader, IonItem, IonModal, IonSelect, IonSelectOption, IonTextarea, IonTitle } from "@ionic/react"
import { API_URL } from "../../../../actions/settings"
import { useEffect, useState } from "react";
import NotificationPopUp from "../../../extras/NotificationPopUp"
import useForm from "../../../../utils/useForm"
import IDSearchSelectInput from "../../../extras/IDSearchSelectInput"

interface props{
    opened:boolean,
    setShowModal:any,
    setData:any,
    edit?:string,
    setEdit?:any
}
interface types {
    uuid:any,
    property_type:number,
    sub_type_name:string

}

async function addSuccessStory(data:any,token:any) {

    return fetch(`${API_URL}/success_story`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }

async function editSuccessStory(data:any,token:any,id:string) {

    return fetch(`${API_URL}/success_story/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }

   async function getAllMarketSegments(token:any) {


    return fetch(`${API_URL}/energystar/primaryfunctions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

   async function getAllMarketSubSegments(token:any) {


    return fetch(`${API_URL}/subtypes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function getBuildings(token:any) {

    return fetch(`${API_URL}/buildings`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }
async function getStory(token:any,id:any) {

    return fetch(`${API_URL}/success_story/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function getEmployees(token:any) {

    return fetch(`${API_URL}/employees`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function getKeywords(token:any) {

    return fetch(`${API_URL}/success_keyword`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const handleMarketSegments = async (token:any, setPrimaryfunctions:any) => {
    const primaryfunctions = await getAllMarketSegments(`Token ${token.slice(1,-1)}`)
    setPrimaryfunctions(primaryfunctions)
    }
const handleMarketSubSegments = async (token:any, setSubTypes:any) => {
        const subtypes = await getAllMarketSubSegments(`Token ${token.slice(1,-1)}`)
        setSubTypes(subtypes)
    }
const handleEmployees = async (token:any, setEmployees:any) => {
        const employees = await getEmployees(`Token ${token.slice(1,-1)}`)
        setEmployees(employees.data)
    }
const handleKeywords = async (token:any, setKeywords:any) => {
        const employees = await getKeywords(`Token ${token.slice(1,-1)}`)
        setKeywords(employees.data)
    }
const handleBuildings = async (token:any, setBuildings:any) => {
        const buildings = await getBuildings(`Token ${token.slice(1,-1)}`)
        let arr:any = []
          for(let building of JSON.parse(buildings)){
            arr.push({
              id:building['pk'],
              name:building['fields']['building_name']
            })
        setBuildings(arr)
    }
}
const handleGetStory = async (token:any,id:any,loadForm:any) => {
    const story = await getStory(`Token ${token.slice(1,-1)}`,id)
    loadForm(story.data)
}

const startForm = {
    "market_segment":"",
    "market_sub_segment":"",
    "location":"",
    "description":"",
    "representative":"",
    "keywords":[]
}
const errorsForm:any = {
    "market_segment":"",
    "market_sub_segment":"",
    "location":"",
    "description": "",
    "keywords":"",
    "representative":"",
}

const validate = (name:string, val:any) => {
    switch (name) {
        case 'customer':
            if(val === null || val.length <1){
                return {customer:'Must Select A Customer'}
            }
            else{
                return {customer:''}
            }
        case 'technology':
            if(val === null || val.length <1){
                return {technology:'Must Select A Technology'}
            }
            else{
                return {technology:''}
            }
        case 'story':
            if(val === null || val.length <1){
                return {story:'Story cannot be empty'}
            }
            else{
                return {story:''}
            }
        case 'location':
            if(val === null || val.length <1){
                return {location:'Location cannot be empty'}
            }
            else{
                return {location:''}
            }
        case 'full_story':
            if(val === null || val.length <1){
                return {full_story:'Full Story cannot be empty'}
            }
            else{
                return {full_story:''}
            }


        default:
            break;
    }
}

const SuccessStoryForm:React.FC<props> = ({opened,setShowModal,setData,edit,setEdit}) => {
    const {form, handleFormChange, errors, resetForm, validateForm,loadForm} = useForm(startForm,errorsForm,validate)
    const [buildings,setBuildings] = useState([])
    const [employees,setEmployees] = useState([])
    const [marketSegments,setMarketSegments] = useState([])
    const [marketSubSegments,setMarketSubSegments] = useState([])
    const [keywords, setKeywords] = useState([])

    useEffect(()=>{


        const token = localStorage.getItem('token') || ""
        handleBuildings(token,setBuildings);
        handleEmployees(token,setEmployees)
        handleMarketSegments(token,setMarketSegments)
        handleMarketSubSegments(token,setMarketSubSegments)
        handleKeywords(token,setKeywords)
        if(edit){
            handleGetStory(token,edit,loadForm)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[edit])

    const handleSubmit = async (form:any) => {
        const token:any = localStorage.getItem('token')
        const valid = validateForm() || false
        if(valid){
            let res:any = null
            edit ?
                res = await editSuccessStory(form,`Token ${token.slice(1,-1)}`,edit)
            :
                res = await addSuccessStory(form,`Token ${token.slice(1,-1)}`)
            if (res?.status === "success"){
                setShowModal(false)
                resetForm()
                if(edit){
                    setData((prevState:any)=>{
                        let state = prevState.map((item:any)=>{
                            return res.data.id === item.id ? res.data : item
                        })
                        return [...state]
                    })
                    NotificationPopUp('success','Story Edited')
                    setEdit('')
                }else{
                    setData((prevState:any) => {
                    return [
                        ...prevState,
                        res?.data
                    ]
                    })
                    NotificationPopUp('success','Story Added')
                }

            }
            else{
                NotificationPopUp('Error',res.detail||res.ERROR,res.error)
            }
        }
    }

    return (
        <IonModal id="storyModal" isOpen={opened} onDidDismiss={()=>{setShowModal(false)}}>
            <IonHeader><IonTitle>Add Success Story</IonTitle></IonHeader>
            <form onSubmit={e=>e.preventDefault()}>
                <IonItem>
                    <IDSearchSelectInput dict={marketSegments} label="Market Segment" dict_key='id' value='name' placeholder="Select Market Segment" form={form} formName="market_segment" errors={errors.market_segment} handleFormChange={handleFormChange}/>
                </IonItem>
                {Number(form.market_segment) === 30 || Number(form.market_segment) === 48 ?
                    <IonItem>

                        <IonSelect className={errors.market_segment === '' ? "valid":'invalid'} label='Market Segment Sub Type' interface='popover' value={form.market_sub_segment} placeholder="Type" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'market_sub_segment'))}>
                            {marketSubSegments.map((item:types,index) => {

                               if(item.property_type === Number(form.market_segment)){
                                    return(<IonSelectOption key={item.uuid} value={item.uuid}>{item.sub_type_name}</IonSelectOption>)
                                }
                                else {
                                    return( <>""</>)
                                }

                            })}
                            </IonSelect>
                            <div className="error-detail">{errors.market_segment}</div>
                    </IonItem>
                : ""}
                <IonItem>
                    <IDSearchSelectInput dict={buildings} label="Location" dict_key='id' value='name' placeholder="Select Location" form={form} formName="location" errors={errors.location} handleFormChange={handleFormChange}/>
                </IonItem>
                <IonItem lines="none">
                    <IonTextarea
                        label="Story"
                        labelPlacement="floating"
                        counter={true}
                        value={form.description}
                        maxlength={500}
                        counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
                        autoGrow={true}
                        onIonInput={(e)=> handleFormChange(e.detail.value!,'description')}/>
                    {/* <IonInput className={errors.description === '' ? "valid":'invalid'} aria-label="story" value={form.description} type="text" placeholder="Story" onIonChange={(e)=> handleFormChange(e.detail.value!,'description')}></IonInput> */}
                    <div className="error-detail">{errors.description}</div>
                </IonItem>
                <IonItem>
                    <IDSearchSelectInput
                    dict={employees} label="Representative" dict_key='id' value='fullname' placeholder="Select Representative" form={form} formName="representative" errors={errors.representative} handleFormChange={handleFormChange}/>
                </IonItem>
                <IonItem>
                    <IonSelect
                        label="Keywords"
                        placeholder="Select All Keywords That Apply"
                        multiple={true}
                        value={form.keywords}
                        onIonChange={e=>(handleFormChange(e.detail.value!,'keywords'))}
                        >
                            {keywords.map((item:any) => {
                                return(<IonSelectOption key={item.id} value={item.id}>
                                    {item.keyword} {item.abbreviation ? `(${item.abbreviation})` : null}
                                </IonSelectOption>)

                            })}
                    </IonSelect>
                    <div className="error-detail">{errors.keywords}</div>
                </IonItem>

            </form>
            <div className='buttons'>
                {edit ? <IonButton onClick={()=>handleSubmit(form)} >Edit</IonButton> :
                <IonButton onClick={()=>handleSubmit(form)} >Add</IonButton>}
                <IonButton onClick={()=>setShowModal(false)} color='danger'>cancel</IonButton>
            </div>
        </IonModal>
    )
}

export default SuccessStoryForm