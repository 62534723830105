import { S3Client, GetObjectCommand} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { REACT_APP_AWS_KEY_BUCKET, REACT_APP_AWS_REGION, REACT_APP_AWS_SECRET_BUCKET } from '../../actions/settings';

async function AE2SoftwareDownload(path:string,filename:string) {

    const path_params = path.split('/')
    const bucket = new S3Client({
        region: REACT_APP_AWS_REGION,
        credentials:{
        accessKeyId: REACT_APP_AWS_KEY_BUCKET || '',
        secretAccessKey: REACT_APP_AWS_SECRET_BUCKET || '',
        }
      }
    );
    const params = {
      Bucket: path_params[0] || '',
      Key: path_params.slice(1).join('/') +'/'+filename
    };
    const command = new GetObjectCommand(params)

    try{
        // const res = await bucket.getSignedUrlPromise(GetObjectCommand,params)
        const url = await getSignedUrl(bucket, command, { expiresIn: 3600 });
        window.location.assign(url)
        return {'Success':'File Downloaded'}
    }catch (error){
        console.log(error)
        return {'Error':'Could not Download File'}
    }

}

export default AE2SoftwareDownload