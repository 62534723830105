import { IonButton, IonContent, IonPage, IonRow } from "@ionic/react";
import Ae2Header from "../../components/AE2/Ae2Header";
import './errors.css'


const NotFound:React.FC = ()=>{

    return (<IonPage>
            <Ae2Header/>
            <IonContent className="ae2-content">
                <IonRow className="ion-justify-content-center">
                    <div>
                    <h1 className="http-404">404</h1>
                    <h2 className="http-msg">Page Not Found</h2>
                    <IonRow className="ion-justify-content-center"><IonButton color="secondary" href="/">Back Home</IonButton></IonRow>
                    </div>
                </IonRow>
            </IonContent>
    </IonPage>)
}

export default NotFound