import { useState } from "react";

export default function useHandleState(startValue:any,data_only=false){
    const [state,setState] = useState<any>(startValue)

    const handleState = async (token:string,func:(token:string,id?:string)=>Promise<any>,id?:string) => {
        let res = null
        if (id){
            res = await func(`Token ${token.slice(1,-1)}`,id)
        }
        else{
            res = await func(`Token ${token.slice(1,-1)}`)
        }
        try{
           if(data_only)
           {
            setState(JSON.parse(res))
           }else{
            setState(JSON.parse(res.data))
           }

        }
        catch(error){
            if(data_only)
           {
            setState(res)
           }else{
            setState(res.data)
           }

        }
    }


    return [state,handleState,setState]
}