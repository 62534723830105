import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonList, IonListHeader, IonModal, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../../actions/settings"
import './UtilityServiceSettings.css'
import { trashBin} from "ionicons/icons"
import TSAreaForm from "./forms/TSAreaForm"
import NotificationPopUp from "../../extras/NotificationPopUp"

async function getTSArea(token:any) {

  return fetch(`${API_URL}/ts_area`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }
async function deleteTSArea(id:any,token:any) {

  return fetch(`${API_URL}/ts_area/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }



const TSAreaSettings:React.FC = () => {

    const [tsArea,setTSArea]= useState([])
    const [showTSAreaModal, setShowTSAreaModal] = useState(false)
    const [opened,setOpened] = useState(false)
    const [toDeleteID,setToDeleteID] = useState<any>()

    useEffect(() => {
        const token = localStorage.getItem('token')
        handleTSArea(token)

    },[])

    const handleTSArea = async (token:string|null) => {
        const areas = await getTSArea(`Token ${token!.slice(1,-1)}`)
        setTSArea(areas.data)
    }

    const handleDelete = async (id:any) =>{
        setOpened(true)
        setToDeleteID(id)
    }

    const confirmDelete = async () =>{
        const token = localStorage.getItem('token')
        const res = await deleteTSArea(toDeleteID,`Token ${token!.slice(1,-1)}`)
        if (res.status === "success"){
            const filtered = tsArea.filter((provider:any) => provider.id !== toDeleteID)
            setTSArea(filtered)

            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('Success','Item Deleted')
        }

        else{
            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('error',res.detail||res.ERROR,res.error)
        }
    }
    return (
        <IonRow className="ion-justify-content-center utility-settings">
                <TSAreaForm opened={showTSAreaModal} setShowTSAreaModal={setShowTSAreaModal} setTSArea={setTSArea}/>
            <IonCol>
                <IonListHeader className="admin-utility">Technical Solution Areas</IonListHeader>
                <IonList>
                    {tsArea.map((area:any)=>{
                        return(
                            <IonItem key={'prov'+area.id} >{area.name} <IonButton color="danger" slot="end" onClick={() => handleDelete(area.id)}><IonIcon src={trashBin}></IonIcon></IonButton></IonItem>
                        )
                    })}
                </IonList>
                <IonButton expand="full" color="primary" id="addProvider" onClick={()=>setShowTSAreaModal(true)}>Add Technical Solution Area</IonButton>
            </IonCol>
            <IonModal isOpen={opened} onDidDismiss={()=>setOpened(false)} className="confirmDelete">
                <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="delete-warning">
                                Are You Sure You Want To Delete This?
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonButton color="danger" onClick={()=> confirmDelete()} >Delete</IonButton>
                            <IonButton color="medium" onClick={ ()=> setOpened(false)} >Cancel</IonButton>
                        </IonRow>
                </IonGrid>
            </IonModal>
        </IonRow>
    )
}

export default TSAreaSettings