import React from "react";
import { IonButton, IonImg } from "@ionic/react";
import  ms_login  from '../../images/ms-symbollockup_signin_dark.png'
import { API_URL } from "../../actions/settings";


interface Props{
    setToken: any
  }

async function getUrl() {

    return fetch(`${API_URL}/msal/login_urls`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },

    })
      .then(data => data.json())
   }

/**
 * Renders a button which, when selected, will open a popup for login
 */
const SignInButton:React.FC<Props> = ({setToken}) => {

    const handleLogin = async () => {
        const res=await getUrl()
        if (res.login_url) {
            window.location.href = res.login_url
        }
    }


    return (
        <IonButton fill="clear" size="large" onClick={() => handleLogin()}><IonImg src={ms_login} alt='microsoft login image'/></IonButton>
    );
}

export default SignInButton