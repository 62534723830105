import S3 from "aws-sdk/clients/s3";
import {
  REACT_APP_AWS_BUCKET_NAME,
  REACT_APP_AWS_KEY_BUCKET,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_SECRET_BUCKET,
} from "../../actions/settings";

const S3Bucket = {
  UploadFile,
  UploadImage,
  Delete,
};

async function UploadImage(
  directory: string,
  id: string,
  image: any,
  filename: string,
  type?: string
) {
  // Upload and image/png to the s3Bucket
  const time = new Date();
  const bucket = new S3({
    accessKeyId: REACT_APP_AWS_KEY_BUCKET,
    secretAccessKey: REACT_APP_AWS_SECRET_BUCKET,
    region: REACT_APP_AWS_REGION,
  });
  const params = {
    Bucket: REACT_APP_AWS_BUCKET_NAME || "",
    Key: directory + "/" + id + "/" + time.getTime() + "-" + filename,
    Body: image,
    ACL: "public-read",
    ContentType: type || "image/png",
  };

  return bucket
    .upload(params, function (err: any, data: any) {
      if (err) {
        console.log("There was an error uploading your file: ", err);
        return {
          status: "Failed",
          detail: String(err),
        };
      }

      console.log("Successfully uploaded file.", data);
      return {
        status: "Success",
        detail: data,
      };
    })
    .promise();
}

async function UploadFile(
  bucket_name: string,
  filename: string,
  file: any,
  contentType: any
) {
  // Upload any File or item to S3 Bucket
  const bucket = new S3({
    accessKeyId: REACT_APP_AWS_KEY_BUCKET,
    secretAccessKey: REACT_APP_AWS_SECRET_BUCKET,
    region: REACT_APP_AWS_REGION,
  });
  const params = {
    Bucket: bucket_name || "",
    Key: filename,
    Body: file,
    ContentType: contentType,
  };
  return bucket
    .upload(params, function (err: any, data: any) {
      if (err) {
        console.log("There was an error uploading your file: ", err);
        return {
          status: "Failed",
          detail: String(err),
        };
      }

      console.log("Successfully uploaded file.", data);
      return {
        status: "Success",
        detail: data,
      };
    })
    .promise();
}

async function Delete(bucket_name: string, filename: string, contentType: any) {
  // Delete item form S3 Bucket
  const bucket = new S3({
    accessKeyId: REACT_APP_AWS_KEY_BUCKET,
    secretAccessKey: REACT_APP_AWS_SECRET_BUCKET,
    region: REACT_APP_AWS_REGION,
  });
  const params = {
    Bucket: bucket_name || "",
    Key: filename,
    ContentType: contentType,
  };
  return bucket
    .deleteObject(params, function (err: any, data: any) {
      if (err) {
        console.log("There was an error deleting from S3 bucket ", err);
        return {
          status: "Failed",
          detail: String(err),
        };
      }

      console.log("Successfully Deleted object.", data);
      return {
        status: "Success",
        detail: data,
      };
    })
    .promise();
}

export default S3Bucket;
