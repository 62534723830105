import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from "react";
import { API_URL, TINYMCE_API_KEY } from "../../../actions/settings";
import NotificationPopUp from "../../extras/NotificationPopUp";

interface vars{
    opened:boolean,
    release:any,
    setOpened:any,
    updateData:any
}

async function editSoftwareNotes(token:any,data:any,id:any) {

  return fetch(`${API_URL}/ae2_software_releases/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    },
    body: JSON.stringify(data)

  })
    .then(data => data.json())
 }

const EditReleaseNotesModal: React.FC<vars> = ({opened=false,release,setOpened,updateData}) => {

  const editorRef = useRef<any>(null);
  const [dirty, setDirty] = useState(false);
  useEffect(() => setDirty(false), [release.notes]);
  const save = async () => {
    const token = localStorage.getItem('token') || ""
    if (editorRef.current) {
      const content = editorRef.current!.getContent();
      setDirty(false);
      editorRef.current.setDirty(false);
      const res = await editSoftwareNotes(`Token ${token.slice(1,-1)}`,{notes:content},release.id)
      if(res.status === "success"){
        setOpened(false)
        NotificationPopUp('success','Release Notes Saved')
        updateData()
    }
      else{
        NotificationPopUp('Error',res.data||"Internal Error Saving Release Notes",res.error)
      }
    }
  };


  return(
      <IonModal className="edit-release-notes-modal" isOpen={opened} onDidDismiss={()=>setOpened(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit Release Notes</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => save()}>Save</IonButton>
            <IonButton onClick={() => setOpened(false)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
      <div className="editor" id='editor'>
      {dirty && <p style={{color:'red'}}>You have unsaved content!</p>}
      <Editor
          apiKey={TINYMCE_API_KEY}
          initialValue={release.notes}
          onInit={(evt, editor) => editorRef.current = editor}
          onDirty={() => setDirty(true)}
          init={{
         height: '100%',
         toolbar: 'undo redo | formatselect | ' +
         'bold italic backcolor | alignleft aligncenter ' +
         'alignright alignjustify | bullist numlist outdent indent | ' +
         'removeformat',
         menu: {
          template: {title: 'Template', items: "edit-template"}
        },
        menubar: 'file edit view insert format',
         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
       }}
     />

      </div>

      </IonContent>
    </IonModal>
  )
}

export default EditReleaseNotesModal;