import { configureStore } from '@reduxjs/toolkit'
import UserReducer from './reducers/UserReducer'
import TaskReducer from './reducers/TaskReducer'

const Store = configureStore({
  reducer: {
      user: UserReducer,
      task: TaskReducer
  }
})


export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch


export default Store