import { IonContent, IonHeader, IonModal, IonRow, IonTitle, IonItem, IonInput, IonCol, IonButton, IonSelect, IonSelectOption, IonIcon, IonLabel } from "@ionic/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../actions/settings";
import useHandleState from "../../../../utils/useHandleState";
import { trashBinOutline } from "ionicons/icons";
import NotificationPopUp from "../../../extras/NotificationPopUp";

interface Props{
    opened:boolean,
    setShowModal:any,
    dashboardID:any,
    setDashboards:any
}

async function addParameter(data:any,token:any) {

    return fetch(`${API_URL}/quicksightDashboard/parameter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }


async function getInputTypes(token:any) {

    return fetch(`${API_URL}/input_types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },

    })
      .then(data => data.json())
   }

const startForm = {
    'id':"",
    'name':"",
    'display_name':"",
    'type':"",
    'choices':[]
}
const errorsForm = {
    'name':"",
    'display_name':"",
    'type':"",
    'choices':[]
}

const QuicksightSingleParameterForm:React.FC<Props> = ({opened,setShowModal,dashboardID,setDashboards}) =>{
    const [form, setForm] = useState<any>(startForm)
    const [types, handleTypes] = useHandleState([])
    // eslint-disable-next-line
    const [errors, setErrors] = useState(errorsForm)

    useEffect(()=>{
        const token = localStorage.getItem('token')
        handleTypes(token,getInputTypes)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dashboardID])

    const handleParamFormChange = (value:any,name:string) => {
        setForm((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
    }
    const handleOptionFormChange = (c_index:any,value:any,name:string) => {
        setForm((prevState:any) => {
            const choices:any = [...prevState['choices']]
            choices[c_index][name]=value
            return {
                ...prevState,
                'choices':[...choices]
            }
        } )
    }
    const handleAddOption = () =>{
        setForm((prevState:any) =>{
            let choices = [...prevState['choices']]
            choices.push({
                'name':"",
                'value':"",
            })
            return {
                ...prevState,
                'choices': [...choices]
            }
        })
    }
    const handleDeleteOption = (i:number) =>{
        setForm((prevState:any) =>{
            let choices:any = [...prevState['choices']]
            choices = choices.filter((choice:any,idx:any) => {return idx !== i})
            return {
                ...prevState,
                'choices': [...choices]
            }
        })
    }

    const handleSubmit = async () =>{
        form['id']=dashboardID
        const token = localStorage.getItem('token') || ""
        const res = await addParameter(form,`Token ${token.slice(1,-1)}`)
        if (res.status === 'success'){
            setForm(startForm)
            setShowModal(false)
            setDashboards((prevState:any)=>{
                let dashboards = [...prevState]
                dashboards.forEach((dash:any,idx:number)=>{
                    if(dash.id === dashboardID){
                        dashboards[idx] = res.data
                    }
                })
                return [...dashboards]
            })
            NotificationPopUp('success','Dashboard Added')
        }
        else{
            NotificationPopUp("Error",res.detail||res.data)
        }
    }


    return (
        <IonModal style={{"--min-width":'50%','--height':'50%','--max-height':'80%', 'overflowY':'auto'}} id="unitsModal" isOpen={opened} onDidDismiss={()=>{setShowModal(false);setForm(startForm)}}>
            <IonHeader><IonTitle>Add Parameter</IonTitle></IonHeader>
            <IonContent style={{'--background':'transparent'}}>
                <form>
                    <IonRow style={{minWidth:'100%'}}>
                        <IonCol >
                            <IonInput className={errors.name === '' ? "valid":'invalid'} value={form.name} placeholder="Name" onIonChange={e => handleParamFormChange(e.detail.value!,'name')}></IonInput>
                            <div className="error-detail">{errors.name}</div>
                        </IonCol><IonCol>
                            <IonInput className={errors.display_name === '' ? "valid":'invalid'} value={form.display_name} placeholder="Display Name" onIonChange={e => handleParamFormChange(e.detail.value!,'display_name')}></IonInput>
                            <div className="error-detail">{errors.display_name}</div>
                        </IonCol>
                    </IonRow>
                    <IonSelect placeholder="Parameter Type" value={form.type} onIonChange={e => handleParamFormChange(e.detail.value!,'type')}>
                        {types.map((type:any)=>{
                            return <IonSelectOption key={type.id} value={type.id} >{type.name}</IonSelectOption>
                        })}
                    </IonSelect>
                    <IonRow hidden={form.type !== '0bca5ed6-f59c-46da-bedd-5f49d5a3e27d'} >
                        {form.choices.map((choice:any,i:number) => {
                            return(
                                <IonItem key={i}>
                                    <IonButton slot="end" fill="solid" color="danger" onClick={()=>handleDeleteOption(i)}>
                                        <IonIcon color="dark" src={trashBinOutline}></IonIcon>
                                    </IonButton>
                                    <IonLabel position="stacked">Select Option: </IonLabel>
                                    <IonRow style={{minWidth:'100%'}}>
                                        <IonCol >
                                            <IonInput className={errors.name === '' ? "valid":'invalid'} value={form.choices[i]['name']} placeholder="Name" onIonChange={e => handleOptionFormChange(i,e.detail.value!,'name')}></IonInput>
                                        </IonCol><IonCol>
                                            <IonInput className={errors.name === '' ? "valid":'invalid'} value={form.choices[i]['value']} placeholder="Value" onIonChange={e => handleOptionFormChange(i,e.detail.value!,'value')}></IonInput>
                                        </IonCol>
                                    </IonRow>
                                </IonItem>
                            )
                        })}
                        <IonItem>
                            <IonButton slot="end" onClick={()=>handleAddOption()}>add option</IonButton>
                        </IonItem>
                    </IonRow>

                </form>
            </IonContent>
            <div className='buttons'>

                    <IonButton onClick={()=>handleSubmit()} >Submit</IonButton>
                    <IonButton onClick={()=>setShowModal(false)} color='danger'>cancel</IonButton>
                    </div>
        </IonModal>
    )
}

export default QuicksightSingleParameterForm