import { IonButton, IonCheckbox, IonCol, IonContent, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPopover, IonRow, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react"
import { useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { API_URL, REACT_APP_GOOGLE_MAPS_KEY } from "../../actions/settings"
import './PropertyForm.css'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import CheckRole from "../extras/CheckRole"
import NotificationPopUp from "../extras/NotificationPopUp"
import useForm from "../../utils/useForm"
import S3Bucket from "../extras/S3Bucket"

interface Params {
    id:string
}

interface types {
    uuid:any,
    property_type:number,
    sub_type_name:string

}

interface apiRes {
    pk: string,
    fields: any
}


async function createBuilding(data:any,token:string,id:any) {
    return fetch(`${API_URL}/property/${id}/building`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }

async function getAllCampuses(token:any) {


        return fetch(`${API_URL}/campuses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }


async function getAllPrimaryFunctions(token:any) {


        return fetch(`${API_URL}/energystar/primaryfunctions`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }

async function getAllSubTypes(token:any) {


        return fetch(`${API_URL}/subtypes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }


const county_choices:any = {
    'IL': [
        ['Adams',	'Adams'],['Alexander',	'Alexander'],['Bond',	'Bond'],['Boone',	'Boone'],['Brown',	'Brown'],['Bureau',	'Bureau'],['Calhoun',	'Calhoun'],['Carroll',	'Carroll'],['Cass',	'Cass'],['Champaign',	'Champaign'],['Christian',	'Christian'],['Clark',	'Clark'],['Clay',	'Clay'],['Clinton',	'Clinton'],['Coles',	'Coles'],['Cook',	'Cook'],['Crawford',	'Crawford'],['Cumberland',	'Cumberland'],['DeKalb',	'DeKalb'],['DeWitt',	'DeWitt'],['Douglas',	'Douglas'],['DuPage',	'DuPage'],['Edgar',	'Edgar'],['Edwards',	'Edwards'],['Effingham',	'Effingham'],['Fayette',	'Fayette'],['Ford',	'Ford'],['Franklin',	'Franklin'],['Fulton',	'Fulton'],['Gallatin',	'Gallatin'],
        ['Greene',	'Greene'],['Grundy',	'Grundy'],['Hamilton',	'Hamilton'],['Hancock',	'Hancock'],['Hardin',	'Hardin'],['Henderson',	'Henderson'],['Henry',	'Henry'],['Iroquois',	'Iroquois'],['Jackson',	'Jackson'],['Jasper',	'Jasper'],['Jefferson',	'Jefferson'],['Jersey',	'Jersey'],['Jo Daviess',	'Jo Daviess'],['Johnson',	'Johnson'],['Kane',	'Kane'],['Kankakee',	'Kankakee'],['Kendall',	'Kendall'],['Knox',	'Knox'],['Lake',	'Lake'],['LaSalle',	'LaSalle'],['Lawrence',	'Lawrence'],['Lee',	'Lee'],['Livingston',	'Livingston'],['Logan',	'Logan'],['Macon',	'Macon'],['Macoupin',	'Macoupin'],['Madison',	'Madison'],['Marion',	'Marion'],['Marshall',	'Marshall'],['Mason',	'Mason'],
        ['Massac',	'Massac'],['McDonough',	'McDonough'],['McHenry',	'McHenry'],['McLean',	'McLean'],['Menard',	'Menard'],['Mercer',	'Mercer'],['Monroe',	'Monroe'],['Montgomery',	'Montgomery'],['Morgan',	'Morgan'],['Moultrie',	'Moultrie'],['Ogle',	'Ogle'],['Peoria',	'Peoria'],['Perry',	'Perry'],['Piatt',	'Piatt'],['Pike',	'Pike'],['Pope',	'Pope'],['Pulaski',	'Pulaski'],['Putnam',	'Putnam'],['Randolph',	'Randolph'],['Richland',	'Richland'],['Rock Island',	'Rock Island'],['Saline',	'Saline'],['Sangamon',	'Sangamon'],['Schuyler',	'Schuyler'],['Scott',	'Scott'],['Shelby',	'Shelby'],['St. Clair',	'St. Clair'],['Stark',	'Stark'],['Stephenson',	'Stephenson'],
        ['Tazewell',	'Tazewell'],['Union',	'Union'],['Vermilion',	'Vermilion'],['Wabash',	'Wabash'],['Warren',	'Warren'],['Washington',	'Washington'],['Wayne',	'Wayne'],['White',	'White'],['Whiteside',	'Whiteside'],['Will',	'Will'],['Williamson',	'Williamson'],['Winnebago',	'Winnebago'],['Woodford',	'Woodford']
    ],
    'WI': [
        ['Adams',	'Adams'], ['Ashland',	'Ashland'], ['Barron',	'Barron'], ['Bayfield',	'Bayfield'], ['Brown',	'Brown'], ['Buffalo',	'Buffalo'], ['Burnett',	'Burnett'], ['Calumet',	'Calumet'], ['Chippewa',	'Chippewa'], ['Clark',	'Clark'], ['Columbia',	'Columbia'], ['Crawford',	'Crawford'], ['Dane',	'Dane'], ['Dodge',	'Dodge'], ['Door',	'Door'], ['Douglas',	'Douglas'], ['Dunn',	'Dunn'], ['Eau Claire',	'Eau Claire'], ['Florence',	'Florence'], ['Fond du Lac',	'Fond du Lac'], ['Forest',	'Forest'], ['Grant',	'Grant'], ['Green',	'Green'], ['Green Lake',	'Green Lake'], ['Iowa',	'Iowa'], ['Iron',	'Iron'], ['Jackson',	'Jackson'], ['Jefferson',	'Jefferson'],
        ['Juneau',	'Juneau'], ['Kenosha',	'Kenosha'], ['Kewaunee',	'Kewaunee'], ['La Crosse',	'La Crosse'], ['Lafayette',	'Lafayette'], ['Langlade',	'Langlade'], ['Lincoln',	'Lincoln'], ['Manitowoc',	'Manitowoc'], ['Marathon',	'Marathon'], ['Marinette',	'Marinette'], ['Marquette',	'Marquette'], ['Menominee',	'Menominee'], ['Milwaukee',	'Milwaukee'], ['Monroe',	'Monroe'], ['Oconto',	'Oconto'], ['Oneida',	'Oneida'], ['Outagamie',	'Outagamie'], ['Ozaukee',	'Ozaukee'], ['Pepin',	'Pepin'], ['Pierce',	'Pierce'], ['Polk',	'Polk'], ['Portage',	'Portage'], ['Price',	'Price'], ['Racine',	'Racine'], ['Richland',	'Richland'], ['Rock',	'Rock'], ['Rusk',	'Rusk'],
        ['Sauk',	'Sauk'], ['Sawyer',	'Sawyer'], ['Shawano',	'Shawano'], ['Sheboygan',	'Sheboygan'], ['St. Croix',	'St. Croix'], ['Taylor',	'Taylor'], ['Trempealeau',	'Trempealeau'], ['Vernon',	'Vernon'], ['Vilas',	'Vilas'], ['Walworth',	'Walworth'], ['Washburn',	'Washburn'], ['Washington',	'Washington'], ['Waukesha',	'Waukesha'], ['Waupaca',	'Waupaca'], ['Waushara',	'Waushara'], ['Winnebago',	'Winnebago'], ['Wood',	'Wood']
    ]
}



const state_choices:string[][] = [
    ['IL', "Illinois"], ['WI', 'Wisconsin'],
    ['AL', 'Alabama'], ['AR', 'Arkansas'], ['AS', 'American Samoa'], ['AZ', 'Arizona'], ['CA', 'California'], ['CO', 'Colorado'],
    ['CT', 'Connecticut'], ['DC', 'District of Columbia'], ['DE', 'Delaware'], ['FL', 'Florida'], ['GA', 'Georgia'], ['GU', 'Guam'],
    ['HI', 'Hawaii'], ['IA', 'Iowa'], ['ID', 'Idaho'], ['IN', 'Indiana'], ['KS', 'Kansas'], ['KY', 'Kentucky'], ['LA', 'Louisiana'], ['MA', 'Massachusetts'],
    ['MD', 'Maryland'], ['ME', 'Maine'], ['MH', 'Marshall Islands'], ['MI', 'Michigan'], ['MN', 'Minnesota'], ['MO', 'Missouri'], ['MP', 'Northern Mariana Islands'],
    ['MS', 'Mississippi'], ['MT', 'Montana'], ['NC', 'North Carolina'], ['ND', 'North Dakota'], ['NE', 'Nebraska'], ['NH', 'New Hampshire'], ['NJ', 'New Jersey'],
    ['NN', 'Navajo Nation'], ['NV', 'Nevada'], ['NY', 'New York'], ['OH', 'Ohio'], ['OK', 'Oklahoma'], ['OR', 'Oregon'], ['PA', 'Pennsylvania'], ['PI', 'Pacific Island'],
    ['PR', 'Puerto Rico'], ['RI', 'Rhode Island'], ['SC', 'South Carolina'], ['SD', 'South Dakota'], ['TN', 'Tennessee'], ['TT', 'Trust Territories'],
    ['TX', 'Texas'], ['UM', 'U.S. Minor Outlying Islands'], ['UT', 'Utah'], ['VA', 'Virginia'], ['VI', 'Virgin Islands'], ['VT', 'Vermont'], ['WA', 'Washington'],
    ['WQ', 'Wake Island'], ['WV', 'West Virginia'], ['WY', 'Wyoming'], ['AB', 'Alberta'], ['BC', 'British Columbia'], ['MB', 'Manitoba'], ['NB', 'New Brunswick'],
    ['NL', 'Newfoundland'], ['NS', 'Nova Scotia'], ['NT', 'Northern Territories'], ['NU', 'Nunavut'], ['ON', 'Ontario'], ['PE', 'Prince Edward Island'],
    ['QC', 'Quebec'], ['SK', 'Saskatchewan'], ['YT', 'Yukon']]

const country_choices:string[][]=[
    ['US','United States'],['CA','Canada']
]


const getBuildings = async (token:any, setCampuses:any) => {
        const customers = await getAllCampuses(`Token ${token.slice(1,-1)}`)
        setCampuses(JSON.parse(customers))
    }
const getPrimaryFunctions = async (token:any, setPrimaryfunctions:any) => {
    const primaryfunctions = await getAllPrimaryFunctions(`Token ${token.slice(1,-1)}`)
    setPrimaryfunctions(primaryfunctions)
}
const getSubTypes = async (token:any, setSubTypes:any) => {
    const subtypes = await getAllSubTypes(`Token ${token.slice(1,-1)}`)
    setSubTypes(subtypes)
}


const BuildingForm: React.FC = () =>{

    const history = useHistory()
    const user = useAppSelector(selectUser)
    const [image,setImg] = useState<any>('')
    const [imageUrl,setImgUrl] = useState<any>('')
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
      } = usePlacesService({
        apiKey: REACT_APP_GOOGLE_MAPS_KEY,
      });

      const handlePlace = (item:any) => {
        placesService?.getDetails(
            {
              placeId: item.place_id,
            },
            (placeDetails:any) => {
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('postal_code'))[0].long_name,'building_zipcode');
                handleFormChange(
                    `${placeDetails.address_components.filter((r:any) => r.types.includes('street_number'))[0].long_name} ${placeDetails.address_components.filter((r:any) => r.types.includes('route'))[0].short_name}`,'building_address');
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('locality'))[0].long_name,'building_city');
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('administrative_area_level_1'))[0].short_name,'building_state');
                }
          );

    }
    console.log('Building Form Loaded')

    const startingForm = {
        cust_uuid:'',
        campus_uuid: null,
        building_name: null,
        building_description: "",
        building_address: null,
        building_address_2: null,
        building_city: null,
        building_state: null,
        building_zipcode: null,
        building_county: null,
        building_sq_ft: null,
        building_type: "",
        building_sub_type: null,
        building_incentive:null,
        add_ae2: true,
        add_estar: true,
        in_estar: false,
        estar_country:"US",
        estar_year_built:null,
        estar_construction_status:null,
        estar_occupancy:null,
        estar_is_federal_property: false,
        estar_federal_owner:null,
        estar_id:null,
        number_of_buildings:1

    }
    const startingErrors:any = {
        campus_uuid:'',
        building_name : '',
        building_description:'',
        building_address: '',
        building_address_2: '',
        building_city: "",
        building_state: "",
        building_zipcode: "",
        building_county:"",
        building_sq_ft:"",
        building_type:"",
        building_sub_type:"",
        building_incentive:"",
        add_ae2:"",
        add_estar:"",
        in_estar:"",
        estar_country:"",
        estar_year_built:"",
        estar_construction_status:"",
        estar_occupancy:"",
        estar_is_federal_owner:"",
        estar_federal_owner:"",
        estar_id:'',
        number_of_buildings:""
    }

    const validate = (name:string, val:any) => {
        const regExp = /[a-zA-Z]/g;
        switch (name) {
            case 'building_name':
                if(val === null || val.length <=1){
                    return {building_name:'Name Must Be longer than 2 letters'}
                }
                else{
                    return {building_name:''}
                }

            case 'building_address':
                if(val === null || val.length === 0 || val === " " ){
                    return {building_address:'Address cannot be empty'}
                }
                else{
                    return {building_address:''}
                }
            case 'building_city':
                if(val === null || val.length === 0 ){
                    return {building_city:'City cannot be empty'}
                }
                else{
                    return {building_city:''}
                }

            case 'building_state':
                if(val === null || val.length === 0 ){
                    return {building_state:'Select A State'}
                }
                else{
                    return {building_state:''}
                }
            case 'building_county':
                if(val === null || val.length === 0 ){
                    return {building_county:'County Cannot be Empty'}
                }
                else{
                    return {building_county:''}
                }
            case 'building_zipcode':
                if(val === null || val.length === 0 ){
                    return {building_zipcode:'Zip Code cannot be empty'}

                }
                if(val < 10000 ){
                    return {building_zipcode:'Not a valid zip code'}

                }
                else if (regExp.test(val)){
                    return {building_zipcode:'Zip Code cannot contain letters'}
                }
                else{
                    return {building_zipcode:''}
                }
            case 'building_sq_ft':
                if (val === null || val < 0){
                    return {building_sq_ft:'Building Square Feet must be greater than 0'}
                }else{
                    return {building_sq_ft:''}
                }
            case 'building_sub_type':
                val = form.building_sub_type
                if(Number(val) === 30 || Number(val) === 48){
                    return {building_sub_type:"Select Sub Type"}
                }else{
                    return {building_sub_type:''}
                }
            case 'estar_id':
                if(form.in_estar && (val === null || val < 0 )){
                    return {estar_id:"Enter a valid Energy star id"}
                }else{
                    return {estar_id:''}
                }
            case 'estar_year_built':
                if(form.add_estar && (val === null || val < 1700 || val > getCurrentYear() )){
                    return {estar_year_built:"Enter a valid year "}
                }else{
                    return {estar_year_built:''}
                }
            case 'estar_construction_status':
                if(form.add_estar && val === null ){
                    return {estar_construction_status:"Construction Status cannot be empty."}
                }else{
                    return {estar_construction_status:''}
                }
            case 'estar_occupancy':
                if(form.add_estar && val === null ){
                    return {estar_occupancy:"Occupancy cannot be empty."}
                }else{
                    return {estar_occupancy:''}
                }
            case 'number_of_buildings':
                if(form.add_estar && (val === null || val < 1) ){
                    return {number_of_buildings:"Number of Buildings must be 1 or greater"}
                }else{
                    return {number_of_buildings:''}
                }
            case 'estar_federal_owner':
                if(form.estar_is_federal_property && val === null ){
                    return {estar_federal_owner:"Federal owner cannot be empty."}
                }else{
                    return {estar_federal_owner:''}
                }

            default:
                break;
        }
    }

    const {form, handleFormChange, errors, resetForm, validateForm} = useForm(startingForm,startingErrors,validate)
    const [campuses, setCampuses] = useState([])
    const [primaryfunctions, setPrimaryfunctions] = useState([])
    const [subTypes, setSubTypes] = useState([])
    const { id } = useParams<Params>();

    useEffect(() => {
        const token = localStorage.getItem('token')
        getBuildings(token,setCampuses)
        getPrimaryFunctions(token,setPrimaryfunctions)
        getSubTypes(token,setSubTypes)
      }, [])


    // const handleFormChange = (value:any,name:string) => {
    //     setErrors({...errors, ...validate(name,value) })
    //     setForm((prevState) => {
    //         return {
    //             ...prevState,
    //             [name]:value
    //         }
    //     } )

    // }

    const handleSubmit = async () => {
        const token:any = localStorage.getItem('token')
        form.cust_uuid = id
        const valid = validateForm() || false
        if(valid){
            const res = await createBuilding(form,`Token ${token.slice(1,-1)}`,id)
        if (res.status === "Created"){
            if(image){
                S3Bucket.UploadImage("thumbnails/pics",res.data.uuid,image,'building')
            }
            if (form.add_ae2){
                resetForm()
                // setForm(startingForm)
                history.push(`/?id=${res.data.uuid}&sec=buildings`)
            }
            else{
                resetForm()
                // setForm(startingForm)
                history.push(`/energystar/properties/${res.data.uuid}`)
            }
            NotificationPopUp('success','Building Created')
        }

        else{
            NotificationPopUp('error',res.detail||res.ERROR,res.error||"Error")
        }
        }
    }




    // const validateAll = (form:any) => {
    // let validation = startingErrors
    // let valid = true

    // for (let item in form){

    //     validation = {...validation, ...validate(item,form[item])}

    // }

    // setErrors({...errors,...validation})

    // for(let res in validation){

    //     if (validation[res] !== ''){
    //         valid = false;
    //     }

    // }

    // return valid
    // }


    const getCurrentYear = () => {
        return new Date().getFullYear();
      };

      if (user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={user.first_name===""}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    const onImageChange = (e:any) =>{
        const img = e.target.files[0]
        setImg(img)
        setImgUrl(URL.createObjectURL(img))
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }
    console.log(form)

    return (

        <form>
            <IonRow className="form-title-row" ><h2>Add Property to AE2/Energy Star </h2> <IonButton className="cancel-btn" size="small"  color="danger" href={`/energystar/properties/${id}`}> Cancel </IonButton></IonRow>
            <IonRow>
                <IonItem lines='none'>
                    <IonLabel position="stacked">Image</IonLabel>
                    <IonRow>
                        {imageUrl ? <IonImg className="preview-image" src={imageUrl}/>:''}
                        <input type="file" accept="image/*" onChange={onImageChange} />
                    </IonRow>
                </IonItem>
            </IonRow>
            <IonItem>
                <IonLabel> ADD Property to AE2</IonLabel>
                <IonCheckbox checked={form.add_ae2} aria-label="Add Property to AE2" mode='ios' slot="start" onIonChange={(e:any) => (handleFormChange(e.detail.checked,'add_ae2'))} />
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Building Name</IonLabel>
                <IonInput className={errors.building_name === '' ? "valid":'invalid'} aria-labelledby='Building Name' value={form.building_name} placeholder="Name" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_name'))}> </IonInput>
                <div className="error-detail">{errors.building_name}</div>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Building Description</IonLabel>
                <IonTextarea className={errors.building_description === '' ? "valid":'invalid'} aria-labelledby='Building Description' auto-grow value={form.building_description} placeholder='Description' onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_description'))}> </IonTextarea>
                <div className="error-detail">{errors.building_description}</div>
            </IonItem>
            <IonItem lines="none">
                <IonRow className="address-row">
                    <IonCol size="6">
                        <IonLabel position="stacked">Address:</IonLabel>
                        <IonInput id="address" className={errors.building_address === '' ? "valid":'invalid'} aria-labelledby='address' value={form.building_address} placeholder="Address" onIonChange={(e:any) => {handleFormChange(e.detail.value!,'building_address'); getPlacePredictions({input:e.detail.value!})}}/>
                        <IonPopover trigger="address" className='address-list' reference="trigger" dismissOnSelect showBackdrop={false} keyboardClose={false} size='cover'>
                            <IonContent>
                                <IonList>
                        {placePredictions.map((item) => <IonItem className="address-item" button key={item.place_id} onClick={() => handlePlace(item)}><IonLabel color="dark">{item.description}</IonLabel></IonItem>)}
                        </IonList></IonContent>
                        </IonPopover>
                        <div className="error-detail">{errors.building_address}</div>
                    </IonCol>
                    <IonCol size="6">
                        <IonLabel position="stacked"></IonLabel>
                        <IonInput className={errors.building_address_2 === '' ? "valid":'invalid'} aria-labelledby='address 2'  value={form.building_address_2} placeholder="Address 2" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_address_2'))}> </IonInput>
                        <div className="error-detail">{errors.building_address_2}</div>
                    </IonCol>
                </IonRow>
            </IonItem>
                <IonRow className="address-row-2">
                    <IonCol>
                        <IonInput className={errors.building_city === '' ? "valid":'invalid'}value={form.building_city} aria-labelledby='City' placeholder="City" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_city'))}> </IonInput>
                        <div className="error-detail">{errors.building_city}</div>
                    </IonCol>
                    <IonCol>
                        <IonSelect className={errors.building_state === '' ? "valid":'invalid'} interface='popover' value={form.building_state} placeholder="State" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_state'))}>
                            {state_choices.map((st) => {
                                return (
                                    <IonSelectOption key={st[0]} value={st[0]}>{st[1]}</IonSelectOption>
                                )
                            })}
                            </IonSelect>
                            <div className="error-detail">{errors.building_state}</div>
                    </IonCol>
                    <IonCol>
                        <IonInput className={errors.building_zipcode === '' ? "valid":'invalid'} value={form.building_zipcode} placeholder="Zip Code" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_zipcode'))}> </IonInput>
                        <div className="error-detail">{errors.building_zipcode} </div>
                    </IonCol>
                    <IonCol>
                        { form.building_state === 'IL' || form.building_state === 'WI' ?
                        <IonItem>
                            <IonSelect className={errors.building_state === '' ? "valid":'invalid'} interface='popover' value={form.building_county} placeholder="County" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_county'))}>
                            {county_choices[form.building_state].map((county:any,index:any) => {
                                return (
                                    <IonSelectOption key={index} value={county[0]}>{county[1]}</IonSelectOption>
                                )
                            })}
                            </IonSelect>
                        </IonItem>
                        :
                        <IonInput className={errors.building_state === '' ? "valid":'invalid'} value={form.building_county} placeholder='County' onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_county'))}> </IonInput>
                        }
                        <div className="error-detail">{errors.building_county}</div>

                    </IonCol>
                </IonRow>

            <IonRow>
                <IonCol>
                    <IonItem lines='none'>
                        <IonLabel position="stacked">Gross Floor Area</IonLabel>
                        <IonInput className={errors.building_sq_ft === '' ? "valid":'invalid'} type="number" value={form.building_sq_ft} placeholder='Gross Floor Area' onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_sq_ft'))}></IonInput>
                        <div className="error-detail">{errors.building_sq_ft}</div>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked"> Campus Associated with This Building</IonLabel>
                        <IonSelect className={errors.campus_uuid === '' ? "valid":'invalid'} interface='popover' value={form.campus_uuid} placeholder="Campus" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'campus_uuid'))}>
                        <IonSelectOption value={null}>None</IonSelectOption>
                        {campuses.map((campus:apiRes) => {
                            return (
                                <IonSelectOption key={campus.pk} value={campus.pk}>{campus.fields.campus_name}</IonSelectOption>
                            )
                        })}
                        </IonSelect>
                        <div className="error-detail">{errors.campus_uuid}</div>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Incentive</IonLabel>
                        <IonInput className={errors.building_incentive === '' ? "valid":'invalid'} type="number" value={form.building_incentive} onIonChange={(e:any)=>(handleFormChange(e.detail.value!,'building_incentive'))}></IonInput>
                        <div className="error-detail">{errors.building_incentive}</div>
                    </IonItem>
                </IonCol>
                </IonRow>
                <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked"> Primary Function: </IonLabel>
                        <IonSelect className={errors.building_type === '' ? "valid":'invalid'} interface='popover' value={form.building_type} placeholder="Primary Function" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_type'))}>
                            {primaryfunctions.map((item:any,index) => {

                                return (
                                    <IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>
                                )
                            })}
                            </IonSelect>
                            <div className="error-detail">{errors.building_type}</div>
                    </IonItem>
                </IonCol>
                {Number(form.building_type) === 30 || Number(form.building_type) === 48 ?
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked"> Sub-Type </IonLabel>
                        <IonSelect className={errors.building_sub_type === '' ? "valid":'invalid'} interface='popover' value={form.building_sub_type} placeholder="Sub Property Type" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'building_sub_type'))}>
                            {subTypes.map((item:types,index) => {

                               if(item.property_type === Number(form.building_type)){
                                    return(<IonSelectOption key={index} value={item.uuid}>{item.sub_type_name}</IonSelectOption>)
                                }
                                else {
                                    return( <>""</>)
                                }

                            })}
                            </IonSelect>
                            <div className="error-detail">{errors.building_sub_type}</div>
                    </IonItem>
                </IonCol> : ""}

            </IonRow>
            <IonItem disabled>
                <IonLabel> ADD Property to EnergyStar</IonLabel>
                <IonCheckbox checked={form.add_estar} mode='ios' slot="start" onIonChange={(e:any) => (handleFormChange(e.detail.checked,'add_estar'))} />
            </IonItem>

            {/* Energy Start Form Data Needed */}
            { form.add_estar ? <>

                <h3>Energy Star Form</h3>
                <p>Fill out the rest of the form To Add Building</p>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel position="stacked"> Country</IonLabel>
                            <IonSelect className={errors.estar_country === '' ? "valid":'invalid'} interface='popover' value={form.estar_country} placeholder="Country" onIonChange={(e:any) => (handleFormChange(e.detail.value!,'estar_country'))}>
                                {country_choices.map((country,index) => {
                                    return (
                                        <IonSelectOption key={index} value={country[0]}>{country[1]}</IonSelectOption>
                                    )
                                })}
                            </IonSelect>
                            <div className="error-detail">{errors.estar_country}</div>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines='none'>
                            <IonLabel position="stacked"> Year Built</IonLabel>
                            <IonInput className={errors.estar_year_built === '' ? "valid":'invalid'} type="number" value={form.estar_year_built} placeholder={`${getCurrentYear()}`} max={getCurrentYear()} min={1800} onIonChange={(e:any) => (handleFormChange(e.detail.value!,'estar_year_built'))}></IonInput>
                            <div className="error-detail">{errors.estar_year_built}</div>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines='none'>
                            <IonLabel position="stacked">Construction Status</IonLabel>
                            <IonSelect value={form.estar_construction_status} className={errors.estar_construction_status === '' ? "valid":'invalid'} interface='popover' placeholder='Construction Status' onIonChange={(e:any) => (handleFormChange(e.detail.value!,'estar_construction_status'))}>
                                <IonSelectOption  value='Existing'>Existing</IonSelectOption>
                                <IonSelectOption  value='Project'>Project</IonSelectOption>
                                <IonSelectOption  value='Test'>Test</IonSelectOption>
                            </IonSelect>
                            <div className="error-detail">{errors.estar_construction_status}</div>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                    <IonItem lines='none'>
                        <IonLabel position="stacked">Occupancy</IonLabel>
                            <IonSelect className={errors.estar_occupancy === '' ? "valid":'invalid'} value={form.estar_occupancy} interface='popover' placeholder='Occupancy' onIonChange={(e:any) => (handleFormChange(e.detail.value!,'estar_occupancy'))}>
                            <IonSelectOption  value='100'>100</IonSelectOption>
                            <IonSelectOption  value='95'>95</IonSelectOption>
                            <IonSelectOption  value='90'>90</IonSelectOption>
                            <IonSelectOption  value='85'>85</IonSelectOption>
                            <IonSelectOption  value='80'>80</IonSelectOption>
                            <IonSelectOption  value='75'>75</IonSelectOption>
                            <IonSelectOption  value='70'>70</IonSelectOption>
                            <IonSelectOption  value='65'>65</IonSelectOption>
                            <IonSelectOption  value='60'>60</IonSelectOption>
                            <IonSelectOption  value='55'>55</IonSelectOption>
                            <IonSelectOption  value='50'>50</IonSelectOption>
                            <IonSelectOption  value='45'>45</IonSelectOption>
                            <IonSelectOption  value='40'>40</IonSelectOption>
                            <IonSelectOption  value='35'>35</IonSelectOption>
                            <IonSelectOption  value='30'>30</IonSelectOption>
                            <IonSelectOption  value='25'>25</IonSelectOption>
                            <IonSelectOption  value='20'>20</IonSelectOption>
                            <IonSelectOption  value='15'>15</IonSelectOption>
                            <IonSelectOption  value='10'>10</IonSelectOption>
                            <IonSelectOption  value='5'>5</IonSelectOption>
                            <IonSelectOption  value='0'>0</IonSelectOption>
                            </IonSelect>
                            <div className="error-detail">{errors.estar_occupancy}</div>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel> Federal Property?</IonLabel>
                            <IonCheckbox checked={form.estar_is_federal_property} mode='ios' slot="start" onIonChange={(e:any) => (handleFormChange(e.detail.checked,'estar_is_federal_property'))} />
                            <div className="error-detail">{errors.estar_is_federal_property}</div>
                        </IonItem>
                    </IonCol>
                    {form.estar_is_federal_property ?
                    <IonCol>
                        <IonItem lines="none">
                            <IonSelect className={errors.estar_federal_owner === '' ? "valid":'invalid'} value={form.estar_federal_owner} interface='popover' placeholder='Federal Owner' onIonChange={(e:any) => (handleFormChange(e.detail.value!, 'estar_federal_owner'))}>
                                <IonSelectOption value='US'>United States</IonSelectOption>
                                <IonSelectOption value='CA'>Canada</IonSelectOption>
                            </IonSelect>
                            <div className="error-detail">{errors.estar_federal_owner}</div>
                        </IonItem>
                    </IonCol>: undefined}
                </IonRow>


                </>

            : " "}





            <div className="button-container">
            <IonItem className='form-submit-button' button color='primary' onClick={handleSubmit}>
                <IonLabel>
                  Submit
                </IonLabel>
            </IonItem>
            </div>

        </form>
    )
}


export default BuildingForm