import S3 from "aws-sdk/clients/s3";
import {
  REACT_APP_AWS_KEY_BUCKET,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_SECRET_BUCKET,
  REACT_APP_AWS_BUCKET_NAME
} from "../../actions/settings";

async function ReadFileFromS3(
  filename: string,
) {
  const bucket = new S3({
    accessKeyId: REACT_APP_AWS_KEY_BUCKET,
    secretAccessKey: REACT_APP_AWS_SECRET_BUCKET,
    region: REACT_APP_AWS_REGION,
  });
  const params = {
    Bucket: REACT_APP_AWS_BUCKET_NAME || "",
    Key: filename
  };

  return bucket
    .getObject(params, function (err: any, data: any) {
      if (err) {
        return {
          status:"Failed",
          detail:String(err)
        };
      }

      return {
        status:"Success",
        detail:data
      };
    })
    .promise();
}

export default ReadFileFromS3;
