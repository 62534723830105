import { IonButton, IonCol, IonImg, IonInput, IonModal } from "@ionic/react";
import { useRef, useState } from "react";
import { API_URL } from "../../actions/settings";
import ES_IMG from "../../assets/estar/ES.jpg";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import NotificationPopUp from "../extras/NotificationPopUp";
import "./EScoreCard.css";
import { isStaff } from "../../components/extras/Functions";
import BaselineTargetForm from "../forms/BaselineTargetForm";

interface Props {
  property: any;
  current?: any;
  ae2?: boolean;
  building_id?: string;
}

const startingForm = {
  date: "",
};

async function getScore(data: any, id: any, token: string) {
  return fetch(`${API_URL}/energystar/properties/${id}/score`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

// const getDate = (stringDate:string) => {
//     const d = new Date(stringDate+'T00:00:00')
//     if(!d){
//         return 'N/A'
//     }
//     return `${d.getMonth()+1}/${d.getFullYear()}`
// }

const EScoreCard: React.FC<Props> = ({
  property,
  current,
  ae2 = false,
  building_id,
}) => {
  const [form, setForm] = useState(startingForm);
  const user = useAppSelector(selectUser);
  const role = user.role.name;
  const modalRef = useRef<HTMLIonModalElement>(null);

  //If its from the ae2 customer page return the basic card only
  if (ae2) {
    return (
      <>
        <IonImg src={ES_IMG} />
        <h4>Energy Score:</h4>
        <h1 className="score">{property["score"].score || "None"}</h1>
        <p>
          {" "}
          On a 1 to 100 Scale. For the 12-month period ending:{" "}
          {property["score"].month}/{property["score"].year}
        </p>
        <p>
          <IonButton
            size="small"
            color="secondary"
            href={`/energystar/properties/${property.property.id}`}
          >
            Energy Star Details
          </IonButton>
          <IonButton
            size="small"
            color="medium"
            href={`/energy_performance?id=${building_id}`}
          >
            Energy Performance Details
          </IonButton>
        </p>
      </>
    );
  }

  const handleFormChange = (value: any, name: string) => {
    setForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const score = async (token: any) => {
    const res = await getScore(
      form,
      property.property_id,
      `Token ${token.slice(1, -1)}`
    );
    if ("Failed" in res) {
      NotificationPopUp("error", res["Failed"], "Error", 12000);
    } else if ("score" in res) {
      NotificationPopUp(
        "success",
        "Energy Star Score: " + res["score"],
        "Success!",
        8000
      );
    } else {
      NotificationPopUp(
        "error",
        "Could not get Score...Try Again Later",
        "Error",
        10000
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    score(token);
  };

  const closeModal = () => {
    modalRef.current?.dismiss();
  };

  console.log(property)

  return (
    <IonCol className="score-container">
      <IonImg src={ES_IMG} />
      <h4>Energy Score:</h4>
      <h1 className="score">{current.score}</h1>
      <p>
        {" "}
        On a 1 to 100 Scale. For the 12-month period ending: {current.month}/
        {current.year}
      </p>
      <form
        hidden={
          !(role === "Supervisor" || role === "Admin" || role === "Employee")
        }
        className="score-form"
        onSubmit={handleSubmit}
      >
        <IonInput
          type="date"
          value={form.date}
          required
          placeholder="Date"
          onIonChange={(e) => handleFormChange(e.detail.value!, "date")}
        ></IonInput>
        <IonButton type="submit" color="secondary">
          {" "}
          Get Score{" "}
        </IonButton>
      </form>
      <div>
        {isStaff(user) ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <IonButton size="small" id="baseline-button" color="medium">
              Set Baseline/Target
            </IonButton>
          </div>
        ) : null}
      </div>
      <IonModal
        ref={modalRef}
        className="modal-background"
        trigger="baseline-button"
      >
        <BaselineTargetForm id={property.id} close={closeModal} />
      </IonModal>
    </IonCol>
  );
};

export default EScoreCard;
