import { IonContent, IonPage } from '@ionic/react';
import { useEffect } from 'react';
import { API_URL } from '../actions/settings';
import useToken from '../components/auth/useToken';
import QuicksightEmbed from '../components/extras/QuicksightEmbed';

async function getDashUrl(token:string,id:any) {
  return fetch(`${API_URL}/buildings/getDashUrl/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const ScreenShot: React.FC = () => {

    let params =  new URLSearchParams(window.location.search);
    const {token} = useToken()
    const id = params.get('dashId') || ""
    const parameters = params.get('params') || ""


    const handleDash = async (dashID:string,params:any) =>{
      // const embeddingContext = await QuickSightEmbedding.createEmbeddingContext()
      // const {embedDashboard} = embeddingContext
      const dashboard = new QuicksightEmbed()
      await dashboard.init()
      const res = await getDashUrl(`Token ${token}`,dashID)
      dashboard.changeSettings({url:res[0],container:`#screenshot-dash`})
      dashboard.setParameters(params)
      await dashboard.embedDash()

      // var frameOptions = {
      //     url: res[0],
      //     container: `#screenshot-dash`,
      //     withIframePlaceholder: true,
      // };
      // var contentOptions = {
      //   locale: 'en-US',
      //   // parameters:[],
      //   toolbarOptions: {
      //     export: false,
      //     undoRedo: false,
      //     reset: false
      //   },
      //   fitToIframeWidth:true,
      //   sheetOptions:{
      //     singleSheet:true
      //   }

      // }

      // await embedDashboard(frameOptions,contentOptions)

  }

  useEffect(()=>{
    handleDash(id,JSON.parse(`${parameters}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id,parameters])

  return (
    <IonPage>
      <IonContent id='screenshot-dash'>
        {/* <iframe title='dashboard' style={{minWidth:'100%',minHeight:'100%'}} src={url}></iframe> */}
        </IonContent>
    </IonPage>
  );
};

export default ScreenShot;
