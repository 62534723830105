import { Store } from "react-notifications-component";

function NotificationPopUp(status?:'success'|"Success"|"error"|"Error",message?:string,title?:string,duration:any=4000){

if(status==="Success" ||  status==='success'){
    Store.addNotification({
        title: title || "Success!",
        message: message || 'Complete',
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
                                                     animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: duration,
          onScreen: true
        },})
}
if(status==="Error" ||  status==='error'){
    Store.addNotification({
        title: title||"Error",
        message: message || "Internal Error",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 8000,
          onScreen: true
        },})
}

return
}

export default NotificationPopUp