import {
  AdvancedMarker,
  InfoWindow,
  Pin,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useState } from "react";
import { fromAddress } from "react-geocode";
import "./GoogleMapMarker.css";
import { API_URL } from "../../actions/settings";
import { IonButton, IonIcon } from "@ionic/react";
import { business, businessSharp, person, personSharp } from "ionicons/icons";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import smartSheetIcon from "../../AE2/static/icons/smartsheet-icon.svg";
import GoogleMapMarkerDetail from "./GoogleMapMarkerDetail";
import { MdOutlineStar, MdStars  } from "react-icons/md";
import { Building } from "../../interfaces/Building";



interface Props {
  building: Building;
  Address: String;
}

async function saveCords(token: string, data: any, id: string) {
  return fetch(`${API_URL}/buildings/${id}/coordinates`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

const getCords = async (
  Address: String,
  setCords: any,
  handleSaveCords: any
) => {
  fromAddress(String(Address))
    .then(({ results }) => {
      const { lat, lng } = results[0].geometry.location;
      setCords({ lat, lng });
      handleSaveCords(lat, lng);
    })
    .catch(console.error);
};

const GoogleMapMarker: React.FC<Props> = ({ building, Address }) => {
  const user = useAppSelector(selectUser);
  const [cords, setCords] = useState<any>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const handleSaveCords = useCallback(
    async (lat: any, lng: any) => {
      const token: any = localStorage.getItem("token");
      const res = await saveCords(
        `Token ${token.slice(1, -1)}`,
        { lat, lng },
        building.uuid
      );
      console.warn(res);
    },
    [building.uuid]
  );


  useEffect(() => {
    const handleCords = async () => {
      await getCords(Address, setCords, handleSaveCords);
    };
    if (!building.latitude && !building.longitude) {
      handleCords();
    } else {
      const lat = Number(building.latitude);
      const lng = Number(building.longitude);
      setCords({
        lat,
        lng,
      });
    }
  }, [Address, handleSaveCords, building.latitude, building.longitude]);

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    []
  );

  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  if (cords) {
    return (
      <>
        <AdvancedMarker
          ref={markerRef}
          position={cords}
          onClick={handleMarkerClick}
          title={building.building_name}
        >
          <Pin
            background={
              building.campus_optimizer
                ? "#00994C"
                : building.building_incentive
                ? "#0000FF"
                : "red"
            }
            borderColor={
              building.estar_score != null && Number(building.estar_score) >= 75
                ? "black" //"rgba(255, 255, 255, 0.901)"
                : building.estar_score != null &&
                  Number(building.estar_score) < 75
                ? "rgba(255, 255, 255, 0.901)"
                : ""
            }
            glyphColor={
              building.foa && building.kpi
                ? "rgb(0, 255, 255)"
                : building.foa
                ? "rgb(255, 128, 0)"
                : building.kpi
                ? "rgb(51, 255, 0)"
                : ""
            }
          >
            {
              building.estar_score != null && Number(building.estar_score) >= 75
                ? <MdStars/>
                : building.estar_score != null &&
                  Number(building.estar_score) < 75
                ? <MdOutlineStar/>
                : null
            }
          </Pin>
        </AdvancedMarker>
        {infoWindowShown && (
          <InfoWindow
            anchor={marker}
            onClose={handleClose}
            className="markerInfo"
            headerContent={building.building_name}
          >
            <GoogleMapMarkerDetail building={building} address={Address} />
            <div className="right-buttons">
              {/* {Number(user.role.id) === 1 ? (
                <IonButton
                  title="smartSheet"
                  size="small"
                  href={`https://app.smartsheet.com/sheets/rMfX5rmVCCPC7Qf7gCQqJQ3CHwcchGC32C85hrc1?view=grid&filterId=3979805927100292`}
                  target="_blank"
                >
                  <IonIcon
                    slot="icon-only"
                    ios={smartSheetIcon}
                    md={smartSheetIcon}
                  />
                </IonButton>
              ) : null} */}
              <IonButton
                title="Building Page"
                size="small"
                href={`/?id=${building.uuid}&sec=buildings`}
              >
                <IonIcon slot="icon-only" ios={businessSharp} md={business} />
              </IonButton>
              <IonButton
                title="Customer Page"
                size="small"
                href={`/?id=${building.cust_uuid}&sec=customers`}
              >
                <IonIcon slot="icon-only" ios={personSharp} md={person} />
              </IonButton>
            </div>
          </InfoWindow>
        )}
      </>
    );
  }
  return null;
};

export default GoogleMapMarker;
