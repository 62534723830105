import { IonButton, IonCheckbox, IonCol, IonInput, IonItem, IonLoading, IonRow } from "@ionic/react"
import { useState } from "react"
import { Redirect } from "react-router"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import CheckRole from "../extras/CheckRole"
import "./SingleBillForm.css"

interface Props{
    index:number,
    onChange:any,
    handleDelete:any,
    values:any,
    valid:any,
    setValid:any,
    meter_type:string
}

interface startingForm{
    startdate:any
    enddate:any
    usage:any
    cost:any
    estimatedvalue:any
    demand:any
    demandCost:any
}
const startingErrors = {
    startdate:"",
    enddate:"",
    usage:"",
    cost:"",
    estimatedvalue:"",
    demand:"",
    demandCost:""
}




const SingleBillForm:React.FC<Props> = ({index,onChange, handleDelete, values, valid, setValid,meter_type}) =>{
    const [form, setForm] = useState<startingForm>(values)
    const [errors, setErrors] = useState(startingErrors)
    const user = useAppSelector(selectUser)

    console.log('type',meter_type)

    const handleFormChange = (input:string,value:any) => {
        setErrors({...errors, ...validate(input,value) })
        setForm((prevState) => {
            return {
                ...prevState,
                [input]:value
            }
        } )
        onChange(index,{...form, [input]:value})

    }
    const getCurrentDate = () => {
        return new Date()
      };

    const validate = (input:string, value:any) => {
        switch (input){
            case "startdate":
                if (value === '' ){
                    valid[index].startdate=false
                    setValid([...valid] )
                    return {'startdate':"Start Date Cannot Be Empty"}
                }
                else if (new Date(value) > getCurrentDate() ){
                    valid[index].startdate=false
                    setValid([...valid] )
                    return {'startdate':"Invalid Date"}
                }
                else {
                    valid[index].startdate=true
                    setValid([...valid])
                    return {'startdate':''}
                }
            case "enddate":
                if (value === '' ){
                    valid[index].enddate=false
                    setValid([...valid] )
                    return {'enddate':"Start Date Cannot Be Empty"}
                }
                else if (new Date(value) > getCurrentDate() ){
                    valid[index].enddate=false
                    setValid([...valid] )
                    return {'enddate':"Invalid Date"}
                }
                else {
                    valid[index].startdate=true
                    setValid([...valid])
                    return {'enddate':''}
                }
            case "usage":
                if (value === ''){
                    valid[index].usage=false
                    setValid([...valid] )
                    return {'usage':"Usage Cannot Be Empty"}
                }
                if (value < 0){
                    valid[index].usage=false
                    setValid([...valid] )
                    return {'usage':"Usage Cannot Be Less Than 0"}
                }
                else {
                    valid[index].usage=true
                    setValid([...valid] )
                    return {'usage':''}
                }
            case "cost":
                if (value === ''){
                    valid[index].cost=false
                    setValid([...valid] )
                    return {'cost':"Cost Cannot Be Empty"}
                }
                if (value < 0){
                    valid[index].cost=false
                    setValid([...valid] )
                    return {'cost':"Cost Cannot Be Less Than 0"}
                }
                else {
                    valid[index].cost=true
                    setValid([...valid] )
                    return {'cost':''}
                }

            default:
                break;
        }
    }

    if (user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={user.first_name===""}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }

    return(
        <form className="bill-form" style={{width:'100%'}}>
            <IonRow>

                <IonCol>
                    <IonItem lines="none">
                        <IonInput className={errors.startdate === '' ? "ion-valid":'ion-invalid ion-touched'} errorText={errors.startdate} label="Start Date" labelPlacement="floating"  type="date" value={form.startdate} placeholder="Start Date" onIonChange={e => (handleFormChange( 'startdate',e.detail.value!))}/>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem lines="none">
                        <IonInput className={errors.enddate === '' ? "valid":'ion-invalid ion-touched'} errorText={errors.enddate} label="End Date" labelPlacement="floating" type="date" required value={form.enddate} onIonChange={e => (handleFormChange( 'enddate',e.detail.value!))}/>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem lines="none">
                        <IonInput className={errors.usage === '' ? "valid":'ion-invalid ion-touched'} errorText={errors.usage} label="Usage" labelPlacement="floating" type='number' value={form.usage} placeholder="Usage" onIonChange={e => (handleFormChange( 'usage',e.detail.value!))}>
                        </IonInput>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem lines="none">
                        <IonInput className={errors.cost === '' ? "valid":'ion-invalid ion-touched'} errorText={errors.cost} label="Cost" labelPlacement="floating" type='number' value={form.cost} placeholder="Cost" onIonChange={e => (handleFormChange( 'cost',e.detail.value!))}>
                        </IonInput>
                    </IonItem>
                </IonCol>
                {meter_type==='Electric'?
                <>
                <IonCol>
                    <IonItem lines="none">
                        <IonInput className={errors.demand === '' ? "valid":'ion-invalid ion-touched'} errorText={errors.demand} label="Demand" labelPlacement="floating" type='number' value={form.demand} placeholder="Demand" onIonChange={e => (handleFormChange( 'demand',e.detail.value!))}>
                        </IonInput>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem lines="none">
                        <IonInput className={errors.demandCost === '' ? "valid":'ion-invalid ion-touched'} errorText={errors.demandCost} label="Demand Cost" labelPlacement="floating" type='number' value={form.demandCost} placeholder="Demand Cost" onIonChange={e => (handleFormChange( 'demandCost',e.detail.value!))}>
                        </IonInput>
                    </IonItem>
                </IonCol>
                </>
                :
                null}
                <IonCol>
                    <IonItem lines="none">
                        <IonCheckbox className={errors.estimatedvalue === '' ? "valid":'invalid'} checked={form.estimatedvalue} mode='ios' slot="start" onIonChange={e => (handleFormChange('estimatedvalue',e.detail.checked))}>Estimated Value?</IonCheckbox>
                        <div className="error-detail">{errors.estimatedvalue}</div>
                    </IonItem>
                </IonCol>
                <IonCol size="1">
                    <IonButton type="button" color="danger" onClick={() => (handleDelete(index))}>Delete</IonButton>
                </IonCol>
            </IonRow>

        </form>
    )
}

export default SingleBillForm