import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../Hooks'
import { selectUser } from '../../reducers/UserReducer'
import { API_URL } from '../../actions/settings'
import AE2CustomerInfo from '../../components/AE2/AE2CustomerInfo'

async function getCustomer(token:any,uuid:any) {

  return fetch(`${API_URL}/customers/${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

const CustomerHomepage: React.FC = () => {

    const user = useAppSelector(selectUser)
    const [customer, setCustomer] = useState('');

    useEffect(()=>{
      const handleCustomer = async () =>{
        const tkn:any = localStorage.getItem('token')
        const token = `Token ${tkn.slice(1,-1)}`
        const res = await getCustomer(token,user.customer_id)
        setCustomer(res.data)
      }

      handleCustomer()
    },[user])


    if(customer && customer !== ''){
      return <AE2CustomerInfo item={customer}/>
    }


  return <div> Your Account Has Not Been Linked To A Customer Account. </div>
}

export default CustomerHomepage