// interface Props {}

const copyright = "Copyright © 2024 - Alpha Controls & Services, LLC";

const InitialOnePageProposalTemplate = () => {
  return `<section class='cover'>
    <header></header>

    <main class='mceEditable'>
    <p></p>
    <p></p>
    <p></p>
    <p></p>
    <p></p>
    <p></p>
    <p></p>
    <p></p>
    <h1 style="text-align: center;" data-mce-style="text-align: center;">
    <img src="https://alpha-estar-dev.s3.us-east-2.amazonaws.com/one-page-proposal/imgs/1/1713364008834-Alpha-Icon.png"
    title="Alpha-Icon.png"
    width="225"
    height="225"
    alt=""
    data-mce-src="https://alpha-estar-dev.s3.us-east-2.amazonaws.com/one-page-proposal/imgs/1/1713364008834-Alpha-Icon.png">
    <br data-mce-bogus="1">
    </h1>
    <h1 style="text-align: center;">One Page Proposal Title</h1>
    </main>

<footer class='mceNonEditable'>
    <p><small>${copyright}</small></p>
</footer>
</section>
<section class=' cover'>
<header></header>
<main class='mceEditable'>
    <h3 data-mce-style="text-align: center;" style="text-align: center;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;"><strong>arcu bibendum at varius</strong></span></h3>
    <p style="text-align: center; line-height: 0.25;" data-mce-style="text-align: center; line-height: 0.25;"><em>scelerisque fermentum</em></p>
    <p style="text-align: left;" data-mce-style="text-align: left;">
        <strong><span class='mceNonEditable' style="font-size: 14pt;" data-mce-style="font-size: 14pt;">Target:</span> </strong>
        <span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </span>
    </p>
    <ul>
        <li data-mce-style="text-align: left;" style="text-align: left;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam pellentesque nec nam aliquam sem et tortor consequat.
        </span></li>
        <li data-mce-style="text-align: left;" style="text-align: left;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </span></li>
        <li data-mce-style="text-align: left;" style="text-align: left;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Est velit egestas dui id ornare arcu odio ut.
        </span></li>
        <li data-mce-style="text-align: left;" style="text-align: left;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </span></li>
    </ul>
    <p>[Insert Success Story Here] </p>
    <p></p>
    <p></p>
    <p></p>
    <p style="text-align: left;" data-mce-style="text-align: left;">
        <strong><span class='mceNonEditable' style="font-size: 14pt;" data-mce-style="font-size: 14pt;">Financial:</span> </strong>
        <span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci sagittis eu volutpat odio facilisis mauris. Tempus quam pellentesque nec nam.
        </span>
    </p>
    <p style="text-align: left;" data-mce-style="text-align: left;">
        <strong><span class='mceNonEditable' style="font-size: 14pt;" data-mce-style="font-size: 14pt;">Status:     </span> </strong>
        <span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </span>
    </p>
    <p style="text-align: left;" data-mce-style="text-align: left;">
        <strong><span class='mceNonEditable' style="font-size: 14pt;" data-mce-style="font-size: 14pt;">Action:</span> </strong>
        <span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci sagittis eu volutpat odio facilisis mauris. Tempus quam pellentesque nec nam.
        </span>
    </p>
</main>
<footer class='mceNonEditable'>
    <p><small>${copyright}</small></p>
</footer>
</section>
`;
};

export default InitialOnePageProposalTemplate;
