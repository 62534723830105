const isStaff = (user:any)=>{

    if (user.first_name !== ""){
        if (user.role.name === 'Employee' || user.role.name === 'Supervisor' || user.role.name === 'Admin' ){
            return true
        }
        return false
    }
    return false
}

export {isStaff}