import { IonItem, IonModal, IonTitle, IonInput, IonButton, IonHeader } from "@ionic/react"
import { useState } from "react"
import { API_URL } from "../../../../actions/settings"
import NotificationPopUp from "../../../extras/NotificationPopUp"

interface Props{
    opened:boolean,
    setShowModal:any,
    setData:any
}

async function addKeyword(data:any,token:any) {

    return fetch(`${API_URL}/success_keyword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }


const startForm = {
    "keyword": "",
    "abbreviation":""
}
const errorsForm:any = {
    "keyword": "",
    "abbreviation":""
}

const SuccessKeywordForm:React.FC<Props> = ({opened,setShowModal,setData}) =>{
    const [form, setForm] = useState(startForm)
    const [errors, setErrors] = useState(errorsForm)

    const validate = (name:string, val:any) => {
        switch (name) {
            case 'keyword':
                if(val === null || val.length <=1){
                    return {keyword:'Keyword cannot be empty.'}
                }
                else if( val.length >100){
                    return {keyword:'Must be less than 100 characters.'}
                }
                else{
                    return {keyword:''}
                }
            case 'abbreviation':
                if( val.length >6){
                    return {abbreviation:'Must be less than 6 characters.'}
                }
                else{
                    return {abbreviation:''}
                }
            default:
                break;
        }
    }

    const validateAll = (form:any) => {
        let validation = errorsForm
        let valid = true
        for (let item in form){

            validation = {...validation, ...validate(item,form[item])}

        }
        setErrors({...errors,...validation})

        for(let val in validation){
            if (validation[val] !== ''){
                valid = false;
            }

        }

        return valid
        }

    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
    }

    const handleSubmit = async (form:any) => {
        const token:any = localStorage.getItem('token')
        const valid = validateAll(form) || false
        if(valid){
            const res = await addKeyword(form,`Token ${token.slice(1,-1)}`)
            if (res.status === "success"){
                setShowModal(false)
                setForm(startForm)
                setData((prevState:any) => {
                    return [
                        ...prevState,
                        res.data
                    ]
                })
                NotificationPopUp('success','Keyword Added')

            }
            else{
                NotificationPopUp('Error',res.detail||res.ERROR,res.error)
            }
        }
    }

    return (
            <IonModal id="technologyModal" isOpen={opened} onDidDismiss={()=>setShowModal(false)}>
                <IonHeader><IonTitle>Add Keywords</IonTitle></IonHeader>
                    <form>
                        <IonItem>
                            <IonInput ion-label="Keyword:" className={errors.keyword === '' ? "valid":'invalid'} value={form.keyword} placeholder="Keyword" onIonChange={(e)=> handleFormChange(e.detail.value!,'keyword')}></IonInput>
                            <div className="error-detail">{errors.keyword}</div>
                        </IonItem>
                        <IonItem>

                            <IonInput ion-label="Abbreviation:" className={errors.abbreviation === '' ? "valid":'invalid'} value={form.abbreviation} placeholder="Abbreviation" onIonChange={(e)=> handleFormChange(e.detail.value!,'abbreviation')}></IonInput>
                            <div className="error-detail">{errors.abbreviation}</div>
                        </IonItem>
                    </form>
                    <div className='buttons'>
                    <IonButton onClick={()=>handleSubmit(form)} >Add</IonButton>
                    <IonButton onClick={()=>setShowModal(false)} color='danger'>cancel</IonButton>
                    </div>
            </IonModal>
    )
}

export default SuccessKeywordForm