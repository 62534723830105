import { IonContent, IonRow } from "@ionic/react";
import { useEffect } from "react";
import QuicksightEmbed from "../../extras/QuicksightEmbed";
import { API_URL } from "../../../actions/settings";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import { isStaff } from "../../extras/Functions";
import { Redirect } from "react-router";

async function getDashUrl(id: any, token: string) {
  return fetch(`${API_URL}/buildings/getDashUrl/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const PerformancePortfolio: React.FC = () => {
  const user = useAppSelector(selectUser);

  const handleDash = async (dashID: string) => {
    const dashboard = new QuicksightEmbed();
    await dashboard.init();
    const token = localStorage.getItem("token") || "";
    const res = await getDashUrl(dashID, `Token ${token.slice(1, -1)}`);
    dashboard.changeSettings({
      url: res[0],
      container: `#performance-portfolio`,
      exportDash: true,
      undoRedo: true,
      reset: true,
      sheetID:
        "a31f6a27-47a8-4be2-a369-1123bc1e28d3_9db1b7d8-1341-4653-90bd-8a4294396d84",
    });
    await dashboard.embedDash();
  };

  useEffect(() => {
    if (isStaff(user)) {
      handleDash("a31f6a27-47a8-4be2-a369-1123bc1e28d3");
    }
  }, [user]);

  if (user.first_name !== "" && !isStaff(user)) {
    return <Redirect to="/403" />;
  }

  return (
    <>
      <IonRow className="ion-justify-content-center">
        <h1 className="main-heading">Performance Results - Portfolio</h1>
      </IonRow>
      <IonContent id="performance-portfolio"></IonContent>
    </>
  );
};

export default PerformancePortfolio;
