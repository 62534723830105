import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonRow,
} from "@ionic/react";
import { Editor } from "@tinymce/tinymce-react";
import { API_URL, TINYMCE_API_KEY } from "../../actions/settings";
import { download_pdf } from "../../AE2/static/icons/download-pdf-icon";
import { file_icon } from "../../AE2/static/icons/file_icon";
import { document_icon } from "../../AE2/static/icons/document_icon";
import { red_trashcan } from "../../AE2/static/icons/red_trashcan";
import Ae2Header from "../../components/AE2/Ae2Header";
import InitialOnePageProposalTemplate from "../../components/AE2/OnePageProposal/InitialOnePageProposalTemplate";
import AddSuccessStoryModal from "../../components/AE2/OnePageProposal/AddSuccessStoryModal";
import { useEffect, useRef, useState } from "react";
import NotificationPopUp from "../../components/extras/NotificationPopUp";
import SaveOnePageProposalModal from "../../components/AE2/OnePageProposal/SaveOnePageProposalModal";
import OpenOnePageProposalModal from "../../components/AE2/OnePageProposal/OpenOnePageProposalModal";
import ReadFileFromS3 from "../../components/extras/ReadFileFromS3";
import S3Bucket from "../../components/extras/S3Bucket";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import { Redirect } from "react-router";

interface Proposal {
  created: string;
  id: string;
  title: string;
  updated: string;
  url: string;
  user: string;
}

declare const tinymce: any;

async function saveProposal(override: boolean, token: any, data: any) {
  return fetch(`${API_URL}/one-page-proposal`, {
    method: override ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

async function deleteProposal(token: any, data: any) {
  return fetch(`${API_URL}/one-page-proposal`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

async function getProposals(token: any) {
  return fetch(`${API_URL}/one-page-proposal`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const OnePageProposal: React.FC = () => {
  const user=useAppSelector(selectUser)
  const editorRef = useRef<any>(null);
  const trashRef = useRef<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSaveModal, setShowSavedModal] = useState<boolean>(false);
  const [showOpenModal, setOpenModal] = useState<boolean>(false);
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [loadedFile, setLoadedFile] = useState<boolean>(false);
  const [override, setOverride] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [proposals, setProposals] = useState<any>([]);
  const [file, setFile] = useState<any>("");
  const styles = `
      body {
        font-family:Helvetica,Arial,sans-serif;
        font-size:30px
        background-color: #f0eeee;
        width:750px;
        margin:auto;
      }
      section {
        box-sizing: content-box;
        width: 100%;
        max-height: 1056px;
        height: 1060px;
        margin: 2em auto;
        box-shadow: rgba(0, 0, 0, 0.75) 0px 10px 15px -3px, rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
        position:relative;
        padding: 1em;
        overflow-y:hidden;
    }
    main{
      height:100%;
    }
    footer{
      position: absolute;
      bottom: 0;
      line-height:0;

    }

    @media print {
      body{
        padding:0px !important;
        margin:0 !important;
        height:1946px;
        width:100%
      }
      section {
        height: 975px;
        max-height: 975px;
        box-shadow: none;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        padding:0p
      }
      footer p {
        margin-block-start: 0.1em;
        margin-block-end: 0.3em;
      }
 }
    `;

  useEffect(() => {
    handleProposals();
  }, []);

  const handleInsertStory = (story: string) => {
    editorRef.current.insertContent(
      `<p class="mceNonEditable" font-size: 14pt;> ${story}</p>`
    );
    setShowModal(false);
  };

  const handleUpload = async (file: any, filename: any) => {
    const res: any = await S3Bucket.UploadImage(
      "one-page-proposal/imgs",
      "1",
      file,
      filename,
      "img/png"
    );
    return res["Location"];
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("token") || "";
    await deleteProposal(`Token ${token.slice(1, -1)}`, file);

    handleNewDocument();
    setDeleteModal(false);
  };

  const handleProposals = async () => {
    const token = localStorage.getItem("token") || "";
    const res: any = await getProposals(`Token ${token.slice(1, -1)}`);
    if (res.status.toLowerCase() === "success") {
      setProposals(res.data);
    } else {
      setProposals([]);
    }
  };

  const handleSaveProposal = async () => {
    const data = {
      title: title,
      file: editorRef.current.contentDocument.body.innerHTML,
    };
    const token = localStorage.getItem("token") || "";
    const res = await saveProposal(
      override,
      `Token ${token.slice(1, -1)}`,
      data
    );
    if (res.status === "success" || res.status === "Success") {
      NotificationPopUp("success", res.detail);
    } else {
      NotificationPopUp("error", res.detail);
    }
    setShowSavedModal(false);
  };

  const handleNewDocument = () => {
    setLoadedFile(false);
    setTitle("");
    setOverride(false);
    trashRef.current.setEnabled(false);
    editorRef.current.setContent(InitialOnePageProposalTemplate());
  };

  const confirmDelete = async () => {
    await handleDelete();
    setTitle("");
    setFile("");
    setDeleteModal(false);
  };

  const loadFile = async (file: Proposal) => {
    setLoadedFile(true);
    setFile(file);
    trashRef.current.setEnabled(true);
    const html_file: any = await ReadFileFromS3(file.url);
    editorRef.current.contentDocument.body.innerHTML =
      html_file?.Body.toString("utf-8");
    setTitle(file.title);
    setOpenModal(false);
  };

  if(user.first_name !=='' && !user.groups.includes('OnePageProposalCreateEditDelete')){
    return <Redirect to="/403"/>
  }

  return (
    <IonContent className="estar-template">
      <Ae2Header />
      <AddSuccessStoryModal
        opened={showModal}
        setOpened={setShowModal}
        selectStory={handleInsertStory}
      />
      <IonRow className="ion-justify-content-center" style={{ height: "100%" }}>
        <Editor
          apiKey={TINYMCE_API_KEY}
          initialValue={InitialOnePageProposalTemplate()}
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            height: "100%",
            width: "1000px",
            plugins: "save image lists",
            editable_root: false,
            newdocument_content: InitialOnePageProposalTemplate(),
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | image save print help | trash",
            menu: {
              file: {
                title: "File",
                items: "open newdoc | print ",
              },
              story: { title: "Story", items: "success-story" },
            },
            menubar: "file edit view insert format story",
            content_style: styles,
            image_title: true,
            automatic_uploads: true,
            file_picker_types: "image",

            file_picker_callback: (cb, value, meta) => {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");

              input.addEventListener("change", (e: any) => {
                const file = e.target.files[0];

                const reader: any = new FileReader();
                reader.addEventListener("load", async () => {
                  const id = "blobid" + new Date().getTime();
                  const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  const base64 = reader.result.split(",")[1];
                  const blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  const url = await handleUpload(file, file.name);

                  /* call the callback and populate the Title field with the file name */
                  cb(url, { title: file.name });
                });
                reader.readAsDataURL(file);
              });

              input.click();
            },
            save_enablewhendirty: false,
            save_onsavecallback: (e: any) => {
              setShowSavedModal(true);
            },
            setup: function (editor) {
              editor.ui.registry.addIcon("download-pdf", download_pdf);
              editor.ui.registry.addIcon("document", file_icon);
              editor.ui.registry.addIcon("new_doc", document_icon);
              editor.ui.registry.addIcon("trash", red_trashcan);
              editor.ui.registry.addToggleMenuItem("success-story", {
                text: "Add Success Story",
                onAction: () => setShowModal(true),
              });
              editor.ui.registry.addToggleMenuItem("open", {
                text: "Open",
                icon: "document",
                onAction: () => setOpenModal(true),
              });
              editor.ui.registry.addToggleMenuItem("newdoc", {
                text: "New Document",
                icon: "new_doc",
                onAction: () => handleNewDocument(),
              });
              editor.ui.registry.addButton("trash", {
                enabled: false,
                icon: "trash",
                tooltip: "Delete Proposal",
                onAction: () => setDeleteModal(true),
                onSetup: (api) => {
                  trashRef.current = api;
                  return () => {};
                },
              });
            },
          }}
        />
      </IonRow>

      <SaveOnePageProposalModal
        opened={showSaveModal}
        setOpened={setShowSavedModal}
        title={title}
        setTitle={setTitle}
        proposals={proposals}
        save={(e: any) => handleSaveProposal()}
        loadedFile={loadedFile}
        override={override}
        setOverride={setOverride}
      />

      <OpenOnePageProposalModal
        opened={showOpenModal}
        setOpened={setOpenModal}
        proposals={proposals}
        loadFile={loadFile}
      />

      <IonModal
        isOpen={showDeleteModal}
        onDidDismiss={() => setDeleteModal(false)}
        className="confirmDelete"
      >
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol className="delete-warning">
              <span>WARNING:</span> Deleting the proposal will delete it
              permanently. This cannot be undone.
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton color="danger" onClick={() => confirmDelete()}>
              Delete
            </IonButton>
            <IonButton color="medium" onClick={() => setDeleteModal(false)}>
              Cancel
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonModal>
    </IonContent>
  );
};

export default OnePageProposal;
