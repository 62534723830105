import { IonButton, IonItem, IonLabel,IonModal, IonContent, IonRow, IonCol} from '@ionic/react';
import { API_URL } from "../../actions/settings"
import { useEffect, useState } from 'react';
import { setTaskState, selectTask, initialState} from '../../reducers/TaskReducer'
import { useAppDispatch, useAppSelector } from '../../Hooks';
import ProgressBar from '../extras/ProgrssBar';
import NotificationPopUp from '../extras/NotificationPopUp';
import EnergyStarTasks from './EnergyStarTasks';
import ConsumptionDataModal from './ConsumptionDataModal';
import ConsumptionResult from './results/ConsumptionResult';
import CSVConsumptionResult from './results/CSVConsumptionResult';
import MonthlyUtilityResults from './results/MonthlyUtilityResults';
import YearlyUtilityResult from './results/YearlyUtilityResult';
import ConsumptionDataTemplateErrors from './results/ConsumptionDataTemplateErrors';
import SearchSelectBuildingInput from '../extras/SearchSelectBuildingInput';
import MonthYearInput from '../extras/MonthYearInput';

async function getAllMonthlyData(data:any,token:string) {
    return fetch(`${API_URL}/energystar/get_monthly_use`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }
async function getAllYearlyData(data:any,token:string) {
    return fetch(`${API_URL}/energystar/properties_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }
async function getConsumptionData(data:any,token:string) {
    return fetch(`${API_URL}/energystar/property/consumption_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }
async function getSingleMonthlyData(id:string, data:any,token:string) {
    return fetch(`${API_URL}/energystar/get_monthly_use/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }
async function getSingleYearlyData(id:string, data:any,token:string) {
    return fetch(`${API_URL}/energystar/property_data/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }

async function getBuildings(token:any) {
    return fetch(`${API_URL}/energystar/customers/buildings`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
}

async function taskProgress(token:any,id:string) {
    return fetch(`${API_URL}/task_status/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
}

const EnergyStarData: React.FC = () =>{
    const today = new Date()
    const startingForm = {
        building_id:'',
        month: today.getMonth()+1,
        year: today.getFullYear()
    }

    const [openDateModal, setDateModal] = useState(false)
    const [consumptionDataModal, setConsumptionDataModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState<any>([])
    const [requestType, setRequestType] = useState("")
    const [buildings, setBuildings] = useState<any>([])
    const [form, setForm] = useState(startingForm)
    const task = useAppSelector(selectTask)
    const dispatch = useAppDispatch()
    const [refresh, setRefresh] = useState(false)


    useEffect(()=>{
        const params =  new URLSearchParams(window.location.search);
        handleBuildings()
        const add_consumption=params.get('modal')
        if(add_consumption === 'consumption'){
            setConsumptionDataModal(true)
        }
      },[])


    const handle_all_monthly_data = async () =>{
        const data = {
            month: form.month,
            year: form.year
        }
        setLoading(true)
        const tkn:any = localStorage.getItem('token')
        const token = `Token ${tkn.slice(1,-1)}`
        const res = await getAllMonthlyData(data,token)
        checkTaskProgress(res.task)

    }
    const handle_all_yearly_data = async () =>{
        const data = {
            month: form.month,
            year: form.year
        }
        setLoading(true)
        const tkn:any = localStorage.getItem('token')
        const token = `Token ${tkn.slice(1,-1)}`
        const res = await getAllYearlyData(data,token)
        checkTaskProgress(res.task)
    }
    const handle_all_consumption_data = async () =>{
        setLoading(true)
        const tkn:any = localStorage.getItem('token')
        const token = `Token ${tkn.slice(1,-1)}`
        const res = await getConsumptionData({'id':'all'},token)
        checkTaskProgress(res.task)

    }
    const handleBuildings = async () => {
        const tkn:any = localStorage.getItem('token')
        const token = `Token ${tkn.slice(1,-1)}`
        const res = await getBuildings(token)
        setBuildings(res.data)
    }

    const handleFilterRequest = () =>{
        setLoading(true)
        setDateModal(false)

        if(requestType === 'monthly'){
            handleMonthlyRequest()
        }
        if(requestType === 'yearly'){
            handleYearlyRequest()
        }
        if(requestType === 'consumption'){
            handleConsumptionRequest()
        }
    }

    const handleMonthlyRequest = async ()  => {
        if (form['building_id'] === ""){
            handle_all_monthly_data()
        }
        else{
            const data = {
                month: form.month,
                year: form.year
            }
            const tkn:any = localStorage.getItem('token')
            const token = `Token ${tkn.slice(1,-1)}`
            const res = await getSingleMonthlyData(form['building_id'],data,token)
            checkTaskProgress(res.task)

        }
      };

    const handleYearlyRequest = async ()  => {
        if (form['building_id'] === ""){
            handle_all_yearly_data()
        }
        else{
            const data = {
                month: form.month,
                year: form.year
            }
            const tkn:any = localStorage.getItem('token')
            const token = `Token ${tkn.slice(1,-1)}`
            const res = await getSingleYearlyData(form['building_id'],data,token)
            checkTaskProgress(res.task)
        }
      };
    const handleConsumptionRequest = async ()  => {
        if (form['building_id'] === ""){
            handle_all_consumption_data()
        }
        else{
            const tkn:any = localStorage.getItem('token')
            const token = `Token ${tkn.slice(1,-1)}`
            const res = await getConsumptionData({"id":form['building_id']},token)
            checkTaskProgress(res.task)
        }
      };

    const uploadingData = (task_id:string) => {
        setLoading(true)
        checkTaskProgress(task_id)
    }

    const checkTaskProgress = async (task_id:string) => {
        const tkn:any = localStorage.getItem('token')
        const token = `Token ${tkn.slice(1,-1)}`
        const task_res = await taskProgress(token,task_id)
        if (task_res.state === 'SUCCESS' || task_res.state === 'PENDING'){
            dispatch(setTaskState({
                task_id:task_id,
                status:task_res.state,
                result:{
                    progress:0
                }
            }))

            const interval = setInterval(async () =>{
            const task_res = await taskProgress(token,task_id)
            dispatch(setTaskState({
                task_id:task_id,
                status:task_res.state,
                result:task_res.details
            }))

            if (task_res.state === "SUCCESS"){
                clearInterval(interval)
                setResults(task_res.details)
                setLoading(false)
                setRefresh(!refresh)

            }
            if((task_res.state === "FAILURE")) {
                if ('Template_Errors' in task_res.details){
                    setResults(task_res.details)
                }else{
                NotificationPopUp("Error", task_res.details, "Could not complete request", 10)
                }
                dispatch(setTaskState(initialState))
                clearInterval(interval)
                setRefresh(!refresh)
                setLoading(false)
            }
            },2000)
        }
        if((task_res.state === "FAILURE")) {
            if ('Template_Errors' in task_res.details){
                setResults(task_res.details)
                setRefresh(!refresh)

            }else{
            NotificationPopUp("Error", task_res.details, "Could not complete request", 10)
            }
            dispatch(setTaskState(initialState))
            setLoading(false)
        }


    }


    const handleFormChange = (value:any,name:string) => {
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }
    return (
        <div>
            <IonRow style={{borderBottom:'solid 2px black'}}>
                <IonCol>
            <h2>Energy Star Utility Data:</h2>
            <p> Get the monthly electricity and gas use for all properties or single property. </p>
            <div>
            <ul  >
                <li> <div className="vertical-center"> Utility Data for all properties for this month.
                <IonButton size='small' shape='round' onClick={() => {setResults([]);setRequestType('monthly');handle_all_monthly_data()}}> Get All Data</IonButton></div> </li>
                <li><div className='vertical-center'>Filter Request for certain properties or month.
                <IonButton size='small' shape='round' onClick={()=>{setResults([]);setRequestType('monthly');setDateModal(true)}}>Filter Request</IonButton>
                </div>
                </li>
            </ul>
            </div>
            </IonCol>
            <IonCol>
            <h2>Energy Star Yearly Data</h2>
            <p> Get the yearly data for all properties or single property. This data includes energy cost, energy star score, electricity use, gas use, target eui, target cost, gas_cost, water cost and ghg_emissions.  </p>
            <div>
            <ul  >
                <li> <div className="vertical-center"> Yearly Data for all Properties.
                <IonButton size='small' shape='round' onClick={() => {setResults([]);setRequestType('yearly');handle_all_yearly_data()}}> Get All Data</IonButton></div> </li>
                <li><div className='vertical-center'> Filter Yearly Data Request.
                <IonButton size='small' shape='round' onClick={()=>{setResults([]);setRequestType('yearly');setDateModal(true)}}>Filter Request</IonButton>
                </div>
                </li>
            </ul>
            </div>
            </IonCol>
            <IonCol>
                <h2> Consumption Data </h2>
                <p>Get Consumption Data (Bills) for all or single property. Visit Meter page to request only a specific meter.</p>
                <div>
                    <ul>
                        <li><div className="vertical-center">Consumption For all Properties
                        <IonButton size='small' shape='round' onClick={() => {setResults([]);setRequestType('consumption');handle_all_consumption_data()}}>Get All Data</IonButton></div></li>
                        <li><div className="vertical-center">Filter Consumption Request
                        <IonButton size='small' shape='round' onClick={()=>{setResults([]);setRequestType('consumption');setDateModal(true)}}>Filter request</IonButton></div></li>
                        <li><div className="vertical-center">Upload Bills
                        <IonButton size='small' shape='round' onClick={()=>{setResults([]);setRequestType('upload');setConsumptionDataModal(true)}}>Upload</IonButton></div></li>
                    </ul>
                </div>
            </IonCol>
            </IonRow>

            <IonRow style={{height:'100%'}}>
                <IonCol size='12' sizeMd='8'>
                    <h2 className='vertical-center' style={{paddingTop:'5px'}}>Results</h2>
                    <div className="results">
                        {task.result? <ProgressBar bgcolor='#212121' completed={task.status === 'SUCCESS' ? '100' : 'progress' in task.result ? task.result.progress :'0'}/> : null}
                        {loading?
                        null:
                        results.length >= 0 ?

                            requestType === 'consumption' ?
                                <ConsumptionResult data={results}/>
                            :
                                requestType === 'upload' ?
                                    <CSVConsumptionResult data={results}/>
                                :
                                    requestType === 'monthly' ?
                                        <MonthlyUtilityResults data={results}/>
                                    :
                                        requestType === 'yearly' ?
                                            <YearlyUtilityResult data={results}/>
                                        :
                                            <div>
                                                No Results
                                            </div>

                        : 'Template_Errors' in results ?
                            <ConsumptionDataTemplateErrors errors={results['Template_Errors']}/>
                            :
                                "No Results"}

                    </div>
                </IonCol>
                <IonCol  size='12' sizeMd='4' style={{height:"500px"}}>
                        <EnergyStarTasks refresh={refresh}/>
                </IonCol>
            </IonRow>
            <IonModal className='modal-background' isOpen={openDateModal} onDidDismiss={()=>{setDateModal(false)}} id='datetime-modal'>
                <IonContent style={{display:'flex',justifyContent:'center'}}>
                    <h2 style={{textAlign:'center'}}> Filter Request</h2>
                    <IonItem>
                        <IonLabel position='stacked'>Property:</IonLabel>
                        <SearchSelectBuildingInput label='filtered_building' dict={buildings} dict_key='id' value_key='name' errors="" form={form} handleFormChange={handleFormChange} formName='building_id' placeholder='ALL' def_value="ALL"/>
                    </IonItem>
                        <MonthYearInput hidden={requestType==='consumption'} form={form} handleFormChange={handleFormChange} startYear={1900}/>
                </IonContent>
                <div className='modal-btns' style={{display:'flex', backgroundColor:'transparent'}}>
                    <IonButton color='primary' fill='solid' onClick={handleFilterRequest}>Get Data</IonButton>
                    <IonButton color='danger' fill='solid' onClick={()=>{setDateModal(false)}}>Cancel</IonButton>
                </div>
            </IonModal>

            <ConsumptionDataModal opened={consumptionDataModal} setOpened={setConsumptionDataModal} progress={uploadingData}/>

        </div>
    )
}

export default EnergyStarData