import {
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import reset_password_icon from "../AE2/static/icons/reset_password_icon.svg";
import EmailUsernameForm from "../components/PasswordReset/EmailUsernameForm";
import OTPForm from "../components/PasswordReset/OTPForm";
import NewPasswordForm from "../components/PasswordReset/NewPasswordForm";

const style = {
  height: "600px",
  width: "600px",
  border: "2px solid black",
  backgroundColor: "#0aa6e9c5",
  borderRadius: "5%",
  boxShadow: "0px 0px 20px black",
};

const renderTab = (
  tab: string,
  email: string,
  setEmail: any,
  setTab: any,
  validOTP: any,
  setValidOTP: any
) => {
  switch (tab) {
    case "email":
      return <EmailUsernameForm setTab={setTab} setOTPEmail={setEmail} />;
    case "OTP":
      return (
        <OTPForm email={email} setTab={setTab} setValidOTP={setValidOTP} />
      );
    case "NewPassword":
      if (validOTP) {
        return <NewPasswordForm email={email} OTP={validOTP} />;
      } else {
        setTab("email");
        return;
      }
    default:
      return "";
  }
};

const ResetPassword = () => {
  const [tab, setTab] = useState("email");
  const [validOTP, setValidOTP] = useState(null);
  const [email, setEmail] = useState("");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div className="toolbar">
            <IonTitle>Alpha Enterprise Server (AE2)</IonTitle>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="main-background">
        <IonRow className="center-all">
          <div className="center-all" style={style}>
            <div>
              <IonRow
                className="ion-justify-content-center"
                style={{ marginBottom: "5px" }}
              >
                <IonCol>
                  <IonIcon
                    icon={reset_password_icon}
                    style={{ width: "150px", height: "120px" }}
                  />
                  <h2>Forgot/Reset Password</h2>
                </IonCol>
              </IonRow>
              {renderTab(tab, email, setEmail, setTab, validOTP, setValidOTP)}
            </div>
          </div>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
