import { IonSearchbar } from "@ionic/react";
import { useState } from "react";

interface Props {
    list:any,
    setFiltered:any,
    setData:Function
}

const handleSearch = (items:any,term:string,setItems:any,setSearchText:any, setData:Function) =>{
    setSearchText(term)
    if(term===''){
        setItems(items)
        setData(items.slice(0,30))
    }
    else{
        const filtered = items.filter((item:any)=> item['name'].toLowerCase().includes(term.toLocaleLowerCase()))
        setItems(filtered);
        setData(filtered.slice(0,30))
    }

}


const Search: React.FC<Props> = ({list,setFiltered, setData}) => {
    const [searchText, setSearchText] = useState('');
    return(
        <div className="searchBar">
            <IonSearchbar value={searchText} onIonChange={e => handleSearch(list, e.detail.value!, setFiltered, setSearchText, setData)} animated></IonSearchbar>
        </div>
    )
}

export default Search