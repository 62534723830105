import {
  IonButton,
  IonCol,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
} from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { Building } from "../../interfaces/Building";

interface props {
  buildings: Building[];
  style?:any
}

const AE2CustomerBuildingList: React.FC<props> = ({ style,buildings }) => {
  if (buildings.length <= 0) {
    return <h3>There are no Buildings at this moment.</h3>;
  }

  return (
    <IonList className="transparent" style={style}>
      {buildings.map((building: Building) => {
        return (
          <IonItem
            key={building.uuid}
            style={{
              border: "black solid 2px",
              margin: "3px",
              "--padding-start": 0,
              "--inner-padding-end": 0,
            }}
          >
            <IonRow style={{ width: "100%" }}>
              <IonCol
                size="8"
                className="vertical-center"
                style={{ borderRight: "black solid 2px", textAlign: "center" }}
              >
                {building.building_name}
              </IonCol>
              <IonCol
                size="3"
                className="vertical-center"
                style={{
                  borderRight: "black solid 2px",
                  justifyContent: "center",
                }}
              >
                {building.baseline?.property.score || "N/A"}
              </IonCol>
              {/* <IonCol size="2" style={{borderRight:'black solid 2px'}}> TBD</IonCol> */}
              <IonCol size="1">
                <IonButton
                  fill="clear"
                  style={{
                    "--padding-start": 0,
                    "--padding-end": 0,
                    width: "100%",
                    justifyContent: "center",
                  }}
                  title="Building Info"
                  href={`/?id=${building.uuid}&sec=buildings`}
                >
                  <IonIcon
                    color="dark"
                    src={chevronForwardOutline}
                    slot="icon-only"
                  />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonItem>
        );
      })}
    </IonList>
  );
};

export default AE2CustomerBuildingList;
