import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonRow,
} from "@ionic/react";
import "./Analytics.css";

const cards = [
  {
    title: "EBO Data",
    href: "/server_data",
    summary: (
      <p>
        Table for All EBO Server Information. <br /> (i.e. Software/hardware
        Version, CPU Usage, etc.)
      </p>
    ),
  },
  {
    title: "REAPR",
    href: "/reapr_status",
    summary: (
      <p>
        Table for REAPR System Status <br /> (i.e. Comm State, Offline Count,
        etc.)
      </p>
    ),
  },
  {
    title: "AE2 Building Performance",
    href: "/buildings_performance",
    summary: (
      <p>
        Table for All AE2 Buildings Performance Table <br /> (i.e. Sq.Ft., kWh,
        therms, kBtu, etc.)
      </p>
    ),
  },
  {
    title: "AE2 Monthly Building Performance",
    href: "/buildings_monthly_performance",
    summary: (
      <p>
        Dashboard for Monthly Performance of all AE2 Buildings
        <br /> (i.e. Campus Optimizer, Fan Cycling, kWh, etc.)
      </p>
    ),
  },
  {
    title: "AE2 Performance Results",
    href: "/performance_portfolio",
    summary: (
      <p>
        Dashboard for AE2 Performance Results Portfolio <br /> (i.e. Meeting
        Targets and Performance){" "}
      </p>
    ),
  },
  {
    title: "Cost Avoidance Dashboard",
    href: "/cost_avoidance_dash",
    summary: (
      <p>Dashboard for cost avoidance calculation based on baseline. </p>
    ),
  },
];

const Analytics: React.FC = () => {
  return (
    <IonRow>
      <IonCol size="12">
        <IonRow className="ion-justify-content-center">
          {cards.map((card: any) => {
            return (
              <IonCard className="analytics-card">
                <IonCardHeader>
                  <IonCardTitle>{card.title}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>{card.summary}</IonCardContent>
                <div className="right-align">
                <IonButton color="medium" href={card.href}>
                  View
                </IonButton>
                </div>

              </IonCard>
            );
          })}
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default Analytics;
