import { IonRow } from "@ionic/react"
import "./LoadingDots.css"

interface Props {
    style?:any
}

const LoadingDots:React.FC<Props> = ({style}) => {
    return (
        <IonRow className="ion-justify-content-center" style={style}>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </IonRow>
    )
}

export default LoadingDots