import { IonContent } from "@ionic/react";
import Ae2Header from "../../components/AE2/Ae2Header";
import PerformancePortfolio from "../../components/AE2/AnalyticsSection/PerformancePortfolio";
import CostAvoidanceDashboard from "../../components/AE2/AdminSection/CostAvoidanceDashboard";
interface vars {
  dash: string;
}

const FullPageDashboard: React.FC<vars> = ({ dash }) => {
  return (
    <IonContent className="energy-performance main-background">
      <Ae2Header />

      {dash === "PerformancePortfolio" ? <PerformancePortfolio /> : ""}
      {dash === "CostAvoidance" ? <CostAvoidanceDashboard /> : ""}
    </IonContent>
  );
};

export default FullPageDashboard;
