import { IonContent, IonInput, IonItem, IonLabel, IonList, IonPopover, IonSelect, IonSelectOption } from "@ionic/react"
import './SearchSelectInput.css'
import { isMobile } from 'react-device-detect';

/* Component used to render a form input with search included. The list must be a list of lists such as
[['IL', "Illinois"], ['WI', 'Wisconsin']] */

interface Props{
    list:string[][],
    handleFormChange:any,
    errors:any,
    form:any,
    formName:string,
    placeholder:string,
    label?:string
}

const clickInput = (e:any) => {
    if (e.detail.relatedTarget !==null){
        e.target.click()
    }
}


const SearchSelectInput:React.FC<Props> = ({list,errors,form,handleFormChange,formName,placeholder,label})=>{


    if (isMobile){
        return<IonItem>

            <IonSelect style={{'max-width':'100%'}} aria-labelledby={label} className={errors === '' ? "valid":'invalid'} interface='popover' value={form} placeholder={placeholder} onIonChange={(e:any) => {handleFormChange(e.detail.value!,formName)}}>
                {list.map((item:any) => {
                    return (
                        <IonSelectOption key={item[0]} value={item[0]}>{formName.includes('state') || formName.includes('country') ? `${item[0]} - ${item[1]}` : item[1]}</IonSelectOption>
                    )
                })}
            </IonSelect>
        </IonItem>
    }

    return<>
        <IonInput id={`${formName}_input`} className={errors === '' ? "valid":'invalid'} aria-labelledby={label} onIonFocus={(e:any)=>{clickInput(e)}} value={form} placeholder={placeholder} onIonChange={(e:any) => {handleFormChange(e.detail.value!,formName)}}></IonInput>
            <IonPopover className="select-popover" trigger={`${formName}_input`} reference="trigger" dismissOnSelect showBackdrop={false} backdropDismiss keyboardClose={false} size='cover'>
                <IonContent>
                    <IonList>
                    {list.filter((item:any)=>(item[1].toLowerCase().includes(form.toLowerCase())||item[0].toLowerCase().includes(form.toLowerCase()))).map((item:any) => {
                    return (
                        <IonItem key={item[0]} button onClick={() => (handleFormChange(item[0],formName))}><IonLabel>{formName.includes('state') || formName.includes('country') ? `${item[0]} - ${item[1]}` : item[1]}</IonLabel></IonItem>
                    )
                })}
                    </IonList>
                </IonContent>
        </IonPopover>
    </>
}

export default SearchSelectInput