import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"

interface Props{
    title:string
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfWorkers : "",
numberOfWorkers_date : ""


}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfWorkers : "",
numberOfWorkers_date : ""
}

const Other: React.FC<Props> = ({title,setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('weeklyOperatingHours'):
                if (value < 0){
                    handleError('weeklyOperatingHours','Hours must be a positive number')
                }
                else if (value > 168){
                    handleError('weeklyOperatingHours','Hours mush be less than 168')
                }
                else {
                    handleError('weeklyOperatingHours','')
                }
                if( value !== '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','Date cannot be empty')
                }
                if( value === '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case ('weeklyOperatingHours_date'):
                if (form.weeklyOperatingHours !== ''){
                    if (value === ''){
                        handleError('weeklyOperatingHours_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('weeklyOperatingHours_date','Invalid Date')
                    }
                    else {
                        handleError('weeklyOperatingHours_date','')
                    }
                }
                break;
            case ('numberOfComputers'):
                if (value < 0){
                    handleError('numberOfComputers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfComputers','')
                }
                if( value !== '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','')
                }
                break;
            case ('numberOfComputers_date'):
                if (form.numberOfComputers !== ''){
                    if (value === ''){
                        handleError('numberOfComputers_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfComputers_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfComputers_date','')
                    }
                }
                break;
            case ('numberOfWorkers'):
                if (value < 0){
                    handleError('numberOfWorkers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfWorkers','')
                }
                if( value !== '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','')
                }
                break;
            case ('numberOfWorkers_date'):
                if (form.numberOfWorkers !== ''){
                    if (value === ''){
                        handleError('numberOfWorkers_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfWorkers_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfWorkers_date','')
                    }
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>{title} Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Weekly Operating Hours</IonLabel>
                        <IonInput type="number" className={errors.weeklyOperatingHours === '' ? "valid":'invalid'}  value={form.weeklyOperatingHours} placeholder='Hours' onIonChange={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours'))}></IonInput>
                        <div className="error-detail">{errors.weeklyOperatingHours}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.weeklyOperatingHours_date === '' ? "valid":'invalid'}  value={form.weeklyOperatingHours_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours_date'))}></IonInput>
                        <div className="error-detail">{errors.weeklyOperatingHours_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number Of Computers</IonLabel>
                        <IonInput type="number" className={errors.numberOfComputers === '' ? "valid":'invalid'}  value={form.numberOfComputers} placeholder='Computers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfComputers_date === '' ? "valid":'invalid'}  value={form.numberOfComputers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfComputers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number Of Workers</IonLabel>
                        <IonInput type="number" className={errors.numberOfWorkers === '' ? "valid":'invalid'}  value={form.numberOfWorkers} placeholder='Computers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWorkers_date === '' ? "valid":'invalid'}  value={form.numberOfWorkers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWorkers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default Other