import { IonImg } from "@ionic/react";
import { useState } from "react";
import stockIMG from "../AE2/static/pics/alpha_small_logo.png";
import "./MainThumbnailImage.css";

interface props {
  src?: any;
  style?: any;
  className?: string;
}

const MainThumbnailImage: React.FC<props> = ({ src, style, className }) => {
  const [img, setImg] = useState(src);

  return (
    <IonImg
      className={"main-thumbnail-image " + className}
      style={style}
      src={img}
      onIonError={(e: any) => {
        setImg(stockIMG);
        e.currentTarget.className += " stock-img";
      }}
    />
  );
};

export default MainThumbnailImage;
