import { IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
    totalGrossFloorArea : "",
    totalGrossFloorArea_units : "",
    totalGrossFloorArea_date : "",
    numberOfIndoorIceRinks : "",
    numberOfIndoorIceRinks_date : "",
    totalIceRinkSurfaceAreaForAllRinks : "",
    totalIceRinkSurfaceAreaForAllRinks_units : "",
    totalIceRinkSurfaceAreaForAllRinks_date : "",
    monthsMainIndoorIceRinkInUse : "",
    monthsMainIndoorIceRinkInUse_date : "",
    numberOfWeeklyIceResurfacingForAllRinks : "",
    numberOfWeeklyIceResurfacingForAllRinks_date : "",
    numberOfFTEWorkers : "",
    numberOfFTEWorkers_date : "",
    numberOfCurlingSheets : "",
    numberOfCurlingSheets_date : "",
    spectatorSeatingCapacity : "",
    spectatorSeatingCapacity_date : "",
}

const startErrors = {
    totalGrossFloorArea : "Field Required",
    totalGrossFloorArea_units : "Select Units",
    totalGrossFloorArea_date : "Date is required",
    numberOfIndoorIceRinks : "",
    numberOfIndoorIceRinks_date : "",
    totalIceRinkSurfaceAreaForAllRinks : "",
    totalIceRinkSurfaceAreaForAllRinks_units : "",
    totalIceRinkSurfaceAreaForAllRinks_date : "",
    monthsMainIndoorIceRinkInUse : "",
    monthsMainIndoorIceRinkInUse_date : "",
    numberOfWeeklyIceResurfacingForAllRinks : "",
    numberOfWeeklyIceResurfacingForAllRinks_date : "",
    numberOfFTEWorkers : "",
    numberOfFTEWorkers_date : "",
    numberOfCurlingSheets : "",
    numberOfCurlingSheets_date : "",
    spectatorSeatingCapacity : "",
    spectatorSeatingCapacity_date : "",
}



const IceRink: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('totalIceRinkSurfaceAreaForAllRinks'):
                if(value !== ''){
                    form.totalIceRinkSurfaceAreaForAllRinks_date === '' ? handleError('totalIceRinkSurfaceAreaForAllRinks_date','Date Cannot be Empty') : handleError('totalIceRinkSurfaceAreaForAllRinks_date',"")
                    form.totalIceRinkSurfaceAreaForAllRinks_units === '' ? handleError('totalIceRinkSurfaceAreaForAllRinks_units','Units Cannot be Empty') : handleError('totalIceRinkSurfaceAreaForAllRinks_units',"")
                    value < 0 ? handleError('totalIceRinkSurfaceAreaForAllRinks','Must be a Positive Number') : handleError('totalIceRinkSurfaceAreaForAllRinks',"")
                }
                else{
                    handleError('totalIceRinkSurfaceAreaForAllRinks','')
                    if(form.totalIceRinkSurfaceAreaForAllRinks_date === '' && form.totalIceRinkSurfaceAreaForAllRinks_units === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks_date','')
                        handleError('totalIceRinkSurfaceAreaForAllRinks_units','')}
                    else if(form.totalIceRinkSurfaceAreaForAllRinks_date === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks'," Field Cannot be empty")
                        handleError('totalIceRinkSurfaceAreaForAllRinks_date'," Date Cannot be empty")
                    }
                    else if(form.totalIceRinkSurfaceAreaForAllRinks_units === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks'," Field Cannot be empty")
                        handleError('totalIceRinkSurfaceAreaForAllRinks_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('totalIceRinkSurfaceAreaForAllRinks_date'):
                if(value !== ''){
                    form.totalIceRinkSurfaceAreaForAllRinks === '' ? handleError('totalIceRinkSurfaceAreaForAllRinks','Field Cannot be Empty') : handleError('totalIceRinkSurfaceAreaForAllRinks',"")
                    form.totalIceRinkSurfaceAreaForAllRinks_units === '' ? handleError('totalIceRinkSurfaceAreaForAllRinks_units','Units Cannot be Empty') : handleError('totalIceRinkSurfaceAreaForAllRinks_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('totalIceRinkSurfaceAreaForAllRinks_date','Invalid Date') : handleError('totalIceRinkSurfaceAreaForAllRinks_date','')
                }
                else{
                    handleError('totalIceRinkSurfaceAreaForAllRinks_date','')
                    if(form.totalIceRinkSurfaceAreaForAllRinks === '' && form.totalIceRinkSurfaceAreaForAllRinks_units === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks','')
                        handleError('totalIceRinkSurfaceAreaForAllRinks_units','')}
                    else if(form.totalIceRinkSurfaceAreaForAllRinks === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks'," Field Cannot be empty")
                        handleError('totalIceRinkSurfaceAreaForAllRinks_date'," Date Cannot be empty")
                    }
                    else if(form.totalIceRinkSurfaceAreaForAllRinks_units === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks_date'," Field Cannot be empty")
                        handleError('totalIceRinkSurfaceAreaForAllRinks'," Unit Cannot be empty")
                    }

                }
                break;
            case('totalIceRinkSurfaceAreaForAllRinks_units'):
                if(value !== ''){
                    form.totalIceRinkSurfaceAreaForAllRinks === '' ? handleError('totalIceRinkSurfaceAreaForAllRinks','Field Cannot be Empty') : handleError('totalIceRinkSurfaceAreaForAllRinks',"")
                    form.totalIceRinkSurfaceAreaForAllRinks_date === '' ? handleError('totalIceRinkSurfaceAreaForAllRinks_date','Date Cannot be Empty') : handleError('totalIceRinkSurfaceAreaForAllRinks_date',"")
                }
                else{
                    handleError('totalIceRinkSurfaceAreaForAllRinks_units','')
                    if(form.totalIceRinkSurfaceAreaForAllRinks === '' && form.totalIceRinkSurfaceAreaForAllRinks_date === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks','')
                        handleError('totalIceRinkSurfaceAreaForAllRinks_date','')}
                    else if(form.totalIceRinkSurfaceAreaForAllRinks === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks'," Field Cannot be empty")
                        handleError('totalIceRinkSurfaceAreaForAllRinks_units'," Date Cannot be empty")
                    }
                    else if(form.totalIceRinkSurfaceAreaForAllRinks === ''){
                        handleError('totalIceRinkSurfaceAreaForAllRinks_date'," Field Cannot be empty")
                        handleError('totalIceRinkSurfaceAreaForAllRinks'," Unit Cannot be empty")
                    }

                }
                break;
            case('monthsMainIndoorIceRinkInUse'):
                if(value !==''){
                    form.monthsMainIndoorIceRinkInUse_date === '' ? handleError('monthsMainIndoorIceRinkInUse_date','Date cannot be empty') : handleError('monthsMainIndoorIceRinkInUse_date','')
                    value < 0 ? handleError('monthsMainIndoorIceRinkInUse','Must be a Positive number') : value > 12 ? handleError('monthsMainIndoorIceRinkInUse','Max Value is 12') : handleError('monthsMainIndoorIceRinkInUse','')
                }
                else{
                    handleError('monthsMainIndoorIceRinkInUse','')
                    handleError('monthsMainIndoorIceRinkInUse_date','')
                }
                break;
            case('monthsMainIndoorIceRinkInUse_date'):
                if(value !==''){
                    form.monthsMainIndoorIceRinkInUse === '' ? handleError('monthsMainIndoorIceRinkInUse','Field cannot be empty') : handleError('monthsMainIndoorIceRinkInUse','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('monthsMainIndoorIceRinkInUse_date','Invalid Date') : handleError('monthsMainIndoorIceRinkInUse_date','')
                }
                else{
                    handleError('monthsMainIndoorIceRinkInUse','')
                    handleError('monthsMainIndoorIceRinkInUse_date','')
                }
                break;
            case('numberOfIndoorIceRinks'):
                if(value !==''){
                    form.numberOfIndoorIceRinks_date === '' ? handleError('numberOfIndoorIceRinks_date','Date cannot be empty') : handleError('numberOfIndoorIceRinks_date','')
                    value < 0 ? handleError('numberOfIndoorIceRinks','Must be a Positive number') : handleError('numberOfIndoorIceRinks','')
                }
                else{
                    handleError('numberOfIndoorIceRinks','')
                    handleError('numberOfIndoorIceRinks_date','')
                }
                break;
            case('numberOfIndoorIceRinks_date'):
                if(value !==''){
                    form.numberOfIndoorIceRinks === '' ? handleError('numberOfIndoorIceRinks','Field cannot be empty') : handleError('numberOfIndoorIceRinks','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfIndoorIceRinks_date','Invalid Date') : handleError('numberOfIndoorIceRinks_date','')
                }
                else{
                    handleError('numberOfIndoorIceRinks','')
                    handleError('numberOfIndoorIceRinks_date','')
                }
                break;
            case('numberOfWeeklyIceResurfacingForAllRinks'):
                if(value !==''){
                    form.numberOfWeeklyIceResurfacingForAllRinks_date === '' ? handleError('numberOfWeeklyIceResurfacingForAllRinks_date','Date cannot be empty') : handleError('numberOfWeeklyIceResurfacingForAllRinks_date','')
                    value < 0 ? handleError('numberOfWeeklyIceResurfacingForAllRinks','Must be a Positive number') : handleError('numberOfWeeklyIceResurfacingForAllRinks','')
                }
                else{
                    handleError('numberOfWeeklyIceResurfacingForAllRinks','')
                    handleError('numberOfWeeklyIceResurfacingForAllRinks_date','')
                }
                break;
            case('numberOfWeeklyIceResurfacingForAllRinks_date'):
                if(value !==''){
                    form.numberOfWeeklyIceResurfacingForAllRinks === '' ? handleError('numberOfWeeklyIceResurfacingForAllRinks','Field cannot be empty') : handleError('numberOfWeeklyIceResurfacingForAllRinks','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWeeklyIceResurfacingForAllRinks_date','Invalid Date') : handleError('numberOfWeeklyIceResurfacingForAllRinks_date','')
                }
                else{
                    handleError('numberOfWeeklyIceResurfacingForAllRinks','')
                    handleError('numberOfWeeklyIceResurfacingForAllRinks_date','')
                }
                break;
            case('numberOfFTEWorkers'):
                if(value !==''){
                    form.numberOfFTEWorkers_date === '' ? handleError('numberOfFTEWorkers_date','Date cannot be empty') : handleError('numberOfFTEWorkers_date','')
                    value < 0 ? handleError('numberOfFTEWorkers','Must be a Positive number') : handleError('numberOfFTEWorkers','')
                }
                else{
                    handleError('numberOfFTEWorkers','')
                    handleError('numberOfFTEWorkers_date','')
                }
                break;
            case('numberOfFTEWorkers_date'):
                if(value !==''){
                    form.numberOfFTEWorkers === '' ? handleError('numberOfFTEWorkers','Field cannot be empty') : handleError('numberOfFTEWorkers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfFTEWorkers_date','Invalid Date') : handleError('numberOfFTEWorkers_date','')
                }
                else{
                    handleError('numberOfFTEWorkers','')
                    handleError('numberOfFTEWorkers_date','')
                }
                break;
            case('numberOfCurlingSheets'):
                if(value !==''){
                    form.numberOfCurlingSheets_date === '' ? handleError('numberOfCurlingSheets_date','Date cannot be empty') : handleError('numberOfCurlingSheets_date','')
                    value < 0 ? handleError('numberOfCurlingSheets','Must be a Positive number') : handleError('numberOfCurlingSheets','')
                }
                else{
                    handleError('numberOfCurlingSheets','')
                    handleError('numberOfCurlingSheets_date','')
                }
                break;
            case('numberOfCurlingSheets_date'):
                if(value !==''){
                    form.numberOfCurlingSheets === '' ? handleError('numberOfCurlingSheets','Field cannot be empty') : handleError('numberOfCurlingSheets','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfCurlingSheets_date','Invalid Date') : handleError('numberOfCurlingSheets_date','')
                }
                else{
                    handleError('numberOfCurlingSheets','')
                    handleError('numberOfCurlingSheets_date','')
                }
                break;
            case('spectatorSeatingCapacity'):
                if(value !==''){
                    form.spectatorSeatingCapacity_date === '' ? handleError('spectatorSeatingCapacity_date','Date cannot be empty') : handleError('spectatorSeatingCapacity_date','')
                    value < 0 ? handleError('spectatorSeatingCapacity','Must be a Positive number') : handleError('spectatorSeatingCapacity','')
                }
                else{
                    handleError('spectatorSeatingCapacity','')
                    handleError('spectatorSeatingCapacity_date','')
                }
                break;
            case('spectatorSeatingCapacity_date'):
                if(value !==''){
                    form.spectatorSeatingCapacity === '' ? handleError('spectatorSeatingCapacity','Field cannot be empty') : handleError('spectatorSeatingCapacity','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('spectatorSeatingCapacity_date','Invalid Date') : handleError('spectatorSeatingCapacity_date','')
                }
                else{
                    handleError('spectatorSeatingCapacity','')
                    handleError('spectatorSeatingCapacity_date','')
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Ice Curling Rink Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Total Gross Floor Area:</IonLabel>
                        <IonInput type="number" className={errors.totalGrossFloorArea === '' ? "valid":'invalid'}  value={form.totalGrossFloorArea} placeholder='GFA' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalGrossFloorArea_date === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></IonInput>
                        <div className="error-detail">{errors.totalGrossFloorArea_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked">Total Ice Rink Surface Area For All Rinks</IonLabel>
                        <IonInput type="number" className={errors.totalIceRinkSurfaceAreaForAllRinks === '' ? "valid":'invalid'}  value={form.totalIceRinkSurfaceAreaForAllRinks} placeholder='Surface Area of All Rinks' onIonChange={e => (handleFormChange(e.detail.value!,'totalIceRinkSurfaceAreaForAllRinks'))}></IonInput>
                        <div className="error-detail">{errors.totalIceRinkSurfaceAreaForAllRinks}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Units</IonLabel>
                        <IonSelect className={errors.totalIceRinkSurfaceAreaForAllRinks_units === '' ? "valid":'invalid'} value={form.totalIceRinkSurfaceAreaForAllRinks_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalIceRinkSurfaceAreaForAllRinks_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalIceRinkSurfaceAreaForAllRinks_units}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.totalIceRinkSurfaceAreaForAllRinks_date === '' ? "valid":'invalid'} value={form.totalIceRinkSurfaceAreaForAllRinks_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'totalIceRinkSurfaceAreaForAllRinks_date'))}></IonInput>
                        <div className="error-detail">{errors.totalIceRinkSurfaceAreaForAllRinks_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Indoor Ice Rinks</IonLabel>
                        <IonInput type="number" className={errors.numberOfIndoorIceRinks === '' ? "valid":'invalid'}  value={form.numberOfIndoorIceRinks} placeholder='# Indoor Rice Rinks' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfIndoorIceRinks'))}></IonInput>
                        <div className="error-detail">{errors.numberOfIndoorIceRinks}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfIndoorIceRinks_date === '' ? "valid":'invalid'}  value={form.numberOfIndoorIceRinks_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfIndoorIceRinks_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfIndoorIceRinks_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Months Main Indoor Ice Rink Is In Use</IonLabel>
                        <IonInput type="number" className={errors.monthsMainIndoorIceRinkInUse === '' ? "valid":'invalid'}  value={form.monthsMainIndoorIceRinkInUse} placeholder='# Months Main Indoor Rink In Use' onIonChange={e => (handleFormChange(e.detail.value!,'monthsMainIndoorIceRinkInUse'))}></IonInput>
                        <div className="error-detail">{errors.monthsMainIndoorIceRinkInUse}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.monthsMainIndoorIceRinkInUse_date === '' ? "valid":'invalid'}  value={form.monthsMainIndoorIceRinkInUse_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'monthsMainIndoorIceRinkInUse_date'))}></IonInput>
                        <div className="error-detail">{errors.monthsMainIndoorIceRinkInUse_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Weekly Ice Resurfacing For All Rinks</IonLabel>
                        <IonInput type="number" className={errors.numberOfWeeklyIceResurfacingForAllRinks === '' ? "valid":'invalid'}  value={form.numberOfWeeklyIceResurfacingForAllRinks} placeholder='# Weekly Ice Resurfacing' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWeeklyIceResurfacingForAllRinks'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWeeklyIceResurfacingForAllRinks}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfWeeklyIceResurfacingForAllRinks_date === '' ? "valid":'invalid'}  value={form.numberOfWeeklyIceResurfacingForAllRinks_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfWeeklyIceResurfacingForAllRinks_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfWeeklyIceResurfacingForAllRinks_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of FTE Workers</IonLabel>
                        <IonInput type="number" className={errors.numberOfFTEWorkers === '' ? "valid":'invalid'}  value={form.numberOfFTEWorkers} placeholder='# FTE Workers' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfFTEWorkers'))}></IonInput>
                        <div className="error-detail">{errors.numberOfFTEWorkers}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfFTEWorkers_date === '' ? "valid":'invalid'}  value={form.numberOfFTEWorkers_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfFTEWorkers_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfFTEWorkers_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Number of Curling Sheets</IonLabel>
                        <IonInput type="number" className={errors.numberOfCurlingSheets === '' ? "valid":'invalid'}  value={form.numberOfCurlingSheets} placeholder='# Curling Sheets' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfCurlingSheets'))}></IonInput>
                        <div className="error-detail">{errors.numberOfCurlingSheets}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.numberOfCurlingSheets_date === '' ? "valid":'invalid'}  value={form.numberOfCurlingSheets_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'numberOfCurlingSheets_date'))}></IonInput>
                        <div className="error-detail">{errors.numberOfCurlingSheets_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Spectator Seating Capacity</IonLabel>
                        <IonInput type="number" className={errors.spectatorSeatingCapacity === '' ? "valid":'invalid'}  value={form.spectatorSeatingCapacity} placeholder='Spectator Seating Capacity' onIonChange={e => (handleFormChange(e.detail.value!,'spectatorSeatingCapacity'))}></IonInput>
                        <div className="error-detail">{errors.spectatorSeatingCapacity}</div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <IonLabel position="stacked"> Current as of:</IonLabel>
                        <IonInput type="date" className={errors.spectatorSeatingCapacity_date === '' ? "valid":'invalid'}  value={form.spectatorSeatingCapacity_date} placeholder='Date' onIonChange={e => (handleFormChange(e.detail.value!,'spectatorSeatingCapacity_date'))}></IonInput>
                        <div className="error-detail">{errors.spectatorSeatingCapacity_date}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default IceRink
