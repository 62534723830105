import { IonApp, IonSplitPane, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Menu from "./components/Menu";
import Login from "./pages/Login";
import useToken from "./components/auth/useToken";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import AzureLogin from "./components/auth/AzureLogin";
import RoutesList from "./RoutesList";
import ScreenShot from "./pages/ScreenShot";
import "./App.css";

import { CookiesProvider } from "react-cookie";
import ResetPassword from "./pages/ResetPassword";

setupIonicReact();

const App: React.FC = () => {
  const { token, setToken } = useToken();

  if (!token && window.location.pathname === "/microsoft-login") {
    let params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const state = params.get("state");
    const session_state = params.get("session_state");
    if (code && state) {
      return (
        <AzureLogin
          setToken={setToken}
          code={code}
          state={state}
          session_state={session_state}
        />
      );
    }
  }

  if (!token && window.location.pathname !== "/reset_password") {
    return <Login setToken={setToken} />;
  }

  if (window.location.pathname === "/dash_screenshot") {
    return <ScreenShot />;
  }

  return (
    <CookiesProvider>
      <IonApp>
        <ReactNotifications />
        <IonReactRouter>
          {window.location.pathname === "/reset_password" ? (
            <ResetPassword />
          ) : (
            <IonSplitPane style={{'--side-max-width':'300px'}} contentId="main">
              <Menu token={token} />
              <RoutesList setToken={setToken} />
            </IonSplitPane>
          )}
        </IonReactRouter>
        {/* <iframe title='printable' name='printable' id="printable"></iframe> */}
      </IonApp>
    </CookiesProvider>
  );
};

export default App;
