import { IonButton, IonContent, IonPage, IonRow } from "@ionic/react";
import { Redirect, useParams } from "react-router";
import Ae2Header from "../../components/AE2/Ae2Header";
import DegreeDaysTable from "../../components/AE2/AnalyticsSection/DegreeDaysTable";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/UserReducer";
import { isStaff } from "../../components/extras/Functions";

interface Params {
  id: any;
}
interface vars {
  all?: boolean;
}

const AE2BuildingPerformance: React.FC<vars> = ({ all = false }) => {
  const user = useSelector(selectUser);
  const { id } = useParams<Params>();

  if (user.first_name !== "" && !isStaff(user)) {
    return <Redirect to="/403" />;
  }

  return (
    <IonPage className="ae2-home">
      <Ae2Header />
      <IonContent className="main-background">
        <IonRow className="ion-justify-content-center">
          <h1 className="main-heading">Annual Building Performance</h1>
        </IonRow>
        <IonRow hidden={all}>
          {" "}
          <IonButton size="small" href={`/?id=${id}&sec=customers`}>
            Customer Page
          </IonButton>
        </IonRow>
        {all ? (
          <DegreeDaysTable type="all" />
        ) : (
          <DegreeDaysTable id={id} type="customer" />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AE2BuildingPerformance;
