import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLoading,
  IonRow,
} from "@ionic/react";
import { addOutline, createOutline } from "ionicons/icons";
import {
  REACT_APP_AWS_BUCKET_NAME,
  REACT_APP_AWS_REGION,
} from "../../actions/settings";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import MainThumbnailImage from "../MainThumbnailImage";
import "./AE2CampusInfo.css";
import AE2CustomerBuildingList from "./AE2CustomerBuildingList";

interface props {
  item: any;
}

const AE2CampusInfo: React.FC<props> = ({ item }) => {
  const user = useAppSelector(selectUser);
  const role = user.role.name;

  if (!item) {
    return (
      <IonLoading
        cssClass="my-custom-class"
        isOpen={!item}
        message={"Loading..."}
        spinner="bubbles"
        duration={10000}
      />
    );
  }

  return (
    <IonGrid>
      <IonRow>
        <IonCol className="pc-only" size="12" sizeLg="2">
          <MainThumbnailImage
            src={`https://${REACT_APP_AWS_BUCKET_NAME}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/thumbnails/pics/${item.uuid}-campus-img`}
          />
        </IonCol>
        <IonCol size="12" sizeLg="10">
          <IonRow className="ion-justify-content-center ae2-campus-name">
            <h1 className="align-bottom">
              <span>{item.campus_name}</span>
              <IonButton
                hidden={!(role === "Supervisor" || role === "Admin")}
                fill="clear"
                color="light"
                slot="icon-only"
                href={`/campus/${item.uuid}/edit`}
              >
                <IonIcon slot="icon-only" src={createOutline}></IonIcon>
              </IonButton>
            </h1>
          </IonRow>
          <IonRow className="ion-justify-content-center mobile-only thumbnail-mobile">
            <IonCol size="12">
              <MainThumbnailImage
                src={`https://${REACT_APP_AWS_BUCKET_NAME}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/thumbnails/pics/${item.uuid}-campus-img`}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="basic-campus-info">
              <h2>Campus Information:</h2>
              <div>
                &emsp;<span>Customer:</span>{" "}
                <span className="customer-name">
                  <a href={`/?id=${item.cust_uuid.uuid}&sec=customers`}>
                    {item.cust_uuid.cust_name}
                  </a>
                </span>
              </div>
              <div>
                &emsp;<span>Description:</span> {item.campus_description}
              </div>
              <div>
                &emsp;<span>Address:</span> {item.campus_address}{" "}
                {item.campus_address_2 ? item.campus_address_2 : ""}
              </div>
              <div>
                &emsp;{item.campus_city}, {item.campus_state},{" "}
                {item.campus_zipcode}
              </div>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="6">
          <h2 className="building-title">
            <span>Buildings:</span>{" "}
            <IonButton
              hidden={!(role === "Supervisor" || role === "Admin")}
              title="Add Building"
              shape="round"
              size="small"
              slot="icon-only"
              href={`/customer/${item.cust_uuid.uuid}/addproperty?campus=${item.uuid}`}
            >
              <IonIcon src={addOutline} />
            </IonButton>
          </h2>
          <AE2CustomerBuildingList buildings={item.buildings} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default AE2CampusInfo;
