import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from "react";
import { API_URL, TINYMCE_API_KEY } from "../../../actions/settings";
import AE2SoftwareUpload from "../../extras/AE2SoftwareUpload";
import LoadingSpinner from "../../extras/LoadingSpinner";
import NotificationPopUp from "../../extras/NotificationPopUp";

interface vars{
    opened:boolean,
    setOpened:any,
    updateData:any,
    software_id:any
}

async function createSoftware(token:any,data:any,id:any) {

  return fetch(`${API_URL}/ae2_software_releases/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    },
    body: JSON.stringify(data)

  })
    .then(data => data.json())
 }
 const startingForm = {
    release_name:"",
    notes:""
 }

async function getSoftware(token:any,id:any) {

  return fetch(`${API_URL}/ae2_software/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    },

  })
    .then(data => data.json())
 }

const UploadSoftwareReleaseModal: React.FC<vars> = ({opened=false,setOpened,updateData,software_id}) => {

    const editorRef = useRef<any>(null);
    const [form,setForm] = useState(startingForm)
    const [errors,setErrors] = useState(startingForm)
    const [file, setFile] = useState<any>(null)
    const [uploading, setUploading] = useState(false)
    const [software, setSoftware] = useState<any>({})
    // eslint-disable-next-line
    const [state, setState] = updateData

    useEffect(()=>{
      const handleSoftware = async () =>{
        const token = localStorage.getItem('token') || ""
        const res = await getSoftware(`Token ${token.slice(1,-1)}`,software_id)
        setSoftware(res.data)
      }
      handleSoftware()
    },[software_id])

    const handleFormChange = (value:any,name:string) => {
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const validate = (name:string, val:any) => {
        switch(name){
            case 'name':
                if(val === null || val.length <=1){
                    return {name:'Name Cannot Be Empty'}
                }
                else{
                    return {name:''}
                }
            case 'path':
                if(val === null || val.length <=1){
                    return {path:'Folder Name (path) Cannot Be Empty'}
                }
                else if(val[0]==='/'){
                    return {path:'Do not start with /'}
                }
                else{
                    return {path:''}
                }
            case 'release_name':
                if(val === null || val.length <=1){
                    return {release_name:'Release Name Cannot Be Empty'}
                }
                else if(val.endsWith('.exe') || val.endsWith('.zip')){
                    return {release_name:'Do Not Include the Extension(.exe)'}
                }
                else{
                    return {release_name:''}
                }
            default:
                break;
        }
    }

  const save = async () => {
    const token = localStorage.getItem('token') || ""

    const paths = software['path'].split('/')

    setUploading(true)
    const upload = await AE2SoftwareUpload(paths[0],paths.slice(1).join('/')+'/'+form.release_name+"."+file!.name.split('.').pop(),file,file.type)
    if(upload){
        const content = editorRef.current!.getContent();
        const data = {
            name:form.release_name+'.'+file!.name.split('.').pop(),
            notes:content,
            software:software.id
        }
        const res = await createSoftware(`Token ${token.slice(1,-1)}`,data,software['id'])
      if(res.status === "success"){
        setForm(startingForm)
        NotificationPopUp('success','Software Uploaded')
        setOpened(false)
        setUploading(false)
        setState((prevState:any) => {
          return [
            {...res.data},
              ...prevState

        ]
      })

        return
    }
    }
    NotificationPopUp('Error',"Could Not Upload File")
    setUploading(false)
  };

  const onFileChange = (e:any) =>{
    const file = e.target.files[0]
    setFile(file)

}



  return(
      <IonModal className="upload-software-modal modal-background" isOpen={opened} onDidDismiss={()=>setOpened(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Upload New Release</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setOpened(false)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRow className="ion-justify-content-center">
        <form>
        <IonRow>
            <IonItem>
                <IonLabel position="stacked">Release Name (without file extension)</IonLabel>
                <IonInput className={errors.release_name === '' ? "valid":'invalid'} value={form.release_name} placeholder="Release Name" onIonChange={e => (handleFormChange(e.detail.value!,'release_name'))}> </IonInput>
                <div className="error-detail">{errors.release_name}</div>
            </IonItem>
        </IonRow>
        <IonRow>
            <IonItem>
                <IonLabel position="stacked">File</IonLabel>
                {/* <input type="file" className={errors.file === '' ? "valid":'invalid'} value={form.file} placeholder="File" onChange={e => (handleFormChange(e,'file'))}> </input> */}
                <input type="file" accept="application/*" onChange={onFileChange}/>
            </IonItem>
        </IonRow>
        <IonRow>
            <IonItem>
                <IonLabel position="stacked">Release Notes:</IonLabel>
                <div className="editor" id='editor'>
      <Editor
          apiKey={TINYMCE_API_KEY}
          initialValue='<p>Add Notes</p>'
          onInit={(evt, editor) => editorRef.current = editor}
          init={{
         height: '350px',
         toolbar: 'undo redo | formatselect | ' +
         'bold italic backcolor | alignleft aligncenter ' +
         'alignright alignjustify | bullist numlist outdent indent | ' +
         'removeformat',
         menu: {
          template: {title: 'Template', items: "edit-template"}
        },
        menubar: 'file edit view insert format',
         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
       }}
     />

      </div>
            </IonItem>
        </IonRow>

        </form>

        </IonRow>
        <IonRow className="ion-justify-content-center">
            {uploading ? <div><LoadingSpinner/>Uploading</div> : <IonButton onClick={()=>save()}>Submit</IonButton>}
        </IonRow>
      </IonContent>
    </IonModal>
  )
}

export default UploadSoftwareReleaseModal;