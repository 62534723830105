import { useEffect, useState } from "react"
import { API_URL } from "../../../actions/settings"
import useHandleState from "../../../utils/useHandleState"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonModal, IonRow, IonTitle } from "@ionic/react"
import QuicksightDashboardForm from "./forms/QuicksightDashboardForm"
import QuicksightSingleParameterForm from "./forms/QuicksightSingleParameterForm"
import { trashBinSharp } from "ionicons/icons"
import NotificationPopUp from "../../extras/NotificationPopUp"

async function loadDashboardList(token:any) {

    return fetch(`${API_URL}/quicksightDashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }
async function deleteDashboard(data:any,token:any) {

    return fetch(`${API_URL}/quicksightDashboard`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }

const QuicksightDashboardsSettings:React.FC = () => {

    const [dashboards,handleDashboards,setDashboards] = useHandleState([])
    const [opened,setOpened] = useState(false)
    const [paramModal, setParamModal] =  useState(false)
    const [selectedDash, setSelectedDash] =  useState('')
    const [deleteModal, setDeleteModal] =  useState(false)
    const [deleteDash, setDeleteDash] =  useState('')

    useEffect(()=>{
        const token = localStorage.getItem('token')
        handleDashboards(token,loadDashboardList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleDeleteDashboard = async () => {
        const token = localStorage.getItem('token') || ""
        const res = await deleteDashboard({'id':deleteDash},`Token ${token.slice(1,-1)}`)
        if (res.status === 'success'){
            console.log(dashboards.filter((dash:any)=>(dash.id !== deleteDash)))
            setDashboards(
                dashboards.filter((dash:any)=>(dash.id !== deleteDash))
            )
            setDeleteDash("")
            NotificationPopUp('success','Dashboard Deleted')
        }
        else{
            NotificationPopUp("Error",res.detail||res.data)
        }
        setDeleteModal(false)
    }

    return(
        <div>
            <IonRow style={{justifyContent:'space-between'}}>
                <IonTitle>Quicksight Dashboards</IonTitle>
                <IonButton onClick={()=>setOpened(true)}>New DashBoard</IonButton>
            </IonRow>

            <IonRow>
                {dashboards.map((dash:any) => {
                    return(
                        <IonCard key={dash.quicksight_id}>
                            <IonCardHeader>
                                <IonCardTitle>
                                    <IonButton fill='clear' style={{"float":"right"}} onClick={()=>{setDeleteDash(dash.id);setDeleteModal(true)}} >
                                        <IonIcon size='small' color="danger" slot="icon-only" src={trashBinSharp}/>
                                    </IonButton>
                                    {dash.name}
                                </IonCardTitle>
                                <IonCardSubtitle>{dash.quicksight_id}</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <h2 style={{color:'black'}}>Parameters:</h2>
                                {dash.params.length > 0 ?
                                <ul>
                                    {dash.params.map((param:any)=>{

                                        return(
                                            <li key={param.id}>
                                                {param.display_name} - {param.type.name}
                                                {param.type.id === '0bca5ed6-f59c-46da-bedd-5f49d5a3e27d' ? <ul>
                                                    {param.choices.map((choice:any) => {
                                                        return (<li key={choice.id}>
                                                            {choice.name}
                                                        </li>)
                                                    })}
                                                    {/* <li><IonButton size="small" fill="solid" color='medium'>Add Choice</IonButton></li> */}
                                                </ul> : ''}
                                            </li>
                                        )
                                    })}

                                </ul> : <p style={{color:'black',marginLeft:'1em'}}>No Parameters</p>}
                            </IonCardContent>
                            <IonItem lines="none">
                                <IonButton fill="solid" slot="end" color="medium" onClick={()=>{setParamModal(true);setSelectedDash(dash.id)}}>Add Parameter</IonButton>
                            </IonItem>
                        </IonCard>
                    )
                })}
            </IonRow>
            <QuicksightDashboardForm opened={opened} setShowModal={setOpened} setDashboards={setDashboards}/>
            <QuicksightSingleParameterForm opened={paramModal} setShowModal={setParamModal} dashboardID={selectedDash} setDashboards={setDashboards}/>

            <IonModal isOpen={deleteModal} onDidDismiss={()=>{setDeleteModal(false);setDeleteDash("")}} className="confirmDelete">
                <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="delete-warning">
                                Are You Sure You Want To Delete This Dashboard?
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonButton color="danger" onClick={()=> handleDeleteDashboard()} >Delete</IonButton>
                            <IonButton color="medium" onClick={ ()=> setDeleteModal(false)} >Cancel</IonButton>
                        </IonRow>
                </IonGrid>
            </IonModal>
        </div>
    )

}

export default QuicksightDashboardsSettings

