export class InvalidRequestError extends Error {
    readonly type = "InvalidRequestError" as const;
  }
export class UnexpectedError extends Error {
    readonly type = "UnexpectedError" as const;
  }
export class DoesNoTExistsError extends Error {
    constructor(message:string) {
        super(message);
        this.name = "DoesNoTExistsError";
      }
  }
export class NotAuthorizedError extends Error {
    constructor(message:string) {
        super(message);
        this.name = "NotAuthorizedError";
      }
  }


