import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from "react";
import { API_URL, TINYMCE_API_KEY } from "../../../actions/settings";
import AE2SoftwareUpload from "../../extras/AE2SoftwareUpload";
import LoadingSpinner from "../../extras/LoadingSpinner";
import NotificationPopUp from "../../extras/NotificationPopUp";

interface vars{
    opened:boolean,
    setOpened:any,
    updateData:any
}

async function createSoftware(token:any,data:any) {

  return fetch(`${API_URL}/ae2_software`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    },
    body: JSON.stringify(data)

  })
    .then(data => data.json())
 }
 const startingForm = {
    name:"",
    path:"",
    release_name:"",
    notes:""
 }

const UploadSoftwareModal: React.FC<vars> = ({opened=false,setOpened,updateData}) => {

    const editorRef = useRef<any>(null);
    const [form,setForm] = useState(startingForm)
    const [errors,setErrors] = useState(startingForm)
    const [file, setFile] = useState<any>(null)
    const [uploading, setUploading] = useState(false)

    const handleFormChange = (value:any,name:string) => {
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const validate = (name:string, val:any) => {
        switch(name){
            case 'name':
                if(val === null || val.length <=1){
                    return {name:'Name Cannot Be Empty'}
                }
                else{
                    return {name:''}
                }
            case 'path':
                if(val === null || val.length <=1){
                    return {path:'Folder Name (path) Cannot Be Empty'}
                }
                else if(val[0]==='/'){
                    return {path:'Do not start with /'}
                }
                else{
                    return {path:''}
                }
            case 'release_name':
                if(val === null || val.length <=1){
                    return {release_name:'Release Name Cannot Be Empty'}
                }
                else if(val.endsWith('.exe') || val.endsWith('.zip')){
                    return {release_name:'Do Not Include the Extension(.exe)'}
                }
                else{
                    return {release_name:''}
                }
            default:
                break;
        }
    }



  const save = async () => {
    const token = localStorage.getItem('token') || ""
    if (form.path[0]==='/'){
        form.path = form.path.slice(1)
    }

    const path = 'alpha-ae2-setup-files/' +form.path
    let errorMessage = ""
    let errorsList = null

    setUploading(true)
    const upload = await AE2SoftwareUpload('alpha-ae2-setup-files',form.path +'/'+form.release_name+"."+file.name.split('.').pop(),file,file.type)
    if(upload){
        const content = editorRef.current!.getContent();
        const data = {
            name:form.release_name+'.'+ file!.name.split('.').pop(),
            notes:content,
            software:{
                name:form.name,
                path:path
            },
        }
      const res = await createSoftware(`Token ${token.slice(1,-1)}`,data)
      if(res.status === "success"){
        setForm(startingForm)
        NotificationPopUp('success','Software Uploaded')
        setOpened(false)
        updateData()
        setUploading(false)
        return
    }
    errorMessage = JSON.stringify(res.data)
    errorsList = JSON.stringify(res.errors)
    }
    NotificationPopUp('Error',errorsList || "Could Not Upload File","Error:"+{errorMessage})
    setUploading(false)
  };

  const onFileChange = (e:any) =>{
    const file = e.target.files[0]
    setFile(file)
    console.log(file.name.split('.').pop())

}



  return(
      <IonModal className="upload-software-modal modal-background" isOpen={opened} onDidDismiss={()=>setOpened(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Upload New Software</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setOpened(false)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRow className="ion-justify-content-center">
          <IonRow className="ion-justify-content-center"><p style={{textAlign:'center',color:'yellow'}}>Warning: Use this form to upload new software not a new release of current software. If you want to upload a new release of a current software visit the releases page for that software.</p></IonRow>
        <form>
        <IonRow>
            <IonItem>
                <IonInput label="Name" labelPlacement="stacked" className={errors.name === '' ? "valid":'invalid'} value={form.name} placeholder="Name" onIonChange={e => (handleFormChange(e.detail.value!,'name'))}> </IonInput>
                <div className="error-detail">{errors.name}</div>
            </IonItem>
        </IonRow>
        <IonRow>
            <IonItem>
                <IonInput label="Folder Name(Path)" labelPlacement="stacked" className={errors.path === '' ? "valid":'invalid'} value={form.path} placeholder="Path" onIonChange={e => (handleFormChange(e.detail.value!,'path'))}> </IonInput>
                <div className="error-detail">{errors.path}</div>
            </IonItem>
        </IonRow>
        <IonRow>
            <IonItem>
                <IonInput label="Release Name (without file extension)" labelPlacement="stacked" className={errors.release_name === '' ? "valid":'invalid'} value={form.release_name} placeholder="Release Name" onIonChange={e => (handleFormChange(e.detail.value!,'release_name'))}> </IonInput>
                <div className="error-detail">{errors.release_name}</div>
            </IonItem>

        </IonRow>
        <IonRow>
            <IonItem>
                <IonLabel position="stacked">File</IonLabel>
                {/* <input type="file" className={errors.file === '' ? "valid":'invalid'} value={form.file} placeholder="File" onChange={e => (handleFormChange(e,'file'))}> </input> */}
                <input type="file" accept="application/*" onChange={onFileChange}/>
            </IonItem>
        </IonRow>
        <IonRow>
            <IonItem>
                <IonLabel position="stacked">Release Notes:</IonLabel>
                <div className="editor" id='editor'>
      <Editor
          apiKey={TINYMCE_API_KEY}
          initialValue='<p>Add Notes</p>'
          onInit={(evt, editor) => editorRef.current = editor}
          init={{
         height: '350px',
         toolbar: 'undo redo | formatselect | ' +
         'bold italic backcolor | alignleft aligncenter ' +
         'alignright alignjustify | bullist numlist outdent indent | ' +
         'removeformat',
         menu: {
          template: {title: 'Template', items: "edit-template"}
        },
        menubar: 'file edit view insert format',
         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
       }}
     />

      </div>
            </IonItem>
        </IonRow>

        </form>

        </IonRow>
        <IonRow className="ion-justify-content-center">
            {uploading ? <div><LoadingSpinner/>Uploading</div> : <IonButton onClick={()=>save()}>Submit</IonButton>}
        </IonRow>
      </IonContent>
    </IonModal>
  )
}

export default UploadSoftwareModal;