import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { document } from "ionicons/icons";
import React, { useState } from "react";
import "./OnePageProposal.css";

interface Proposal {
  created: string;
  id: string;
  title: string;
  updated: string;
  url: string;
  user: string;
}

interface Props {
  opened: boolean;
  setOpened: any;
  proposals: Proposal[] | [];
  loadFile: any;
}
const OpenOnePageProposalModal: React.FC<Props> = ({
  opened,
  setOpened,
  proposals,
  loadFile,
}) => {
  const [selected, setSelected] = useState<Proposal>();

  return (
    <IonModal
      id="saveOnePageProposalModal"
      className="modal-background"
      isOpen={opened}
      onDidDismiss={() => {
        setOpened(false);
      }}
    >
      <IonToolbar>
        <IonTitle>Select Proposal To Open</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => setOpened(false)}>close</IonButton>
        </IonButtons>
      </IonToolbar>
      {proposals.length > 0 ? (
        <IonContent className="button-bottom-right">
          <IonRow class="open-proposal-row">
            {proposals.map((proposal: Proposal) => {
              return (
                <IonCard
                  key={proposal.id}
                  onClick={() => setSelected(proposal)}
                  className={proposal.id === selected?.id ? "selected" : ""}
                >
                  <IonItem lines="none">
                    <IonIcon icon={document} />
                  </IonItem>
                  <IonCardContent>{proposal.title}</IonCardContent>
                </IonCard>
              );
            })}
          </IonRow>
          <IonButton
            size="large"
            color="secondary"
            onClick={() => loadFile(selected)}
          >
            Open
          </IonButton>
        </IonContent>
      ) : (
        <IonContent>
          <IonItem>No Saved Proposals Available.</IonItem>
        </IonContent>
      )}
    </IonModal>
  );
};

export default OpenOnePageProposalModal;
