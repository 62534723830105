import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { API_URL } from "../../../actions/settings";

interface Props {
  id: string;
}

async function getData(token: string, id: string) {
  return fetch(`${API_URL}/graphs/three_month_score_trends/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return false;
    }
  });
}

const CustomTooltip = ({ active, payload, label }:any)=>{
  return <div style={{background:'white',border:'black solid 2px', padding:'5px'}}>
        {payload.map((entry:any, index:any) => (<div key={index}>
          <div style={{color:'black'}}>{entry.payload.building}</div>
          <div style={{color:'black'}} >
            Score Trend: {entry.value}
          </div>
          </div>
        ))}
  </div>

}


const ThreeMonthScoreTrendGraph: React.FC<Props> = ({ id }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleData = async () => {
      const token = localStorage.getItem("token") || "";
      const res = await getData(`Token ${token.slice(1, -1)}`, id);
      if (res) {
        setData(res.data);
      } else {
        setError(true);
      }
    };
    handleData();
  }, [id]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      {error ? (
        <div className="center-all">Could Not Load Table</div>
      ) : (
        <BarChart
          width={500}
          height={300}
          data={data}
          // margin={{
          //   top: 5,
          //   right: 30,
          //   left: 20,
          //   bottom: 5,
          // }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fill: "white" }} dataKey="abbreviation"/>
          <YAxis tick={{ fill: "white" }}  width={20}/>
          <Tooltip content={<CustomTooltip />}/>
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="diff" fill="#82ca9d" />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default ThreeMonthScoreTrendGraph;
