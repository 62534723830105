import { IonCol, IonRow } from "@ionic/react";
import React from "react";

interface Props {
  building: any;
  address: String;
}

const GoogleMapMarkerDetail: React.FC<Props> = ({ building, address }) => {
  return (
    <div style={{ height: "100%" }}>
      <IonRow>
        {building.estar_score ? (
          <IonCol size="3">
            <div className="map-estar-score vertical-center">
              <div>
                <div className="score-title">SCORE</div>
                <span>{building.estar_score}</span>
              </div>
            </div>
          </IonCol>
        ) : null}
        <IonCol>
          {/* <h5>{building.building_name}</h5> */}
          <div>{building.building_address}</div>
          <div>
            {building.building_city}, {building.building_state},{" "}
            {building.building_zipcode}
          </div>
        </IonCol>
      </IonRow>
      <ul className="map-detail-list">
        <IonRow>
          <IonCol size="12" sizeMd="8">
            <li className={building.building_incentive ? "check" : "noCheck"}>
              Incentives{" "}
              {building.building_incentive
                ? `- $${Number(building.building_incentive).toLocaleString(
                    "en"
                  )}`
                : null}{" "}
            </li>
            <li className={building.campus_optimizer ? "check" : "noCheck"}>
              Campus Optimizer{" "}
              {building.cumulative_savings
                ? `- Savings: $${Number(
                    building.cumulative_savings
                  ).toLocaleString("en")}`
                : null}{" "}
            </li>
          </IonCol>
          <IonCol size="12" sizeMd="4">
            <li className={building.foa ? "check" : "noCheck"}>FOA</li>
            <li className={building.kpi ? "check" : "noCheck"}>KPI</li>
          </IonCol>
        </IonRow>
      </ul>
    </div>
  );
};

export default GoogleMapMarkerDetail;
