import { IonButton, IonCol, IonItem } from "@ionic/react"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import './EstarMetersList.css'

interface Props{
    meters:any,
    id:string
}



const EstarMetersList:React.FC<Props> = ({meters,id}) => {
    const user = useAppSelector(selectUser)
    const role = user.role.name


    return(
        <IonCol sizeLg="6">
            <h3>Meters:</h3>
            {meters.length === 0 ?
            <div>
                No Meters Added
            </div>:
            <ul>
            {meters.map((item:any)=>{
                return(
                <li key={item.meter.id}>
                    <IonItem className="meter-list-item" href={`/energystar/meters/${item.meter.id}`} routerDirection='forward'>
                        {item.meter.name}
                    </IonItem>
                </li>)
            })}
            </ul>}
            <IonButton hidden={!(role==='Supervisor' || role==='Admin')} color="secondary" href={`/energystar/property/${id}/meter`}>Add Meter</IonButton>
        </IonCol>
    )
}

export default EstarMetersList